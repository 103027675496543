import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {DOMAIN} from "../../../env";

const deletePost = async (
	id,
	history,
	category,
	imagesUploaded,
	setEditorModification,
	jwtToken
) => {
	const loadingMessage = message.loading("Loading...", 0);
	await axios
		.post(
			`${DOMAIN}/api/knowledgeHub/expertArticle/delete/${id}`,
			{
				category: category,
				imageList: imagesUploaded,
			},
			{
				headers: {
					"jwt-token": jwtToken,
				},
			}
		)
		.then((response) => {
			setEditorModification(false);
			history.push("/knowledge-hub");
			message.success({
				content: `Post Deleted!`,
				className: "text-center",
				duration: 2,
			});
			//setModified(!modified)
		})
		.catch((error) => {
			message.error({
				content: `Network Error!`,
				className: "text-center",
				duration: 2,
			});
		})
		.finally(() => {
			loadingMessage();
		});
};

export default function DeleteModal(props) {
	const [confirmDelete, setConfirmDelete] = useState("");
	const history = useHistory();

	const handleOkModal = async () => {
		deletePost(
			props.id,
			history,
			props.category,
			props.imagesUploaded,
			props.setEditorModification,
			props.jwtToken
		);
		props.setIsDeleteModalVisible(false);
	};

	const handleCancelModal = () => {
		props.setIsDeleteModalVisible(false);
	};

	return (
		<>
			<Modal
				zIndex={1000}
				title="Are you absolutely sure?"
				open={props.isDeleteModalVisible}
				onCancel={() => handleCancelModal()}
				footer={[
					<Button
						key="submit"
						type="primary"
						onClick={() => handleOkModal()}
						danger
						disabled={confirmDelete === "confirm" ? false : true}
					>
						Confirm
					</Button>,
				]}
			>
				<div>
					<p className="pb-3 text-center">
						This action cannot be undone. This will permanently delete the
						<span className=" font-bold"> {props.title} </span> post. <br />
						<br />
						Please type <span className=" font-bold"> confirm </span> to
						confirm.
					</p>
					<input
						className="h-12 w-full appearance-none border-b-4 border-green-11 bg-grey-13 py-2 px-3 text-center leading-tight text-gray-700 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-green-11"
						onChange={(e) => setConfirmDelete(e.target.value)}
					/>
				</div>
			</Modal>
		</>
	);
}
