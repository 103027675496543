import React from "react";
import TextareaAutosize from 'react-textarea-autosize';

export default function Recommendations(props) {
    if (props.recommendations.state.length === 0)
        return(
            <>
                <div className="flex justify-center">
                    <p className="font-raleway font-bold">No recommendations</p>
                </div>
                <div className="flex justify-center pt-3">
                    <div
                        className=" bg-green-11 py-3 px-4 rounded-lg cursor-pointer text-center"
                        onClick={e=>{
                            props.recommendations.state.push("Please modify");
                            props.setRecommendations({...props.recommendations, state:[...props.recommendations.state]})
                        }}
                    >
                        <p className="text-white font-raleway font-bold">
                            Add recommendation
                        </p>
                    </div>
                </div>
            </>
        )
    else return(
        <ul className="list-disc ml-5">
            {props.recommendations.state.map((element,index)=>
                <li className="border-b-2 mb-2 border-green-11" key={index + "Act"}>
                    <div className="flex mb-1">
                        <TextareaAutosize
                            className="w-full h-full font-montserrat text-xl appearance-none focus:outline-none resize-none"
                            style={{marginBottom:0}}
                            onChange={ e => {
                                props.recommendations.state[index] = e.target.value;
                                props.setRecommendations({...props.recommendations, state:[...props.recommendations.state]});
                                props.onChange('recommendations', props.recommendations.state, props.pathNumber)
                            }}
                            onBlur={ e => {
                                if(e.target.value.length === 0){
                                    props.recommendations.state.splice(index,1); 
                                    props.setRecommendations({...props.recommendations, state:[...props.recommendations.state]})
                                }
                            }}
                            value={element}
                        />
                        <div 
                            className=" cursor-pointer w-4 h-4 self-end mx-2"
                            onClick={ e => {
                                props.recommendations.state.splice(index,1); 
                                props.setRecommendations({...props.recommendations, state:[...props.recommendations.state]})
                            }}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 fill-current text-green-11 hover:text-red-600" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>                                    
                        </div>
                    </div>
                </li>
            )}
            <div className="flex justify-center pt-3">
                <div
                    className=" bg-green-11 py-3 px-4 rounded-lg cursor-pointer text-center"
                    onClick={e=>{
                        props.recommendations.state.push("Please modify");
                        props.setRecommendations({...props.recommendations, state:[...props.recommendations.state]})
                    }}
                >
                    <p className="text-white font-raleway font-bold">
                        Add recommendation
                    </p>
                </div>
            </div>
        </ul>
    )
}