import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { Prompt } from "react-router";
import UserContext from "../../../context/context";
import { message, Modal } from "antd";
import { useSetState } from "@mantine/hooks";
import SeoInformation from "../../../components/KnowledgeHub/CaseStudy/Seo";

import CaseStudyHero from "../../../components/KnowledgeHub/CaseStudy/Hero";
import CaseStudyTestimonial from "../../../components/KnowledgeHub/CaseStudy/Testimonial";
import Editor from "../../../utils/Editor/Instance";
import CaseStudyTimeline from "../../../components/KnowledgeHub/CaseStudy/Timeline";
import CaseStudyClient from "../../../components/KnowledgeHub/CaseStudy/Client";
import CaseStudyLast from "../../../components/KnowledgeHub/CaseStudy/Last";
import { DOMAIN, STORAGE_DOMAIN } from "../../../env";

export default function NewEditCaseStudy() {
  const history = useHistory();
  const { id } = useParams();
  const user = useContext(UserContext);
  const [current, setCurrent] = useState(0);
  const [title, setTitle] = useState("");

  const [modified, setModified] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState("");

  const [editorInstance, setEditorInstance] = useState();
  const [instanceModification, setInstanceModification] = useState(false);
  const [imagesUploaded, setImagesUploaded] = useState([]);

  const [data, setData] = useSetState(undefined);
  const [content, setContent] = useSetState({});

  useEffect(() => {
    if (!["Developer", "Marketing", "Admin"].includes(user.user.user_role)) {
      message.error({
        content: `Unauthorized!`,
        className: "text-center",
        duration: 2,
      });
      history.push("/");
    }

    axios
      .get(`${DOMAIN}/api/knowledgeHub/caseStudyNew/getById/${id}`, {
        headers: {
          "jwt-token": user.jwtToken,
        },
      })
      .then((response) => {
        const tempData = response.data.data;
        setTitle(tempData.title);
        setData({
          id: tempData._id,
          published: tempData.published,
          lastSaved: new Date(tempData.lastSaved),
          topic: { state: tempData.topic, error: "" },
          link: { state: tempData.link, error: "" },
          hubSpotLink: { state: tempData.hubSpotLink, error: "" },
          postedDate: { state: tempData.postedDate, error: "" },
          description: { state: tempData.description, error: "" },
          title: { state: tempData.title, error: "" },
          cardImage: {
            name: "No file chosen",
            state: null,
            error: "",
            link: tempData.cardImage,
            src: [],
          },
          seoImage: {
            name: "No file chosen",
            state: null,
            error: "",
            link: tempData.seoImage,
            src: [],
          },
        });
        const blocks = tempData.content.content.blocks.map((block) => {
          if (block.type === "image") {
            return {
              ...block,
              data: {
                ...block.data,
                file: {
                  ...block.data.file,
                  url: STORAGE_DOMAIN + block.data.file.url,
                },
              },
            };
          } else if (block.type === "columns") {
            return {
              ...block,
              data: {
                ...block.data,
                cols: block.data.cols.map((col) => {
                  return {
                    ...col,
                    blocks: col.blocks.map((block) => {
                      if (block.type === "image") {
                        return {
                          ...block,
                          data: {
                            ...block.data,
                            file: {
                              ...block.data.file,
                              url: STORAGE_DOMAIN + block.data.file.url,
                            },
                          },
                        };
                      } else return block;
                    }),
                  };
                }),
              },
            };
          } else return block;
        });
        // console.log(blocks)
        setContent({
          hero: tempData.content.hero,
          testimonial: tempData.content.testimonial,
          content: { ...tempData.content.content, blocks },
          timeline: tempData.content.timeline,
          client: tempData.content.client,
          last: {
            numberOfPages: tempData.content.last.numberOfPages,
            image: {
              name: "No file chosen",
              state: null,
              error: false,
              link: tempData.content.last.image,
              src: [],
            },
          },
        });
        setInstanceModification(false);
      });
    // eslint-disable-next-line
  }, [modified]);

  const addError = (name, error) => {
    setData({
      [name]: {
        state: data[name].state,
        error: error,
      },
    });
  };

  const removeError = (name) => {
    setData({
      [name]: {
        state: data[name].state,
        error: "",
      },
    });
  };

  const checkField = (name) => {
    if (!data[name].state) {
      addError(name, "This field can not be empty!");
      return true;
    } else {
      removeError(name);
      return false;
    }
  };

  const validate = () => {
    let error = false;
    error = checkField("topic");
    error = checkField("link");
    error = checkField("hubSpotLink");
    error = checkField("postedDate");
    error = checkField("description");
    error = checkField("title");
    return error;
  };

  const onSave = async () => {
    const outputData = await editorInstance.save();

    if (validate()) return;

    const blocks = outputData.blocks.map((block) => {
      if (block.type === "image") {
        let url = "";
        if (block.data.file.url.includes(STORAGE_DOMAIN))
          url = block.data.file.url.split(STORAGE_DOMAIN)[1];
        else url = block.data.file.url;
        return {
          ...block,
          data: {
            ...block.data,
            file: {
              ...block.data.file,
              url: url,
            },
          },
        };
      } else if (block.type === "columns") {
        return {
          ...block,
          data: {
            ...block.data,
            cols: block.data.cols.map((col) => {
              return {
                ...col,
                blocks: col.blocks.map((block) => {
                  if (block.type === "image") {
                    let url = "";
                    if (block.data.file.url.includes(STORAGE_DOMAIN))
                      url = block.data.file.url.split(STORAGE_DOMAIN)[1];
                    return {
                      ...block,
                      data: {
                        ...block.data,
                        file: {
                          ...block.data.file,
                          url,
                        },
                      },
                    };
                  } else return block;
                }),
              };
            }),
          },
        };
      } else return block;
    });
    console.log(blocks);
    // const loadingMessage = message.loading("Loading...", 0);
    // const formData = new FormData();
    // formData.append("topic", data.topic.state);
    // formData.append("link", data.link.state);
    // formData.append("hubSpotLink", data.hubSpotLink.state);
    // formData.append("postedDate", data.postedDate.state);
    // formData.append("description", data.description.state);
    // formData.append("title", data.title.state);
    // if (data.seoImage.state) formData.append("seoImage", data.seoImage.state);
    // if (data.cardImage.state)
    //   formData.append("cardImage", data.cardImage.state);
    // if (content.last.image.state)
    //   formData.append("pageImage", content.last.image.state);
    // formData.append("sectionHero", JSON.stringify(content.hero));
    // formData.append("sectionTestimonial", JSON.stringify(content.testimonial));
    // formData.append("sectionClient", JSON.stringify(content.client));
    // formData.append("sectionTimeline", JSON.stringify(content.timeline));
    // formData.append("sectionLast", content.last.numberOfPages);
    // formData.append(
    //   "sectionContent",
    //   JSON.stringify({ ...outputData, blocks })
    // );

    // await axios
    //   .post(`${DOMAIN}/api/knowledgeHub/caseStudyNew/edit/${id}`, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       "jwt-token": user.jwtToken,
    //     },
    //   })
    //   .then((response) => {
    //     message.success({
    //       content: `Case Study Saved!`,
    //       className: "text-center",
    //       duration: 2,
    //     });
    //     setInstanceModification(false);
    //     setModified(!modified);
    //   })
    //   .catch((error) => {
    //     message.error({
    //       content: `Error`,
    //       className: "text-center",
    //       duration: 2,
    //     });
    //   })
    //   .finally(() => {
    //     loadingMessage();
    //   });
  };

  const handlePublishStatus = async () => {
    await axios
      .post(
        `${DOMAIN}/api/knowledgeHub/caseStudyNew/togglePublishStatus/${id}`,
        {
          published: !data.published,
        },
        {
          headers: {
            "jwt-token": user.jwtToken,
          },
        }
      )
      .then((response) => {
        message.success({
          content: `Case Study ${
            !data.published ? "Published" : "Unpublished"
          }!`,
          className: "text-center",
          duration: 2,
        });
        setModified(!modified);
      })
      .catch((error) => {
        message.error({
          content: `${error.response.data.error}`,
          className: "text-center",
          duration: 2,
        });
      });
  };

  const handleDelete = async () => {
    await axios
      .delete(`${DOMAIN}/api/knowledgeHub/caseStudyNew/delete/${id}`, {
        headers: {
          "jwt-token": user.jwtToken,
        },
      })
      .then((response) => {
        message.success({
          content: `Case Study Deleted!`,
          className: "text-center",
          duration: 2,
        });
        setInstanceModification(false);
        setDeleteModal(false);
        history.push(`/knowledge-hub`);
      })
      .catch((error) => {
        message.error({
          content: `${error.response.data.error}`,
          className: "text-center",
          duration: 2,
        });
      });
  };

  if (data === undefined)
    return (
      <div className="relative h-full">
        <div className=" absolute w-full">
          <div className="flex flex-col items-center justify-between gap-5 bg-grey-1 p-5 lg:flex-row">
            <div className="h-7 w-48 animate-pulse rounded-full bg-stone-600"></div>
          </div>
        </div>
        <div className=" flex h-full items-center justify-center">
          <div className="flex flex-col items-center justify-center gap-5">
            <div className="h-40 w-40 animate-spin rounded-full border-b-4 border-t-4 border-green-11"></div>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <Prompt
        when={instanceModification}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Modal
        open={deleteModal}
        title="Are you absolutely sure?"
        onCancel={() => setDeleteModal(false)}
        afterClose={() => setConfirmDelete("")}
        closeIcon={
          <svg
            viewBox="0 0 24 24"
            className="h-6 w-6 fill-current text-grey-1 transition-all duration-300 hover:rotate-180"
          >
            <path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" />
          </svg>
        }
        footer={[
          <button
            className={`${
              confirmDelete === "confirm"
                ? "cursor-pointer lg:hover:px-10"
                : "cursor-not-allowed opacity-50"
            } group mx-auto flex items-center justify-center gap-2 rounded-2xl bg-red-600 px-5 py-2 shadow-lg transition-all duration-500`}
            onClick={() => handleDelete()}
            disabled={confirmDelete === "confirm" ? false : true}
          >
            <p className="font-montserrat font-bold uppercase text-white">
              confirm
            </p>
          </button>,
        ]}
      >
        <div>
          <p className="pb-3 text-center">
            This action cannot be undone. This will permanently delete the
            <span className=" font-bold"> {title} </span> post. <br />
            <br />
            Please type <span className=" font-bold"> confirm </span> to
            confirm.
          </p>
          <input
            value={confirmDelete}
            onChange={(e) => setConfirmDelete(e.target.value)}
            className="peer h-12 w-full appearance-none rounded-xl border-2 px-3 py-2 text-center font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus:border-green-11 focus:outline-none"
          />
        </div>
      </Modal>
      <div className="flex flex-col items-center justify-between gap-5 bg-grey-1 p-5 lg:flex-row">
        <p className=" mb-0 truncate whitespace-pre-wrap text-center font-poppins text-lg font-bold text-white lg:text-left">
          {title}
        </p>
      </div>
      <div className=" sticky top-16 z-10 flex flex-row flex-wrap items-center justify-center gap-5 border-b-2 bg-grey-4 p-3 md:top-0 md:justify-between">
        <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
          <div
            onClick={() => handlePublishStatus()}
            className={`${
              data.published ? "bg-green-11" : "bg-orange-1"
            } relative flex w-28 cursor-pointer items-center justify-center rounded-2xl px-6 py-2 shadow-lg transition-all duration-300`}
          >
            <p className="font-montserrat font-bold uppercase text-white">
              {data.published ? "Active" : "draft"}
            </p>
          </div>
          <p className=" hidden font-raleway md:block">
            Modified{" "}
            {Math.ceil(
              Math.abs(new Date(data.lastSaved) - new Date()) /
                (1000 * 60 * 60 * 24)
            )}{" "}
            days ago
          </p>
        </div>
        <div className="flex justify-center gap-4">
          <div>
            <button
              type="button"
              onClick={() => onSave()}
              className={`flex cursor-pointer items-center justify-center gap-2 rounded-2xl border-2 border-green-11 bg-green-11 px-5 py-2 shadow-lg transition-all duration-500`}
            >
              <svg
                viewBox="0 0 24 24"
                className=" h-4 w-4 fill-current text-white"
              >
                <path d="M12,10a4,4,0,1,0,4,4A4,4,0,0,0,12,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,16Z" />
                <path d="M22.536,4.122,19.878,1.464A4.966,4.966,0,0,0,16.343,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7.657A4.966,4.966,0,0,0,22.536,4.122ZM17,2.08V3a3,3,0,0,1-3,3H10A3,3,0,0,1,7,3V2h9.343A2.953,2.953,0,0,1,17,2.08ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2V3a5.006,5.006,0,0,0,5,5h4a4.991,4.991,0,0,0,4.962-4.624l2.16,2.16A3.02,3.02,0,0,1,22,7.657Z" />
              </svg>
              <p className=" bg-grey1 font-montserrat font-bold uppercase text-white">
                Save
              </p>
            </button>
          </div>
          <button
            type="button"
            onClick={() => setDeleteModal(true)}
            className="group flex cursor-pointer items-center justify-center gap-2 rounded-2xl border-2 border-red-600 px-5 py-2 shadow-lg transition-all duration-500 hover:bg-red-600"
          >
            <svg
              className=" h-4 w-4 fill-current text-red-600 transition-all duration-500 group-hover:text-white"
              viewBox="0 0 24 24"
            >
              <path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
              <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
              <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
            </svg>

            <p className=" font-montserrat font-bold uppercase text-red-600 transition-all duration-500 group-hover:text-white">
              Delete
            </p>
          </button>
        </div>
      </div>
      <div className="px-3 py-5">
        <div className="mx-auto flex max-w-5xl flex-col gap-5 md:flex-row">
          <div
            onClick={() => setCurrent(0)}
            className={`${
              current === 0
                ? "bg-green-11 text-white"
                : "bg-white text-green-11 hover:bg-green-11 hover:text-white"
            } flex w-full cursor-pointer flex-row items-center justify-center gap-4 rounded-xl border-2 border-green-11 px-6 py-3 shadow transition-colors duration-500`}
          >
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <g id="_01_align_center" data-name="01 align center">
                <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm8.647,7H17.426a19.676,19.676,0,0,0-2.821-4.644A10.031,10.031,0,0,1,20.647,7ZM16.5,12a10.211,10.211,0,0,1-.476,3H7.976A10.211,10.211,0,0,1,7.5,12a10.211,10.211,0,0,1,.476-3h8.048A10.211,10.211,0,0,1,16.5,12ZM8.778,17h6.444A19.614,19.614,0,0,1,12,21.588,19.57,19.57,0,0,1,8.778,17Zm0-10A19.614,19.614,0,0,1,12,2.412,19.57,19.57,0,0,1,15.222,7ZM9.4,2.356A19.676,19.676,0,0,0,6.574,7H3.353A10.031,10.031,0,0,1,9.4,2.356ZM2.461,9H5.9a12.016,12.016,0,0,0-.4,3,12.016,12.016,0,0,0,.4,3H2.461a9.992,9.992,0,0,1,0-6Zm.892,8H6.574A19.676,19.676,0,0,0,9.4,21.644,10.031,10.031,0,0,1,3.353,17Zm11.252,4.644A19.676,19.676,0,0,0,17.426,17h3.221A10.031,10.031,0,0,1,14.605,21.644ZM21.539,15H18.1a12.016,12.016,0,0,0,.4-3,12.016,12.016,0,0,0-.4-3h3.437a9.992,9.992,0,0,1,0,6Z" />
              </g>
            </svg>
            <p className="font-poppins text-xl font-bold uppercase">seo</p>
          </div>
          <div
            onClick={() => setCurrent(1)}
            className={`${
              current === 1
                ? "bg-green-11 text-white"
                : "bg-white text-green-11 hover:bg-green-11 hover:text-white"
            } flex w-full cursor-pointer flex-row items-center justify-center gap-4 rounded-xl border-2 border-green-11 px-6 py-3 shadow transition-colors duration-500`}
          >
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path d="m19 1h-14a5.006 5.006 0 0 0 -5 5v12a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5v-12a5.006 5.006 0 0 0 -5-5zm-14 2h14a3 3 0 0 1 3 3v1h-20v-1a3 3 0 0 1 3-3zm14 18h-14a3 3 0 0 1 -3-3v-9h20v9a3 3 0 0 1 -3 3zm0-8a1 1 0 0 1 -1 1h-12a1 1 0 0 1 0-2h12a1 1 0 0 1 1 1zm-4 4a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-12-12a1 1 0 1 1 1 1 1 1 0 0 1 -1-1zm3 0a1 1 0 1 1 1 1 1 1 0 0 1 -1-1zm3 0a1 1 0 1 1 1 1 1 1 0 0 1 -1-1z" />
            </svg>
            <p className="font-poppins text-xl font-bold uppercase">content</p>
          </div>
        </div>
        <div
          className={`${
            current === 0 ? "block" : "hidden"
          } mx-auto max-w-5xl py-5`}
        >
          <div className=" pb-10 text-center font-poppins text-3xl font-bold text-grey-1 md:text-4xl">
            <span className="text-green-11">SEO</span> Information
          </div>
          <SeoInformation
            id={data.id}
            user={user}
            data={data}
            setData={setData}
          />
        </div>
        <div className={`${current === 1 ? "block" : "hidden"} py-5`}>
          <div className=" pb-10 text-center font-poppins text-3xl font-bold text-grey-1 lg:text-4xl">
            <span className="text-green-11">Case Study</span> Content
          </div>
          <CaseStudyHero data={content.hero} setData={setContent} />
          <div className=" mx-auto flex w-full max-w-[1000px] flex-col gap-14 pt-14">
            <CaseStudyTestimonial
              data={content.testimonial}
              setData={setContent}
            />
            <Editor
              bodyData={content.content}
              setEditorInstance={setEditorInstance}
              imagesUploaded={imagesUploaded}
              setImagesUploaded={setImagesUploaded}
              setEditorModification={setInstanceModification}
            />
            <CaseStudyTimeline data={content.timeline} setData={setContent} />
            <CaseStudyClient data={content.client} setData={setContent} />
            <CaseStudyLast
              id={data.id}
              data={content.last}
              setData={setContent}
              link={data.hubSpotLink.state}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
