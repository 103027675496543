import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import PingPongGamesHistory from "../../components/PingPongComponents/gamesHistory";
import Leaderboard from "../../components/PingPongComponents/Leaderboard";

export default function PingPong() {
	return (
		<div>
			<Helmet>
				<title>Logarithmic Tournament</title>
			</Helmet>
			<div className="flex flex-col lg:flex-row justify-between items-center bg-grey-1 px-4 py-5">
				<p className=" font-bold font-poppins text-xl md:text-2xl text-white text-center lg:text-left mb-0">
					Logarithmic Tournament
				</p>
			</div>
			<div className="p-4 flex flex-col lg:flex-row gap-10">
				<Leaderboard />
				<PingPongGamesHistory />
			</div>
			<div className=" fixed bottom-8 right-5 lg:right-8 z-10 flex rounded-full shadow-xl">
				<Link
					to={"/ping-pong/new-match"}
					className="bg-green-11 w-full rounded-full p-6 cursor-pointer transition-all duration-500 group"
				>
					<svg
						viewBox="0 0 24 24"
						className=" fill-current text-white w-8 h-8 group-hover:animate-pulse"
					>
						<path d="M20.463,7.713l-9.1-6.677A5.317,5.317,0,0,0,2.9,5.323V18.677a5.311,5.311,0,0,0,8.46,4.287l9.105-6.677a5.315,5.315,0,0,0,0-8.574Zm-1.774,6.155-9.1,6.677A2.317,2.317,0,0,1,5.9,18.677V5.323a2.276,2.276,0,0,1,1.27-2.066A2.328,2.328,0,0,1,8.223,3a2.3,2.3,0,0,1,1.362.455l9.1,6.677a2.316,2.316,0,0,1,0,3.736Z" />
					</svg>
				</Link>
			</div>
		</div>
	);
}
