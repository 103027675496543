import { useEffect, useContext, useState } from "react";
import UserContext from "../../context/context";
import axios from "axios";
import IndividualGame from "./individualGameHistory";
import {DOMAIN} from "../../env";
export default function PingPongGamesHistory() {
	const user = useContext(UserContext);
	const [data, setData] = useState(undefined);

	useEffect(() => {
		axios
			.get(`${DOMAIN}/api/pingpong/getAllGames`, {
				headers: {
					"jwt-token": user.jwtToken,
				},
			})
			.then((results) => {
				setData(results.data.games.splice(0, 12));
			})
			.catch((error) => {
				console.log(error);
			});
		// eslint-disable-next-line
	}, []);

	return (
		<div className="w-full flex flex-col items-center pb-20">
			<div className="flex justify-center md:justify-start pb-5">
				<p className="text-grey-1 font-poppins font-bold text-4xl text-center">
					Recent Games
				</p>
			</div>
			<div className="w-full flex flex-row flex-wrap justify-center gap-3">
				{data && data.length === 0 ? (
					<p className=" font-montserrat text-grey-1 text-3xl">
						No games ... yet
					</p>
				) : null}
				{data &&
					data.map((elem, index) => {
						return <IndividualGame data={elem} key={`game-${index}`} />;
					})}
			</div>
		</div>
	);
}
