import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import UserContext from "../../context/context";
import { Modal, Button, Select, message } from "antd";
import {DOMAIN} from "../../env";

export default function AddUserModal({ setUpdateData, updateData }) {

    const search = useLocation().search;

    const user = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const { Option } = Select;

    const [userName, setUserName] = useState({ state: "", error: "" });
    const [userEmail, setUserEmail] = useState({ state: "", error: "" });
    const [userRole, setUserRole] = useState({ state: "", error: "" });
    const [userPassword, setUserPassword] = useState({ state: "", error: "", });
    const [showPassword, setShowPassword] = useState(false);

    const handleGeneratePassword = () => {
        const characterList =
            "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!'^+%&/()=?_#$[]|;:>÷`<.*-@";

        let password = "";
        const characterListLength = characterList.length;

        for (let i = 0; i < 25; i++) {
            const characterIndex = Math.round(Math.random() * characterListLength);
            password = password + characterList.charAt(characterIndex);
        }
        setUserPassword({ ...userPassword, state: password });
        setShowPassword(true);
    };

    const inputChecker = () => {
        let formIsValid = true;
        let re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!userEmail.state) {
            formIsValid = false;
            setUserEmail({ ...userEmail, error: "This field can not be empty!" });
        } else if (!re.test(userEmail.state)) {
            formIsValid = false;
            setUserEmail({
                ...userEmail,
                error: "Please enter a valid email address.",
            });
        } else if (!/@logdms.com\s*$/.test(userEmail.state)) {
            formIsValid = false;
            setUserEmail({ ...userEmail, error: "Incorrect email type." });
        } else {
            setUserEmail({ ...userEmail, error: "" });
        }
        if (!userName.state) {
            formIsValid = false;
            setUserName({ ...userName, error: "This field can not be empty!" });
        } else {
            setUserName({ ...userName, error: "" });
        }
        if (!userPassword.state) {
            formIsValid = false;
            setUserPassword({
                ...userPassword,
                error: "This field can not be empty!",
            });
        } else {
            setUserPassword({ ...userPassword, error: "" });
        }
        if (!userRole.state) {
            formIsValid = false;
            setUserRole({ ...userRole, error: "Please select a value!" });
        } else {
            setUserRole({ ...userRole, error: "" });
        }

        return formIsValid;
    };

    const handleSubmit = async () => {
        if (inputChecker()) {
            const loadingMessage = message.loading("Loading...", 0);
            await axios
                .post(
                    `${DOMAIN}/api/user/register`,
                    {
                        user_email: userEmail.state,
                        user_name: userName.state,
                        user_password: userPassword.state,
                        user_role: userRole.state,
                    },
                    {
                        headers: {
                            "jwt-token": user.jwtToken,
                        },
                    }
                )
                .then((response) => {
                    if (response.data.success === false) {
                        message.error({
                            content: "Error!",
                            className: "text-center",
                            duration: 2,
                        });
                    } else {
                        setUpdateData(!updateData)
                        handleClose()
                        message.success({
                            content: `User Registered!`,
                            className: "text-center",
                            duration: 2,
                        });
                    }
                })
                .catch((error) => {
                    message.error({
                        content: `Network Error!`,
                        className: "text-center",
                        duration: 2,
                    });
                })
                .finally(() => {
                    loadingMessage();
                });
        } else {
            message.error({
                content: `Please check all fields!`,
                className: "text-center",
                duration: 2,
            });
        }
    }

    function handleClose() {
        setUserName({ state: "", error: "" });
        setUserEmail({ state: "", error: "" });
        setUserRole({ state: "", error: "" });
        setUserPassword({ state: "", error: "" });
        setShowPassword(false)
        setOpen(false);
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        if (Boolean(searchParams.get("modal"))) {
            setOpen(true)
        }
    }, [search])

    return (
        <>
            <button onClick={() => setOpen(true)} className="flex h-12 w-full min-w-[10rem] cursor-pointer items-center justify-center gap-2 rounded-2xl bg-grey-1 px-6 shadow-lg transition-all duration-300 hover:bg-green-11 md:w-40" >
                <svg className=" h-5 w-5 fill-current text-white" viewBox="0 0 24 24" > <path d="M23,11H21V9a1,1,0,0,0-2,0v2H17a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V13h2a1,1,0,0,0,0-2Z" /> <path d="M9,12A6,6,0,1,0,3,6,6.006,6.006,0,0,0,9,12ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z" /> <path d="M9,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,9,14Z" /> </svg>
                <p className=" font-poppins text-base font-bold uppercase text-white">
                    Add User
                </p>
            </button>
            <Modal
                zIndex={1000}
                title={`Add new user`}
                open={open}
                width={700}
                onCancel={() => handleClose()}
                footer={[
                    <Button key="back" onClick={() => handleClose()}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => handleSubmit()}>
                        Confirm
                    </Button>,
                ]}
            >
                <div className="grid grid-cols-2 col-span-4 lg:col-span-3 gap-5">
                    <div className="col-span-2 md:col-span-1">
                        <div className="relative w-full group">
                            <input
                                id="username"
                                type="text"
                                name="username"
                                placeholder="username"
                                value={userName.state}
                                onChange={(e) =>
                                    setUserName({ ...userName, state: e.target.value })
                                }
                                className={`${userName.error?.length !== 0
                                    ? "border-orange-1 focus:ring-orange-1"
                                    : ""
                                    } peer h-12 font-raleway appearance-none bg-white w-full py-2 px-3 border-2 rounded-xl text-grey-1 text-lg leading-tight focus:outline-none focus:ring-0 focus:border-green-11 placeholder-transparent`}
                            />
                            <label
                                htmlFor="username"
                                className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg pointer-events-none"
                            >
                                Name
                            </label>
                            <div className="absolute">
                                <p className="font-raleway text-sm text-orange-1 m-0">
                                    {userName.error}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                        <div className="relative w-full">
                            <input
                                type="email"
                                className={`${userEmail.error?.length !== 0
                                    ? "border-orange-1 focus:ring-orange-1"
                                    : ""
                                    } peer h-12 font-raleway appearance-none bg-white w-full py-2 px-3 border-2 rounded-xl text-grey-1 text-lg leading-tight focus:outline-none focus:ring-0 focus:border-green-11 placeholder-transparent`}
                                placeholder="Email address"
                                onChange={(e) =>
                                    setUserEmail({ ...userEmail, state: e.target.value })
                                }
                            />
                            <label
                                htmlFor="email"
                                className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg pointer-events-none"
                            >
                                Email
                            </label>
                            <div className="absolute">
                                <p className="font-raleway text-sm text-orange-1 m-0">
                                    {userEmail.error}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div className="relative w-full">
                            <Select
                                id="role"
                                showSearch
                                placement="bottomLeft"
                                className={`peer h-12 font-raleway appearance-none w-full py-2 border-2 rounded-xl text-lg leading-tight focus:outline-none focus:border-transparent placeholder-transparent cursor-default focus:border-green-11 ${userRole.error?.length !== 0
                                    ? "border-orange-1 focus:ring-orange-1"
                                    : ""
                                    }`}
                                value={userRole.state || undefined}
                                placeholder=""
                                getPopupContainer={(trigger) => trigger.parentElement}
                                onChange={(value) => setUserRole({ ...userRole, state: value })}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="HumanResources"> Human Resources </Option>
                                <Option value="Marketing"> Marketing </Option>
                                <Option value="Developer"> Developer </Option>
                                <Option value="User"> User </Option>
                            </Select>
                            <label
                                htmlFor="role"
                                className={`${userRole.state.length
                                    ? "-top-2 text-sm"
                                    : "top-[0.65rem] text-lg"
                                    } transform transition-all duration-500 absolute left-3 px-1 font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus-within:-top-2 peer-focus-within:text-sm peer-placeholder-shown:text-lg pointer-events-none`}
                            >
                                Role
                            </label>
                            <div className="absolute">
                                <p className="font-raleway text-sm text-orange-1 m-0">
                                    {userRole.error}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div className="w-full flex flex-row gap-2">
                            <div className="relative w-full h-full">
                                <div className="flex flex-row gap-2">
                                    <div className="relative w-full">
                                        <input
                                            name="password"
                                            id="password"
                                            className={`peer h-12 font-raleway appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none focus:ring-0 placeholder-transparent focus:border-green-11 ${userPassword.error?.length !== 0
                                                ? "border-orange-1 focus:ring-orange-1"
                                                : ""
                                                }`}
                                            placeholder="User Password"
                                            value={userPassword.state}
                                            type={showPassword ? "text" : "password"}
                                            onChange={(e) =>
                                                setUserPassword({
                                                    ...userPassword,
                                                    state: e.target.value,
                                                })
                                            }
                                        />
                                        <label
                                            htmlFor="password"
                                            className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg pointer-events-none"
                                        >
                                            Password
                                        </label>
                                        <div
                                            className="absolute top-1/4 right-5 cursor-pointer group"
                                            onClick={() =>
                                                setShowPassword((showPassword) => !showPassword)
                                            }
                                        >
                                            {showPassword ? (
                                                <svg
                                                    className=" fill-current text-grey-1 w-6 h-6 group-hover:text-green-11 transition-all duration-500"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M17,8H7V7a5,5,0,0,1,9.375-2.422,1,1,0,0,0,1.749-.971A7,7,0,0,0,5,7V8.424A5,5,0,0,0,2,13v6a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V13A5.006,5.006,0,0,0,17,8Zm3,11a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V13a3,3,0,0,1,3-3H17a3,3,0,0,1,3,3Z" />
                                                    <path d="M12,14a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V15A1,1,0,0,0,12,14Z" />
                                                </svg>
                                            ) : (
                                                <svg
                                                    className=" fill-current text-grey-1 w-6 h-6 group-hover:text-green-11 transition-all duration-500"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M19,8.424V7A7,7,0,0,0,5,7V8.424A5,5,0,0,0,2,13v6a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V13A5,5,0,0,0,19,8.424ZM7,7A5,5,0,0,1,17,7V8H7ZM20,19a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V13a3,3,0,0,1,3-3H17a3,3,0,0,1,3,3Z" />
                                                    <path d="M12,14a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V15A1,1,0,0,0,12,14Z" />
                                                </svg>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <div
                                            className="cursor-pointer transition-all hover:rotate-180 duration-500 group"
                                            onClick={() => handleGeneratePassword()}
                                        >
                                            <svg
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 512.016 512.016"
                                                className=" fill-current text-grey-1 w-6 h-6 group-hover:text-green-11 transition-all duration-500"
                                            >
                                                <g>
                                                    <path d="M256.008,42.675c57.16,0.188,111.87,23.233,151.936,64h-66.603c-11.782,0-21.333,9.551-21.333,21.333   s9.551,21.333,21.333,21.333h88.384c21.874-0.012,39.604-17.742,39.616-39.616V21.341c0-11.782-9.551-21.333-21.333-21.333   s-21.333,9.551-21.333,21.333v44.331C321.782-28.795,160.169-20.343,65.702,84.55C28.733,125.6,6.058,177.523,1.075,232.541   c-1.096,11.814,7.593,22.279,19.407,23.375c0.64,0.059,1.283,0.09,1.927,0.092c10.813,0.138,19.954-7.977,21.099-18.731   C53.35,127.213,145.505,42.82,256.008,42.675z" />
                                                    <path d="M489.629,256.008c-10.813-0.138-19.954,7.977-21.099,18.731C458.35,391.953,355.076,478.721,237.861,468.54   c-50.715-4.405-98.176-26.825-133.789-63.199h66.603c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333   H82.291c-21.868-0.012-39.604,17.706-39.616,39.573c0,0.014,0,0.028,0,0.043v88.384c0,11.782,9.551,21.333,21.333,21.333   s21.333-9.551,21.333-21.333v-44.331c104.893,94.467,266.505,86.015,360.972-18.878c36.97-41.05,59.645-92.973,64.628-147.992   c1.096-11.814-7.593-22.279-19.407-23.375C490.901,256.041,490.265,256.01,489.629,256.008z" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute">
                                    <p className="font-raleway text-sm text-orange-1 m-0">
                                        {userPassword.error}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}