import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Modal, Popconfirm, message } from "antd";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import UserContext from "../../context/context";
import CareerLayout from "../../components/CareerPagesComponents/Layout";
import {DOMAIN} from "../../env";

function EditCareerPosts() {
	const [data, setData] = useState();
	const user = useContext(UserContext);
	const { id } = useParams();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState("");
	const history = useHistory();

	const [jobDescription1, setJobDescription1] = useState({
		state: "",
		error: "",
	});
	const [jobTitle, setJobTitle] = useState({ state: "", error: "" });
	const [jobLocation, setJobLocation] = useState({ state: "", error: "" });
	const [jobLinkBambooHR, setJobLinkBambooHR] = useState({
		state: "",
		error: "",
	});
	const [modified, setModified] = useState(false);

	function confirm(e) {
		const loadingMessage = message.loading("Loading...", 0);
		axios
			.post(
				`${DOMAIN}/api/careers/editCareerPost/save`,
				{
					id: id,
					jobDescription1: jobDescription1.state,
					jobTitle: jobTitle.state,
					jobLocation: jobLocation.state,
					jobLinkBambooHR: jobLinkBambooHR.state,
					published: data?.jobPost.published,
				},
				{
					headers: {
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					setModified(!modified);
					message.success({
						content: "Post Saved!",
						className: "text-center",
						duration: 2,
					});
				}
			})
			.catch(function (error) {
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			})
			.finally(() => {
				loadingMessage();
			});
	}

	const togglePublished = () => {
		const checkField = () => {
			let valid = true;
			if (!jobDescription1.state) {
				setJobDescription1({
					...jobDescription1,
					error: "This field can not be empty!",
				});
				valid = false;
			} else {
				setJobDescription1({ ...jobDescription1, error: "" });
			}
			if (!jobTitle.state) {
				setJobTitle({ ...jobTitle, error: "This field can not be empty!" });
				valid = false;
			} else {
				setJobTitle({ ...jobTitle, error: "" });
			}
			if (!jobLocation.state) {
				setJobLocation({
					...jobLocation,
					error: "This field can not be empty!",
				});
				valid = false;
			} else {
				setJobLocation({ ...jobLocation, error: "" });
			}
			if (!jobLinkBambooHR.state) {
				setJobLinkBambooHR({
					...jobLinkBambooHR,
					error: "This field can not be empty!",
				});
				valid = false;
			} else {
				setJobLinkBambooHR({ ...jobLinkBambooHR, error: "" });
			}
			return valid;
		};
		if (checkField) {
			const loadingMessage = message.loading("Loading...", 0);
			axios
				.post(
					`${DOMAIN}/api/careers/editCareerPost/publish`,
					{
						id: id,
						jobDescription1: jobDescription1.state,
						jobTitle: jobTitle.state,
						jobLocation: jobLocation.state,
						jobLinkBambooHR: jobLinkBambooHR.state,
						published: !data?.jobPost.published,
					},
					{
						headers: {
							"jwt-token": user.jwtToken,
						},
					}
				)
				.then(function (response) {
					if (response.status === 200) {
						setModified(!modified);
						message.success({
							content: `Post ${
								!data?.jobPost.published ? "published" : "unpublished"
							}!`,
							className: "text-center",
							duration: 2,
						});
					}
				})
				.catch(function (error) {
					console.log(error);
					message.error({
						content: `Network Error!`,
						className: "text-center",
						duration: 2,
					});
				})
				.finally(() => {
					loadingMessage();
				});
		} else
			message.error({
				content: "Error check fields!",
				className: "text-center",
				duration: 2,
			});
	};

	const deletePost = () => {
		const loadingMessage = message.loading("Loading...", 0);
		axios
			.delete(
				`${DOMAIN}/api/careers/editCareerPost/delete/${id}`,
				{
					headers: {
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					setIsModalVisible(false);
					history.push("/careerPosts");
					message.success({
						content: `Post deleted!`,
						className: "text-center",
						duration: 2,
					});
				}
			})
			.catch(function (error) {
				console.log(error);
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			})
			.finally(() => {
				loadingMessage();
			});
	};

	useEffect(() => {
		const fetchApiData = async () => {
			try {
				const response = await axios.get(
					`${DOMAIN}/api/careers/getJobPostById/${id}`
				);
				setData(response.data);
				setJobDescription1({
					...jobDescription1,
					state: response.data?.jobPost.jobDescription1,
				});
				setJobTitle({ ...jobTitle, state: response.data?.jobPost.jobTitle });
				setJobLinkBambooHR({
					...jobLinkBambooHR,
					state: response.data?.jobPost.jobLinkBambooHR,
				});
				setJobLocation({
					...jobLocation,
					state: response.data?.jobPost.jobLocation,
				});
			} catch (error) {
				history.push("/");
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			}
		};
		fetchApiData();
		// eslint-disable-next-line
	}, [modified, id]);

	if (data === undefined)
		return (
			<div className="flex h-screen items-center justify-center bg-white">
				<div className="flex flex-col items-center justify-center gap-5">
					<div className="h-40 w-40 animate-spin rounded-full border-t-4 border-b-4 border-green-11"></div>
				</div>
			</div>
		);

	return (
		<CareerLayout>
			<div className=" flex w-full flex-wrap justify-center gap-4 border-b-2 bg-grey-4 p-4 md:justify-between">
				<div
					onClick={(e) => togglePublished()}
					className={`${
						data?.jobPost.published ? "bg-green-11" : "bg-orange-1"
					} relative flex w-28 cursor-pointer items-center justify-center rounded-2xl px-6 py-2 shadow-lg transition-all duration-500`}
				>
					<p className="font-montserrat font-bold uppercase text-white">
						{data?.jobPost.published ? "Active" : "draft"}
					</p>
				</div>
				<div className="flex justify-center gap-4">
					<div>
						{data?.jobPost.published ? (
							<Popconfirm
								title={
									<p className="w-full">
										Are you sure you want to save? <br /> This post is already
										published and the changes will me made live if you save
									</p>
								}
								placement="bottomLeft"
								onConfirm={() => confirm()}
								okText="Yes"
								cancelText="No"
								className={`flex cursor-pointer items-center gap-2 rounded-2xl border-2 border-green-11 bg-green-11 px-5 py-2 shadow-lg transition-all duration-500`}
							>
								<svg
									viewBox="0 0 24 24"
									className=" h-4 w-4 fill-current text-white"
								>
									<path d="M12,10a4,4,0,1,0,4,4A4,4,0,0,0,12,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,16Z" />
									<path d="M22.536,4.122,19.878,1.464A4.966,4.966,0,0,0,16.343,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7.657A4.966,4.966,0,0,0,22.536,4.122ZM17,2.08V3a3,3,0,0,1-3,3H10A3,3,0,0,1,7,3V2h9.343A2.953,2.953,0,0,1,17,2.08ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2V3a5.006,5.006,0,0,0,5,5h4a4.991,4.991,0,0,0,4.962-4.624l2.16,2.16A3.02,3.02,0,0,1,22,7.657Z" />
								</svg>
								<p className=" font-montserrat font-bold uppercase text-white">
									Save
								</p>
							</Popconfirm>
						) : (
							<div
								onClick={(e) => confirm()}
								className={`flex cursor-pointer items-center gap-2 rounded-2xl border-2 border-green-11 bg-green-11 px-5 py-2 shadow-lg transition-all duration-500`}
							>
								<svg
									viewBox="0 0 24 24"
									className=" h-4 w-4 fill-current text-white"
								>
									<path d="M12,10a4,4,0,1,0,4,4A4,4,0,0,0,12,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,16Z" />
									<path d="M22.536,4.122,19.878,1.464A4.966,4.966,0,0,0,16.343,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7.657A4.966,4.966,0,0,0,22.536,4.122ZM17,2.08V3a3,3,0,0,1-3,3H10A3,3,0,0,1,7,3V2h9.343A2.953,2.953,0,0,1,17,2.08ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2V3a5.006,5.006,0,0,0,5,5h4a4.991,4.991,0,0,0,4.962-4.624l2.16,2.16A3.02,3.02,0,0,1,22,7.657Z" />
								</svg>
								<p className=" font-montserrat font-bold uppercase text-white">
									Save
								</p>
							</div>
						)}
					</div>
					<div
						onClick={() => setIsModalVisible(true)}
						className="group flex cursor-pointer items-center gap-2 rounded-2xl border-2 border-red-600 px-5 py-2 shadow-lg transition-all duration-500 hover:bg-red-600"
					>
						<svg
							className=" h-4 w-4 fill-current text-red-600 transition-all duration-500 group-hover:text-white"
							viewBox="0 0 24 24"
						>
							<path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
							<path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
							<path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
						</svg>

						<p className=" font-montserrat font-bold uppercase text-red-600 transition-all duration-500 group-hover:text-white">
							Delete
						</p>
					</div>
				</div>
			</div>
			<Modal
				zIndex={1000}
				title="Are you absolutely sure?"
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
				footer={[
					<button
						className={`${
							confirmDelete === "confirm"
								? "cursor-pointer lg:hover:px-10"
								: "cursor-not-allowed opacity-50"
						} group mx-auto flex items-center justify-center gap-2 rounded-2xl bg-red-600 px-5 py-2 shadow-lg transition-all duration-500`}
						onClick={() => deletePost()}
						disabled={confirmDelete === "confirm" ? false : true}
					>
						<p className="font-montserrat font-bold uppercase text-white">
							confirm
						</p>
					</button>,
				]}
			>
				<div>
					<p className="pb-3 text-center">
						This action cannot be undone. This will permanently delete the
						<span className=" font-bold">
							{" "}
							{data?.jobPost.jobTitle}
						</span> post. <br />
						<br />
						Please type <span className=" font-bold"> confirm </span> to delete
						the post.
					</p>
					<input
						onChange={(e) => setConfirmDelete(e.target.value)}
						className="peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 text-center font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus:border-green-11 focus:outline-none"
					/>
				</div>
			</Modal>
			<div className="grid grid-cols-1 gap-x-5 gap-y-7 px-4 pt-4 pb-12 md:grid-cols-2">
				<div className="relative col-span-1 flex w-full flex-col gap-x-5 gap-y-3 md:col-span-2">
					<TextareaAutosize
						id="jobDescription"
						name="jobDescription"
						placeholder="description"
						className={`peer h-12 w-full appearance-none rounded-xl border-2 py-3 px-3 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:outline-none focus:ring-0 ${
							jobDescription1.error?.length !== 0
								? "border-orange-1 focus:ring-orange-1"
								: ""
						}`}
						value={jobDescription1.state}
						onChange={(e) =>
							setJobDescription1({
								...jobDescription1,
								state: e.target.value,
							})
						}
					/>
					<label
						htmlFor="jobDescription"
						className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
					>
						Description
					</label>
					<div className="absolute">
						<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
							{" "}
							{jobDescription1.error}{" "}
						</p>
					</div>
				</div>
				<div className="relative flex w-full flex-col gap-x-5 gap-y-3">
					<input
						className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:outline-none focus:ring-0 ${
							jobTitle.error?.length !== 0
								? "border-orange-1 focus:ring-orange-1"
								: ""
						}`}
						value={jobTitle.state}
						onChange={(e) =>
							setJobTitle({ ...jobTitle, state: e.target.value })
						}
						placeholder="Ex: Marketing Automation Data Scientist"
					/>
					<label
						htmlFor="jobTitle"
						className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
					>
						Title
					</label>
					<div className="absolute">
						<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
							{" "}
							{jobTitle.error}{" "}
						</p>
					</div>
				</div>
				<div className="relative flex w-full flex-col gap-x-5 gap-y-3">
					<input
						id="jobLink"
						name="jobLink"
						className={` peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:outline-none focus:ring-0 ${
							jobLinkBambooHR.error?.length !== 0 ? "border-orange-1" : ""
						}`}
						placeholder="link"
						onChange={(e) =>
							setJobLinkBambooHR({
								...jobLinkBambooHR,
								state: e.target.value,
							})
						}
						value={jobLinkBambooHR.state}
					/>
					<label
						htmlFor="jobLink"
						className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
					>
						Link
					</label>
					<div className="absolute">
						<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
							{" "}
							{jobLinkBambooHR.error}{" "}
						</p>
					</div>
				</div>
				<div className="realative relative flex w-full flex-col gap-x-5 gap-y-3">
					<input
						id="jobLocation"
						name="jobLocation"
						className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:outline-none focus:ring-0 ${
							jobLocation.error?.length !== 0 ? "border-orange-1" : ""
						}`}
						onChange={(e) =>
							setJobLocation({ ...jobLocation, state: e.target.value })
						}
						value={jobLocation.state}
						placeholder="Ex: Bucharest, Iasi"
					/>
					<label htmlFor="jobLocation" className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm" >
						Location
					</label>
					<div className="absolute">
						<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
							{jobLocation.error}
						</p>
					</div>
				</div>
			</div>
		</CareerLayout>
	);
}

export default EditCareerPosts;
