import { useState, useRef } from "react";
import { Popover } from "antd";
import {DOMAIN} from "../../../env";

export default function CaseStudyLast({ id, data, setData, link }) {
	const fileInput = useRef(null);

	const [image, setImage] = useState({
		ImgName: "No file chosen",
		state: null,
		error: "",
		link: "",
		ImgSrc: [],
	});

	const changeNumberOfPages = (
		<div>
			<input
				placeholder="10"
				className="peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none"
				onChange={(e) =>
					setData({
						last: {
							numberOfPages: e.target.value,
							image: data.image,
						},
					})
				}
				value={data.numberOfPages}
			/>
		</div>
	);

	const onImageChange = (event) => {
		const fileType = ["image/png", "image/jpg", "image/jpeg", "image/webp"];
		if (event.target.value.length !== 0)
			if (event.target.files[0].size > 10485760) {
				event.target.value = null;
				setData({
					last: {
						numberOfPages: data.numberOfPages,
						image: {
							name: "No file chosen",
							state: null,
							error: "Only files under 10MB",
							link: "",
							src: [],
						},
					},
				});
				return;
			}
		if (!fileType.includes(event.target.files[0].type)) {
			event.target.value = null;
			setData({
				last: {
					numberOfPages: data.numberOfPages,
					image: {
						name: "No file chosen",
						state: null,
						error: "Incorrect file type, only: .png, .jpg, .jpeg",
						link: "",
						src: [],
					},
				},
			});
		} else {
			var reader = new FileReader();
			// eslint-disable-next-line
			var url = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function () {
				setImage({
					...image,
					state: event.target.files[0],
					ImgName: event.target.files[0].name,
					error: "",
					ImgSrc: [reader.result],
				});
				setData({
					last: {
						numberOfPages: data.numberOfPages,
						image: {
							name: event.target.files[0].name,
							state: event.target.files[0],
							error: false,
							link: "",
							src: [reader.result],
						},
					},
				});
			};
		}
	};

	return (
		<div className="flex flex-col-reverse items-center gap-10 md:flex-row">
			<div className="flex w-full flex-col gap-5">
				<h1 className=" font-poppins text-3xl font-extrabold  uppercase md:text-3xl">
					Want to know more ?
				</h1>
				<Popover
					content={changeNumberOfPages}
					title="Number of pages"
					trigger="click"
					placement="top"
					className=" cursor-pointer"
				>
					<p className="font-montserrat text-lg text-grey-1 md:text-xl">
						Access the{" "}
						<b className="uppercase">
							{" "}
							{data.numberOfPages || "0"} page extended Case Study{" "}
						</b>{" "}
						for all the details behind our technical solutions.
					</p>
				</Popover>
				<div className="flex justify-start">
					<a
						href={link}
						target="_blank"
						rel="noreferrer"
						className="cursor-pointer rounded-xl bg-orange-1 py-3 px-8 shadow-md"
					>
						<p className="font-poppins text-lg font-extrabold uppercase text-white">
							download now
						</p>
					</a>
				</div>
			</div>
			<div
				onClick={() => fileInput.current.click()}
				className={`${
					!id ? "cursor-not-allowed opacity-30" : "cursor-pointer"
				} relative flex w-full justify-center rounded-3xl border-2 border-stone-300 bg-stone-100 py-4 shadow-lg md:w-9/12`}
			>
				<div className=" absolute hidden">
					<input
						disabled={!id ? true : false}
						ref={fileInput}
						aria-describedby="update profile image"
						type="file"
						accept="image/png, image/jpeg, image/jpg, image/webp"
						onChange={(e) => onImageChange(e)}
					/>
				</div>
				{data.image.src.length ? (
					<img
						className="w-64 object-contain"
						alt="Case Study Cover"
						src={data.image.src}
					/>
				) : data.image.link ? (
					<img
						alt="FeaturedImage"
						src={data.image.link}
						className="w-64 object-contain"
					/>
				) : (
					<div className="flex h-full min-h-[18rem] flex-col items-center justify-center gap-2">
						<svg
							className="h-12 w-12 fill-current text-stone-400"
							viewBox="0 0 24 24"
						>
							<path d="M21,12h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Zm1,7c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-4c0-.552,.448-1,1-1l3-.002v.002c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4h3c.552,0,1,.448,1,1v4ZM7.293,5.293c-.391-.391-.391-1.023,0-1.414L10.586,.586C10.972,.2,11.479,.006,11.986,.003l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l3.293,3.293c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.293-2.293v7c0,.553-.447,1-1,1s-1-.447-1-1V3l-2.293,2.293c-.391,.391-1.023,.391-1.414,0Z" />
						</svg>
						<p className="text-center font-montserrat text-base text-stone-400">
							Image type: PNG, JPEG, JPG <br /> Max size: 80MB
						</p>
					</div>
				)}
			</div>
		</div>
	);
}
