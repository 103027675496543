import axios from 'axios';
import { message } from 'antd';
import {DOMAIN} from "../../../env";

export default async function togglePublished (id, published, category, setModified, modified, jwtToken) {
    const loadingMessage = message.loading('Loading...', 0);
    await axios.post(`${DOMAIN}/api/knowledgeHub/expertArticle/togglePublishStatus/${id}`,{
        published: published,
        category: category,
    },{
        headers:{
            'jwt-token': jwtToken
        }
    }).then((response)=>{
        message.success({
            content: `Published Status Changed!`,
            className: 'text-center',
            duration: 2
        })
        setModified(!modified)
    }).catch((error)=>{
        message.error({
            content: `Network Error!`,
            className: 'text-center',
            duration: 2
        });
    }).finally(()=>{
        loadingMessage();
    })
}