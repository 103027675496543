import React from "react";

export default function jobCard(props) {
	return (
		<div
			className={`h-full w-full transform cursor-pointer rounded-3xl border-2 bg-stone-100 p-6 shadow-lg transition duration-500 ${
				props.published ? "border-green-11" : "border-stone-300"
			}`}
		>
			<div className="flex h-full flex-col">
				<p className="text-left font-poppins text-2xl font-bold text-grey-1 line-clamp-2">
					{props.jobTitle}
				</p>
				<div className="mt-auto flex flex-col gap-x-10 gap-y-3 lg:flex-row">
					<div className="flex cursor-default items-center justify-start gap-x-2">
						<svg
							className="h-4 w-4 fill-current text-grey-1"
							viewBox="0 0 24 24"
						>
							<path d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z" />
							<path d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z" />
						</svg>

						<p className="font-poppins text-lg font-normal capitalize text-grey-1">
							{props.jobLocation}
						</p>
					</div>
				</div>
				<div className="hidden md:block">
					<div className="h-3"></div>
					<p className="font-raleway text-base leading-5 text-grey-6 line-clamp-2">
						{props.jobDescription}
					</p>
				</div>
			</div>
		</div>
	);
}
