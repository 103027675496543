import React, { useState } from "react";
import { Select } from 'antd';
import Observation from "./Observation";
import Recommendations from "./Recommendations";

export default function Path(props) {
    const { Option } = Select;
    const [recommendations, setRecommendations] = useState({state:[],error:""});
    const [observation, setObservation] = useState({state:[],error:""});
    const [pathType, setPathType] = useState("Graphs");

    return (
        <div style={{borderColor:props.colorScheme}} className={`w-full border-2 rounded shadow-lg p-5`} key={props.pathNumber}> 
            <div className="flex justify-center">
                <div className="px-5 py-2 transform -translate-y-11 shadow-lg bg-white" style={{backgroundColor:props.colorScheme}}>
                    <p className=" font-montserrat font-bold text-lg text-white">
                        Path {props.pathNumber+1}
                    </p>
                </div>
            </div>
            <p className="font-montserrat font-bold text-lg text-center pb-5">
                Information
            </p>
            <div className="flex flex-col gap-10 w-full">
                <div className="flex flex-row justify-center items-center w-full">
                    <p className=" font-montserrat font-bold text-lg w-44">
                        Card Summary
                    </p>
                    <input 
                        placeholder="Card Summary"
                        onChange={(e) => props.onChange('pathSummary', e.target.value, props.pathNumber)}
                        className={`h-12 font-montserrat appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 border-green-11 focus:ring-green-11 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent`}
                    />
                </div>
                <div className="flex flex-row justify-center items-center w-full">
                    <p className=" font-montserrat font-bold text-lg w-44">
                        Page Text
                    </p>
                    <input 
                        placeholder="Page Text"
                        onChange={(e) => props.onChange('pathText', e.target.value, props.pathNumber)}
                        className={`h-12 font-montserrat appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 border-green-11 focus:ring-green-11 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent`}
                    />
                </div>
            </div>
            <p className="font-montserrat font-bold text-lg text-center pt-10 pb-5">
                Content
            </p>
            <div className="flex flex-row gap-10">
                <div className="pt-16 pb-10 w-full mx-auto">
                    <div className="w-full shadow-lg px-10 border-2 relative">
                        <div className="transform -translate-y-16 -translate-x-9 absolute">
                            <svg fill={props.colorScheme} id="idea" xmlns="http://www.w3.org/2000/svg" width="106.92" height="103.6" viewBox="0 0 106.92 103.6">
                                <g id="Group_19" data-name="Group 19" transform="translate(12.55 12.55)">
                                    <g id="Group_18" data-name="Group 18">
                                        <path id="Path_39" data-name="Path 39" d="M83.272,79.538,75.8,72.071A2.64,2.64,0,0,0,72.071,75.8l7.467,7.467a2.64,2.64,0,0,0,3.733-3.733Z" transform="translate(-71.298 -71.298)"/>
                                    </g>
                                </g>
                                <g id="Group_21" data-name="Group 21" transform="translate(0 49.16)">
                                    <g id="Group_20" data-name="Group 20">
                                        <path id="Path_40" data-name="Path 40" d="M13.377,241H2.64a2.64,2.64,0,1,0,0,5.28H13.377a2.64,2.64,0,1,0,0-5.28Z" transform="translate(0 -241)"/>
                                    </g>
                                </g>
                                <g id="Group_23" data-name="Group 23" transform="translate(90.902 49.16)">
                                    <g id="Group_22" data-name="Group 22">
                                        <path id="Path_41" data-name="Path 41" d="M434.377,241H423.64a2.64,2.64,0,0,0,0,5.28h10.737a2.64,2.64,0,0,0,0-5.28Z" transform="translate(-421 -241)"/>
                                    </g>
                                </g>
                                <g id="Group_25" data-name="Group 25" transform="translate(81.623 12.55)">
                                    <g id="Group_24" data-name="Group 24">
                                        <path id="Path_42" data-name="Path 42" d="M380.255,72.071a2.639,2.639,0,0,0-3.733,0l-7.467,7.467a2.64,2.64,0,0,0,3.733,3.733l7.467-7.467A2.639,2.639,0,0,0,380.255,72.071Z" transform="translate(-368.281 -71.298)"/>
                                    </g>
                                </g>
                                <g id="Group_27" data-name="Group 27" transform="translate(50.82)">
                                    <g id="Group_26" data-name="Group 26">
                                        <path id="Path_43" data-name="Path 43" d="M243.64,0A2.639,2.639,0,0,0,241,2.64V13.377a2.64,2.64,0,1,0,5.28,0V2.64A2.639,2.639,0,0,0,243.64,0Z" transform="translate(-241)"/>
                                    </g>
                                </g>
                                <g id="Group_29" data-name="Group 29" transform="translate(29.713 29.033)">
                                    <g id="Group_28" data-name="Group 28">
                                        <path id="Path_44" data-name="Path 44" d="M159.607,126.141a23.867,23.867,0,0,0-20.488-4.435,23.409,23.409,0,0,0-17.32,17.056,23.886,23.886,0,0,0,6.389,22.97,12.974,12.974,0,0,1,3.432,9.047v.581a2.615,2.615,0,0,0,2.64,2.64h21.122a2.615,2.615,0,0,0,2.64-2.64v-.581a13.107,13.107,0,0,1,3.644-9.206,23.84,23.84,0,0,0-2.059-35.432Zm-14.785,10.72a7.721,7.721,0,0,0-7.642,5.7,2.64,2.64,0,1,1-5.126-1.269,13.038,13.038,0,0,1,12.768-9.713,2.64,2.64,0,0,1,0,5.28Z" transform="translate(-121.072 -121.024)"/>
                                    </g>
                                </g>
                                <g id="Group_31" data-name="Group 31" transform="translate(42.899 93.039)">
                                    <g id="Group_30" data-name="Group 30">
                                        <path id="Path_45" data-name="Path 45" d="M196,452v2.64a7.929,7.929,0,0,0,7.921,7.921h5.28a7.929,7.929,0,0,0,7.921-7.921V452Z" transform="translate(-196 -452)"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className='flex flex-col py-16'>
                            <p className=" font-montserrat text-grey-1 font-black text-3xl pb-5">
                                Recommendations
                            </p>
                            <Recommendations recommendations={recommendations} setRecommendations={setRecommendations} pathNumber={props.pathNumber} onChange={props.onChange}/>
                        </div>
                    </div>
                </div>
                <div className="pt-16 pb-10 w-full mx-auto">
                    <div className="w-full shadow-lg px-10 border-2 relative">
                        <div className="transform -translate-y-16 -translate-x-9 absolute">
                            <svg fill={props.colorScheme} id="magnifier" xmlns="http://www.w3.org/2000/svg" width="103.587" height="103.6" viewBox="0 0 103.587 103.6">
                                <path id="Path_46" data-name="Path 46" d="M77.419,112.364a2.6,2.6,0,0,0-3.679,0,17.72,17.72,0,0,0,0,25.029,2.6,2.6,0,1,0,3.679-3.679,12.513,12.513,0,0,1,0-17.671A2.6,2.6,0,0,0,77.419,112.364Z" transform="translate(-53.927 -87.768)"/>
                                <path id="Path_47" data-name="Path 47" d="M100.715,86.677,69.4,55.362a37.08,37.08,0,1,0-14,14.036l31.3,31.3a9.914,9.914,0,0,0,14.022-14.02ZM18.237,55.994A26.716,26.716,0,1,1,37.13,63.816,26.745,26.745,0,0,1,18.237,55.994Z" transform="translate(-0.031)"/>
                            </svg>
                        </div>
                        <div className='flex flex-col py-16'>
                            <p className=" font-montserrat text-grey-1 font-black text-3xl pb-5">
                                Observation
                            </p>
                            <Observation observation={observation} setObservation={setObservation} pathNumber={props.pathNumber} onChange={props.onChange}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row items-center pt-5">
                <p className=" font-montserrat font-bold text-lg w-44">
                    Path type:
                </p>
                <Select 
                    defaultValue="Graphs"
                    onChange={(value) => {
                        props.onChange('pathType', value, props.pathNumber);
                        setPathType(value)
                    }}
                    className={`h-12 font-montserrat appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 border-green-11 focus:ring-green-11 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent cursor-pointer`}
                >
                    <Option value="Graphs">Graphs</Option>
                    <Option value="Images">Images</Option>
                </Select>
            </div>
            {
                pathType === "Graphs" ?
                <div>
                    <p className="font-montserrat font-bold text-lg text-center pt-5 pb-5">
                        Graphs
                    </p>
                </div>
                :
                <div>
                    <p className="font-montserrat font-bold text-lg text-center pt-5 pb-5">
                        Images
                    </p>
                </div>
            }
        </div> 
    )
}