import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { message } from "antd";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import UserContext from "../../context/context";
import {DOMAIN, STORAGE_DOMAIN} from "../../env";

const UsersLayout = ({ children }) => {
	const { id } = useParams();
	const [data, setData] = useState([]);
	const user = useContext(UserContext);

	useEffect(() => {
		const fetchApiData = async () => {
			try {
				const response = await axios.get(
					`${DOMAIN}/api/user/getAllUsers`,
					{
						headers: {
							"jwt-token": user.jwtToken,
						},
					}
				);
				setData(response.data.userList);
			} catch (error) {
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			}
		};
		fetchApiData();
		// eslint-disable-next-line
	}, []);

	const renderCards = () => {
		if (data.length === 0)
			return new Array(10).fill("").map((placeholder, index) => {
				return (
					<div
						key={index}
						className="w-full h-20 border-2 p-4 rounded-2xl shadow-lg flex justify-center items-center"
					>
						<div className="w-full h-4 bg-grey-6 rounded-full animate-pulse"></div>
					</div>
				);
			});
		else
			return data.map((user, index) => {
				return (
					<Link
						key={index}
						to={`/users/edit/${user._id}`}
						className={`${user._id === id
							? "bg-green-11 border-green-11"
							: "bg-white hover:border-green-11"
							} px-4 py-2 border-2 rounded-2xl shadow-lg transition-all min-w-[15.5rem] duration-500 flex flex-col lg:flex-row gap-2 lg:gap-4 items-center justify-center lg:justify-start`}
					>
						{user.user_image !== undefined ? (
							<div className="h-12 w-12 rounded-full bg-white overflow-hidden shadow-lg">
								<img
									alt="user_avatar"
									className="h-full w-full"
									src={STORAGE_DOMAIN+ user.user_image}
									key={user.user_image}
								/>
							</div>
						) : (
							<div className=" h-12 w-12 rounded-full bg-white border-2 overflow-hidden shadow-lg flex items-center justify-center">
								<p className=" text-grey-1 font-bold font-poppins text-lg">
									{user.user_name.match(/\b(\w)/g).join(".")}
								</p>
							</div>
						)}
						<p
							className={`${user._id === id ? "text-white" : "text-grey-1"
								} font-poppins font-bold text-lg line-clamp-3 text-center lg:line-clamp-1 lg:text-left`}
						>
							{user.user_name}
						</p>
					</Link>
				);
			});
	};

	return (
		<div className="flex flex-col lg:flex-row w-full">
			<div className="w-full lg:w-100 relative">
				<div className="hidden lg:block absolute top-0 w-full bg-gradient-to-b from-white to-transparent h-8 z-10 -left-4 pointer-events-none"></div>
				<div className="block lg:hidden absolute z-10 h-full w-8 bg-gradient-to-r -top-4 from-white to-transparent pointer-events-none"></div>
				<div className="w-full flex flex-row lg:flex-col gap-4 py-7 px-4 overflow-x-auto lg:overflow-y-scroll max-h-screen scrollBarHome relative">
					{renderCards()}
				</div>
				<div className="hidden lg:block absolute bottom-0 w-full bg-gradient-to-t from-white to-transparent h-8 z-10 -left-4 pointer-events-none"></div>
				<div className="block lg:hidden absolute z-10 h-full w-8 bg-gradient-to-l -top-4 right-0 from-white to-transparent pointer-events-none"></div>
			</div>
			<div className="w-full">{children}</div>
		</div>
	);
};

export default UsersLayout;
