import { useState, useEffect, useRef } from "react";
import { Select, DatePicker } from "antd";
import axios from "axios";
import moment from "moment";
import {DOMAIN, STORAGE_DOMAIN} from "../../../env";

export default function SeoInformation({ id, user, data, setData }) {
	const seoImageRef = useRef(null);
	const cardImageRef = useRef(null);

	const [checkLink, setCheckLink] = useState({
		status: undefined,
		state: undefined,
	});

	function handleChangeIMG(event, image) {
		const fileType = ["image/png", "image/jpg", "image/jpeg", "image/webp"];
		if (event.target.value.length !== 0)
			if (event.target.files[0].size > 10485760) {
				event.target.value = null;
				setData({
					[image]: {
						error: "Only files under 10MB",
						name: "No file chosen",
						state: null,
						link: "",
						src: [],
					},
				});
				return;
			}
		if (!fileType.includes(event.target.files[0].type)) {
			event.target.value = null;
			setData({
				[image]: {
					error: "Incorrect file type, only: .png, .jpg, .jpeg, .webp",
					name: "No file chosen",
					state: null,
					link: "",
					src: [],
				},
			});
		} else {
			var reader = new FileReader();
			// eslint-disable-next-line
			var url = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function () {
				setData({
					[image]: {
						error: "",
						name: event.target.files[0].name,
						state: event.target.files[0],
						link: "",
						src: [reader.result],
					},
				});
			};
		}
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			if (data.link.state.length)
				axios
					.post(
						`${DOMAIN}/api/knowledgeHub/caseStudyNew/checkurl/${data.link.state}`,
						{
							id: data.id ? data.id : undefined,
						},
						{
							headers: {
								"jwt-token": user.jwtToken,
							},
						}
					)
					.then((results) => {
						setCheckLink({
							...checkLink,
							status: true,
							state: results.data.success,
						});
					});
			else setCheckLink({ ...checkLink, status: undefined, state: undefined });
		}, 500);
		return () => clearTimeout(timer);
		// eslint-disable-next-line
	}, [data.link.state]);

	return (
		<div className=" grid grid-cols-2 gap-5">
			<div className=" relative col-span-2 md:col-span-1">
				<Select
					id="topic"
					className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 ${
						data.topic.error
							? "border-orange-1 focus:ring-orange-1"
							: "hover:border-green-11"
					}`}
					value={data.topic.state}
					onChange={(value) => setData({ topic: { state: value, error: "" } })}
					options={[
						{ value: "Privacy", label: "Eloqua Privacy" },
						{ value: "Implementation", label: "Eloqua Implementation" },
						{
							value: "CampaignProduction",
							label: "Eloqua Campaign Prod",
						},
						{ value: "PlatformAdmin", label: "Eloqua Platform Admin" },
						{ value: "Reporting", label: "Eloqua Reporting" },
					]}
				/>
				<label
					htmlFor="topic"
					className={`${
						data.topic.state ? "-top-2 text-sm" : "top-[0.65rem] text-lg"
					} absolute left-3 transform rounded-lg bg-white px-1 font-poppins font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus-within:-top-2 peer-focus-within:text-sm`}
				>
					Topic
				</label>
				<div
					className={`${
						data.topic.error
							? "translate-y-0 opacity-100"
							: "translate-y-3 opacity-0"
					} pointer-events-none absolute -bottom-2 left-3 bg-white px-1 transition-all duration-300`}
				>
					<p className=" font-raleway text-xs text-orange-1">
						{data.topic.error}
					</p>
				</div>
			</div>
			<div className=" relative col-span-2 md:col-span-1">
				<input
					id="link"
					className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus:border-green-11 focus:outline-none ${
						data.link.error
							? "border-orange-1 focus:ring-orange-1"
							: "hover:border-green-11"
					}`}
					value={data.link.state}
					onChange={(e) => {
						setData({ link: { state: e.target.value, error: "" } });
						setCheckLink({ ...checkLink, status: false });
					}}
				/>
				<label
					htmlFor="link"
					className={`${
						data.link.state.length ? "-top-2 text-sm" : "top-[0.65rem] text-lg"
					} absolute left-3 transform rounded-lg bg-white px-1 font-poppins font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus-within:-top-2 peer-focus-within:text-sm`}
				>
					Link
				</label>
				<div
					className={`${
						data.link.error
							? "translate-y-0 opacity-100"
							: "translate-y-3 opacity-0"
					} pointer-events-none absolute -bottom-2 left-3 bg-white px-1 transition-all duration-300`}
				>
					<p className=" font-raleway text-xs text-orange-1">
						{data.link.error}
					</p>
				</div>
				<div className="absolute right-3 top-0 flex h-full items-center">
					{checkLink.status === false ? (
						<svg
							role="status"
							className={`inline h-5 w-5 animate-spin text-gray-200`}
							viewBox="0 0 100 101"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								className="fill-current text-grey-4"
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
							/>
							<path
								className="fill-current text-green-11"
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
							/>
						</svg>
					) : null}
					{checkLink.status === true && checkLink.state === true ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-5 w-5 fill-current text-green-11"
							viewBox="0 0 24 24"
						>
							<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
						</svg>
					) : null}
					{checkLink.status === true && checkLink.state === false ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-5 w-5 fill-current text-orange-1"
							viewBox="0 0 24 24"
						>
							<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
						</svg>
					) : null}
				</div>
			</div>
			<div className=" relative col-span-2 md:col-span-1">
				<input
					id="hubspotlink"
					className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus:border-green-11 focus:outline-none ${
						data.hubSpotLink.error
							? "border-orange-1 focus:ring-orange-1"
							: "hover:border-green-11"
					}`}
					value={data.hubSpotLink.state}
					onChange={(e) => {
						setData({ hubSpotLink: { state: e.target.value, error: "" } });
					}}
				/>
				<label
					htmlFor="hubspotlink"
					className={`${
						data.hubSpotLink.state.length
							? "-top-2 text-sm"
							: "top-[0.65rem] text-lg"
					} absolute left-3 transform rounded-lg bg-white px-1 font-poppins font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus-within:-top-2 peer-focus-within:text-sm`}
				>
					HubSpot Link
				</label>
				<div
					className={`${
						data.hubSpotLink.error
							? "translate-y-0 opacity-100"
							: "translate-y-3 opacity-0"
					} pointer-events-none absolute -bottom-2 left-3 bg-white px-1 transition-all duration-300`}
				>
					<p className=" font-raleway text-xs text-orange-1">
						{data.hubSpotLink.error}
					</p>
				</div>
			</div>
			<div className=" relative col-span-2 md:col-span-1">
				<DatePicker
					id="date"
					placeholder=""
					format={["DD/MM/YYYY", "DD/MM/YY"]}
					className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg placeholder-transparent focus:border-green-11 focus:outline-none ${
						data.postedDate.error ? "border-orange-1 focus:ring-orange-1" : ""
					}`}
					defaultValue={
						data.postedDate.state
							? moment(new Date(data.postedDate.state), "DD/MM/YYYY")
							: ""
					}
					onChange={(value) =>
						setData({ postedDate: { state: value?._d || "", error: "" } })
					}
				/>
				<label
					htmlFor="date"
					className={`${
						data.postedDate.state ? "-top-2 text-sm" : "top-[0.65rem] text-lg"
					} absolute left-3 transform rounded-full bg-white px-1 font-poppins font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus-within:-top-2 peer-focus-within:text-sm`}
				>
					Date
				</label>
				<div
					className={`${
						data.postedDate.error
							? "translate-y-0 opacity-100"
							: "translate-y-3 opacity-0"
					} pointer-events-none absolute -bottom-2 left-3 bg-white px-1 transition-all duration-300`}
				>
					<p className=" font-raleway text-xs text-orange-1">
						{data.postedDate.error}
					</p>
				</div>
			</div>
			<div className=" relative col-span-2">
				<input
					id="description"
					className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 pl-3 pr-20 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:outline-none  ${
						data.description.error
							? "border-orange-1 focus:ring-orange-1"
							: "hover:border-green-11"
					}`}
					value={data.description.state}
					onChange={(e) =>
						setData({ description: { state: e.target.value, error: "" } })
					}
					placeholder="Meta Description"
					maxLength={158}
				/>
				<label
					htmlFor="description"
					className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
				>
					Meta Description
				</label>
				<div
					className={`${
						data.description.error
							? "translate-y-0 opacity-100"
							: "translate-y-3 opacity-0"
					} pointer-events-none absolute -bottom-2 left-3 bg-white px-1 transition-all duration-300`}
				>
					<p className=" font-raleway text-xs text-orange-1">
						{data.description.error}
					</p>
				</div>
				<div className="absolute right-3 top-3 font-raleway">
					{data.description.state.length} / 158
				</div>
			</div>
			<div className="relative col-span-2">
				<input
					id="title"
					className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:outline-none  ${
						data.title.error
							? "border-orange-1 focus:ring-orange-1"
							: "hover:border-green-11"
					}`}
					onChange={(e) =>
						setData({ title: { state: e.target.value, error: "" } })
					}
					value={data.title.state}
					placeholder="Title"
				/>
				<label
					htmlFor="title"
					className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
				>
					Title
				</label>
				<div
					className={`${
						data.title.error
							? "translate-y-0 opacity-100"
							: "translate-y-3 opacity-0"
					} pointer-events-none absolute -bottom-2 left-3 bg-white px-1 transition-all duration-300`}
				>
					<p className=" font-raleway text-xs text-orange-1">
						{data.title.error}
					</p>
				</div>
			</div>
			<div className="relative col-span-2 flex flex-col gap-5 lg:col-span-1">
				<div
					className={`${
						!id ? "cursor-not-allowed opacity-30" : "group cursor-pointer"
					}`}
				>
					<div className="absolute flex h-12 w-full appearance-none items-center justify-between">
						<div
							className={`${
								data.cardImage.error
									? "border-orange-1"
									: "group-hover:border-green-11"
							} w-full truncate rounded-l-xl border-y-2 border-l-2 py-2 px-3 text-lg transition-all duration-500`}
						>
							<p className="font-raleway text-grey-8">{data.cardImage.name}</p>
						</div>
						<div className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300">
							Card image
						</div>
						<div
							className={`${
								data.cardImage.error
									? "border-orange-1 group-hover:bg-orange-1"
									: "border-green-11 group-hover:bg-green-11"
							} flex h-full items-center justify-center rounded-r-xl border-2 bg-white px-10 transition-all duration-500`}
						>
							<svg
								viewBox="0 0 24 24"
								className={`${
									data.cardImage.error ? "text-orange-1" : "text-green-11"
								} h-6 w-6 fill-current transition-all duration-500 group-hover:text-white`}
							>
								<path d="M21,12h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Zm1,7c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-4c0-.552,.448-1,1-1l3-.002v.002c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4h3c.552,0,1,.448,1,1v4ZM7.293,5.293c-.391-.391-.391-1.023,0-1.414L10.586,.586C10.972,.2,11.479,.006,11.986,.003l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l3.293,3.293c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.293-2.293v7c0,.553-.447,1-1,1s-1-.447-1-1V3l-2.293,2.293c-.391,.391-1.023,.391-1.414,0Z" />
							</svg>
						</div>
					</div>
					<input
						disabled={!id ? true : false}
						onChange={(e) => handleChangeIMG(e, "cardImage")}
						className={`${
							!id ? "cursor-not-allowed" : "cursor-pointer"
						} focus:shadow-outline h-12 w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 opacity-0 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500`}
						ref={cardImageRef}
						type="file"
						accept="image/*"
					/>
				</div>
				<div
					onClick={() => cardImageRef.current.click()}
					className={`${
						!id ? "cursor-not-allowed opacity-30" : " cursor-pointer"
					} relative top-0 mx-auto h-72 w-full max-w-[19.25rem] transform overflow-hidden bg-orange-1 shadow-lg`}
				>
					<div className="relative h-4/6 w-full">
						{data.cardImage.src.length ? (
							<img
								alt="FeaturedImage"
								src={data.cardImage.src}
								className="h-full w-full object-cover"
							/>
						) : data.cardImage.link ? (
							<img
								alt="FeaturedImage"
								src={STORAGE_DOMAIN+data.cardImage.link}
								className="h-full w-full object-cover"
							/>
						) : (
							<div className="flex h-full w-full items-center justify-center bg-gradient-to-b from-orange-2 to-orange-3">
								<p className=" font-poppins text-lg font-bold text-white">
									No Image
								</p>
							</div>
						)}
					</div>
					<div className="absolute top-1/2 flex h-36 w-full items-center justify-center px-8">
						<div className="absolute left-0 h-full w-full bg-white bg-opacity-20 bg-clip-content backdrop-blur-sm backdrop-filter"></div>
						<p className="text-shadow relative font-montserrat text-lg font-bold text-white md:text-base">
							{data.title.state ? data.title.state : "No title"}
						</p>
					</div>
				</div>
			</div>
			<div className="relative col-span-2 flex flex-col gap-5 lg:col-span-1">
				<div
					className={`${
						!id ? "cursor-not-allowed opacity-30" : "group"
					} relative col-span-2 flex flex-col gap-5 lg:col-span-1`}
				>
					<div
						className={`absolute flex h-12 w-full appearance-none items-center justify-between`}
					>
						<div
							className={`${
								data.seoImage.error
									? "border-orange-1"
									: "group-hover:border-green-11"
							} w-full truncate rounded-l-xl border-y-2 border-l-2 py-2 px-3 text-lg transition-all duration-500`}
						>
							<p className="font-raleway text-grey-8">{data.seoImage.name}</p>
						</div>
						<div className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300">
							Seo image
						</div>
						<div
							className={`${
								data.seoImage.error
									? "border-orange-1 group-hover:bg-orange-1"
									: "border-green-11 group-hover:bg-green-11"
							} flex h-full items-center justify-center rounded-r-xl border-2 bg-white px-10 transition-all duration-500`}
						>
							<svg
								viewBox="0 0 24 24"
								className={`${
									data.seoImage.error ? "text-orange-1" : "text-green-11"
								} h-6 w-6 fill-current transition-all duration-500 group-hover:text-white`}
							>
								<path d="M21,12h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Zm1,7c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-4c0-.552,.448-1,1-1l3-.002v.002c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4h3c.552,0,1,.448,1,1v4ZM7.293,5.293c-.391-.391-.391-1.023,0-1.414L10.586,.586C10.972,.2,11.479,.006,11.986,.003l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l3.293,3.293c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.293-2.293v7c0,.553-.447,1-1,1s-1-.447-1-1V3l-2.293,2.293c-.391,.391-1.023,.391-1.414,0Z" />
							</svg>
						</div>
					</div>
					<input
						disabled={!id ? true : false}
						onChange={(e) => handleChangeIMG(e, "seoImage")}
						className={`${
							!id ? "cursor-not-allowed" : "cursor-pointer"
						} focus:shadow-outline h-12 w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 opacity-0 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500`}
						ref={seoImageRef}
						type="file"
						accept="image/*"
					/>
				</div>
				<div
					onClick={() => seoImageRef.current.click()}
					className={`${
						!id ? "cursor-not-allowed opacity-30" : " cursor-pointer"
					} relative mx-auto flex w-full max-w-lg items-center`}
				>
					{data.seoImage.src.length ? (
						<img
							alt="FeaturedImage"
							src={data.seoImage.src}
							className="w-full rounded-xl object-contain shadow-lg"
						/>
					) : data.seoImage.link ? (
						<img
							alt="FeaturedImage"
							src={STORAGE_DOMAIN+data.seoImage.link}
							className="w-full rounded-xl object-contain shadow-lg"
						/>
					) : (
						<div className="flex h-full w-full items-center justify-center rounded-xl border-2 border-stone-300 bg-stone-100">
							<div className="flex h-full min-h-[18rem] flex-col items-center justify-center gap-2">
								<svg
									className="h-12 w-12 fill-current text-stone-400"
									viewBox="0 0 24 24"
								>
									<path d="M21,12h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Zm1,7c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-4c0-.552,.448-1,1-1l3-.002v.002c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4h3c.552,0,1,.448,1,1v4ZM7.293,5.293c-.391-.391-.391-1.023,0-1.414L10.586,.586C10.972,.2,11.479,.006,11.986,.003l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l3.293,3.293c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.293-2.293v7c0,.553-.447,1-1,1s-1-.447-1-1V3l-2.293,2.293c-.391,.391-1.023,.391-1.414,0Z" />
								</svg>
								<p className="text-center font-montserrat text-base text-stone-400">
									Image type: PNG, JPEG, JPG <br /> Max size: 80MB
								</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
