import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Popconfirm, message } from "antd";
import UserContext from "../../context/context";
import axios from "axios";
import { useReward } from "react-rewards";
import { Link } from "react-router-dom";
import {
	ServingCard1Player,
	ServingCard2Players,
	TeamCard1Player,
	TeamCard2Players,
	WinnerCard1Player,
	WinnerCard2Players,
} from "../../components/PingPongComponents/UIComponents";
import {DOMAIN} from "../../env";

export default function PingPongGame() {
	const history = useHistory();
	const { id } = useParams();
	const user = useContext(UserContext);
	const [data, setData] = useState(undefined);

	const { reward: confettiReward } = useReward("confettiReward", "confetti", {
		lifetime: 200,
		spread: 50,
		elementCount: 150,
		zIndex: 31,
	});

	useEffect(() => {
		setTeam1Score(0);
		setTeam2Score(0);
		setWinner(0);
		setTurn(0);
		setServing(undefined);
		axios
			.get(`${DOMAIN}/api/pingpong/getGame/${id}`, {
				headers: {
					"jwt-token": user.jwtToken,
				},
			})
			.then((results) => {
				if (results.data.game.status === "Finished") history.push("/ping-pong");
				setData(results.data.game);
			})
			.catch((error) => {
				console.log(error);
				message.error({
					content: `Game not found!`,
					className: "text-center",
					duration: 2,
				});
				history.push("/ping-pong");
			});
		// eslint-disable-next-line
	}, [id]);

	const [team1Score, setTeam1Score] = useState(0);
	const [team2Score, setTeam2Score] = useState(0);
	const [winner, setWinner] = useState(0);
	const [serving, setServing] = useState(undefined);
	const [turn, setTurn] = useState(0);

	const adjustScoreTeam1 = (value) => {
		setTurn((turn) => {
			return (turn += 1);
		});
		setTeam1Score((currentCount) => {
			if (value === -1) {
				if (currentCount + value < 0) return currentCount;
				setTurn((turn) => {
					if ((turn - 1) % 2 === 0 && turn - 1 > 0) setServing(!serving);
					return (turn -= 1);
				});
				return (currentCount += value);
			} else {
				return (currentCount += value);
			}
		});
	};

	const adjustScoreTeam2 = (value) => {
		setTurn((turn) => {
			return (turn += 1);
		});
		setTeam2Score((currentCount) => {
			if (value === -1) {
				if (currentCount + value < 0) return currentCount;
				setTurn((turn) => {
					if ((turn - 1) % 2 === 0 && turn - 1 > 0) setServing(!serving);
					return (turn -= 1);
				});
				return (currentCount += value);
			} else {
				return (currentCount += value);
			}
		});
	};

	const updateWinner = (value) => {
		setWinner(() => {
			return value;
		});
	};

	useEffect(() => {
		if (winner !== 0) {
			confettiReward();
			let finishedGame = {};
			let winnerTeam = {};

			if (data.type === 1) {
				if (winner === 1) winnerTeam = data.team1.player1._id;
				else winnerTeam = data.team2.player1._id;
				finishedGame = {
					winner: winnerTeam,
					team1: {
						score: team1Score,
						player1: data.team1.player1._id,
					},
					team2: {
						score: team2Score,
						player1: data.team2.player1._id,
					},
				};
			} else {
				if (winner === 1)
					winnerTeam = {
						player1: data.team1.player1._id,
						player2: data.team1.player2._id,
					};
				else
					winnerTeam = {
						player1: data.team2.player1._id,
						player2: data.team2.player2._id,
					};
				finishedGame = {
					winner: winnerTeam,
					team1: {
						score: team1Score,
						player1: data.team1.player1._id,
						player2: data.team1.player2._id,
					},
					team2: {
						score: team2Score,
						player1: data.team2.player1._id,
						player2: data.team2.player2._id,
					},
				};
			}
			axios
				.post(
					`${DOMAIN}/api/pingpong/endGame/${id}`,
					finishedGame,
					{
						headers: {
							"jwt-token": user.jwtToken,
						},
					}
				)
				.then((results) => {
					//console.log(results);
				})
				.catch((error) => {
					message.error({
						content: `Server error!`,
						className: "text-center",
						duration: 2,
					});
				});
		}
		// eslint-disable-next-line
	}, [winner]);

	const rematch = () => {
		let newGame = {};
		if (data.type === 1)
			newGame = {
				type: data.type,
				createdBy: data.createdBy,
				team1: {
					player1: data.team1.player1._id,
				},
				team2: {
					player1: data.team2.player1._id,
				},
			};
		else
			newGame = {
				type: data.type,
				createdBy: data.createdBy,
				team1: {
					player1: data.team1.player1._id,
					player2: data.team1.player2._id,
				},
				team2: {
					player1: data.team2.player1._id,
					player2: data.team2.player2._id,
				},
			};

		axios
			.post(`${DOMAIN}/api/pingpong/newGame`, newGame, {
				headers: {
					"jwt-token": user.jwtToken,
				},
			})
			.then((response) => {
				history.push(response.data.id);
			})
			.catch((error) => {
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			});
	};

	const deleteGame = () => {
		axios
			.delete(
				`${DOMAIN}/api/pingpong/deleteGame/${id}`,
				{
					headers: {
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then((result) => {
				console.log(result);
				history.push("/ping-pong");
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		if (data && data.type === 1) {
			if (team1Score >= 10 && team2Score >= 10) {
				if (team1Score >= team2Score + 2) updateWinner(1);
				if (team2Score >= team1Score + 2) updateWinner(2);
			} else {
				if (team1Score === 11) updateWinner(1);
				if (team2Score === 11) updateWinner(2);
			}
		} else {
			if (team1Score >= 21 && team2Score >= 21) {
				if (team1Score >= team2Score + 2) updateWinner(1);
				if (team2Score >= team1Score + 2) updateWinner(2);
			} else {
				if (team1Score === 21) updateWinner(1);
				if (team2Score === 21) updateWinner(2);
			}
		}
		if (turn % 2 === 0 && turn > 0) setServing(!serving);
		// eslint-disable-next-line
	}, [team1Score, team2Score]);

	if (data === undefined)
		return (
			<div className="h-screen flex justify-center items-center bg-white">
				<div className="flex flex-col items-center justify-center gap-5">
					<div className="w-40 h-40 border-t-4 border-b-4 border-green-11 rounded-full animate-spin"></div>
				</div>
			</div>
		);

	return (
		<div>
			<Helmet>
				<title>Logarithmic PingPong</title>
			</Helmet>
			<div className="px-4 py-5 relative">
				<div className={`${serving === undefined ? "block" : "hidden"}`}>
					<div className="pt-5">
						<p className=" font-poppins text-grey-1 text-4xl font-bold text-center uppercase">
							Serving?
						</p>
					</div>
					<div className="pt-4 flex flex-col lg:flex-row gap-5 justify-center items-center relative">
						<div
							onClick={() => setServing(false)}
							className="bg-grey-4 px-8 py-4 rounded-3xl shadow-lg cursor-pointer w-full max-w-[250px]"
						>
							{data.type === 1 ? (
								<ServingCard1Player player={data.team1.player1} />
							) : (
								<ServingCard2Players
									player1={data.team1.player1}
									player2={data.team1.player2}
								/>
							)}
						</div>
						<div
							onClick={() => setServing(true)}
							className="bg-grey-4 px-8 py-4 rounded-3xl shadow-lg cursor-pointer w-full max-w-[250px]"
						>
							{data.type === 1 ? (
								<ServingCard1Player player={data.team2.player1} />
							) : (
								<ServingCard2Players
									player1={data.team2.player1}
									player2={data.team2.player2}
								/>
							)}
						</div>
					</div>
				</div>
				<div className={`${winner === 0 ? "block" : "hidden"}`}>
					<div className={`${serving === undefined ? "hidden" : "block"}`}>
						<div className="pt-2">
							<p className=" font-poppins text-grey-1 text-4xl font-bold text-center uppercase">
								Score
							</p>
						</div>
						<div className="pt-2 flex flex-row gap-4 justify-center max-w-[400px] mx-auto relative">
							<div
								className={`${
									serving
										? " translate-x-[27vw] md:translate-x-[15vw] lg:translate-x-[11.5vw] xl:translate-x-[7.5vw]"
										: " translate-x-[-27vw] md:translate-x-[-15vw] lg:translate-x-[-11.5vw] xl:translate-x-[-7.5vw]"
								} absolute top-[4.5rem] transition-all duration-500`}
							>
								<div className="w-10 h-2 rounded-full bg-green-11"></div>
							</div>
							<div className="w-full">
								<p className=" font-poppins text-grey-1 text-6xl font-bold text-center uppercase pb-4">
									{team1Score}
								</p>
								{data.type === 1 ? (
									<TeamCard1Player player={data.team1.player1} />
								) : (
									<TeamCard2Players
										player1={data.team1.player1}
										player2={data.team1.player2}
									/>
								)}
							</div>
							<div>
								<p className=" font-poppins text-grey-1 text-6xl font-bold text-center uppercase">
									-
								</p>
							</div>
							<div className="w-full">
								<p className=" font-poppins text-grey-1 text-6xl font-bold text-center uppercase pb-4">
									{team2Score}
								</p>
								{data.type === 1 ? (
									<TeamCard1Player player={data.team2.player1} />
								) : (
									<TeamCard2Players
										player1={data.team2.player1}
										player2={data.team2.player2}
									/>
								)}
							</div>
						</div>
						<div className="pt-5 flex flex-row justify-between max-w-[400px] mx-auto gap-10">
							<div className="flex flex-col gap-5 justify-center items-center w-full">
								<button
									disabled={winner !== 0}
									onClick={() => adjustScoreTeam1(1)}
									className={`${
										winner === 0 ? "opacity-100" : "opacity-50"
									} w-full h-44 bg-green-11 rounded-full shadow-xl flex justify-center items-center`}
								>
									<svg
										className=" fill-current text-white w-16 h-16"
										x="0px"
										y="0px"
										viewBox="0 0 512 512"
									>
										<g>
											<path d="M480,224H288V32c0-17.673-14.327-32-32-32s-32,14.327-32,32v192H32c-17.673,0-32,14.327-32,32s14.327,32,32,32h192v192   c0,17.673,14.327,32,32,32s32-14.327,32-32V288h192c17.673,0,32-14.327,32-32S497.673,224,480,224z" />
										</g>
									</svg>
								</button>
								<div className="pt-5">
									<button
										disabled={team1Score - 1 < 0 || winner !== 0}
										onClick={() => adjustScoreTeam1(-1)}
										className={`${
											team1Score - 1 < 0 || winner !== 0
												? " opacity-50"
												: " opacity-100"
										} w-28 h-14 bg-orange-1 rounded-full flex justify-center items-center shadow-lg`}
									>
										<svg
											viewBox="0 0 24 24"
											className=" fill-current text-white h-full w-full"
										>
											<path d="M16.5,13.5h-9a1.5,1.5,0,0,1,0-3h9a1.5,1.5,0,0,1,0,3Z" />
										</svg>
									</button>
								</div>
							</div>
							<div className="flex flex-col gap-5 justify-center items-center w-full">
								<button
									disabled={winner !== 0}
									onClick={() => adjustScoreTeam2(1)}
									className={`${
										winner === 0 ? "opacity-100" : "opacity-50"
									} w-full h-44 bg-green-11 rounded-full shadow-xl flex justify-center items-center`}
								>
									<svg
										className=" fill-current text-white w-16 h-16"
										x="0px"
										y="0px"
										viewBox="0 0 512 512"
									>
										<g>
											<path d="M480,224H288V32c0-17.673-14.327-32-32-32s-32,14.327-32,32v192H32c-17.673,0-32,14.327-32,32s14.327,32,32,32h192v192   c0,17.673,14.327,32,32,32s32-14.327,32-32V288h192c17.673,0,32-14.327,32-32S497.673,224,480,224z" />
										</g>
									</svg>
								</button>
								<div className="pt-5">
									<button
										disabled={team2Score - 1 < 0 || winner !== 0}
										onClick={() => adjustScoreTeam2(-1)}
										className={`${
											team2Score - 1 < 0 || winner !== 0
												? " opacity-50"
												: " opacity-100"
										} w-28 h-14 bg-orange-1 rounded-full flex justify-center items-center shadow-lg`}
									>
										<svg
											viewBox="0 0 24 24"
											className=" fill-current text-white h-full w-full"
										>
											<path d="M16.5,13.5h-9a1.5,1.5,0,0,1,0-3h9a1.5,1.5,0,0,1,0,3Z" />
										</svg>
									</button>
								</div>
							</div>
						</div>
						<div className="pt-12 pb-5 flex flex-row justify-between max-w-[400px] mx-auto">
							<Popconfirm
								title="Are you sure to end the game?"
								onConfirm={() => deleteGame()}
								okText="Yes"
								cancelText="No"
							>
								<div className="bg-orange-1 py-2 w-full max-w-[250px] mx-auto rounded-full flex justify-center shadow-lg cursor-pointer">
									<p className="font-poppins text-white font-bold text-2xl uppercase">
										end match
									</p>
								</div>
							</Popconfirm>
						</div>
					</div>
				</div>
				<div className={`${winner === 0 ? "hidden" : "block"}`}>
					<div className="pt-5">
						<p className=" font-poppins text-grey-1 text-4xl font-bold text-center uppercase">
							{data.type === 1 ? "Winner" : "Winners"}
						</p>
						<div className="pt-4 flex flex-row gap-4 justify-center max-w-[300px] mx-auto relative">
							{winner === 1 ? (
								<div>
									{data.type === 1 ? (
										<WinnerCard1Player player={data.team1.player1} />
									) : (
										<WinnerCard2Players
											player1={data.team1.player1}
											player2={data.team1.player2}
										/>
									)}
								</div>
							) : (
								<div>
									{data.type === 1 ? (
										<WinnerCard1Player player={data.team2.player1} />
									) : (
										<WinnerCard2Players
											player1={data.team2.player1}
											player2={data.team2.player2}
										/>
									)}
								</div>
							)}
						</div>
						<div className="flex justify-center items-center w-full">
							<span id="confettiReward" />
						</div>
						<div className="pt-20 flex flex-col gap-5 justify-center items-center">
							<div
								onClick={() => rematch()}
								className=" w-64 bg-green-11 border-2 border-solid border-green-11 py-3 flex justify-center rounded-2xl shadow-lg cursor-pointer"
							>
								<p className=" font-poppins text-2xl font-bold uppercase text-white">
									rematch
								</p>
							</div>
							<Link to="/ping-pong/new-match">
								<div className=" w-64 bg-white border-2 border-solid border-green-11 py-3 flex justify-center rounded-2xl shadow-lg">
									<p className=" font-poppins text-2xl font-bold uppercase text-green-11">
										new match
									</p>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
