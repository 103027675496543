import React, { useState, useEffect, useContext, useRef } from "react";
import { useDetectOutsideClick } from "../../utils/useDetectOutsideClick";
import { useHistory } from "react-router-dom";
import UserContext from "../../context/context";
import { Link } from "react-router-dom";
import axios from "axios";
import { message, Modal } from "antd";
import { Helmet } from "react-helmet";
import AddUserModal from "../../components/UserPagesComponents/addUserModal";
import {DOMAIN} from "../../env";

export default function Users() {
	const [data, setData] = useState();
	const [filteredData, setFilteredData] = useState([]);
	const [updateData, setUpdateData] = useState(true);

	const history = useHistory();
	const user = useContext(UserContext);
	const [filter, setFilter] = useState({
		category: [],
		name: "",
		email: "",
	});
	const [pagination, setPagination] = useState({
		firstItem: 0,
		lastItem: 24,
		numberOfPages: 0,
		currentPage: 1,
		itemsPerPage: 24,
	});
	const [showSelect, setShowSelect] = useState(false);

	const categoryRef = useRef(null);
	const [isCategoryActive, setIsCategoryActive] = useDetectOutsideClick(
		categoryRef,
		false
	);
	const [isFilterModalActive, setIsFilterModalActive] = useState(false);

	const toggleCategorySelection = (val) => {
		if (!filter.category.includes(val)) {
			setFilter({ ...filter, category: [...filter.category, val] });
		} else {
			setFilter({
				...filter,
				category: filter.category.filter((e) => e !== val),
			});
		}
	};

	const checkColorCategory = (arr) => {
		if (arr.length === 0) return "bg-grey-1";
		else if (arr.length === 4) return "bg-green-11";
		else return "bg-orange-1";
	};

	useEffect(() => {
		async function fetchData() {
			axios
				.get(`${DOMAIN}/api/user/getAllUsers`, {
					headers: {
						"jwt-token": user.jwtToken,
					},
				})
				.then((response) => {
					setData(response.data.userList);
					setFilteredData(dataFilter(response.data.userList, filter));
					setPagination({
						...pagination,
						numberOfPages: Math.ceil(
							response.data.userList.length / pagination.itemsPerPage
						),
					});
				})
				.catch((error) => {
					message.error({
						content: `${error.response.statusText}!`,
						className: "text-center",
						duration: 2,
					});
					history.push("/");
				});
		}
		fetchData()
		// eslint-disable-next-line
	}, [updateData]);

	const dataFilter = function (dataArr, filterArr) {
		let data = dataArr;
		if (filterArr.category.length !== 0)
			data =
				data && data.filter((i) => filterArr.category.includes(i.user_role));
		if (filterArr.name)
			data =
				data &&
				data.filter(
					(i) =>
						i.user_name.toLowerCase().includes(filterArr.name.toLowerCase()) ||
						i.user_email.toLowerCase().includes(filterArr.name.toLowerCase())
				);
		return data;
	};

	useEffect(() => {
		const filterFunction = async () => {
			const newFilter = await dataFilter(data, filter);
			if (newFilter) {
				setFilteredData(newFilter);
				setPagination({
					...pagination,
					numberOfPages: Math.ceil(newFilter.length / pagination.itemsPerPage),
					currentPage: 1,
					firstItem: 0,
					lastItem: pagination.itemsPerPage,
				});
			}
		};
		filterFunction();
		// eslint-disable-next-line
	}, [filter]);

	const renderCards = () => {
		const cards = filteredData
			?.slice(pagination.firstItem, pagination.lastItem)
			.map((element, index) => {
				return (
					<Link
						key={index}
						to={`/users/edit/${element._id}`}
						className="group relative flex w-full transform cursor-pointer flex-col justify-center rounded-3xl border-2 border-solid border-green-11 bg-white py-5 text-center transition duration-500 hover:bg-green-11"
					>
						<p className="px-4 font-poppins text-2xl font-bold text-grey-1 transition-all duration-500 group-hover:text-white lg:group-hover:scale-110">
							{element.user_name}
						</p>
						<p className="font-montserrat text-base font-bold text-grey-1 transition-all duration-500 group-hover:text-white">
							{element.user_role.replace(/([A-Z])/g, " $1").trim()}
						</p>
					</Link>
				);
			});

		if (cards) return cards;
		else {
			return [...new Array(20)].map((item, index) => (
				<div
					key={index}
					className="relative mx-auto w-72 rounded-2xl bg-gray-600 md:w-full"
				>
					<div className="relative flex animate-pulse flex-col gap-y-4 rounded-2xl bg-gray-600 p-8">
						<div className="h-4 w-full animate-pulse rounded-xl bg-gray-500"></div>
						<div></div>
						<div className="mx-auto h-4 w-3/4 animate-pulse rounded-xl bg-gray-500"></div>
					</div>
				</div>
			));
		}
	};

	return (
		<>
			<Helmet>
				<title>Users</title>
			</Helmet>
			<div className="flex justify-center bg-grey-1 p-3  md:justify-start">
				<p className=" mb-0 font-poppins text-xl font-bold text-white md:text-2xl">
					Users List
				</p>
			</div>
			<div className="p-3">
				<div className="flex flex-col gap-5 md:flex-row">
					<AddUserModal setUpdateData={setUpdateData} updateData={updateData} />
					<div className="md:ml-auto">
						<div className="hidden flex-col gap-5 md:flex md:flex-row">
							<div ref={categoryRef} className="group relative">
								<div
									onClick={() => setIsCategoryActive(!isCategoryActive)}
									className={`${checkColorCategory(
										filter.category
									)} relative flex h-12 w-40 cursor-pointer flex-row items-center justify-center gap-4 rounded-2xl px-6 shadow-lg`}
								>
									<p className="font-montserrat text-base font-bold uppercase text-white">
										Category
									</p>
								</div>
								<div
									className={`${isCategoryActive
										? "translate-y-0 opacity-100"
										: "pointer-events-none -translate-y-10 opacity-0"
										} absolute z-40 mt-2 flex h-48 w-full min-w-[190px] flex-col justify-center gap-2 overflow-hidden rounded-2xl border-2 bg-grey-4 px-2 shadow-lg transition-all duration-500`}
								>
									<div
										onClick={() => toggleCategorySelection("Marketing")}
										className={`${filter.category.includes("Marketing")
											? "bg-orange-1"
											: "bg-grey-1"
											} flex cursor-pointer flex-row items-center justify-center gap-3 rounded-xl py-2 px-3 transition-all duration-300`}
									>
										<p className="mr-auto w-11/12 font-montserrat font-bold capitalize text-white">
											Marketing
										</p>
									</div>
									<div
										onClick={() => toggleCategorySelection("HumanResources")}
										className={`${filter.category.includes("HumanResources")
											? "bg-orange-1"
											: "bg-grey-1"
											} flex cursor-pointer flex-row items-center justify-center gap-3 rounded-xl py-2 px-3 transition-all duration-300`}
									>
										<p className="mr-auto w-11/12 font-montserrat font-bold capitalize text-white">
											Human Resources
										</p>
									</div>
									<div
										onClick={() => toggleCategorySelection("Developer")}
										className={`${filter.category.includes("Developer")
											? "bg-orange-1"
											: "bg-grey-1"
											} flex cursor-pointer flex-row items-center justify-center gap-3 rounded-xl py-2 px-3 transition-all duration-300`}
									>
										<p className="mr-auto w-11/12 font-montserrat font-bold capitalize text-white">
											Developer
										</p>
									</div>
									<div
										onClick={() => toggleCategorySelection("User")}
										className={`${filter.category.includes("User")
											? "bg-orange-1"
											: "bg-grey-1"
											} flex cursor-pointer flex-row items-center justify-center gap-3 rounded-xl py-2 px-3 transition-all duration-300`}
									>
										<p className="mr-auto w-11/12 font-montserrat font-bold capitalize text-white">
											User
										</p>
									</div>
								</div>
							</div>
							<div className="flex h-12 w-48 flex-row items-center justify-center gap-2 rounded-2xl bg-grey-1 pl-4 pr-2">
								<div className="">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className=" h-5 w-5 fill-current text-white"
										viewBox="0 0 24 24"
									>
										<path d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z" />
									</svg>
								</div>
								<input
									type="text"
									placeholder="Search by name"
									onChange={(e) =>
										setFilter({ ...filter, name: e.target.value })
									}
									className={`h-8 w-full appearance-none rounded-lg bg-grey-13 py-2 px-3 leading-tight text-gray-700 focus:border-transparent focus:outline-none`}
								/>
							</div>
						</div>
						<div className="md:hidden">
							<div
								onClick={() => setIsFilterModalActive(true)}
								className=" flex h-12 w-full items-center justify-center gap-3 rounded-2xl bg-grey-1 px-4 md:w-40"
							>
								<svg
									className=" h-5 w-5 fill-current text-white"
									viewBox="0 0 24 24"
								>
									<path d="m14 24a1 1 0 0 1 -.6-.2l-4-3a1 1 0 0 1 -.4-.8v-5.62l-7.016-7.893a3.9 3.9 0 0 1 2.916-6.487h14.2a3.9 3.9 0 0 1 2.913 6.488l-7.013 7.892v8.62a1 1 0 0 1 -1 1zm-3-4.5 2 1.5v-7a1 1 0 0 1 .253-.664l7.268-8.177a1.9 1.9 0 0 0 -1.421-3.159h-14.2a1.9 1.9 0 0 0 -1.421 3.158l7.269 8.178a1 1 0 0 1 .252.664z" />
								</svg>
								<p className="font-poppins text-base font-bold uppercase text-white">
									filter
								</p>
							</div>
							<Modal
								title={
									<p className="font-poppins text-lg font-bold uppercase text-grey-1">
										Filter
									</p>
								}
								open={isFilterModalActive}
								onOk={() => setIsFilterModalActive(false)}
								onCancel={() => setIsFilterModalActive(false)}
								footer={null}
								closeIcon={
									<svg
										viewBox="0 0 24 24"
										className="h-6 w-6 fill-current text-grey-1 transition-all duration-300 hover:rotate-180"
									>
										<path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" />
									</svg>
								}
							>
								<div className=" space-y-5">
									<div className="relative">
										<label
											htmlFor="Name"
											className="font-poppins text-lg font-bold uppercase text-grey-1"
										>
											Name
										</label>
										<input
											id="Name"
											type="text"
											placeholder="Search by name"
											onChange={(e) =>
												setFilter({ ...filter, name: e.target.value })
											}
											className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none`}
										/>
									</div>
									<div>
										<p className="font-poppins text-lg font-bold uppercase text-grey-1">
											Category
										</p>
										<div className=" flex flex-col space-y-2 rounded-xl border-2 px-2 py-3">
											<div
												onClick={() => toggleCategorySelection("Marketing")}
												className={`${filter.category.includes("Marketing")
													? "bg-orange-1"
													: "bg-grey-1"
													} flex cursor-pointer flex-row items-center justify-center gap-3 rounded-xl py-2 px-3 transition-all duration-300`}
											>
												<p className="mr-auto w-11/12 font-montserrat font-bold capitalize text-white">
													Marketing
												</p>
											</div>
											<div
												onClick={() =>
													toggleCategorySelection("HumanResources")
												}
												className={`${filter.category.includes("HumanResources")
													? "bg-orange-1"
													: "bg-grey-1"
													} flex cursor-pointer flex-row items-center justify-center gap-3 rounded-xl py-2 px-3 transition-all duration-300`}
											>
												<p className="mr-auto w-11/12 font-montserrat font-bold capitalize text-white">
													Human Resources
												</p>
											</div>
											<div
												onClick={() => toggleCategorySelection("Developer")}
												className={`${filter.category.includes("Developer")
													? "bg-orange-1"
													: "bg-grey-1"
													} flex cursor-pointer flex-row items-center justify-center gap-3 rounded-xl py-2 px-3 transition-all duration-300`}
											>
												<p className="mr-auto w-11/12 font-montserrat font-bold capitalize text-white">
													Developer
												</p>
											</div>
											<div
												onClick={() => toggleCategorySelection("User")}
												className={`${filter.category.includes("User")
													? "bg-orange-1"
													: "bg-grey-1"
													} flex cursor-pointer flex-row items-center justify-center gap-3 rounded-xl py-2 px-3 transition-all duration-300`}
											>
												<p className="mr-auto w-11/12 font-montserrat font-bold capitalize text-white">
													User
												</p>
											</div>
										</div>
									</div>
									<div>
										<p className="font-poppins text-lg font-bold uppercase text-grey-1">
											Cards per page
										</p>
										<div className="flex flex-row gap-2 rounded-xl border-2 px-2 py-3">
											<div
												className={`${pagination.itemsPerPage === 24
													? "bg-green-11"
													: "bg-grey-1"
													} flex w-full justify-center rounded-xl py-2`}
												onClick={() =>
													setPagination({
														...pagination,
														itemsPerPage: 24,
														firstItem: 0,
														lastItem: 24,
														numberOfPages: Math.ceil(filteredData?.length / 24),
														currentPage: 1,
													})
												}
											>
												<p className="font-poppins text-base font-bold text-white">
													24
												</p>
											</div>
											<div
												className={`${pagination.itemsPerPage === 48
													? "bg-green-11"
													: "bg-grey-1"
													} flex w-full justify-center rounded-xl py-2`}
												onClick={() =>
													setPagination({
														...pagination,
														itemsPerPage: 48,
														firstItem: 0,
														lastItem: 48,
														numberOfPages: Math.ceil(filteredData?.length / 48),
														currentPage: 1,
													})
												}
											>
												<p className="font-poppins text-base font-bold text-white">
													48
												</p>
											</div>
											<div
												className={`${pagination.itemsPerPage === 72
													? "bg-green-11"
													: "bg-grey-1"
													} flex w-full justify-center rounded-xl py-2`}
												onClick={() =>
													setPagination({
														...pagination,
														itemsPerPage: 72,
														firstItem: 0,
														lastItem: 72,
														numberOfPages: Math.ceil(filteredData?.length / 72),
														currentPage: 1,
													})
												}
											>
												<p className="font-poppins text-base font-bold text-white">
													72
												</p>
											</div>
										</div>
									</div>
								</div>
							</Modal>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-5 py-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
					{renderCards()}
				</div>
				<div className={`${filteredData?.length === 0 ? "hidden" : "flex"} sticky z-10 mt-auto flex-wrap justify-center gap-5 pb-6 bottom-0 inset-x-0`} >
					<div className="pointer-events-auto flex flex-row rounded-xl bg-white shadow-lg">
						<div
							className={`${pagination.currentPage === 1 ? " opacity-70" : ""
								} group cursor-pointer select-none rounded-l-xl border-2 px-4 py-2 transition-colors duration-500 hover:bg-green-11`}
							onClick={() => {
								if (pagination.currentPage > 1) {
									setPagination({
										...pagination,
										firstItem: pagination.firstItem - pagination.itemsPerPage,
										lastItem: pagination.lastItem - pagination.itemsPerPage,
										currentPage: pagination.currentPage - 1,
									});
								}
							}}
						>
							<div className=" flex h-full items-center justify-center">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className=" h-4 w-4 fill-current text-grey-1 transition-colors duration-500 group-hover:text-white"
									viewBox="0 0 24 24"
								>
									<path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
								</svg>
							</div>
						</div>
						{[...Array(pagination.numberOfPages)].map((elem, index) => {
							return (
								<div
									onClick={() => {
										setPagination({
											...pagination,
											firstItem: index * pagination.itemsPerPage,
											lastItem: (index + 1) * pagination.itemsPerPage,
											currentPage: index + 1,
										});
									}}
									className={`${pagination.currentPage === index + 1
										? "bg-green-11 text-white"
										: "text-grey-1"
										} flex h-full cursor-pointer select-none items-center justify-center border-y-2 border-r-2 px-4 py-2 transition-all duration-500 hover:bg-green-11 hover:text-white`}
									key={`page-btn-${index}`}
								>
									<p className="font-poppins font-bold">{index + 1}</p>
								</div>
							);
						})}
						<div
							className={`${pagination.currentPage === pagination.numberOfPages
								? " opacity-70"
								: ""
								} group cursor-pointer select-none rounded-r-xl border-r-2 border-b-2 border-t-2 px-4 py-2 transition-colors duration-500 hover:bg-green-11`}
							onClick={() => {
								if (pagination.currentPage < pagination.numberOfPages) {
									setPagination({
										...pagination,
										firstItem: pagination.firstItem + pagination.itemsPerPage,
										lastItem: pagination.lastItem + pagination.itemsPerPage,
										currentPage: pagination.currentPage + 1,
									});
								}
							}}
						>
							<div className=" flex h-full rotate-180 transform items-center justify-center">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className=" h-4 w-4 fill-current text-grey-1 transition-colors duration-500 group-hover:text-white"
									viewBox="0 0 24 24"
								>
									<path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
								</svg>
							</div>
						</div>
					</div>
					<div
						className="relative hidden cursor-pointer rounded-xl border-2 bg-white px-3 py-2 lg:block"
						onClick={() => setShowSelect(!showSelect)}
					>
						<div className="flex flex-row items-center justify-center gap-2">
							<p className=" font-poppins font-bold text-grey-1">
								{pagination.itemsPerPage}
							</p>
							<div
								className={`transform transition-transform duration-500 ${showSelect ? "" : "rotate-180"
									}`}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="12"
									height="12"
									viewBox="0 0 24 24"
								>
									<path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
								</svg>
							</div>
						</div>
						<div
							className={`${showSelect
								? "translate-y-0 opacity-100"
								: "-translate-y-10 opacity-0"
								} absolute -top-32 left-0 flex w-full transform flex-col transition-all duration-500`}
						>
							<div
								className="flex justify-center rounded-t-xl border-2 bg-white px-3 py-2 text-grey-1 transition-colors duration-500 hover:bg-green-11 hover:text-white"
								onClick={() =>
									setPagination({
										...pagination,
										itemsPerPage: 72,
										firstItem: 0,
										lastItem: 72,
										numberOfPages: Math.ceil(filteredData?.length / 72),
										currentPage: 1,
									})
								}
							>
								<p className="font-poppins font-bold">72</p>
							</div>
							<div
								className="flex justify-center border-r-2 border-l-2 bg-white px-3 py-2 text-grey-1 transition-colors duration-500 hover:bg-green-11 hover:text-white"
								onClick={() =>
									setPagination({
										...pagination,
										itemsPerPage: 48,
										firstItem: 0,
										lastItem: 48,
										numberOfPages: Math.ceil(filteredData?.length / 48),
										currentPage: 1,
									})
								}
							>
								<p className="font-poppins font-bold">48</p>
							</div>
							<div
								className="flex justify-center rounded-b-xl border-2 bg-white px-3 py-2 text-grey-1 transition-colors duration-500 hover:bg-green-11 hover:text-white"
								onClick={() =>
									setPagination({
										...pagination,
										itemsPerPage: 24,
										firstItem: 0,
										lastItem: 24,
										numberOfPages: Math.ceil(filteredData?.length / 24),
										currentPage: 1,
									})
								}
							>
								<p className="font-poppins font-bold">24</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
