import React, { useState, useContext } from "react";
import UserContext from "../../context/context";
import { Modal, Button, Select, message } from "antd";
import axios from "axios";
import {DOMAIN} from "../../env";

export default function AddQuestionModal(props) {
	const user = useContext(UserContext);
	const { Option } = Select;
	const [numberOfAnswers, setNumberOfAnswers] = useState(4);
	const [questionData, setQuestionData] = useState({
		type: { state: "", error: "" },
		format: { state: "", error: "" },
		name: { state: "", error: "" },
		paragraph: { state: "", error: "" },
		imageText: { state: "" },
		image: {
			state: null,
			name: "No file chosen",
			imgSrc: null,
			error: "",
		},
		questions: [
			{
				name: {
					state: "",
					error: "",
				},
				answers: [
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
				],
				error: "",
			},
			{
				name: {
					state: "",
					error: "",
				},
				answers: [
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
				],
				error: "",
			},
			{
				name: {
					state: "",
					error: "",
				},
				answers: [
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
				],
				error: "",
			},
			{
				name: {
					state: "",
					error: "",
				},
				answers: [
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
				],
				error: "",
			},
			{
				name: {
					state: "",
					error: "",
				},
				answers: [
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
				],
				error: "",
			},
			{
				name: {
					state: "",
					error: "",
				},
				answers: [
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
					{
						correct: false,
						state: "",
						error: "",
					},
				],
				error: "",
			},
		],
		answears: [
			{
				correct: false,
				name: {
					state: "",
					error: "",
				},
				image: {
					state: null,
					name: "",
					imgSrc: null,
					error: "",
				},
			},
			{
				correct: false,
				name: {
					state: "",
					error: "",
				},
				image: {
					state: null,
					name: "",
					imgSrc: null,
					error: "",
				},
			},
			{
				correct: false,
				name: {
					state: "",
					error: "",
				},
				image: {
					state: null,
					name: "",
					imgSrc: null,
					error: "",
				},
			},
			{
				correct: false,
				name: {
					state: "",
					error: "",
				},
				image: {
					state: null,
					name: "",
					imgSrc: null,
					error: "",
				},
			},
			{
				correct: false,
				name: {
					state: "",
					error: "",
				},
				image: {
					state: null,
					name: "",
					imgSrc: null,
					error: "",
				},
			},
			{
				correct: false,
				name: {
					state: "",
					error: "",
				},
				image: {
					state: null,
					name: "",
					imgSrc: null,
					error: "",
				},
			},
		],
	});

	const closeModal = () => {
		props.setIsModalActive(false);
		setQuestionData({
			type: { state: "", error: "" },
			format: { state: "", error: "" },
			name: { state: "", error: "" },
			paragraph: { state: "", error: "" },
			imageText: { state: "" },
			image: {
				state: null,
				name: "No file chosen",
				imgSrc: null,
				error: "",
			},
			questions: [
				{
					name: {
						state: "",
						error: "",
					},
					answers: [
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
					],
					error: "",
				},
				{
					name: {
						state: "",
						error: "",
					},
					answers: [
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
					],
					error: "",
				},
				{
					name: {
						state: "",
						error: "",
					},
					answers: [
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
					],
					error: "",
				},
				{
					name: {
						state: "",
						error: "",
					},
					answers: [
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
					],
					error: "",
				},
				{
					name: {
						state: "",
						error: "",
					},
					answers: [
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
					],
					error: "",
				},
				{
					name: {
						state: "",
						error: "",
					},
					answers: [
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
						{
							correct: false,
							state: "",
							error: "",
						},
					],
					error: "",
				},
			],
			answears: [
				{
					correct: false,
					name: {
						state: "",
						error: "",
					},
					image: {
						state: null,
						name: "",
						imgSrc: null,
						error: "",
					},
				},
				{
					correct: false,
					name: {
						state: "",
						error: "",
					},
					image: {
						state: null,
						name: "",
						imgSrc: null,
						error: "",
					},
				},
				{
					correct: false,
					name: {
						state: "",
						error: "",
					},
					image: {
						state: null,
						name: "",
						imgSrc: null,
						error: "",
					},
				},
				{
					correct: false,
					name: {
						state: "",
						error: "",
					},
					image: {
						state: null,
						name: "",
						imgSrc: null,
						error: "",
					},
				},
				{
					correct: false,
					name: {
						state: "",
						error: "",
					},
					image: {
						state: null,
						name: "",
						imgSrc: null,
						error: "",
					},
				},
				{
					correct: false,
					name: {
						state: "",
						error: "",
					},
					image: {
						state: null,
						name: "",
						imgSrc: null,
						error: "",
					},
				},
			],
		});
	};

	function handleChangeIMG(event) {
		const fileType = ["image/png", "image/jpg", "image/jpeg"];
		if (event.target.value.length !== 0)
			if (event.target.files[0].size > 10485760) {
				event.target.value = null;
				setQuestionData({
					...questionData,
					image: {
						error: "Only files under 10MB",
						name: "No file chosen",
					},
				});
				return;
			}
		if (!fileType.includes(event.target.files[0].type)) {
			event.target.value = null;
			setQuestionData({
				...questionData,
				image: {
					error: "Incorrect file type, only: .png, .jpg, .jpeg",
					name: "No file chosen",
				},
			});
		} else {
			var reader = new FileReader();
			// eslint-disable-next-line
			var url = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function () {
				setQuestionData({
					...questionData,
					image: {
						...questionData.image,
						state: event.target.files[0],
						imgSrc: [reader.result],
						error: "",
						name: event.target.files[0].name,
					},
				});
			};
		}
	}

	const handleChangeImgAnswer = (event, index) => {
		const fileType = ["image/png", "image/jpg", "image/jpeg"];
		if (event.target.value.length !== 0)
			if (event.target.files[0].size > 10485760) {
				event.target.value = null;
				setQuestionData((oldData) => {
					let newData = { ...oldData };
					newData.answears[index].image = {
						error: "Only files under 10MB",
						name: "No file chosen",
					};
					return newData;
				});
				return;
			}
		if (!fileType.includes(event.target.files[0].type)) {
			event.target.value = null;
			setQuestionData((oldData) => {
				let newData = { ...oldData };
				newData.answears[index].image = {
					error: "Incorrect file type, only: .png, .jpg, .jpeg",
					name: "No file chosen",
				};
				return newData;
			});
		} else {
			var reader = new FileReader();
			// eslint-disable-next-line
			var url = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function () {
				setQuestionData((oldData) => {
					let newData = { ...oldData };
					newData.answears[index].image = {
						state: event.target.files[0],
						imgSrc: [reader.result],
						error: "",
						name: event.target.files[0].name,
					};
					return newData;
				});
			};
		}
	};

	const handleChangeInputAnswers = (index, value) => {
		setQuestionData((oldData) => {
			let newData = { ...oldData };
			newData.answears[index].name.state = value;
			return newData;
		});
	};

	const handleChangeInputParagraphQuestionsName = (index, value) => {
		setQuestionData((oldData) => {
			let newData = { ...oldData };
			newData.questions[index].name.state = value;
			return newData;
		});
	};

	const handleChangeInputParagraphQuestionsAnswers = ( questionIndex, answearIndex, value ) => {
		setQuestionData((oldData) => {
			let newData = { ...oldData };
			newData.questions[questionIndex].answers[answearIndex].state = value;
			return newData;
		});
	};

	const changeCorrectAnswerParagraph = (questionIndex, answearIndex, value) => {
		setQuestionData((oldData) => {
			let newData = { ...oldData };
			newData.questions[questionIndex].answers.map(
				(elem) => (elem.correct = false)
			);
			newData.questions[questionIndex].answers[answearIndex].correct = value;
			return newData;
		});
	};

	const changeCorrectAnswerMultipleChoice = (index, value) => {
		setQuestionData((oldData) => {
			let newData = { ...oldData };
			newData.answears[index].correct = value;
			let checkArray = newData.answears.slice(0, numberOfAnswers);
			const IfAllTrue = checkArray.every((obj) => obj.correct === true);
			if (IfAllTrue) {
				newData.answears[index].correct = !value;
				return oldData;
			}
			return newData;
		});
	};

	const changeCorrectAnswerSingleChoice = (index, value) => {
		setQuestionData((oldData) => {
			let newData = { ...oldData };
			newData.answears.map((elem) => (elem.correct = false));
			newData.answears[index].correct = value;
			return newData;
		});
	};

	const handleValidation = () => {
		let error = false;
		if (!questionData.type.state) {
			error = true;
			setQuestionData((oldData) => {
				let newData = { ...oldData };
				newData.type.error = "Please select a value!";
				return newData;
			});
		} else {
			setQuestionData((oldData) => {
				let newData = { ...oldData };
				newData.type.error = "";
				return newData;
			});
		}
		if (!questionData.format.state) {
			error = true;
			setQuestionData((oldData) => {
				let newData = { ...oldData };
				newData.format.error = "Please select a value!";
				return newData;
			});
		} else {
			setQuestionData((oldData) => {
				let newData = { ...oldData };
				newData.format.error = "";
				return newData;
			});
		}
		if (questionData.format.state === "Text" && questionData.type.state !== "Paragraph"){
			if (!questionData.name.state) {
				error = true;
				setQuestionData((oldData) => {
					let newData = { ...oldData };
					newData.name.error = "This field can not be empty!";
					return newData;
				});
			} else {
				setQuestionData((oldData) => {
					let newData = { ...oldData };
					newData.name.error = "";
					return newData;
				});
			}
		}
		if (questionData.format.state === "Image")
			if (!questionData.image.state) {
				error = true;
				setQuestionData((oldData) => {
					let newData = { ...oldData };
					newData.image.error = "Please select an image!";
					return newData;
				});
			} else {
				setQuestionData((oldData) => {
					let newData = { ...oldData };
					newData.image.error = "";
					return newData;
				});
			}
		if ( (questionData.type.state === "Multiple Choice" || questionData.type.state === "Single Choice") && questionData.type.state !== "" ) {
			questionData.answears
				.filter((item, index) => index < numberOfAnswers)
				.forEach((elem, index) => {
					console.log(elem)
					if (questionData.format.state === "Image") {
						console.log(elem.image.state)
						if (!elem.image.state) {
							error = true;
							setQuestionData((oldData) => {
								let newData = { ...oldData };
								newData.answears[index].image.error = "Please select an image!";
								return newData;
							});
						} else {
							setQuestionData((oldData) => {
								let newData = { ...oldData };
								newData.answears[index].image.error = "";
								return newData;
							});
						}
					}
					if (questionData.format.state === "Text") {
						if (!elem.name.state) {
							error = true;
							setQuestionData((oldData) => {
								let newData = { ...oldData };
								newData.answears[index].name.error =
									"Please complete this field!";
								return newData;
							});
						} else {
							setQuestionData((oldData) => {
								let newData = { ...oldData };
								newData.answears[index].name.error = "";
								return newData;
							});
						}
					}
				});
		}

		if (questionData.type.state === "Paragraph") {
			if (!questionData.paragraph.state) {
				error = true;
				setQuestionData((oldData) => {
					let newData = { ...oldData };
					newData.paragraph.error = "This field can not be empty!";
					return newData;
				});
			} else {
				setQuestionData((oldData) => {
					let newData = { ...oldData };
					newData.paragraph.error = "";
					return newData;
				});
			}

			questionData.questions
				.filter((item, index) => index < numberOfAnswers)
				.forEach((elem, index) => {
					if (!questionData.questions[index].name.state) {
						error = true;
						setQuestionData((oldData) => {
							let newData = { ...oldData };
							newData.questions[index].name.error =
								"This field can not be empty!";
							return newData;
						});
					} else {
						setQuestionData((oldData) => {
							let newData = { ...oldData };
							newData.questions[index].name.error = "";
							return newData;
						});
					}

					questionData.questions[index].answers.forEach((elem1, index1) => {
						if (!questionData.questions[index].answers[index1].state) {
							error = true;
							setQuestionData((oldData) => {
								let newData = { ...oldData };
								newData.questions[index].answers[index1].error =
									"This field can not be empty!";
								return newData;
							});
						} else {
							setQuestionData((oldData) => {
								let newData = { ...oldData };
								newData.questions[index].answers[index1].error = "";
								return newData;
							});
						}
					});

					const checkIfAllFalse = questionData.questions[index].answers.every(
						(el) => {
							return el.correct === false;
						}
					);
					if (checkIfAllFalse) {
						error = true;
						setQuestionData((oldData) => {
							let newData = { ...oldData };
							newData.questions[index].error = "At least one correct answer!";
							return newData;
						});
					} else {
						setQuestionData((oldData) => {
							let newData = { ...oldData };
							newData.questions[index].error = "";
							return newData;
						});
					}
				});
		}

		if ( questionData.type.state !== "Free Text" && questionData.type.state !== "Paragraph" ) {
			const checkIfAllFalse = questionData.answears.every((el) => {
				return el.correct === false;
			});
			if (checkIfAllFalse) {
				error = true;
				message.error({
					content: `At least one correct answer!`,
					className: "text-center",
					duration: 2,
				});
			}
		}

		return error;
	};

	const handleSubmit = () => {
		if (!handleValidation()) {
			const loadingMessage = message.loading("Loading...", 0);
			var formData = new FormData();
			formData.append("type", questionData.type.state);
			formData.append("format", questionData.format.state);
			formData.append("questionBankId", props.questionBankId);
			formData.append("numberOfAnswers", numberOfAnswers);
			if (questionData.type.state !== "Paragraph") {
				if (questionData.format.state === "Text")
					formData.append("name", questionData.name.state);
				if (questionData.format.state === "Image") {
					formData.append("question_image", questionData.image.state);
					formData.append("question_imageText", questionData.imageText.state);
				}
			}
			if (
				questionData.type.state === "Single Choice" ||
				questionData.type.state === "Multiple Choice"
			) {
				if (questionData.format.state === "Text") {
					questionData.answears
						.filter((item, index) => index < numberOfAnswers)
						.forEach((elementInArray, index) => {
							formData.append(
								`ans_${index + 1}_value`,
								questionData.answears[index].name.state
							);
							formData.append(
								`ans_${index + 1}_correct`,
								questionData.answears[index].correct
							);
						});
				}
				if (questionData.format.state === "Image") {
					questionData.answears
						.filter((item, index) => index < numberOfAnswers)
						.forEach((elementInArray, index) => {
							formData.append(
								`ans_${index + 1}_img`,
								questionData.answears[index].image.state
							);
							formData.append(
								`ans_${index + 1}_correct`,
								questionData.answears[index].correct
							);
						});
				}
			}
			if (questionData.type.state === "Paragraph") {
				formData.append("paragraph", questionData.paragraph.state);
				questionData.questions
					.filter((item, index) => index < numberOfAnswers)
					.forEach((elementInArray, questionIndex) => {
						formData.append(
							`question_${questionIndex + 1}`,
							questionData.questions[questionIndex].name.state
						);
						questionData.questions[questionIndex].answers.forEach(
							(answer, answerIndex) => {
								formData.append(
									`question_${questionIndex + 1}_answer_${
										answerIndex + 1
									}_text`,
									questionData.questions[questionIndex].answers[answerIndex]
										.state
								);
								formData.append(
									`question_${questionIndex + 1}_answer_${
										answerIndex + 1
									}_correct`,
									questionData.questions[questionIndex].answers[answerIndex]
										.correct
								);
							}
						);
					});
			}

			axios
				.post(
					`${DOMAIN}/api/careers_quiz/create_new_question`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
							"jwt-token": user.jwtToken,
						},
					}
				)
				.then((res) => {
					closeModal();
					message.success({
						content: `Question Added!`,
						className: "text-center",
						duration: 2,
					});
					props.setUpdateQuestionList(!props.updateQuestionList);
				})
				.catch((error) => {
					message.error({
						content: `Network Error!`,
						className: "text-center",
						duration: 2,
					});
				})
				.finally(() => {
					loadingMessage();
				});
		}
	};

	return (
		<Modal
			zIndex={1000}
			title={`Add new question`}
			open={props.isModalActive}
			onCancel={() => closeModal()}
			footer={[
				<Button key="back" onClick={() => closeModal()}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={() => handleSubmit()}>
					Confirm
				</Button>,
			]}
			maskClosable={false}
			width={900}
		>
			<div className="flex flex-col gap-5">
				<div className="flex flex-col md:flex-row w-full gap-5">
					<div className="w-full relative">
						<Select
							id="quetionType"
							className={`peer h-12 font-raleway appearance-none w-full py-2 border-2 rounded-xl text-lg leading-tight placeholder-transparent cursor-default focus:border-green-11 transition-colors duration-500 focus-within:border-green-11 ${
								questionData.type.error
									? "border-orange-1 focus:ring-orange-1"
									: "hover:border-green-11"
							}`}
							value={questionData.type.state || undefined}
							onChange={(value) => {
								if (value === "Free Text" || value === "Paragraph")
									setQuestionData((questionData) => {
										let newData = { ...questionData };
										newData.format.state = "Text";
										newData.type.state = value;
										return newData;
									});
								else {
									setQuestionData((oldData) => {
										let newData = { ...oldData };
										newData.answears.map((elem) => (elem.correct = false));
										return newData;
									});
									setQuestionData((questionData) => {
										let newData = { ...questionData };
										newData.type.state = value;
										return newData;
									});
								}
							}}
							getPopupContainer={(trigger) => trigger.parentElement}
						>
							<Option value="Multiple Choice">Multiple Choice</Option>
							<Option value="Single Choice">Single Choice</Option>
							<Option value="Free Text">Free Text</Option>
							<Option value="Paragraph">Paragraph</Option>
						</Select>
						<label
							htmlFor="quetionType"
							className={`${
								questionData.type.state.length
									? "-top-2 text-sm"
									: "top-[0.65rem] text-lg"
							} transform transition-all duration-500 absolute left-3 px-1 font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus-within:-top-2 peer-focus-within:text-sm peer-placeholder-shown:text-lg`}
						>
							Type
						</label>
						<div
							className={`absolute transform transition-all duration-500 ${
								questionData.type.error ? " translate-y-0" : "translate-y-2"
							}`}
						>
							<p className="text-orange-1 font-raleway">
								{questionData.type.error}
							</p>
						</div>
					</div>
					<div className="w-full relative">
						<Select
							id="questionFormat"
							value={questionData.format.state || undefined}
							className={`peer h-12 font-raleway appearance-none w-full py-2 border-2 rounded-xl text-lg leading-tight placeholder-transparent cursor-default focus:border-green-11 transition-colors duration-500 focus-within:border-green-11 ${
								questionData.format.error
									? "border-orange-1 focus:ring-orange-1"
									: "hover:border-green-11"
							}`}
							onChange={(value) => {
								setQuestionData((oldData) => {
									let newData = { ...oldData };
									newData.answears.map((elem) => (elem.correct = false));
									return newData;
								});
								setQuestionData((questionData) => {
									let newData = { ...questionData };
									newData.format.state = value;
									return newData;
								});
							}}
							getPopupContainer={(trigger) => trigger.parentElement}
						>
							<Option value="Text">Text</Option>
							<Option
								disabled={
									questionData.type.state === "Free Text" ||
									questionData.type.state === "Paragraph"
								}
								value="Image"
							>
								Image
							</Option>
						</Select>
						<label
							htmlFor="questionFormat"
							className={`${
								questionData.format.state.length
									? "-top-2 text-sm"
									: "top-[0.65rem] text-lg"
							} transform transition-all duration-500 absolute left-3 px-1 font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus-within:-top-2 peer-focus-within:text-sm peer-placeholder-shown:text-lg`}
						>
							Format
						</label>
						<div
							className={`absolute transform transition-all duration-500 ${
								questionData.format.error ? " translate-y-0" : "translate-y-2"
							}`}
						>
							<p className="text-orange-1 font-raleway">
								{questionData.format.error}
							</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-full gap-5">
					{
						// For Single/Multipe Choice Questions
						questionData.format.state === "Text" && questionData.type.state !== "Paragraph" ? (
							<div className="relative">
								<input
									id="questionName"
									placeholder="Please specify the text of the question"
									value={questionData.name.state}
									onChange={(e) =>
										setQuestionData({
											...questionData,
											name: { ...questionData.name, state: e.target.value },
										})
									}
									className={` peer h-12 font-raleway appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 ${
										questionData.name.error?.length !== 0
											? "border-orange-1 focus:ring-orange-1"
											: "hover:border-green-11"
									}`}
								/>
								<label
									htmlFor="questionName"
									className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
								>
									Question Name
								</label>
								<div
									className={`absolute transform transition-all duration-500 ${
										questionData.name.error ? " translate-y-0" : "translate-y-2"
									}`}
								>
									<p className="text-orange-1 font-poppins font-semibold">
										{questionData.name.error}
									</p>
								</div>
							</div>
						) : null
					}
					{
						// For Paragraph Questions
						questionData.format.state === "Text" && questionData.type.state === "Paragraph" ? (
							<div className="relative">
								<div className=" relative">
									<textarea
										id="questionParagraph"
										placeholder="Please specify the paragraph"
										value={questionData.paragraph.state}
										onChange={(e) =>
											setQuestionData({
												...questionData,
												paragraph: {
													...questionData.paragraph,
													state: e.target.value,
												},
											})
										}
										maxLength={1000}
										className={`peer h-12 min-h-[3rem] font-raleway appearance-none w-full py-2 pl-3 pr-20 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent transition-colors duration-500 focus-within:border-green-11  ${
											questionData.paragraph.error
												? "border-orange-1 focus:ring-orange-1"
												: "hover:border-green-11"
										}`}
									/>
									<label
										htmlFor="questionParagraph"
										className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
									>
										Paragraph
									</label>
									<div className="absolute bottom-2 right-3 pointer-events-none">
										<p className=" text-grey-1 font-raleway">
											{questionData.paragraph.state.length}/1000
										</p>
									</div>
								</div>
								<div
									className={`absolute transform transition-all duration-500 ${
										questionData.paragraph.error
											? " translate-y-0"
											: "translate-y-2"
									}`}
								>
									<p className="text-orange-1 font-poppins font-semibold">
										{questionData.paragraph.error}
									</p>
								</div>
								<div className="pt-4 flex flex-col gap-5">
									<div className="w-full flex justify-center items-center relative">
										<div className=" absolute h-1 bg-grey-1 w-full rounded-full"></div>
										<div className=" bg-white px-3 relative rounded-full">
											<p className="font-poppins text-grey-1 font-bold text-2xl uppercase">
												Questions
											</p>
										</div>
									</div>
									<div className="relative">
										<Select
											id="numberOfQuestions"
											placeholder="Please select the type of the question"
											className={`peer h-12 font-poppins appearance-none w-full py-2 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent cursor-default focus:border-green-11 transition-colors duration-500 focus-within:border-green-11 hover:border-green-11`}
											value={numberOfAnswers || undefined}
											onChange={(value) => {
												setNumberOfAnswers(parseInt(value));
											}}
											getPopupContainer={(trigger) => trigger.parentElement}
										>
											<Option value="3">3</Option>
											<Option value="4">4</Option>
											<Option value="5">5</Option>
											<Option value="6">6</Option>
										</Select>
										<label
											htmlFor="numberOfQuestions"
											className={`${
												numberOfAnswers
													? "-top-2 text-sm"
													: "top-[0.65rem] text-lg"
											} transform transition-all duration-500 absolute left-3 px-1 font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus-within:-top-2 peer-focus-within:text-sm peer-placeholder-shown:text-lg`}
										>
											Number of questions
										</label>
									</div>

									<div className="grid grid-cols-2 gap-5">
										{questionData.questions
											.filter((item, index) => index < numberOfAnswers)
											.map((elementInArray, index) => (
												<div
													key={`question-${index}`}
													className="flex flex-col select-none col-span-2 md:col-span-1 relative"
												>
													<div className="flex justify-center bg-zinc-200 px-4 py-2 rounded-t-2xl">
														<p className=" font-poppins text-grey-1 font-bold text-lg">
															Question {index + 1}
														</p>
													</div>
													<div className=" border-x-2 border-b-2 border-grey-4 rounded-b-3xl p-4 flex flex-col gap-5 shadow-md">
														<div className="relative">
															<input
																id={`question-${index + 1}-text`}
																placeholder={`Question ${index + 1} text`}
																value={questionData.questions[index].name.state}
																onChange={(e) =>
																	handleChangeInputParagraphQuestionsName(
																		index,
																		e.target.value
																	)
																}
																className={`peer h-12 font-raleway appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 focus-within:border-green-11 ${
																	questionData.questions[index].name.error
																		.length !== 0
																		? "border-orange-1 focus:ring-orange-1"
																		: "hover:border-green-11"
																}`}
															/>
															<label
																htmlFor={`question-${index + 1}-text`}
																className="transform transition-all duration-300 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
															>
																Text
															</label>
															<div
																className={`absolute transform transition-all duration-500 ${
																	questionData.questions[index].name.error
																		? " translate-y-0"
																		: "translate-y-2"
																}`}
															>
																<p className="text-orange-1 font-poppins font-semibold">
																	{questionData.questions[index].name.error}
																</p>
															</div>
														</div>
														<div className=" grid grid-cols-1 gap-7 pt-2 pb-7">
															{questionData.questions[index].answers.map(
																(elementInArray, index1) => (
																	<div
																		className="relative flex flex-row items-center"
																		key={`answear-${index1 + 1}`}
																	>
																		<input
																			id={`question-${index + 1}-answer-${
																				index1 + 1
																			}`}
																			placeholder={`Answear ${index1 + 1}`}
																			value={
																				questionData.questions[index].answers[
																					index1
																				].state
																			}
																			onChange={(e) =>
																				handleChangeInputParagraphQuestionsAnswers(
																					index,
																					index1,
																					e.target.value
																				)
																			}
																			className={`peer h-12 font-raleway appearance-none w-full py-2 px-3 border-y-2 border-l-2 rounded-y-xl rounded-l-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 focus-within:border-green-11 ${
																				questionData.questions[index].answers[
																					index1
																				].error.length !== 0
																					? "border-orange-1 focus:ring-orange-1"
																					: "hover:border-green-11"
																			}`}
																		/>
																		<label
																			htmlFor={`question-${index + 1}-answer-${
																				index1 + 1
																			}`}
																			className="transform transition-all duration-300 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
																		>
																			Answer {index1 + 1}
																		</label>
																		<div
																			className={`absolute -bottom-5 left-0 transform transition-all duration-500 ${
																				questionData.questions[index].answers[
																					index1
																				].error
																					? " translate-y-0"
																					: "translate-y-2"
																			}`}
																		>
																			<p className="text-orange-1 font-poppins font-semibold">
																				{
																					questionData.questions[index].answers[
																						index1
																					].error
																				}
																			</p>
																		</div>
																		<div
																			onClick={() =>
																				changeCorrectAnswerParagraph(
																					index,
																					index1,
																					!questionData.questions[index].answers[
																						index1
																					].correct
																				)
																			}
																			className={`${
																				questionData.questions[index].answers[
																					index1
																				].correct
																					? "border-green-11 bg-green-11"
																					: "border-orange-1 hover:bg-orange-1"
																			} border-2 flex h-full px-4 items-center rounded-r-xl transition-all duration-500 group cursor-pointer`}
																		>
																			{questionData.questions[index].answers[
																				index1
																			].correct ? (
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					className={`${
																						questionData.questions[index].answers[
																							index1
																						].correct
																							? "text-white"
																							: "text-green-11"
																					} w-6 h-6 fill-current  cursor-pointer group-hover:text-white transition-colors duration-500`}
																					viewBox="0 0 24 24"
																				>
																					<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
																				</svg>
																			) : (
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					className=" w-6 h-6 fill-current text-orange-1 cursor-pointer group-hover:text-white transition-colors duration-500"
																					viewBox="0 0 24 24"
																				>
																					<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
																				</svg>
																			)}
																		</div>
																	</div>
																)
															)}
														</div>
														<div
															className={`absolute w-full text-center bottom-1 transform transition-all duration-500 ${
																questionData.questions[index].error
																	? "translate-y-0"
																	: "translate-y-2"
															}`}
														>
															<p className="text-orange-1 font-poppins font-semibold">
																{questionData.questions[index].error}
															</p>
														</div>
													</div>
												</div>
											))}
									</div>
								</div>
							</div>
						) : null
					}
					{
						// For Image Questions
						questionData.format.state === "Image" ? (
							<div className="flex flex-col gap-5 items-start">
								<div className="w-full h-full relative group">
									<input onChange={(event) => handleChangeIMG(event)} className="absolute w-full h-full opacity-0 cursor-pointer" id="resume" type="file" accept="image/png, image/jpg,image/jpeg"/>
									<div className="h-full w-full cursor-pointer pointer-events-none">
										<div className="absolute left-0 top-0 group-hover:opacity-100 opacity-0 flex items-center justify-center w-full h-full bg-zinc-800/60 transition-all duration-500 pointer-events-none rounded-2xl">
											<svg className="w-16 h-16 fill-current text-white" viewBox="0 0 24 24"><path d="M21,12h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Zm1,7c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-4c0-.552,.448-1,1-1l3-.002v.002c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4h3c.552,0,1,.448,1,1v4ZM7.293,5.293c-.391-.391-.391-1.023,0-1.414L10.586,.586C10.972,.2,11.479,.006,11.986,.003l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l3.293,3.293c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.293-2.293v7c0,.553-.447,1-1,1s-1-.447-1-1V3l-2.293,2.293c-.391,.391-1.023,.391-1.414,0Z"/></svg>
										</div>
										{questionData?.image?.imgSrc?.length ? (
											<div className="flex items-center justify-center mx-auto">
												<img src={questionData.image.imgSrc} className="mx-auto h-full" alt="Featured_image" />
											</div>
										) : (
											<div className={`${questionData?.image?.error?"border-orange-1 text-orange-1":"border-zinc-200 text-zinc-400"} bg-zinc-100 min-h-[10rem] w-full h-full rounded-2xl border-2 flex flex-col items-center justify-center transition-colors duration-500 cursor-pointer`}>
												<p className="font-poppins text-lg font-bold"> No Image </p>
												<p className="font-raleway text-sm text-orange-1 pt-1"> {questionData?.image?.error} </p>
											</div>
										)}
									</div>
								</div>
								<div className="relative w-full">
									<input
										id="imageInstrusction"
										placeholder="Image instructions"
										value={questionData.imageText.state}
										onChange={(e) =>
											setQuestionData({
												...questionData,
												imageText: {
													...questionData.state,
													state: e.target.value,
												},
											})
										}
										className={`peer h-12 font-raleway appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 hover:border-green-11`}
									/>
									<label htmlFor="imageInstrusction" className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg" >
										Text <span className=" text-grey-1 opacity-40">optional</span>
									</label>
								</div>
							</div>
						) : null
					}
					{
						// Answears section
						questionData.type.state !== "Free Text" && questionData.type.state !== "Paragraph" && questionData.format.state !== "" ? (
						<div className="flex flex-col gap-5">
							<div className="w-full flex justify-center items-center relative">
								<div className=" absolute h-1 bg-grey-1 w-full"></div>
								<div className=" bg-white px-3 relative">
									<p className="font-poppins font-bold text-2xl uppercase text-grey-1">
										Answears
									</p>
								</div>
							</div>
							<div className="pb-4 relative">
								<Select
									id="numberOfQuestions"
									placeholder="Please select the type of the question"
									className={`peer h-12 font-poppins appearance-none w-full py-2 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent cursor-default focus:border-green-11 transition-colors duration-500 focus-within:border-green-11 hover:border-green-11`}
									value={numberOfAnswers || undefined}
									onChange={(value) => {
										setNumberOfAnswers(parseInt(value));
									}}
									getPopupContainer={(trigger) => trigger.parentElement}
								>
									<Option value="3">3</Option>
									<Option value="4">4</Option>
									<Option value="5">5</Option>
									<Option value="6">6</Option>
								</Select>
								<label
									htmlFor="numberOfQuestions"
									className={`${
										numberOfAnswers ? "-top-2 text-sm" : "top-[0.65rem] text-lg"
									} transform transition-all duration-500 absolute left-3 px-1 font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus-within:-top-2 peer-focus-within:text-sm peer-placeholder-shown:text-lg`}
								>
									Number of answears
								</label>
							</div>
							<div className="grid grid-cols-2 gap-5">
								{questionData.answears
									.filter((item, index) => index < numberOfAnswers)
									.map((elementInArray, index) => (
										<div
											id={`answer_${index + 1}`}
											className={`flex flex-col h-full select-none col-span-2 md:col-span-1`}
											key={index}
										>
											<div
												onClick={() => {
													if (questionData.type.state === "Multiple Choice")
														changeCorrectAnswerMultipleChoice( index, !questionData.answears[index].correct );
													if (questionData.type.state === "Single Choice")
														changeCorrectAnswerSingleChoice(index, !questionData.answears[index].correct);
												}}
												className={`${
													questionData.answears[index].correct
														? "border-green-11 bg-green-11"
														: "border-orange-1 bg-orange-1"
												} border-2 px-4 py-2 flex flex-row gap-5 justify-center items-center w-full rounded-t-2xl cursor-pointer transition-all duration-300`}
											>
												<p className=" font-montserrat text-lg font-bold text-white">
													Option {index + 1}
												</p>
											</div>
											<div className="w-full h-full border-2 border-t-0 rounded-b-2xl overflow-hidden">
												{questionData.format.state === "Text" ||
												questionData.format.state === "" ? (
													<div className="relative p-2 pb-6">
														<input
															id={`answer-${index + 1}`}
															placeholder="Please complete this field"
															value={questionData.answears[index].name.state}
															onChange={(e) =>
																handleChangeInputAnswers(index, e.target.value)
															}
															className={`peer h-12 font-raleway appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 focus-within:border-green-11 ${
																questionData.answears[index].name.error.length !== 0
																	? "border-orange-1 focus:ring-orange-1"
																	: "hover:border-green-11"
															}`}
														/>
														<label htmlFor={`answer-${index + 1}`} className="transform transition-all duration-300 absolute top-0 left-5 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[1.1rem] peer-focus:top-0 peer-focus:text-sm peer-placeholder-shown:text-lg">
															Answer {index + 1}
														</label>
														<div className={`absolute transform transition-all duration-500 ${
																questionData.answears[index].name.error
																	? " translate-y-0"
																	: "translate-y-2"
															}`}
														>
															<p className="text-orange-1 font-poppins font-semibold">
																{questionData.answears[index].name.error}
															</p>
														</div>
													</div>
												) : (
													<div className="h-full min-h-[10rem] max-h-[10rem] mb-auto relative group cursor-pointer p-2">
														<div className="h-full w-full relative">
															<input onChange={(event) => handleChangeImgAnswer(event, index) } className="cursor-pointer w-full h-full absolute opacity-0" id="resume" type="file" accept="image/png, image/jpg,image/jpeg" />
															<div className=" absolute group-hover:opacity-100 opacity-0 flex items-center justify-center w-full h-full bg-zinc-800/60 transition-all duration-500 pointer-events-none rounded-2xl">
																<svg className="w-16 h-16 fill-current text-white" viewBox="0 0 24 24"><path d="M21,12h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Zm1,7c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-4c0-.552,.448-1,1-1l3-.002v.002c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4h3c.552,0,1,.448,1,1v4ZM7.293,5.293c-.391-.391-.391-1.023,0-1.414L10.586,.586C10.972,.2,11.479,.006,11.986,.003l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l3.293,3.293c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.293-2.293v7c0,.553-.447,1-1,1s-1-.447-1-1V3l-2.293,2.293c-.391,.391-1.023,.391-1.414,0Z"/></svg>
															</div>
															{elementInArray.image.imgSrc ? (
																<img
																	src={elementInArray.image.imgSrc}
																	className="mx-auto h-full"
																	alt="Featured_image"
																/>
															) : (
																<div className={`${elementInArray.image.error?"border-orange-1 text-orange-1":"border-zinc-200 text-zinc-400"} bg-zinc-100 h-full rounded-2xl border-2 w-full flex items-center justify-center transition-colors duration-500`}>
																	<p className="font-poppins text-lg font-bold">
																		No Image
																	</p>
																</div>
															)}
														</div>
													</div>
												)}
											</div>
										</div>
									))}
							</div>
						</div>
						) : null
					}
				</div>
			</div>
		</Modal>
	);
}
