import React, { useState, useEffect, useContext } from "react";
import { message } from "antd";
import UserContext from "../../context/context";
import axios from "axios";
import UploadUserImage from "../../components/UserPagesComponents/uploadUserImage";
import {DOMAIN} from "../../env";

export default function UserProfile() {
  const [data, setData] = useState();
  const user = useContext(UserContext);

  const [userPassword, setUserPassword] = useState({
    state: undefined,
    error: "",
    strength: 0,
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleGeneratePassword = () => {
    const characterList =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!'^+%&/()=?_#$[]|;:>÷`<.*-@";

    let password = "";
    const characterListLength = characterList.length;

    for (let i = 0; i < 20; i++) {
      const characterIndex = Math.round(Math.random() * characterListLength);
      password = password + characterList.charAt(characterIndex);
    }
    setUserPassword({ ...userPassword, state: password });
    setShowPassword(true);
  };

  const [userRole, setUserRole] = useState({ state: "", error: "" });
  const [userName, setUserName] = useState({ state: "", error: "" });

  useEffect(() => {
    axios
      .get(`${DOMAIN}/api/user/getUserInfo/${user.user.user_id}`, {
        headers: {
          "jwt-token": user.jwtToken,
        },
      })
      .then((response) => {
        setData(response.data.user[0]);
        setUserName({ ...userName, state: response.data.user[0].user_name });
        setUserRole({ ...userRole, state: response.data.user[0].user_role });
      })
      .catch((error) => {
        message.error({
          content: `${error.response.statusText}!`,
          className: "text-center",
          duration: 2,
        });
      });
    // eslint-disable-next-line
  }, [user]);

  const inputChecker = () => {
    let formIsValid = true;
    if (!userName.state) {
      formIsValid = false;
      setUserName({ ...userName, error: "This field can not be empty!" });
    } else {
      setUserName({ ...userName, error: "" });
    }
    return formIsValid;
  };

  const submitFunction = async () => {
    if (inputChecker()) {
      const loadingMessage = message.loading("Loading...", 0);
      await axios
        .post(
          `${DOMAIN}/api/user/updateUserInfo/${user.user.user_id}`,
          {
            user_name: userName.state,
            user_role: user.user.user_role,
            user_password: userPassword.state,
          },
          {
            headers: {
              "jwt-token": user.jwtToken,
            },
          }
        )
        .then((response) => {
          if (response.data.success === false) {
            message.error({
              content: response.data.error,
              className: "text-center",
              duration: 2,
            });
          } else {
            message.success({
              content: `Updated!`,
              className: "text-center",
              duration: 2,
            });
          }
        })
        .catch((error) => {
          message.error({
            content: `Network Error!`,
            className: "text-center",
            duration: 2,
          });
        })
        .finally(() => {
          loadingMessage();
        });
    } else {
      message.error({
        content: `Please check all fields!`,
        className: "text-center",
        duration: 2,
      });
    }
  };

  if (data === undefined)
    return (
      <div className="flex h-screen items-center justify-center bg-white">
        <div className="flex flex-col items-center justify-center gap-5">
          <div className="h-40 w-40 animate-spin rounded-full border-b-4 border-t-4 border-green-11"></div>
        </div>
      </div>
    );

  return (
    <>
      <div className=" w-full">
        <div
          className={`relative flex w-full flex-col gap-10 bg-green-11 p-5 shadow-lg lg:flex-row`}
        >
          <UploadUserImage user={user} />
          <div className="flex flex-col self-center">
            <p className=" text-center font-poppins text-4xl font-bold text-white lg:text-left">
              {user.user.user_name}
            </p>
            <p className=" mb-5 text-center font-montserrat text-2xl text-white lg:mb-0 lg:text-left">
              {user.user.user_role}
            </p>
          </div>
        </div>
      </div>
      <div className=" flex flex-col gap-5 p-5">
        <div className="col-span-4 grid grid-cols-2 gap-5 lg:col-span-3">
          <div className="col-span-2 flex justify-center">
            <p className=" mb-0 text-center font-poppins text-2xl font-bold text-grey-1 lg:text-left">
              Information
            </p>
          </div>
          <div className=" col-span-2 md:col-span-1">
            <p className=" w-72 font-montserrat text-lg font-bold text-grey-1">
              Name:
            </p>
            <div className="relative w-full">
              <input
                className={` h-12 w-full appearance-none border-b-4 bg-grey-13 px-3 py-2 ${
                  userName.error?.length !== 0
                    ? "border-orange-1 focus:ring-orange-1"
                    : "border-green-11 focus:ring-green-11"
                } leading-tight text-gray-700 focus:border-transparent focus:outline-none focus:ring-2`}
                value={userName.state}
                onChange={(e) =>
                  setUserName({ ...userName, state: e.target.value })
                }
              />
              <div className="absolute">
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {userName.error}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className=" col-span-2 md:col-span-1">
            <p className=" w-72 font-montserrat text-lg font-bold text-grey-1">
              User Password:
            </p>
            <div className="flex w-full flex-row gap-2">
              <div className="relative h-full w-full">
                <div className="flex flex-row gap-2">
                  <div className="relative w-full">
                    <input
                      className={` h-12 w-full appearance-none border-b-4 bg-grey-13 px-3 py-2 ${
                        userPassword.error?.length !== 0
                          ? "border-orange-1 focus:ring-orange-1"
                          : "border-green-11 focus:ring-green-11"
                      } leading-tight text-gray-700 focus:border-transparent focus:outline-none focus:ring-2`}
                      placeholder="User Password"
                      value={userPassword.state}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) =>
                        setUserPassword({
                          ...userPassword,
                          state: e.target.value,
                        })
                      }
                      //onBlur={e=>checkJobTitle(title,setJobTitle)}
                    />
                    <div
                      className="absolute right-5 top-1/4"
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#00B388"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#00B388"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z" />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <div
                      className="cursor-pointer"
                      onClick={() => handleGeneratePassword()}
                    >
                      <svg
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#00B388"
                        fillRule="evenodd"
                        clipRule="evenodd"
                      >
                        <path d="M23.621 9.012c.247.959.379 1.964.379 3 0 6.623-5.377 11.988-12 11.988s-12-5.365-12-11.988c0-6.623 5.377-12 12-12 2.581 0 4.969.822 6.927 2.211l1.718-2.223 1.935 6.012h-6.58l1.703-2.204c-1.62-1.128-3.582-1.796-5.703-1.796-5.52 0-10 4.481-10 10 0 5.52 4.48 10 10 10 5.519 0 10-4.48 10-10 0-1.045-.161-2.053-.458-3h2.079zm-7.621 7.988h-8v-6h1v-2c0-1.656 1.344-3 3-3s3 1.344 3 3v2h1v6zm-5-8v2h2v-2c0-.552-.448-1-1-1s-1 .448-1 1z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="absolute">
                  <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                    {" "}
                    {userPassword.error}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4 lg:col-span-1">
          <div className="flex flex-col items-center gap-5 pt-5">
            <div
              onClick={() => submitFunction()}
              className=" group w-48 cursor-pointer rounded-xl border-2 border-green-11 bg-green-11 py-3 text-center shadow-lg transition-all duration-500 hover:bg-white"
            >
              <p className="font-poppins font-bold uppercase text-white transition-all duration-500 group-hover:text-green-11">
                save changes
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
