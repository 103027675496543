import React, { useState } from "react";
import { Select, Steps, Drawer, Tooltip } from "antd";
import Path from "../../components/EloquaAudit/Path";
import { useParams } from 'react-router-dom';

export default function EloquaAuditAddSection() {
	const { Option } = Select;
	const { Step } = Steps;
	const [numberOfPaths, setNumberOfPaths] = useState(3);
	const [lockNumberOfPaths, setLockNumberOfPaths] = useState(false);
	const [visible, setVisible] = useState(false);
	const colorScheme = ["#00B388", "#FC8A17", "#43CB83", "#00ADC0"];
    const paramas = useParams();

	const [progress, setProgress] = useState(0);
	// eslint-disable-next-line
	const [pathsData, setPathsData] = useState(
		new Array(8).fill({
			pathType: "Graphs",
			pathSummary: "",
			pathText: "",
			recommendations: [],
			observations: [],
		})
	);

	const onPathChange = (key, value, i) => {
		setPathsData((oldPathData) => {
			let paths = [...oldPathData];
			let path = {
				...paths[i],
				[key]: value,
			};
			paths[i] = path;
			return paths;
		});
	};

	const onNumberOfPathsChange = (value) => {
		setNumberOfPaths(parseInt(value));
		setLockNumberOfPaths(true);
		setProgress(0);
	};

	const renderPaths = () => {
		return [...Array(numberOfPaths)].map((elementInArray, index) => (
			<div
				id={`path_${index + 1}`}
				className={`${progress === index ? "block" : "hidden"} p-5`}
				key={index}
			>
				<Path
					pathNumber={index}
					colorScheme={colorScheme[index]}
					onChange={onPathChange}
				/>
			</div>
		));
	};

	return (
		<div>
			<p className=" font-poppins py-5 px-2 text-3xl font-bold text-grey-1 text-center">
				{paramas.id.match(/[A-Z][a-z]+|[0-9]+|[&]/g).join(" ")}
			</p>
			<div className="w-full flex justify-center px-5 pt-5">
				<div className="flex flex-row gap-5 justify-center items-center">
					<p className="font-montserrat w-96 font-bold text-lg">
						Number of paths:
					</p>
					<Select
						defaultValue="3"
						className={`h-12 appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 border-green-11 focus:ring-green-11 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent cursor-pointer`}
						onChange={(value) => onNumberOfPathsChange(value)}
						disabled={lockNumberOfPaths}
					>
						<Option value="2">2</Option>
						<Option value="3">3</Option>
						<Option value="4">4</Option>
						<Option value="5">5</Option>
						<Option value="6">6</Option>
						<Option value="7">7</Option>
						<Option value="8">8</Option>
					</Select>
					<div className="transition-all duration-500">
						{lockNumberOfPaths ? (
							<svg
								onClick={() => setLockNumberOfPaths(!lockNumberOfPaths)}
								xmlns="http://www.w3.org/2000/svg"
								className="cursor-pointer fill-current text-green-11 w-8 h-8"
								viewBox="0 0 24 24"
							>
								<path d="M17 9.761v-4.761c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8c0-2.525-1.173-4.773-3-6.239zm-8-4.761c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587c-.927-.376-1.938-.587-3-.587s-2.073.211-3 .587v-3.587zm3 17c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm2-6c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z" />
							</svg>
						) : (
							<svg
								onClick={() => setLockNumberOfPaths(!lockNumberOfPaths)}
								xmlns="http://www.w3.org/2000/svg"
								className="cursor-pointer fill-current text-green-11 w-8 h-8"
								viewBox="0 0 24 24"
							>
								<path d="M12 18c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2zm0-10c-1.062 0-2.073.211-3 .587v-3.587c0-1.654 1.346-3 3-3s3 1.346 3 3v1h2v-1c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8zm0 14c-3.313 0-6-2.687-6-6s2.687-6 6-6 6 2.687 6 6-2.687 6-6 6z" />
							</svg>
						)}
					</div>
				</div>
			</div>
			<div className="pt-10 flex gap-5 flex-row px-5">
				<div
					className=" flex items-center"
					onClick={() => (progress > 0 ? setProgress(progress - 1) : null)}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className={`transform transition-all duration-500 rotate-180 fill-current text-green-11 ${
							progress === 0 ? " opacity-50" : "cursor-pointer"
						}`}
						width="24"
						height="24"
						viewBox="0 0 24 24"
					>
						<path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
					</svg>
				</div>
				<Steps current={progress} size="small" progressDot>
					{[...Array(numberOfPaths)].map((elementInArray, index) => (
						<Step
							title={
								<p className=" font-montserrat font-bold">Path {index + 1}</p>
							}
							description=""
							onClick={() => setProgress(index)}
						/>
					))}
				</Steps>
				<div
					className=" flex items-center"
					onClick={() =>
						progress < numberOfPaths - 1 ? setProgress(progress + 1) : null
					}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className={`fill-current text-green-11 ${
							progress === numberOfPaths - 1 ? " opacity-50" : "cursor-pointer"
						}`}
						width="24"
						height="24"
						viewBox="0 0 24 24"
					>
						<path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
					</svg>
				</div>
			</div>
			<div className="py-16 flex flex-col gap-16">{renderPaths()}</div>
			<Tooltip placement="left" title="Actions">
                    <div onClick={()=>setVisible(true)} className={`fixed right-5 top-40 bg-green-11 w-14 h-14 transition-all duration-500 flex justify-center items-center rounded-lg shadow-lg cursor-pointer group`}>
                        <svg xmlns="http://www.w3.org/2000/svg" className=" w-8 h-8 fill-current text-white transform group-hover:rotate-90 transition-all duration-500" viewBox="0 0 24 24"><path d="M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z"/></svg>
                    </div>
                </Tooltip>
                <Drawer title={<p className="font-bold font-poppins text-xl text-grey-1">Actions:</p>} placement="right" onClose={()=>setVisible(false)} visible={visible}>
                    <div className="flex flex-col gap-5 h-full">
						<div className="bg-green-11 hover:bg-white border-2 border-green-11 rounded-lg cursor-pointer text-center shadow-lg transition-all duration-500 group">
							<p className="text-white font-raleway font-bold px-9 py-4 group-hover:text-green-11 transition-all duration-500">
								Save
							</p>
						</div>
						<div className="mt-auto">
							<div className="w-full h-0.5 bg-grey-2 rounded-full"></div>
							<p className=" font-poppins text-2xl font-bold text-center py-2">
								Switch to
							</p>
							<div className="flex flex-col gap-2">
								{["DatabaseHealth","EmailPerformance","Form&amp;WebEngagement","Performance&amp;Administration","FeatureAdoption"].map((element, index) => {
									if(paramas.id !== element)
										return <div className="bg-grey-1 hover:bg-green-11 rounded-lg cursor-pointer text-center shadow-lg transition-all duration-500">
											<p className="text-white font-raleway font-bold px-9 py-4">
												{element.match(/[A-Z][a-z]+|[0-9]+|[&]/g).join(" ")}
											</p>
										</div>
									return null
								})}
								<div className="bg-grey-1 hover:bg-green-11 rounded-lg cursor-pointer text-center shadow-lg transition-all duration-500">
									<p className="text-white font-raleway font-bold px-9 py-4">
										Client Information
									</p>
								</div>
							</div>
							<p className="text-xs text-center leading-4 pt-2 text-grey-1">
								If you wish to switch to another section all the progress will be lost. Save before moving to another section.
							</p>
						</div>
                    </div>
                </Drawer>
		</div>
	);
}
