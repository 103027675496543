import React from "react";
import { useState, useRef } from "react";
import { message } from "antd";
import axios from "axios";
import {DOMAIN} from "../../../env";

export default function Login({ logIn, setShowLoginForm, showLoginForm }) {
	const emailRef = useRef();
	const passwordRef = useRef();

	const [email, setEmail] = useState({ state: "", error: "" });
	const [password, setPassword] = useState({ state: "", error: "" });
	const [showPassword, setShowPassword] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleValidation = () => {
		let formIsValid = true;

		let re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!email.state) {
			formIsValid = false;
			setEmail({ ...email, error: "Please enter your email address." });
		} else if (!re.test(email.state)) {
			formIsValid = false;
			setEmail({ ...email, error: "Please enter a valid email address." });
		} else if (!/@logdms.com\s*$/.test(email.state)) {
			formIsValid = false;
			setEmail({ ...email, error: "Incorrect email type." });
		} else {
			setEmail({ ...email, error: "" });
		}
		if (!password.state) {
			formIsValid = false;
			setPassword({ ...password, error: "Please complete your password." });
		} else {
			setPassword({ ...password, error: "" });
		}

		return formIsValid;
	};

	const handleSubmit = () => {
		if (handleValidation()) {
			setLoading(true);
			axios
				.post(`${DOMAIN}/api/user/login`, {
					user_email: email.state,
					user_password: password.state,
				})
				.then((response) => {
					if (response.data.success) {
						setLoading(false);
						logIn(response.data.user, response.data.JWT);
						if (rememberMe) {
							localStorage.setItem("user", JSON.stringify(response.data.user));
							localStorage.setItem("JWT", JSON.stringify(response.data.JWT));
						}
					} else {
						setLoading(false);
						message.error({
							content: response.data.error,
							className: "text-center",
							duration: 10,
						});
					}
				})
				.catch((error) => {
					setLoading(false);
					message.error({
						content: `Server error!`,
						className: "text-center",
						duration: 2,
					});
				});
		}
	};

	return (
		<form className={`${showLoginForm ? "block" : "hidden"}`}>
			<div className="flex flex-col gap-6 pb-5">
				<div className="flex flex-col items-center justify-center relative">
					<input
						type="email"
						ref={emailRef}
						placeholder="Email Address"
						className={`${
							email.error.length !== 0 ? "border-orange-1" : "border-green-11"
						} peer h-12 bg-transparent appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 text-gray-700 dark:text-white font-montserrat`}
						id="email"
						onChange={(e) => setEmail({ ...email, state: e.target.value })}
					/>
					<label
						htmlFor="email"
						className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 dark:text-white rounded-full bg-white dark:bg-grey-1 font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
					>
						Email
					</label>
					<p
						className={`${
							email.error.length
								? "opacity-100 -translate-y-1"
								: "opacity-0 translate-y-2"
						} font-raleway text-sm text-orange-1 pt-1 m-0 w-full absolute -bottom-6 transition-all duration-500`}
					>
						{" "}
						{email.error}{" "}
					</p>
				</div>
				<div className="flex flex-col items-center justify-center relative">
					<div className="relative w-full">
						<input
							ref={passwordRef}
							type={showPassword ? "text" : "password"}
							placeholder="Password"
							className={`${
								password.error.length !== 0
									? "border-orange-1"
									: "border-green-11"
							} peer h-12 bg-transparent appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 text-gray-700 dark:text-white font-montserrat`}
							id="password"
							onChange={(e) =>
								setPassword({ ...password, state: e.target.value })
							}
						/>
						<label
							htmlFor="password"
							className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 dark:text-white rounded-full bg-white dark:bg-grey-1 font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
						>
							Password
						</label>
						<div
							className="absolute top-1/4 right-5"
							onClick={() => setShowPassword((showPassword) => !showPassword)}
						>
							{showPassword ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="#00B388"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z" />
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="#00B388"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z" />
								</svg>
							)}
						</div>
					</div>
					<p
						className={`${
							password.error.length
								? "opacity-100 -translate-y-1"
								: "opacity-0 translate-y-2"
						} font-raleway text-sm text-orange-1 pt-1 m-0 w-full absolute -bottom-6 transition-all duration-500 pointer-events-none`}
					>
						{" "}
						{password.error}{" "}
					</p>
				</div>
			</div>
			<div className="w-full flex justify-end items-center">
				<label
					htmlFor="checkbox"
					className="text-grey-1 font-poppins dark:text-white cursor-pointer pr-3"
				>
					Remember me ?
				</label>
				<input
					type="checkbox"
					id="checkbox"
					value={rememberMe}
					onChange={(e) => setRememberMe(e.target.checked)}
					className="checkbox"
				/>
			</div>
			<div className="flex flex-col gap-5 justify-center items-center mt-6">
				<div
					onClick={() => handleSubmit()}
					className="bg-green-11 transition-all duration-500 border-2 border-solid border-green-11 w-full max-w-[200px] rounded-xl flex justify-center overflow-hidden relative h-10 items-center cursor-pointer shadow-lg group"
				>
					<div
						className={`absolute ${
							loading ? " translate-y-10" : ""
						} transform transition-all duration-500`}
					>
						<p className="text-xl font-poppins font-bold text-white rounded-xl uppercase dark:group-hover:text-white group-hover:text-green-11 transition-all duration-500">
							login
						</p>
					</div>
					<div
						className={`absolute w-full flex justify-center ${
							loading ? "" : "-translate-y-10"
						} transform transition-all duration-500`}
					>
						<svg
							className="animate-spin h-5 w-5 text-white"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								className="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								strokeWidth="4"
							></circle>
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							></path>
						</svg>
					</div>
				</div>
			</div>
		</form>
	);
}
