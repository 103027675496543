import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Prompt } from "react-router";
import axios from "axios";
import {
  message,
  Select,
  Divider,
  DatePicker,
  Popconfirm,
  Steps,
  Tooltip,
  Carousel,
} from "antd";
import togglePublished from "../../../components/KnowledgeHub/ExpertArticle/togglePublished";
import Editor from "../../../utils/Editor/Instance";
import DeleteModal from "../../../components/KnowledgeHub/ExpertArticle/deleteModal";
import EditModal from "../../../components/KnowledgeHub/Author/editModal";
import AddModal from "../../../components/KnowledgeHub/Author/addModal";
import moment from "moment";
import UserContext from "../../../context/context";
import TextareaAutosize from "react-textarea-autosize";
import { DOMAIN, STORAGE_DOMAIN } from "../../../env";

export default function EditExpertArticle() {
  const user = useContext(UserContext);
  const { id } = useParams();
  const history = useHistory();
  const { Option } = Select;

  const [progress, setProgress] = useState(0);
  const [data, setData] = useState();
  const [category, setCategory] = useState();
  const [modified, setModified] = useState(false);
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [selectData, setSelectData] = useState();
  const [selectTagsData, setSelectTagsData] = useState({
    items: [
      "Eloqua",
      "Eloqua Integration",
      "Eloqua Administration",
      "Integrations",
    ],
    value: "",
  });

  const [editorInstance, setEditorInstance] = useState();
  const [instanceModification, setInstanceModification] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddAuthorModalVisible, setIsAddAuthorModalVisible] = useState(false);
  const [isEditAuthorModalVisible, setIsEditAuthorModalVisible] =
    useState(false);

  const [title, setTitle] = useState({ state: "", error: "" });
  const [link, setLink] = useState({ state: "", error: "" });
  const [author, setAuthor] = useState({ state: "", error: "", info: {} });
  const [tags, setTags] = useState({ state: [], error: "" });
  const [blogTopic, setBlogTopic] = useState({ state: "", error: "" });
  const [blogPostedDate, setBlogPostedDate] = useState({
    state: "",
    error: "",
  });
  const [blogNumberOfLikes, setBlogNumberOfLikes] = useState({
    state: "",
    error: "",
  });
  const [blogPreview, setBlogPreview] = useState({ state: "", error: "" });
  const [blogMetaDescription, setBlogMetaDescription] = useState({
    state: "",
    error: "",
  });
  const [blogEstimateTimeOfRead, setBlogEstimateTimeOfRead] = useState({
    state: "",
    error: "",
  });
  const [blogFeaturedImage, setBlogFeaturedImage] = useState({
    ImgName: "No file chosen",
    state: null,
    error: "",
    link: "",
    ImgSrc: [],
  });
  const [homePageImage, setHomePageImage] = useState({
    ImgName: "No file chosen",
    state: null,
    error: "",
    link: "",
    ImgSrc: [],
  });
  const [checkLink, setCheckLink] = useState({
    status: undefined,
    state: undefined,
  });


  useEffect(() => {
    const fetchApiData = async () => {
      try {
        if (
          !["Developer", "Marketing", "Admin"].includes(user.user.user_role)
        ) {
          message.error({
            content: `Unauthorized!`,
            className: "text-center",
            duration: 2,
          });
          history.push("/");
        }
        await axios
          .get(`${DOMAIN}/api/knowledgeHub/expertArticle/getById/${id}`, {
            headers: {
              "jwt-token": user.jwtToken,
            },
          })
          .then(async (response) => {
            setBlogTopic({ ...blogTopic, state: response.data.data.topic });
            setTags({ ...tags, state: response.data.data?.tags || [] });
            setBlogPostedDate({
              ...blogPostedDate,
              state: new Date(response.data.data?.postedDate),
            });
            const blocks = response.data.data.bodyData.blocks.map((block) => {
              if (block.type === "image") {
                return {
                  ...block,
                  data: {
                    ...block.data,
                    file: {
                      ...block.data.file,
                      url: STORAGE_DOMAIN + block.data.file.url,
                    },
                  },
                };
              } else if (block.type === "columns") {
                return {
                  ...block,
                  data: {
                    ...block.data,
                    cols: block.data.cols.map(col=>{
						return {...col, blocks: col.blocks.map((block) => {
							if (block.type === "image") {
							  return {
								...block,
								data: {
								  ...block.data,
								  file: {
									...block.data.file,
									url: STORAGE_DOMAIN + block.data.file.url,
								  },
								},
							  };
							} else return block;
						  })}
					}),
                  },
                };
              }else return block;
            });
            setData({
              ...response.data.data,
              bodyData: { ...response.data.data.bodyData, blocks },
            });
            setCategory(response.data.category);
            setTitle({ ...title, state: response.data.data.title });
            setLink({ ...link, state: response.data.data.link });
            setBlogNumberOfLikes({
              ...blogNumberOfLikes,
              state: response.data.data?.numberOfLikes,
            });
            setBlogEstimateTimeOfRead({
              ...blogEstimateTimeOfRead,
              state: response.data.data?.timeOfRead,
            });
            setBlogPreview({
              ...blogPreview,
              state: response.data.data?.blogPreview || "",
            });
            setBlogMetaDescription({
              ...blogMetaDescription,
              state: response.data.data?.metaDescription || "",
            });
            setBlogFeaturedImage({
              ...blogFeaturedImage,
              link: response.data.data.imageUrl,
            });
            setHomePageImage({
              ...homePageImage,
              link: response.data.data.imageUrl1,
            });
            await axios
              .get(`${DOMAIN}/api/knowledgeHub/author/getAll`, {
                headers: {
                  "jwt-token": user.jwtToken,
                },
              })
              .then((response1) => {
                setSelectData(response1.data.data);
                setAuthor({
                  ...author,
                  state: response.data.data.authorId,
                  info: response1.data.data.find(
                    (o) => o._id === response.data.data.authorId
                  ),
                });
              })
              .catch((error) => {
                throw error;
              });
            await axios
              .get(`${DOMAIN}/api/knowledgeHub/expertArticle/getAllTags`)
              .then((response1) => {
                setSelectTagsData({
                  items: response1.data.data,
                  value: "",
                });
              })
              .catch((error) => {
                throw error;
              });
          });
      } catch (error) {
        history.push("/knowledge-hub");
        message.error({
          content: `Blog Post not found!`,
          className: "text-center",
          duration: 2,
        });
      }
    };
    fetchApiData();
    // eslint-disable-next-line
  }, [modified]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (link.state.length)
        axios
          .post(
            `${DOMAIN}/api/knowledgeHub/expertArticle/checkurl/${link.state}`,
            {
              id: id,
            },
            {
              headers: {
                "jwt-token": user.jwtToken,
              },
            }
          )
          .then((results) => {
            setCheckLink({
              ...checkLink,
              status: true,
              state: results.data.success,
            });
          });
      else setCheckLink({ ...checkLink, status: undefined, state: undefined });
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [link]);

  console.log(data);

  const handlePublishStatus = async () => {
    const error = await checkFields();

    if (!data.published) {
      if (!error) {
        togglePublished(
          id,
          !data.published,
          category,
          setModified,
          modified,
          user.jwtToken
        );
      } else {
        message.error({
          content: `Please check all fields!`,
          className: "text-center",
          duration: 2,
        });
      }
    } else {
      togglePublished(
        id,
        !data.published,
        category,
        setModified,
        modified,
        user.jwtToken
      );
    }
  };

  const checkFields = () => {
    let error = false;
    if (!blogTopic.state) {
      setBlogTopic({ ...blogTopic, error: "Please select a value!" });
      error = true;
    } else {
      setBlogTopic({ ...blogTopic, error: "" });
    }
    if (!title.state) {
      setTitle({ ...title, error: "This field can not be empty!" });
      error = true;
    } else {
      setTitle({ ...title, error: "" });
    }
    if (!link.state) {
      setLink({ ...link, error: "This field can not be empty!" });
      error = true;
    } else {
      setLink({ ...link, error: "" });
    }
    if (tags.state.length === 0) {
      setTags({ ...tags, error: "Please select at least one value!" });
      error = true;
    } else {
      setTags({ ...tags, error: "" });
    }
    if (!author.state) {
      setAuthor({ ...author, error: "Please select an author!" });
      error = true;
    } else {
      setAuthor({ ...author, error: "" });
    }
    if (!blogPostedDate.state) {
      setBlogPostedDate({ ...blogPostedDate, error: "Please select a date!" });
      error = true;
    } else {
      setBlogPostedDate({ ...blogPostedDate, error: "" });
    }
    if (!blogNumberOfLikes.state) {
      setBlogNumberOfLikes({
        ...blogNumberOfLikes,
        error: "This field can not be empty!",
      });
      error = true;
    } else {
      setBlogNumberOfLikes({ ...blogNumberOfLikes, error: "" });
    }
    if (!blogPreview.state) {
      setBlogPreview({ ...blogPreview, error: "This field can not be empty!" });
      error = true;
    } else {
      setBlogPreview({ ...blogPreview, error: "" });
    }
    if (!blogMetaDescription.state) {
      setBlogMetaDescription({
        ...blogMetaDescription,
        error: "This field can not be empty!",
      });
      error = true;
    } else {
      setBlogMetaDescription({ ...blogMetaDescription, error: "" });
    }
    if (!blogEstimateTimeOfRead.state) {
      setBlogEstimateTimeOfRead({
        ...blogEstimateTimeOfRead,
        error: "This field can not be empty!",
      });
      error = true;
    } else {
      setBlogEstimateTimeOfRead({ ...blogEstimateTimeOfRead, error: "" });
    }
    if (!blogFeaturedImage.state && !blogFeaturedImage.link) {
      setBlogFeaturedImage({
        ...blogFeaturedImage,
        error: "No featured image! Please upload a featured image!",
      });
      error = true;
    } else setBlogFeaturedImage({ ...blogFeaturedImage, error: "" });
    return error;
  };

  const onSave = async () => {
    const outputData = await editorInstance.save();
    const error = await checkFields();

    if (!error) {
      const blocks = outputData.blocks.map((block) => {

        if (block.type === "image") {
		let url = "";
        if (block.data.file.url.includes(STORAGE_DOMAIN))
          url = block.data.file.url.split(STORAGE_DOMAIN)[1];
        else url = block.data.file.url;
          return {
            ...block,
            data: {
              ...block.data,
              file: {
                ...block.data.file,
                url: url,
              },
            },
          };
        } else return block;
      });
      var formData = new FormData();
      formData.append("blogTopic", blogTopic.state);
      formData.append("blogTitle", title.state);
      formData.append("blogLink", link.state);
      formData.append("tags", tags.state);
      formData.append("blogAuthor", author.state);
      formData.append("blogNumberOfLikes", blogNumberOfLikes.state);
      formData.append("blogPreview", blogPreview.state);
      formData.append("blogMetaDescription", blogMetaDescription.state);
      formData.append("blogPostedDate", blogPostedDate.state);
      formData.append("published", data.published);
      formData.append("timeOfRead", blogEstimateTimeOfRead.state);
      formData.append("bodyData", JSON.stringify({ ...outputData, blocks }));
      formData.append("category", category);
      formData.append("image", blogFeaturedImage.state);
      formData.append("image1", homePageImage.state);

      const loadingMessage = message.loading("Loading...", 0);
      await axios
        .post(`${DOMAIN}/api/knowledgeHub/expertArticle/edit/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "jwt-token": user.jwtToken,
          },
        })
        .then((response) => {
          setInstanceModification(false);
          message.success({
            content: `Post Saved!`,
            className: "text-center",
            duration: 2,
          });
          setModified(!modified);
        })
        .catch((error) => {
          message.error({
            content: `Network Error!`,
            className: "text-center",
            duration: 2,
          });
        })
        .finally(() => {
          loadingMessage();
        });
    } else {
      message.error({
        content: `Please check all fields!`,
        className: "text-center",
        duration: 2,
      });
    }
  };

  function handleChangeIMG(event) {
    const fileType = ["image/png", "image/jpg", "image/jpeg"];
    if (event.target.value.length !== 0)
      if (event.target.files[0].size > 10485760) {
        event.target.value = null;
        setBlogFeaturedImage({
          ...blogFeaturedImage,
          error: "Only files under 10MB",
          ImgName: "No file chosen",
        });
        return;
      }
    if (!fileType.includes(event.target.files[0].type)) {
      event.target.value = null;
      setBlogFeaturedImage({
        ...blogFeaturedImage,
        error: "Incorrect file type, only: .png, .jpg, .jpeg",
        ImgName: "No file chosen",
      });
    } else {
      setInstanceModification(true);
      var reader = new FileReader();
      // eslint-disable-next-line
      var url = reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = function () {
        setBlogFeaturedImage({
          ...blogFeaturedImage,
          state: event.target.files[0],
          ImgName: event.target.files[0].name,
          error: "",
          ImgSrc: [reader.result],
        });
      };
    }
  }

  function changeHomePageImage(event) {
    const fileType = ["image/png", "image/jpg", "image/jpeg"];
    if (event.target.value.length !== 0)
      if (event.target.files[0].size > 10485760) {
        event.target.value = null;
        setHomePageImage({
          ...homePageImage,
          error: "Only files under 10MB",
          ImgName: "No file chosen",
        });
        return;
      }
    if (!fileType.includes(event.target.files[0].type)) {
      event.target.value = null;
      setHomePageImage({
        ...homePageImage,
        error: "Incorrect file type, only: .png, .jpg, .jpeg",
        ImgName: "No file chosen",
      });
    } else {
      setInstanceModification(true);
      var reader = new FileReader();
      // eslint-disable-next-line
      var url = reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = function () {
        setHomePageImage({
          ...homePageImage,
          state: event.target.files[0],
          ImgName: event.target.files[0].name,
          error: "",
          ImgSrc: [reader.result],
        });
      };
    }
  }

  if (data === undefined)
    return (
      <div className="flex h-screen items-center justify-center bg-white">
        <div className="flex flex-col items-center justify-center gap-5">
          <div className="h-40 w-40 animate-spin rounded-full border-b-4 border-t-4 border-green-11"></div>
        </div>
      </div>
    );

  return (
    <div className=" relative">
      <Prompt
        when={instanceModification}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <DeleteModal
        isDeleteModalVisible={isDeleteModalVisible}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
        title={data?.title}
        id={id}
        category={category}
        imagesUploaded={imagesUploaded}
        setEditorModification={setInstanceModification}
        jwtToken={user.jwtToken}
      />
      <EditModal
        isEditAuthorModalVisible={isEditAuthorModalVisible}
        setIsEditAuthorModalVisible={setIsEditAuthorModalVisible}
        authorId={author.state}
        modified={modified}
        setModified={setModified}
        jwtToken={user.jwtToken}
      />
      <AddModal
        setIsAddAuthorModalVisible={setIsAddAuthorModalVisible}
        isAddAuthorModalVisible={isAddAuthorModalVisible}
        modified={modified}
        setModified={setModified}
        jwtToken={user.jwtToken}
      />
      <div className="flex flex-col items-center justify-between gap-5 bg-grey-1 p-5 lg:flex-row">
        <p className=" mb-0 truncate whitespace-pre-wrap text-center font-poppins text-lg font-bold text-white lg:text-left">
          {data?.title}
        </p>
      </div>
      <div className=" sticky top-0 z-10 flex flex-row flex-wrap items-center justify-center gap-5 border-b-2 bg-grey-4 p-5 md:justify-between">
        <div className="flex flex-col items-center justify-center md:flex-row md:gap-2">
          <div
            onClick={() => handlePublishStatus()}
            className={`${
              data?.published ? "bg-green-11" : "bg-orange-1"
            } relative flex w-28 cursor-pointer items-center justify-center rounded-2xl px-6 py-2 shadow-lg transition-all duration-500`}
          >
            <p className="font-montserrat font-bold uppercase text-white">
              {data?.published ? "Active" : "draft"}
            </p>
          </div>
          <p className=" font-raleway">
            Modified{" "}
            {Math.ceil(
              Math.abs(new Date(data.lastSaved) - new Date()) /
                (1000 * 60 * 60 * 24)
            )}{" "}
            days ago
          </p>
        </div>
        <div className="flex justify-center gap-4">
          <div>
            {data?.published ? (
              <Popconfirm
                title={
                  <p className="w-full">
                    Are you sure you want to save? <br /> This post is already
                    published and the changes will me made live if you save
                  </p>
                }
                placement="bottomLeft"
                onConfirm={() => onSave()}
                okText="Yes"
                cancelText="No"
                className={`relative flex cursor-pointer items-center gap-2 rounded-2xl border-2 border-green-11 bg-green-11 px-5 py-2 shadow-lg transition-all duration-500`}
              >
                <svg
                  viewBox="0 0 24 24"
                  className=" h-4 w-4 fill-current text-white"
                >
                  <path d="M12,10a4,4,0,1,0,4,4A4,4,0,0,0,12,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,16Z" />
                  <path d="M22.536,4.122,19.878,1.464A4.966,4.966,0,0,0,16.343,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7.657A4.966,4.966,0,0,0,22.536,4.122ZM17,2.08V3a3,3,0,0,1-3,3H10A3,3,0,0,1,7,3V2h9.343A2.953,2.953,0,0,1,17,2.08ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2V3a5.006,5.006,0,0,0,5,5h4a4.991,4.991,0,0,0,4.962-4.624l2.16,2.16A3.02,3.02,0,0,1,22,7.657Z" />
                </svg>
                <p className=" font-montserrat font-bold uppercase text-white">
                  Save
                </p>
              </Popconfirm>
            ) : (
              <div
                onClick={() => onSave()}
                className={`flex cursor-pointer items-center gap-2 rounded-2xl border-2 border-green-11 bg-green-11 px-5 py-2 shadow-lg transition-all duration-500`}
              >
                <svg
                  viewBox="0 0 24 24"
                  className=" h-4 w-4 fill-current text-white"
                >
                  <path d="M12,10a4,4,0,1,0,4,4A4,4,0,0,0,12,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,16Z" />
                  <path d="M22.536,4.122,19.878,1.464A4.966,4.966,0,0,0,16.343,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7.657A4.966,4.966,0,0,0,22.536,4.122ZM17,2.08V3a3,3,0,0,1-3,3H10A3,3,0,0,1,7,3V2h9.343A2.953,2.953,0,0,1,17,2.08ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2V3a5.006,5.006,0,0,0,5,5h4a4.991,4.991,0,0,0,4.962-4.624l2.16,2.16A3.02,3.02,0,0,1,22,7.657Z" />
                </svg>
                <p className=" font-montserrat font-bold uppercase text-white">
                  Save
                </p>
              </div>
            )}
          </div>
          <div
            onClick={() => setIsDeleteModalVisible(true)}
            className="group flex cursor-pointer items-center gap-2 rounded-2xl border-2 border-red-600 px-5 py-2 shadow-lg transition-all duration-500 hover:bg-red-600"
          >
            <svg
              className=" h-4 w-4 fill-current text-red-600 transition-all duration-500 group-hover:text-white"
              viewBox="0 0 24 24"
            >
              <path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
              <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
              <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
            </svg>

            <p className=" font-montserrat font-bold uppercase text-red-600 transition-all duration-500 group-hover:text-white">
              Delete
            </p>
          </div>
        </div>
      </div>
      <div className=" relative hidden lg:block">
        <div className=" w-ful mx-auto flex max-w-5xl flex-row gap-5 p-5">
          <div
            className=" flex items-center"
            onClick={() => (progress > 0 ? setProgress(progress - 1) : null)}
          >
            <svg
              className={`transform fill-current text-green-11 transition-all duration-500 ${
                progress === 0 ? " opacity-50" : "cursor-pointer"
              }`}
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path d="M17.921,1.505a1.5,1.5,0,0,1-.44,1.06L9.809,10.237a2.5,2.5,0,0,0,0,3.536l7.662,7.662a1.5,1.5,0,0,1-2.121,2.121L7.688,15.9a5.506,5.506,0,0,1,0-7.779L15.36.444a1.5,1.5,0,0,1,2.561,1.061Z" />
            </svg>
          </div>
          <Steps
            className=" select-none pt-4 font-poppins font-bold text-grey-1"
            current={progress}
            size="small"
            progressDot
            onChange={(value) => setProgress(value)}
            items={[
              {
                title: (
                  <p
                    className={`${
                      progress === 0
                        ? "font-bold text-green-11"
                        : "font-bold text-grey-11 hover:text-green-11"
                    } font-montserrat transition-all duration-300`}
                  >
                    SEO
                  </p>
                ),
              },
              {
                title: (
                  <p
                    className={`${
                      progress === 1
                        ? "font-bold text-green-11"
                        : "font-bold text-grey-11 hover:text-green-11"
                    } font-montserrat transition-all duration-300`}
                  >
                    Information
                  </p>
                ),
              },
              {
                title: (
                  <p
                    className={`${
                      progress === 2
                        ? "font-bold text-green-11"
                        : "font-bold text-grey-11 hover:text-green-11"
                    } font-montserrat transition-all duration-300`}
                  >
                    Card
                  </p>
                ),
              },
              {
                title: (
                  <p
                    className={`${
                      progress === 3
                        ? "font-bold text-green-11"
                        : "font-bold text-grey-11 hover:text-green-11"
                    } font-montserrat transition-all duration-300`}
                  >
                    Content
                  </p>
                ),
              },
            ]}
          />
          <div
            className=" flex items-center"
            onClick={() => (progress < 3 ? setProgress(progress + 1) : null)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`rotate-180 fill-current text-green-11 ${
                progress === 3 ? " opacity-50" : "cursor-pointer"
              }`}
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path d="M17.921,1.505a1.5,1.5,0,0,1-.44,1.06L9.809,10.237a2.5,2.5,0,0,0,0,3.536l7.662,7.662a1.5,1.5,0,0,1-2.121,2.121L7.688,15.9a5.506,5.506,0,0,1,0-7.779L15.36.444a1.5,1.5,0,0,1,2.561,1.061Z" />
            </svg>
          </div>
        </div>
        <div
          id="section_1"
          className={`${
            progress === 0 ? "block" : "hidden"
          } mx-auto max-w-5xl p-5`}
        >
          <div className=" pb-10 text-center font-poppins text-4xl font-bold text-grey-1">
            <span className="text-green-11">SEO</span> Information
          </div>
          <div className="grid w-full grid-cols-1 gap-5 lg:grid-cols-2">
            <div className="relative w-full">
              <Select
                id="topic"
                showSearch
                className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 ${
                  blogTopic.error?.length !== 0
                    ? "border-orange-1 focus:ring-orange-1"
                    : "hover:border-green-11"
                }`}
                placeholder="Select topic"
                defaultValue={blogTopic.state}
                onChange={(value) => {
                  setBlogTopic({ ...blogTopic, state: value });
                  setInstanceModification(true);
                }}
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <Option value="Privacy"> Eloqua Privacy </Option>
                <Option value="Implementation"> Eloqua Implementation </Option>
                <Option value="CampaignProduction">
                  {" "}
                  Eloqua Campaign Production{" "}
                </Option>
                <Option value="PlatformAdmin">
                  {" "}
                  Eloqua Platform Administration{" "}
                </Option>
                <Option value="Reporting"> Eloqua Reporting </Option>
              </Select>
              <label
                htmlFor="topic"
                className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus-within:-top-2 peer-focus:-top-2 peer-focus:text-sm"
              >
                Topic
              </label>
              <div className="absolute">
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {blogTopic.error}{" "}
                </p>
              </div>
            </div>
            <div className="relative w-full">
              <input
                id="title"
                className={` peer h-12 w-full appearance-none rounded-xl border-2 px-3 py-2 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus:border-green-11 focus:outline-none ${
                  title.error?.length !== 0
                    ? "border-orange-1 focus:ring-orange-1"
                    : "hover:border-green-11"
                }`}
                placeholder="title"
                value={title.state}
                onChange={(e) => {
                  setTitle({ ...title, state: e.target.value });
                  setInstanceModification(true);
                }}
              />
              <label
                htmlFor="title"
                className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
              >
                Title
              </label>
              <div className="absolute">
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {title.error}{" "}
                </p>
              </div>
            </div>
            <div className="relative w-full">
              <input
                id="link"
                className={` peer h-12 w-full appearance-none truncate rounded-xl border-2 py-2 pl-3 pr-8 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus:border-green-11 focus:outline-none ${
                  link.error?.length !== 0
                    ? "border-orange-1 focus:ring-orange-1"
                    : "hover:border-green-11"
                }`}
                value={link.state}
                onChange={(e) => {
                  setLink({ ...link, state: e.target.value });
                  setCheckLink({ ...checkLink, status: false });
                  setInstanceModification(true);
                }}
              />
              <div className=" absolute right-2 top-0 flex h-full items-center">
                {checkLink.status === false ? (
                  <svg
                    role="status"
                    class={`h-5 w-5 animate-spin text-gray-200`}
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="fill-current text-grey-4"
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    />
                    <path
                      className="fill-current text-green-11"
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    />
                  </svg>
                ) : null}
                {checkLink.status === true && checkLink.state === true ? (
                  <Tooltip placement="bottom" title={"Link Available"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 fill-current text-green-11"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
                    </svg>
                  </Tooltip>
                ) : null}
                {checkLink.status === true && checkLink.state === false ? (
                  <Tooltip placement="bottom" title={"Link Not Available"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 fill-current text-orange-1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
                    </svg>
                  </Tooltip>
                ) : null}
              </div>
              <label
                htmlFor="link"
                className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
              >
                Link
              </label>
              <div className="absolute">
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {link.error}{" "}
                </p>
              </div>
            </div>
            <div className="relative w-full">
              <Select
                id="tags"
                mode="multiple"
                allowClear
                showArrow
                maxTagCount={"responsive"}
                defaultValue={tags.state}
                onChange={(value) => {
                  setTags({ ...tags, state: value });
                  setInstanceModification(true);
                }}
                className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 ${
                  tags.error?.length !== 0
                    ? "border-orange-1 focus:ring-orange-1"
                    : "hover:border-green-11"
                }`}
                placeholder="Select tags"
                getPopupContainer={(trigger) => trigger.parentElement}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div className="relative flex p-2">
                      <input
                        id="addField"
                        placeholder="newtag"
                        value={selectTagsData.value}
                        onKeyDown={(e) => e.stopPropagation()}
                        onChange={(event) =>
                          setSelectTagsData({
                            ...selectTagsData,
                            value: event.target.value,
                          })
                        }
                        className={` peer mr-4 h-12 w-full appearance-none rounded-xl border-2 px-3 py-2 font-montserrat leading-tight placeholder-transparent focus:border-green-11 focus:outline-none`}
                      />
                      <label
                        htmlFor="addField"
                        className="absolute left-4 top-0 transform rounded-full bg-white px-2 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-5 peer-placeholder-shown:text-base peer-focus:top-0 peer-focus:text-sm"
                      >
                        New Tag
                      </label>
                      <button
                        disabled={selectTagsData.value.length === 0}
                        className=" group-enabled group flex w-44 cursor-pointer items-center justify-center gap-2 rounded-xl border-2 border-green-11 bg-white p-2 transition-all duration-500 enabled:hover:bg-green-11 disabled:cursor-not-allowed disabled:opacity-50 "
                        onClick={() => {
                          setSelectTagsData({
                            ...selectTagsData,
                            items: [
                              ...selectTagsData.items,
                              selectTagsData.value || "New item",
                            ],
                            value: "",
                          });
                        }}
                      >
                        <p className="font-montserrat text-base font-bold uppercase text-green-11 transition-all duration-500 group-enabled:group-hover:text-white">
                          Add
                        </p>
                      </button>
                    </div>
                  </div>
                )}
              >
                {selectTagsData.items.map((item) => (
                  <Option key={item}>{item}</Option>
                ))}
              </Select>
              <label
                htmlFor="tags"
                className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
              >
                Tags
              </label>
              <div className="absolute">
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {tags.error}{" "}
                </p>
              </div>
            </div>
            <div className="relative col-span-2 w-full">
              <textarea
                id="metaDescription"
                className={` peer h-full w-full appearance-none rounded-xl border-2 py-2 pl-3 pr-20 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:outline-none ${
                  blogMetaDescription.error?.length !== 0
                    ? "border-orange-1 focus:ring-orange-1"
                    : "hover:border-green-11"
                }`}
                value={blogMetaDescription.state}
                onChange={(e) => {
                  setBlogMetaDescription({
                    ...blogMetaDescription,
                    state: e.target.value,
                  });
                  setInstanceModification(true);
                }}
                placeholder="Meta Description"
                maxLength={158}
              />
              <div className="pointer-events-none absolute right-3 top-3 font-raleway">
                {blogMetaDescription.state.length} / 158
              </div>
              <label
                htmlFor="metaDescription"
                className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
              >
                Meta Description
              </label>
              <div className="absolute">
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {blogMetaDescription.error}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          id="section_2"
          className={`${
            progress === 1 ? "block" : "hidden"
          } mx-auto max-w-5xl p-5`}
        >
          <div className=" pb-10 text-center font-poppins text-4xl font-bold text-grey-1">
            <span className="text-green-11">Article</span> Information
          </div>
          <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
            <div className="relative flex w-full flex-row gap-2">
              <Select
                id="author"
                showSearch
                defaultValue={data.authorId}
                className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 focus:outline-none ${
                  author.error?.length !== 0
                    ? "border-orange-1 focus:ring-orange-1"
                    : "hover:border-green-11"
                }`}
                placeholder="Select a person"
                onChange={(value) => {
                  setAuthor({
                    ...author,
                    state: value,
                    info: selectData.find((o) => o._id === value),
                  });
                  setInstanceModification(true);
                }}
                getPopupContainer={(trigger) => trigger.parentElement}
                filterOption={(input, option) =>
                  option?.children[1]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {selectData &&
                  selectData.map((element, index) => {
                    return (
                      <Option key={index} value={element._id}>
                        {" "}
                        {element.authorName}{" "}
                      </Option>
                    );
                  })}
              </Select>
              <label
                htmlFor="author"
                className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
              >
                Author
              </label>
              <div className="flex items-center justify-center">
                <div
                  onClick={() =>
                    author.state ? setIsEditAuthorModalVisible(true) : null
                  }
                  className={`${
                    author.state ? "cursor-pointer opacity-100" : "opacity-50"
                  } group flex h-12 w-12 cursor-pointer items-center justify-center rounded-2xl border-2 border-green-11 bg-white shadow-md transition-all duration-500 hover:bg-green-11`}
                >
                  <svg
                    className="h-5 w-5 fill-current text-green-11 transition-all duration-500 group-hover:text-white"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22.853,1.148a3.626,3.626,0,0,0-5.124,0L1.465,17.412A4.968,4.968,0,0,0,0,20.947V23a1,1,0,0,0,1,1H3.053a4.966,4.966,0,0,0,3.535-1.464L22.853,6.271A3.626,3.626,0,0,0,22.853,1.148ZM5.174,21.122A3.022,3.022,0,0,1,3.053,22H2V20.947a2.98,2.98,0,0,1,.879-2.121L15.222,6.483l2.3,2.3ZM21.438,4.857,18.932,7.364l-2.3-2.295,2.507-2.507a1.623,1.623,0,1,1,2.295,2.3Z" />
                  </svg>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div
                  onClick={() => setIsAddAuthorModalVisible(true)}
                  className="group flex h-12 w-12 cursor-pointer items-center justify-center rounded-2xl border-2 border-green-11 bg-white shadow-md transition-all duration-500 hover:bg-green-11"
                >
                  <svg
                    className="h-5 w-5 fill-current text-green-11 transition-all duration-500 group-hover:text-white"
                    viewBox="0 0 24 24"
                  >
                    <path d="M23,11H21V9a1,1,0,0,0-2,0v2H17a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V13h2a1,1,0,0,0,0-2Z" />
                    <path d="M9,12A6,6,0,1,0,3,6,6.006,6.006,0,0,0,9,12ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z" />
                    <path d="M9,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,9,14Z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="relative w-full">
              <DatePicker
                id="date"
                defaultValue={
                  blogPostedDate.state
                    ? moment(new Date(blogPostedDate.state), "DD/MM/YYYY")
                    : ""
                }
                className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:border-transparent focus:outline-none ${
                  blogPostedDate.error?.length !== 0
                    ? "border-orange-1 focus:ring-orange-1"
                    : ""
                }`}
                format={["DD/MM/YYYY", "DD/MM/YY"]}
                placeholder="Select Date"
                getPopupContainer={(trigger) => trigger.parentElement}
                onChange={(value) => {
                  setBlogPostedDate({
                    ...blogPostedDate,
                    state: value?._d || "",
                  });
                  setInstanceModification(true);
                }}
              />
              <label
                htmlFor="date"
                className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
              >
                Date
              </label>
              <div className="absolute">
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {blogPostedDate.error}{" "}
                </p>
              </div>
            </div>
            <div className="relative w-full">
              <input
                id="likes"
                className={`peer h-12 w-full appearance-none rounded-xl border-2 px-3 py-2 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 focus:outline-none ${
                  blogNumberOfLikes.error?.length !== 0
                    ? "border-orange-1 focus:ring-orange-1"
                    : "hover:border-green-11"
                }`}
                value={blogNumberOfLikes.state}
                onChange={(e) => {
                  setBlogNumberOfLikes({
                    ...blogNumberOfLikes,
                    state: e.target.value,
                  });
                  setInstanceModification(true);
                }}
                placeholder="420"
              />
              <label
                htmlFor="likes"
                className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
              >
                Likes
              </label>
              <div className="absolute">
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {blogNumberOfLikes.error}{" "}
                </p>
              </div>
            </div>
            <div className="relative w-full">
              <input
                className={`peer h-12 w-full appearance-none rounded-xl border-2 px-3 py-2 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 focus:outline-none ${
                  blogEstimateTimeOfRead.error?.length !== 0
                    ? "border-orange-1 focus:ring-orange-1"
                    : "hover:border-green-11"
                }`}
                value={blogEstimateTimeOfRead.state}
                onChange={(e) => {
                  setBlogEstimateTimeOfRead({
                    ...blogEstimateTimeOfRead,
                    state: e.target.value,
                  });
                  setInstanceModification(true);
                }}
                placeholder="4"
              />
              <label
                htmlFor="readTime"
                className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
              >
                Read Time
              </label>
              <div className="absolute">
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {blogEstimateTimeOfRead.error}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          id="section_3"
          className={`${
            progress === 2 ? "block" : "hidden"
          } mx-auto max-w-5xl p-5`}
        >
          <div className=" pb-10 text-center font-poppins text-4xl font-bold text-grey-1">
            <span className="text-green-11">Article</span> Card
          </div>
          <div className="flex w-full flex-row gap-10">
            <div className="flex w-full flex-col gap-5">
              <div className="relative w-full">
                <TextareaAutosize
                  id="previewText"
                  className={` scrollBarHome peer h-12 w-full appearance-none rounded-xl border-2 py-3 pl-3 pr-20 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 focus:outline-none ${
                    blogPreview.error?.length !== 0
                      ? "border-orange-1 focus:ring-orange-1"
                      : "hover:border-green-11"
                  }`}
                  value={blogPreview.state}
                  onChange={(e) => {
                    setBlogPreview({ ...blogPreview, state: e.target.value });
                    setInstanceModification(true);
                  }}
                  placeholder="preview text"
                  maxLength={500}
                />
                <label
                  htmlFor="previewText"
                  className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
                >
                  Preview Text
                </label>
                <div className="absolute right-3 top-3 font-raleway">
                  {blogPreview.state.length} / 500
                </div>
                <div className="absolute">
                  <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                    {" "}
                    {blogPreview.error}{" "}
                  </p>
                </div>
              </div>
              <div className="group relative w-full">
                <div
                  className={`absolute flex h-12 w-full appearance-none items-center justify-between`}
                >
                  <div
                    className={`${
                      blogFeaturedImage.error.length !== 0
                        ? "border-orange-1"
                        : "group-hover:border-green-11"
                    } w-full rounded-l-xl border-y-2 border-l-2 px-3 py-2 text-lg transition-all duration-500`}
                  >
                    <p className="font-raleway text-grey-8">
                      {blogFeaturedImage.ImgName}
                    </p>
                  </div>
                  <div className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300">
                    Featured image
                  </div>
                  <div
                    className={`${
                      blogFeaturedImage.error.length !== 0
                        ? "border-orange-1 group-hover:bg-orange-1"
                        : "border-green-11 group-hover:bg-green-11"
                    } flex h-full items-center justify-center rounded-r-xl border-2 bg-white px-10 transition-all duration-500`}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      className={`${
                        blogFeaturedImage.error.length !== 0
                          ? "text-orange-1"
                          : "text-green-11"
                      } h-6 w-6 fill-current transition-all duration-500 group-hover:text-white`}
                    >
                      <path d="M21,12h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Zm1,7c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-4c0-.552,.448-1,1-1l3-.002v.002c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4h3c.552,0,1,.448,1,1v4ZM7.293,5.293c-.391-.391-.391-1.023,0-1.414L10.586,.586C10.972,.2,11.479,.006,11.986,.003l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l3.293,3.293c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.293-2.293v7c0,.553-.447,1-1,1s-1-.447-1-1V3l-2.293,2.293c-.391,.391-1.023,.391-1.414,0Z" />
                    </svg>
                  </div>
                </div>
                <input
                  onChange={handleChangeIMG}
                  className="focus:shadow-outline h-12 w-full cursor-pointer appearance-none rounded border px-3 py-2 leading-tight text-gray-700 opacity-0 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  id="resume"
                  type="file"
                  accept="image/png, image/jpg,image/jpeg"
                />
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {blogFeaturedImage.error}{" "}
                </p>
              </div>
              <div className="group relative w-full">
                <div
                  className={`absolute flex h-12 w-full appearance-none items-center justify-between`}
                >
                  <div
                    className={`${
                      homePageImage.error.length !== 0
                        ? "border-orange-1"
                        : "group-hover:border-green-11"
                    } w-full rounded-l-xl border-y-2 border-l-2 px-3 py-2 text-lg transition-all duration-500`}
                  >
                    <p className="font-raleway text-grey-8">
                      {homePageImage.ImgName}
                    </p>
                  </div>
                  <div className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300">
                    Home Page Image
                  </div>
                  <div
                    className={`${
                      homePageImage.error.length !== 0
                        ? "border-orange-1 group-hover:bg-orange-1"
                        : "border-green-11 group-hover:bg-green-11"
                    } flex h-full items-center justify-center rounded-r-xl border-2 bg-white px-10 transition-all duration-500`}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      className={`${
                        homePageImage.error.length !== 0
                          ? "text-orange-1"
                          : "text-green-11"
                      } h-6 w-6 fill-current transition-all duration-500 group-hover:text-white`}
                    >
                      <path d="M21,12h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Zm1,7c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-4c0-.552,.448-1,1-1l3-.002v.002c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4h3c.552,0,1,.448,1,1v4ZM7.293,5.293c-.391-.391-.391-1.023,0-1.414L10.586,.586C10.972,.2,11.479,.006,11.986,.003l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l3.293,3.293c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.293-2.293v7c0,.553-.447,1-1,1s-1-.447-1-1V3l-2.293,2.293c-.391,.391-1.023,.391-1.414,0Z" />
                    </svg>
                  </div>
                </div>
                <input
                  onChange={changeHomePageImage}
                  className="focus:shadow-outline h-12 w-full cursor-pointer appearance-none rounded border px-3 py-2 leading-tight text-gray-700 opacity-0 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  id="resume"
                  type="file"
                  accept="image/png, image/jpg,image/jpeg"
                />
                <p className="m-0 pt-1 font-raleway text-sm text-orange-1">
                  {" "}
                  {homePageImage.error}{" "}
                </p>
              </div>
            </div>
            <div className=" w-full">
              <div
                style={{ maxWidth: "414px" }}
                className=" group mx-auto flex flex-col"
              >
                <Carousel autoplay draggable className="max-h-[16rem]">
                  <div className=" h-full max-h-[16rem] overflow-hidden rounded-3xl bg-green-11">
                    {blogFeaturedImage.ImgSrc.length ? (
                      <img
                        src={blogFeaturedImage.ImgSrc}
                        className=" h-full w-full rounded-3xl object-fill"
                        alt="Featured_image"
                      />
                    ) : data.imageUrl ? (
                      <img
                        src={STORAGE_DOMAIN + data.imageUrl}
                        alt="CardImg"
                        className=" h-full w-full rounded-3xl object-fill"
                      />
                    ) : (
                      <div className=" flex h-64 w-full items-center justify-center bg-grey-17">
                        <p className=" font-poppins text-lg font-bold text-white">
                          No image
                        </p>
                      </div>
                    )}
                  </div>
                  <div className=" h-full max-h-[16rem] overflow-hidden rounded-3xl bg-green-11">
                    {homePageImage.ImgSrc.length ? (
                      <img
                        src={homePageImage.ImgSrc}
                        className=" h-full w-full rounded-3xl object-fill"
                        alt="Featured_image"
                      />
                    ) : data.imageUrl1 ? (
                      <img
                        src={STORAGE_DOMAIN + data.imageUrl1}
                        alt="CardImg"
                        className=" h-full w-full rounded-3xl object-fill"
                      />
                    ) : (
                      <div className=" flex h-64 w-full items-center justify-center bg-grey-17">
                        <p className=" font-poppins text-lg font-bold text-white">
                          No image
                        </p>
                      </div>
                    )}
                  </div>
                </Carousel>
                <div className="flex flex-grow flex-col justify-between gap-2">
                  {title.state ? (
                    <p className="py-2 font-poppins text-xl font-bold text-grey-1 transition-all duration-300 group-hover:text-green-11">
                      {title.state}
                    </p>
                  ) : (
                    <p className="py-2 font-poppins text-xl font-bold text-grey-1 transition-all duration-300 group-hover:text-green-11">
                      Lorem ipsum
                    </p>
                  )}
                  <div className="flex flex-col">
                    {blogPreview.state ? (
                      <p
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 6,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className=" font-raleway leading-5 text-grey-7"
                      >
                        {blogPreview.state}
                      </p>
                    ) : (
                      <p
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 6,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        className=" font-raleway leading-5 text-grey-7"
                      >
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Maxime mollitia, molestiae quas vel sint commodi
                        repudiandae consequuntur voluptatum laborum numquam
                        blanditiis harum quisquam eius sed odit fugiat iusto
                        fuga praesentium optio, eaque rerum! Provident similique
                        accusantium nemo autem. Veritatis obcaecati tenetur iure
                        eius earum ut molestias architecto voluptate aliquam
                        nihil, eveniet aliquid culpa officia aut! Impedit sit
                        sunt quaerat, odit, tenetur error, harum nesciunt ipsum
                        debitis quas aliquid.
                      </p>
                    )}
                    <div className="relative flex flex-row items-center justify-center py-2">
                      <div className="absolute h-1 w-full bg-grey-18"></div>
                      {blogPostedDate.state ? (
                        <p className=" z-10 bg-white px-4 font-raleway font-semibold text-grey-7 transition-all duration-300 group-hover:text-green-11">
                          {new Date(blogPostedDate.state).toLocaleString(
                            "default",
                            { month: "long" }
                          )}{" "}
                          {new Date(blogPostedDate.state).getDate()},{" "}
                          {new Date(blogPostedDate.state).getFullYear()}
                        </p>
                      ) : (
                        <p className=" z-10 bg-white px-4 font-raleway font-semibold text-grey-7 transition-all duration-300 group-hover:text-green-11">
                          Please Select a date
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="section_4"
          className={`${progress === 3 ? "block" : "hidden"}`}
        >
          <div className=" pb-10 text-center font-poppins text-4xl font-bold text-grey-1">
            <span className="text-green-11">Article</span> Content
          </div>
          <div className="mb-16 border-b-2 border-solid border-grey-4 bg-grey-15">
            <div
              id="first_section"
              className=" mx-auto flex max-w-4xl flex-col items-center py-9"
            >
              <p className="px-5 text-center font-poppins text-3xl font-bold text-grey-16">
                {title.state ? (
                  <span>{title.state}</span>
                ) : (
                  <span>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit
                  </span>
                )}
              </p>
              <div className="h-8"></div>
              <div className="mx-auto flex w-full max-w-4xl flex-col items-center gap-5 px-5 md:flex-row md:justify-between">
                <div className="flex gap-3">
                  <div className=" h-12 w-12 overflow-hidden rounded-full">
                    {author.state ? (
                      <img
                        src={STORAGE_DOMAIN + author.info.authorImage}
                        height="48"
                        width="48"
                        className=" rounded-full"
                        alt="Eloqua Consulting"
                      />
                    ) : (
                      <div className="h-full w-full bg-grey-17"></div>
                    )}
                  </div>
                  <div>
                    <p className="font-poppins text-base font-normal text-grey-1">
                      {author.state ? (
                        <span>{author.info.authorName}</span>
                      ) : (
                        <span>Please select an author</span>
                      )}
                    </p>
                    <p className="font-raleway text-sm text-grey-2">
                      {blogPostedDate.state ? (
                        <span>
                          {blogPostedDate.state.toLocaleString("default", {
                            month: "long",
                          })}{" "}
                          {blogPostedDate.state.getDate()},{" "}
                          {blogPostedDate.state.getFullYear()}
                        </span>
                      ) : (
                        <span>Please Select a date</span>
                      )}
                      <span> • </span>
                      {blogEstimateTimeOfRead.state ? (
                        <span>{blogEstimateTimeOfRead.state}</span>
                      ) : (
                        <span> no time specified </span>
                      )}{" "}
                      mins read
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="group flex cursor-pointer items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      className={`fill-current text-green-11 transition-all duration-300 group-hover:text-green-11`}
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
                    </svg>
                    <p
                      className={`font-raleway text-sm text-green-11 transition-all duration-300 group-hover:text-green-11`}
                    >
                      {blogNumberOfLikes.state}
                    </p>
                  </div>
                  <div className="cursor-pointer text-grey-16 transition-all duration-200 hover:text-green-11">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      className="fill-current"
                      viewBox="0 0 24 24"
                    >
                      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                    </svg>
                  </div>
                  <div className="cursor-pointer text-grey-16 transition-all duration-200 hover:text-green-11">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      className="fill-current"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                    </svg>
                  </div>
                  <div className="cursor-pointer text-grey-16 transition-all duration-200 hover:text-green-11">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      className="fill-current"
                      viewBox="0 0 24 24"
                    >
                      <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" mx-auto max-w-5xl lg:px-20">
            <Editor
              bodyData={data?.bodyData}
              setEditorInstance={setEditorInstance}
              imagesUploaded={imagesUploaded}
              setImagesUploaded={setImagesUploaded}
              setEditorModification={setInstanceModification}
            />
          </div>
          <div className="mx-auto mt-16 max-w-4xl">
            <div className=" border-b-2 border-t-2 border-solid border-grey-4 px-2 py-5 lg:px-0">
              <div className="flex gap-3">
                <div className=" h-12 w-12 overflow-hidden rounded-full">
                  {author.state ? (
                    <img
                      src={STORAGE_DOMAIN + author.info.authorImage}
                      height="48"
                      width="48"
                      className=" rounded-full"
                      alt="Eloqua Consulting"
                    />
                  ) : (
                    <div className="h-full w-full bg-grey-17"></div>
                  )}
                </div>
                <div className="w-full">
                  <p className="font-poppins text-base font-normal text-grey-1">
                    {author.state ? (
                      <span>{author.info.authorName}</span>
                    ) : (
                      <span>Please select an author</span>
                    )}
                  </p>
                  {author.state ? (
                    <p
                      style={{ color: "#606060" }}
                      className="pb-5 font-poppins text-sm font-bold"
                    >
                      {author.info.authorPosition}
                    </p>
                  ) : (
                    ""
                  )}
                  <p
                    style={{ color: "#004040" }}
                    className=" font-raleway text-sm leading-4"
                  >
                    {author?.info?.authorDescription}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col items-center gap-5 px-2 py-5 md:flex-row md:items-start md:justify-between lg:px-0">
              <div className="flex max-w-md flex-wrap justify-center gap-3 md:justify-start">
                {tags.state &&
                  tags.state.map((element, index) => (
                    <div
                      key={index}
                      className=" cursor-pointer rounded bg-green-10 px-4 shadow-lg transition-all duration-500 hover:bg-grey-1"
                    >
                      <p className=" font-poppins text-xs font-semibold leading-7 text-white">
                        {element}
                      </p>
                    </div>
                  ))}
              </div>
              <div className="flex items-center gap-3">
                <div className="group flex cursor-pointer items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    className={`fill-current text-green-11 transition-all duration-300 group-hover:text-green-11`}
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
                  </svg>
                  <p
                    className={`font-raleway text-sm text-green-11 transition-all duration-300 group-hover:text-green-11`}
                  >
                    {blogNumberOfLikes.state}
                  </p>
                </div>
                <div className="cursor-pointer text-grey-16 transition-all duration-200 hover:text-green-11">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    className="fill-current"
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                </div>
                <div className="cursor-pointer text-grey-16 transition-all duration-200 hover:text-green-11">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    className="fill-current"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </div>
                <div className="cursor-pointer text-grey-16 transition-all duration-200 hover:text-green-11">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    className="fill-current"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" block p-5 lg:hidden">
        <p className="mb-0 text-center font-poppins text-lg font-bold text-grey-1 md:text-2xl lg:text-left">
          Editor is not available on mobile or tablet. Please login on a
          compatible device.
        </p>
      </div>
      <div className="h-10"></div>
    </div>
  );
}
