import {DOMAIN, STORAGE_DOMAIN} from "../../env";

const ServingCard1Player = (props) => {
	return (
		<div className="flex flex-col items-center justify-center">
			<div className="h-24 w-24 rounded-full shadow-lg overflow-hidden relative">
				{props.player.user_image !== undefined ? (
					<img
						className="h-24 w-24"
						src={`${STORAGE_DOMAIN+props.player.user_image}`}
						alt="player"
					/>
				) : (
					<div className="w-full h-full flex justify-center items-center bg-green-11">
						<p className=" text-white font-bold font-poppins text-2xl">
							{props.player.user_name.match(/\b(\w)/g).join(".")}
						</p>
					</div>
				)}
			</div>
			<div className="pt-3 w-full">
				<p className=" text-grey-1 font-poppins font-bold text-xl text-center whitespace-pre-wrap truncate">
					{props.player.user_name}
				</p>
			</div>
		</div>
	);
};

const ServingCard2Players = (props) => {
	return (
		<div>
			<div className="flex gap-4">
				<div className="h-20 w-20 rounded-full shadow-lg overflow-hidden">
					{props.player1.user_image !== undefined ? (
						<img
							className="h-24 w-24"
							src={`${STORAGE_DOMAIN+props.player1.user_image}`}
							alt="player-1"
						/>
					) : (
						<div className="w-full h-full flex justify-center items-center bg-green-11">
							<p className=" text-white font-bold font-poppins text-2xl">
								{props.player1.user_name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
				<div className="h-20 w-20 rounded-full shadow-lg overflow-hidden">
					{props.player2.user_image !== undefined ? (
						<img
							className="h-24 w-24"
							src={`${STORAGE_DOMAIN+props.player2.user_image}`}
							alt="player-2"
						/>
					) : (
						<div className="w-full h-full flex justify-center items-center bg-orange-1">
							<p className=" text-white font-bold font-poppins text-2xl">
								{props.player2.user_name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
			</div>
			<div className="pt-3">
				<p className=" text-grey-1 font-poppins font-bold text-xl text-center whitespace-pre-wrap truncate">
					{props.player1.user_name.split(" ")[0]} &{" "}
					{props.player2.user_name.split(" ")[0]}
				</p>
			</div>
		</div>
	);
};

const TeamCard1Player = (props) => {
	return (
		<div className="flex flex-col items-center justify-center">
			<div
				className={`h-16 w-16 rounded-full shadow-lg overflow-hidden relative`}
			>
				{props.player.user_image !== undefined ? (
					<img
						className="h-16 w-16"
						src={`${STORAGE_DOMAIN+props.player.user_image}`}
						alt="player"
					/>
				) : (
					<div className="w-full h-full flex justify-center items-center bg-green-11">
						<p className=" text-white font-bold font-poppins text-2xl">
							{props.player.user_name.match(/\b(\w)/g).join(".")}
						</p>
					</div>
				)}
			</div>
			<p className=" font-poppins text-grey-1 font-bold pt-2 text-lg text-center leading-6 whitespace-pre-wrap truncate max-w-[159px]">
				{props.player.user_name.split(" ")[0]}
			</p>
		</div>
	);
};

const TeamCard2Players = (props) => {
	return (
		<div className="flex flex-col">
			<div className="flex transform justify-center translate-x-2">
				<div
					className={`h-14 w-14 rounded-full shadow-lg overflow-hidden transition-all duration-500`}
				>
					{props.player1.user_image !== undefined ? (
						<img
							className="h-14 w-14"
							src={`${STORAGE_DOMAIN+props.player1.user_image}`}
							alt="player-1"
						/>
					) : (
						<div className="w-full h-full flex justify-center items-center bg-grey-1">
							<p className=" text-white font-bold font-poppins text-2xl">
								{props.player1.user_name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
				<div
					className={`h-14 w-14 rounded-full shadow-lg overflow-hidden transform -translate-x-3 translate-y-3 transition-all duration-500`}
				>
					{props.player2.user_image !== undefined ? (
						<img
							className="h-14 w-14"
							src={`${STORAGE_DOMAIN+props.player2.user_image}`}
							alt="player-2"
						/>
					) : (
						<div className="w-full h-full flex justify-center items-center bg-orange-1">
							<p className=" text-white font-bold font-poppins text-2xl">
								{props.player2.user_name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
			</div>
			<div className="pt-3">
				<p className=" text-grey-1 font-poppins font-bold text-xl text-center whitespace-pre-wrap truncate">
					{props.player1.user_name.split(" ")[0]} &{" "}
					{props.player2.user_name.split(" ")[0]}
				</p>
			</div>
		</div>
	);
};

const WinnerCard1Player = (props) => {
	return (
		<div className="flex flex-col items-center gap-5 justify-center">
			<div
				className={`h-36 w-36 rounded-full shadow-lg overflow-hidden relative`}
			>
				{props.player.user_image !== undefined ? (
					<img
						className="h-36 w-36"
						src={`${STORAGE_DOMAIN+props.player.user_image}`}
						alt="player-1"
					/>
				) : (
					<div className="w-full h-full flex justify-center items-center bg-green-11">
						<p className=" text-white font-bold font-poppins text-3xl">
							{props.player.user_name.match(/\b(\w)/g).join(".")}
						</p>
					</div>
				)}
			</div>
			<p className=" text-grey-1 font-poppins font-bold text-3xl whitespace-pre-wrap truncate">
				{props.player.user_name.split(" ")[0]}
			</p>
		</div>
	);
};

const WinnerCard2Players = (props) => {
	return (
		<div className="flex gap-5 flex-col justify-center items-center">
			<div className="flex gap-5">
				<div
					className={`h-32 w-32 rounded-full shadow-lg overflow-hidden transition-all duration-500`}
				>
					{props.player1.user_image !== undefined ? (
						<img
							className="h-32 w-32"
							src={`${STORAGE_DOMAIN+props.player1.user_image}`}
							alt="team-1-player-1"
						/>
					) : (
						<div className="w-full h-full flex justify-center items-center bg-green-11">
							<p className=" text-white font-bold font-poppins text-3xl">
								{props.player1.user_name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
				<div className={`h-32 w-32 rounded-full shadow-lg overflow-hidden`}>
					{props.player2.user_image !== undefined ? (
						<img
							className="h-32 w-32"
							src={`${STORAGE_DOMAIN+props.player2.user_image}`}
							alt="team-1-player-2"
						/>
					) : (
						<div className="w-full h-full flex justify-center items-center bg-orange-1">
							<p className=" text-white font-bold font-poppins text-3xl">
								{props.player2.user_name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
			</div>
			<div>
				<p className=" text-grey-1 font-poppins font-bold text-3xl text-center whitespace-pre-wrap truncate">
					{props.player1.user_name.split(" ")[0]} &{" "}
					{props.player2.user_name.split(" ")[0]}
				</p>
			</div>
		</div>
	);
};

export {
	ServingCard1Player,
	ServingCard2Players,
	TeamCard1Player,
	TeamCard2Players,
	WinnerCard1Player,
	WinnerCard2Players,
};
