import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { animated } from "react-spring";

export const Card = ({ id, text, index, moveCard, published }) => {
	const ref = useRef(null);
	const [{ handlerId }, drop] = useDrop({
		accept: "card",
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex);
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});
	const [{ isDragging }, drag] = useDrag({
		type: "card",
		item: () => {
			return { id, index };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	drag(drop(ref));

	return (
		<animated.div
			className={`mb-5 flex cursor-move flex-col rounded-2xl border-2 border-solid bg-grey-4 p-5 shadow-lg ${
				published ? "border-green-11" : "border-grey-4"
			} ${isDragging ? "opacity-0" : " opacity-100"}`}
			ref={ref}
			data-handler-id={handlerId}
		>
			<p className="select-none overflow-hidden text-center font-poppins text-3xl font-bold text-grey-1 line-clamp-1">
				{text}
			</p>
		</animated.div>
	);
};
