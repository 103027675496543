import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Prompt } from "react-router";
import axios from "axios";
import { message, Select, DatePicker, Steps } from "antd";
import UserContext from "../../../context/context";
import TextareaAutosize from "react-textarea-autosize";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/src/Page/AnnotationLayer.css";
import NoFileFound from "../../../images/pdf_not_found.png";
import {DOMAIN} from "../../../env";

export default function AddCaseStudy() {
	const history = useHistory();
	const { Option } = Select;
	const user = useContext(UserContext);
	const { Step } = Steps;

	const [numPages, setNumPages] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [instanceModification, setInstanceModification] = useState(false);

	const [progress, setProgress] = useState(0);
	const [title, setTitle] = useState({ state: "", error: "" });
	const [link, setLink] = useState({ state: "", error: "" });
	const [checkLink, setCheckLink] = useState({
		status: undefined,
		state: undefined,
		error: false,
	});
	const [blogPostedDate, setBlogPostedDate] = useState({
		state: "",
		error: "",
	});
	const [HubSpotlink, setHubSpotLink] = useState({ state: "", error: "" });
	const [blogTopic, setBlogTopic] = useState({ state: "", error: "" });
	const [blogPreview, setBlogPreview] = useState({ state: "", error: "" });
	const [blogMetaDescription, setBlogMetaDescription] = useState({
		state: "",
		error: "",
	});
	const [file, setFile] = useState({
		fileName: "No file chosen",
		state: null,
		error: "",
	});
	const [img, setImg] = useState({
		ImgName: "No file chosen",
		state: null,
		error: "",
		ImgSrc: [],
	});

	useEffect(() => {
		if (!["Developer", "Marketing", "Admin"].includes(user.user.user_role)) {
			message.error({
				content: `Unauthorized!`,
				className: "text-center",
				duration: 2,
			});
			history.push("/");
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (link.state.length)
				axios
					.post(
						`${DOMAIN}/api/knowledgeHub/caseStudy/checkurl/${link.state}`,
						{
							id: undefined,
						},
						{
							headers: {
								"jwt-token": user.jwtToken,
							},
						}
					)
					.then((results) => {
						if (results.data.success)
							setCheckLink({
								...checkLink,
								status: true,
								state: true,
								error: false,
							});
						else
							setCheckLink({
								...checkLink,
								status: true,
								state: false,
								error: true,
							});
					});
			else setCheckLink({ ...checkLink, status: undefined, state: undefined });
		}, 500);
		return () => clearTimeout(timer);
		// eslint-disable-next-line
	}, [link.state]);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	function handleChangePDF(event) {
		const fileType = ["application/pdf"];
		if (event.target.value.length !== 0)
			if (event.target.files[0].size > 10485760) {
				event.target.value = null;
				setFile({
					...file,
					error: "Only files under 10MB",
					fileName: "No file chosen",
				});
				return;
			}
		if (!fileType.includes(event.target.files[0].type)) {
			event.target.value = null;
			setFile({
				...file,
				error: "Incorrect file type, only: .pdf",
				fileName: "No file chosen",
			});
		} else {
			setInstanceModification(true);
			setFile({
				...file,
				state: event.target.files[0],
				fileName: event.target.files[0].name,
				error: "",
			});
		}
	}

	function handleChangeIMG(event) {
		const fileType = ["image/png", "image/jpg", "image/jpeg"];
		if (event.target.value.length !== 0)
			if (event.target.files[0].size > 10485760) {
				event.target.value = null;
				setImg({
					...img,
					error: "Only files under 10MB",
					ImgName: "No file chosen",
				});
				return;
			}
		if (!fileType.includes(event.target.files[0].type)) {
			event.target.value = null;
			setImg({
				...img,
				error: "Incorrect file type, only: .png, .jpg, .jpeg",
				ImgName: "No file chosen",
			});
		} else {
			setInstanceModification(true);
			var reader = new FileReader();
			// eslint-disable-next-line
			var url = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function () {
				setImg({
					...img,
					state: event.target.files[0],
					ImgName: event.target.files[0].name,
					error: "",
					ImgSrc: [reader.result],
				});
			};
		}
	}

	const onSave = async () => {
		const loadingMessage = message.loading("Loading...", 0);
		var formData = new FormData();
		formData.append("Topic", blogTopic.state);
		formData.append("Title", title.state);
		formData.append("Link", link.state);
		formData.append("postedDate", blogPostedDate.state);
		formData.append("HubSpotLPLink", HubSpotlink.state);
		formData.append("Preview", blogPreview.state);
		formData.append("MetaDescription", blogMetaDescription.state);
		formData.append("PDF", file.state);
		formData.append("image", img.state);
		formData.append("published", false);

		await axios
			.post(
				`${DOMAIN}/api/knowledgeHub/caseStudy/create`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then((response) => {
				message.success({
					content: `Blog Post Saved!`,
					className: "text-center",
					duration: 2,
				});
				setInstanceModification(false);
				history.push(`/knowledge-hub/caseStudy/edit/${response.data.id}`);
			})
			.catch((error) => {
				message.error({
					content: `${error.response.data.error}`,
					className: "text-center",
					duration: 2,
				});
			})
			.finally(() => {
				loadingMessage();
			});
	};

	return (
		<div className="relative">
			<Prompt
				when={instanceModification}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
			<div className=" sticky top-0 z-50 flex flex-row flex-wrap items-center justify-center gap-5 border-b-2 bg-grey-4 p-5 md:justify-between">
				<p className=" mb-0 text-center font-poppins text-lg font-bold text-grey-1 md:text-2xl lg:text-left">
					New Case Study
				</p>
				<div className="flex justify-center gap-4">
					<div>
						<div
							onClick={() => onSave()}
							className={`flex cursor-pointer items-center gap-2 rounded-2xl border-2 border-green-11 bg-green-11 px-5 py-2 shadow-lg transition-all duration-500`}
						>
							<svg
								viewBox="0 0 24 24"
								className=" h-4 w-4 fill-current text-white"
							>
								<path d="M12,10a4,4,0,1,0,4,4A4,4,0,0,0,12,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,16Z" />
								<path d="M22.536,4.122,19.878,1.464A4.966,4.966,0,0,0,16.343,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7.657A4.966,4.966,0,0,0,22.536,4.122ZM17,2.08V3a3,3,0,0,1-3,3H10A3,3,0,0,1,7,3V2h9.343A2.953,2.953,0,0,1,17,2.08ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2V3a5.006,5.006,0,0,0,5,5h4a4.991,4.991,0,0,0,4.962-4.624l2.16,2.16A3.02,3.02,0,0,1,22,7.657Z" />
							</svg>
							<p className=" font-montserrat font-bold uppercase text-white">
								Save
							</p>
						</div>
					</div>
					<div
						onClick={() => history.push("/knowledge-hub")}
						className="group flex cursor-pointer items-center gap-2 rounded-2xl border-2 border-red-600 px-5 py-2 shadow-lg transition-all duration-500 hover:bg-red-600"
					>
						<svg
							className=" h-4 w-4 fill-current text-red-600 transition-all duration-500 group-hover:text-white"
							viewBox="0 0 24 24"
						>
							<path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
							<path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
							<path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
						</svg>

						<p className=" font-montserrat font-bold uppercase text-red-600 transition-all duration-500 group-hover:text-white">
							Cancel
						</p>
					</div>
				</div>
			</div>
			<div className=" hidden md:block">
				<div className=" w-ful flex flex-row gap-5 p-5">
					<div
						className=" flex items-center"
						onClick={() => (progress > 0 ? setProgress(progress - 1) : null)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className={`rotate-180 transform fill-current text-green-11 transition-all duration-500 ${
								progress === 0 ? " opacity-50" : "cursor-pointer"
							}`}
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
						</svg>
					</div>
					<Steps
						className=" select-none pt-4"
						current={progress}
						size="small"
						progressDot
					>
						<Step
							title="SEO Information"
							description=""
							onClick={() => setProgress(0)}
						/>
						<Step
							title="Case Study Card"
							description=""
							onClick={() => setProgress(1)}
						/>
						<Step
							title="Case Study Content"
							description=""
							onClick={() => setProgress(2)}
						/>
					</Steps>
					<div
						className=" flex items-center"
						onClick={() => (progress < 2 ? setProgress(progress + 1) : null)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className={`fill-current text-green-11 ${
								progress === 2 ? " opacity-50" : "cursor-pointer"
							}`}
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
						</svg>
					</div>
				</div>
				<div
					id="section_1"
					className={`${progress === 0 ? "block" : "hidden"} p-5`}
				>
					<div className=" pb-10 text-center font-poppins text-4xl font-bold text-grey-1">
						<span className="text-green-11">SEO</span> Information
					</div>
					<div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
						<div>
							<p className=" w-72 font-montserrat text-lg font-bold">Topic:</p>
							<div className="relative w-full" id="topicSelect">
								<Select
									showSearch
									className={`h-12 w-full appearance-none border-b-4 bg-grey-13 py-2 px-3 ${
										blogTopic.error?.length !== 0
											? "border-orange-1 focus:ring-orange-1"
											: "border-green-11 focus:ring-green-11"
									} cursor-pointer leading-tight text-gray-700 focus:border-transparent focus:outline-none focus:ring-2`}
									placeholder="Select blog post topic"
									onChange={(value) => {
										setBlogTopic({ ...blogTopic, state: value });
										setInstanceModification(true);
									}}
									getPopupContainer={() =>
										document.getElementById("topicSelect")
									}
								>
									<Option value="Privacy"> Eloqua Privacy </Option>
									<Option value="Implementation">
										{" "}
										Eloqua Implementation{" "}
									</Option>
									<Option value="CampaignProduction">
										{" "}
										Eloqua Campaign Prod{" "}
									</Option>
									<Option value="PlatformAdmin"> Eloqua Platform Admin </Option>
									<Option value="Reporting"> Eloqua Reporting </Option>
								</Select>
								<div className="absolute">
									<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
										{" "}
										{blogTopic.error}{" "}
									</p>
								</div>
							</div>
						</div>
						<div>
							<div className="flex items-center gap-3">
								<p className=" font-montserrat text-lg font-bold">Link:</p>
								{checkLink.status === false ? (
									<svg
										role="status"
										class={`inline h-4 w-4 animate-spin text-gray-200`}
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											className="fill-current text-grey-4"
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										/>
										<path
											className="fill-current text-green-11"
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										/>
									</svg>
								) : null}
								{checkLink.status === true && checkLink.state === true ? (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-4 w-4 fill-current text-green-11"
										viewBox="0 0 24 24"
									>
										<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
									</svg>
								) : null}
								{checkLink.status === true && checkLink.state === false ? (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-4 w-4 fill-current text-orange-1"
										viewBox="0 0 24 24"
									>
										<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
									</svg>
								) : null}
							</div>
							<div className="relative w-full">
								<input
									className={` h-12 w-full appearance-none border-b-4 bg-grey-13 py-2 px-3 ${
										link.error?.length !== 0
											? "border-orange-1 focus:ring-orange-1"
											: "border-green-11 focus:ring-green-11"
									} leading-tight text-gray-700 focus:border-transparent focus:outline-none focus:ring-2`}
									value={link.state}
									onChange={(e) => {
										setLink({ ...link, state: e.target.value });
										setCheckLink({ ...checkLink, status: false });
										setInstanceModification(true);
									}}
									placeholder="linkedIn-campaign-manager-integration-with-oracle-eloqua"
									//onBlur={e=>checkJobTitle(title,setJobTitle)}
								/>
								<div className="absolute">
									<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
										{" "}
										{link.error}{" "}
									</p>
								</div>
							</div>
						</div>
						<div className="">
							<p className=" w-72 font-montserrat text-lg font-bold">
								HubSpot LP Link:
							</p>
							<div className="relative w-full">
								<input
									className={` h-12 w-full appearance-none border-b-4 bg-grey-13 py-2 px-3 ${
										HubSpotlink.error?.length !== 0
											? "border-orange-1 focus:ring-orange-1"
											: "border-green-11 focus:ring-green-11"
									} leading-tight text-gray-700 focus:border-transparent focus:outline-none focus:ring-2`}
									value={HubSpotlink.state}
									onChange={(e) => {
										setHubSpotLink({ ...HubSpotlink, state: e.target.value });
										setInstanceModification(true);
									}}
									placeholder=""
									//onBlur={e=>checkJobTitle(title,setJobTitle)}
								/>
								<div className="absolute">
									<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
										{" "}
										{HubSpotlink.error}{" "}
									</p>
								</div>
							</div>
						</div>
						<div>
							<p className=" w-72 font-montserrat text-lg font-bold">
								Posted Date:
							</p>
							<div className="relative z-10 w-full" id="datePicker">
								<DatePicker
									className={`h-12 w-full appearance-none border-b-4 bg-grey-13 py-2 px-3 ${
										blogPostedDate.error?.length !== 0
											? "border-orange-1 focus:ring-orange-1"
											: "border-green-11 focus:ring-green-11"
									} cursor-pointer border-0 leading-tight text-gray-700 focus:border-transparent focus:outline-none focus:ring-2`}
									format={["DD/MM/YYYY", "DD/MM/YY"]}
									style={{ zIndex: 10 }}
									getPopupContainer={(trigger) => trigger.parentElement}
									onChange={(value) => {
										setBlogPostedDate({
											...blogPostedDate,
											state: value?._d || "",
										});
										setInstanceModification(true);
									}}
								/>
								<div className="absolute">
									<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
										{" "}
										{blogPostedDate.error}{" "}
									</p>
								</div>
							</div>
						</div>
						<div className="col-span-2">
							<p className=" w-72 font-montserrat text-lg font-bold">
								Meta Description:
							</p>
							<div className="relative w-full">
								<input
									className={` h-12 w-full appearance-none border-b-4 bg-grey-13 py-2 px-3 ${
										blogMetaDescription.error?.length !== 0
											? "border-orange-1 focus:ring-orange-1"
											: "border-green-11 focus:ring-green-11"
									} border-t-0 border-r-0 border-l-0 leading-tight text-gray-700 focus:border-transparent focus:outline-none focus:ring-2`}
									value={blogMetaDescription.state}
									onChange={(e) => {
										setBlogMetaDescription({
											...blogMetaDescription,
											state: e.target.value,
										});
										setInstanceModification(true);
									}}
									placeholder=""
									maxLength={158}
									//onBlur={e=>checkJobTitle(title,setJobTitle)}
								/>
								<div className="absolute right-3 top-3 font-raleway">
									{blogMetaDescription.state.length} / 158
								</div>
								<div className="absolute">
									<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
										{" "}
										{blogMetaDescription.error}{" "}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					id="section_2"
					className={`${progress === 1 ? "block" : "hidden"} p-5`}
				>
					<div className=" pb-10 text-center font-poppins text-4xl font-bold text-grey-1">
						<span className="text-green-11">Case Study</span> Card
					</div>
					<div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
						<div className="flex w-full flex-col gap-5">
							<div>
								<p className=" w-72 font-montserrat text-lg font-bold">
									Title:
								</p>
								<div className="relative w-full">
									<input
										className={` h-12 w-full appearance-none border-b-4 bg-grey-13 py-2 px-3 ${
											title.error?.length !== 0
												? "border-orange-1 focus:ring-orange-1"
												: "border-green-11 focus:ring-green-11"
										} leading-tight text-gray-700 focus:border-transparent focus:outline-none focus:ring-2`}
										value={title.state}
										onChange={(e) => {
											setTitle({ ...title, state: e.target.value });
											setInstanceModification(true);
										}}
										placeholder="LinkedIn Campaign Manager Integration with Oracle Eloqua"
										//onBlur={e=>checkJobTitle(title,setJobTitle)}
									/>
									<div className="absolute">
										<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
											{" "}
											{title.error}{" "}
										</p>
									</div>
								</div>
							</div>
							<div className="mb-3 flex flex-col">
								<div className="w-full">
									<p className="font-montserrat text-lg font-bold">
										Attach Image:
									</p>
								</div>
								<div className="relative z-auto w-full">
									<div
										className={`${
											img.error.length !== 0
												? "border-orange-1"
												: "border-green-11"
										} absolute flex h-12 w-full appearance-none items-center justify-between border-b-4 bg-grey-13 py-2 px-3 leading-tight text-gray-700 focus:outline-none`}
									>
										<p className="font-raleway text-grey-8">{img.ImgName}</p>
										<div className="bg-grey-14 py-2 px-8">
											<p className="font-raleway text-white">Browse</p>
										</div>
									</div>
									<input
										onChange={handleChangeIMG}
										className="focus:shadow-outline h-12 w-full cursor-pointer appearance-none rounded border py-2 px-3 leading-tight text-gray-700 opacity-0 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
										id="resume"
										type="file"
										accept="image/png, image/jpg,image/jpeg"
									/>
									<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
										{" "}
										{img.error}{" "}
									</p>
								</div>
							</div>
						</div>
						<div className="relative top-0 mx-auto h-72 w-82 transform overflow-hidden bg-orange-1 shadow-lg transition-all duration-500 hover:-top-3">
							<div className="relative h-full w-full">
								{img.ImgSrc.length ? (
									<img
										alt="FeaturedImage"
										src={img.ImgSrc}
										className="h-full w-full"
									/>
								) : (
									<div className="flex h-full w-full items-center justify-center bg-gradient-to-b from-orange-2 to-orange-3">
										<p className=" font-poppins text-lg font-bold text-white">
											No Image
										</p>
									</div>
								)}
							</div>
							<div className="absolute top-1/2 flex h-36 w-full items-center justify-center px-8">
								<div className="absolute left-0 h-full w-full bg-white bg-opacity-20 bg-clip-content backdrop-blur-sm backdrop-filter"></div>
								<p className="text-md relative max-h-36 font-montserrat font-bold text-white">
									{title.state ? title.state : "No title"}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					id="section_3"
					className={`${progress === 2 ? "block" : "hidden"} p-5`}
				>
					<div className=" pb-10 text-center font-poppins text-4xl font-bold text-grey-1">
						<span className="text-green-11">Case Study</span> Content
					</div>
					<div className="flex flex-col gap-10">
						<div className="flex w-full flex-col gap-5">
							<div className="">
								<p className=" w-72 font-montserrat text-lg font-bold">
									Preview Text:
								</p>
								<div className="relative w-full">
									<TextareaAutosize
										className={` h-12 w-full appearance-none border-b-4 bg-grey-13 py-2 px-3 pr-20 ${
											blogPreview.error?.length !== 0
												? "border-orange-1 focus:ring-orange-1"
												: "border-green-11 focus:ring-green-11"
										} border-t-0 border-r-0 border-l-0 leading-tight text-gray-700 focus:border-transparent focus:outline-none focus:ring-2`}
										value={blogPreview.state}
										onChange={(e) => {
											setBlogPreview({ ...blogPreview, state: e.target.value });
											setInstanceModification(true);
										}}
										placeholder=""
										maxLength={500}
										//onBlur={e=>checkJobTitle(title,setJobTitle)}
									/>
									<div className="absolute right-3 top-3 font-raleway">
										{blogPreview.state.length} / 500
									</div>
									<div className="absolute">
										<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
											{" "}
											{blogPreview.error}{" "}
										</p>
									</div>
								</div>
							</div>
							<div className="">
								<div className="w-full">
									<p className="font-montserrat text-lg font-bold">
										Attach PDF:
									</p>
								</div>
								<div className="relative z-auto w-full">
									<div
										className={`${
											file.error.length !== 0
												? "border-orange-1"
												: "border-green-11"
										} absolute flex h-12 w-full appearance-none items-center justify-between border-b-4 bg-grey-13 py-2 px-3 leading-tight text-gray-700 focus:outline-none`}
									>
										<p className="font-raleway text-grey-8">{file.fileName}</p>
										<div className="bg-grey-14 py-2 px-8">
											<p className="font-raleway text-white">Browse</p>
										</div>
									</div>
									<input
										onChange={handleChangePDF}
										className="focus:shadow-outline h-12 w-full cursor-pointer appearance-none rounded border py-2 px-3 leading-tight text-gray-700 opacity-0 focus:outline-none focus:ring-2 focus:ring-blue-500"
										id="resume"
										type="file"
										accept=".pdf"
									/>
									<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
										{" "}
										{file.error}{" "}
									</p>
								</div>
							</div>
						</div>
						<div className="mx-auto w-full max-w-7xl">
							<div className="flex flex-col lg:rounded-t-xl lg:border-2 lg:border-b-0 lg:border-grey-21 lg:shadow-inner">
								<Document
									file={file.state}
									onLoadSuccess={onDocumentLoadSuccess}
									noData={
										<div className=" mx-auto max-w-5xl">
											<img
												src={NoFileFound}
												alt="No File Found"
												className=" h-full max-w-xl"
											></img>
										</div>
									}
									className="group relative mx-auto my-10 h-104 max-w-5xl"
								>
									<Page key={`page_${currentPage}`} pageNumber={currentPage} />
									<div class="page-controls flex flex-row items-center rounded-lg opacity-0 shadow-lg group-hover:opacity-100">
										<button
											onClick={() => setCurrentPage(currentPage - 1)}
											className={`${
												currentPage - 1 === 0 ? "cursor-default" : ""
											} group rounded-l-lg p-4`}
											disabled={currentPage - 1 === 0}
											type="button"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="15"
												height="15"
												className={`fill-current transition-all duration-300 ${
													currentPage - 1 === 0
														? "text-grey-2"
														: "text-grey-12 group-hover:text-green-11"
												}`}
												viewBox="0 0 24 24"
											>
												<path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
											</svg>
										</button>
										<span className="">
											{currentPage} of {numPages}
										</span>
										<button
											onClick={() => setCurrentPage(currentPage + 1)}
											className={`${
												currentPage + 1 > numPages ? "cursor-default" : ""
											} group rounded-r-lg p-4`}
											disabled={currentPage + 1 > numPages}
											type="button"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="15"
												height="15"
												className={`fill-current transition-all duration-300 ${
													currentPage + 1 > numPages
														? "text-grey-2"
														: "text-grey-12 group-hover:text-green-11"
												}`}
												viewBox="0 0 24 24"
											>
												<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
											</svg>
										</button>
									</div>
								</Document>
							</div>
							<div className="flex w-full flex-col lg:flex-row">
								<div className=" group w-full cursor-pointer rounded-none bg-green-11 px-4 py-5 shadow-lg lg:rounded-bl-xl">
									<div className="flex flex-col items-center justify-center gap-x-5 md:flex-row">
										<p className=" font-montserrat text-lg text-white md:text-xl">
											Download full Case Study
										</p>
										<div className="group-hover:animate-bounce">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className=" h-7 w-7 fill-current text-white"
												viewBox="0 0 24 24"
											>
												<path d="M17 13v-13h-2v5h-2v-3h-2v7h-2v-9h-2v13h-6l11 11 11-11z" />
											</svg>
										</div>
									</div>
								</div>
								<div className="group block w-full cursor-pointer rounded-b-xl bg-grey-19 px-4 py-5 shadow-lg lg:rounded-br-xl lg:rounded-bl-none">
									<div className="flex flex-col items-center justify-center gap-x-5 md:flex-row">
										<p className=" font-montserrat text-lg text-white md:text-xl">
											Expand to Fullscreen
										</p>
										<div className=" group-hover:animate-ping">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className=" h-7 w-7 fill-current text-white"
												viewBox="0 0 24 24"
											>
												<path d="M24 9h-4v-5h-5v-4h9v9zm-9 15v-4h5v-5h4v9h-9zm-15-9h4v5h5v4h-9v-9zm9-15v4h-5v5h-4v-9h9z" />
											</svg>
										</div>
									</div>
								</div>
							</div>
							<div className="h-14"></div>
							<p className="font-montserrat text-3xl font-bold text-grey-5">
								{title.state
									? title.state
									: "Lorem ipsum dolor sit amet consectetur adipisicing elit"}
							</p>
							<p className="pt-4 font-montserrat text-lg text-grey-5">
								{blogPreview.state
									? blogPreview.state
									: "Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdamrecusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem!"}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className=" block p-5 md:hidden">
				<p className="mb-0 text-center font-poppins text-lg font-bold text-grey-1 md:text-2xl lg:text-left">
					Editor is not available on mobile. Please login on a compatible
					device.
				</p>
			</div>
			<div className="h-10"></div>
		</div>
	);
}
