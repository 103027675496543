import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Prompt } from "react-router";
import axios from "axios";
import { message, Select, DatePicker, Steps } from "antd";
import UserContext from "../../../context/context";
import {DOMAIN} from "../../../env";

export default function AddInfographic() {
	const history = useHistory();
	const { Option } = Select;
	const user = useContext(UserContext);

	const [instanceModification, setInstanceModification] = useState(false);

	const [progress, setProgress] = useState(0);
	const [title, setTitle] = useState({ state: "", error: "" });
	const [link, setLink] = useState({ state: "", error: "" });
	const [blogPostedDate, setBlogPostedDate] = useState({
		state: "",
		error: "",
	});
	const [blogTopic, setBlogTopic] = useState({ state: "", error: "" });

	const [img, setImg] = useState({
		ImgName: "No file chosen",
		state: null,
		error: "",
		ImgSrc: [],
	});

	useEffect(() => {
		if (!["Developer", "Marketing", "Admin"].includes(user.user.user_role)) {
			message.error({
				content: `Unauthorized!`,
				className: "text-center",
				duration: 2,
			});
			history.push("/");
		}
		// eslint-disable-next-line
	}, []);

	function handleChangeIMG(event) {
		const fileType = ["image/png", "image/jpg", "image/jpeg"];
		if (event.target.value.length !== 0)
			if (event.target.files[0].size > 10485760) {
				event.target.value = null;
				setImg({
					...img,
					error: "Only files under 10MB",
					ImgName: "No file chosen",
				});
				return;
			}
		if (!fileType.includes(event.target.files[0].type)) {
			event.target.value = null;
			setImg({
				...img,
				error: "Incorrect file type, only: .png, .jpg, .jpeg",
				ImgName: "No file chosen",
			});
		} else {
			setInstanceModification(true);
			var reader = new FileReader();
			// eslint-disable-next-line
			var url = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function () {
				setImg({
					...img,
					state: event.target.files[0],
					ImgName: event.target.files[0].name,
					error: "",
					ImgSrc: [reader.result],
				});
			};
		}
	}

	const onSave = async () => {
		const loadingMessage = message.loading("Loading...", 0);
		var formData = new FormData();
		formData.append("Topic", blogTopic.state);
		formData.append("Title", title.state);
		formData.append("Link", link.state);
		formData.append("postedDate", blogPostedDate.state);
		formData.append("image", img.state);
		formData.append("published", false);

		await axios
			.post(
				`${DOMAIN}/api/knowledgeHub/infographic/create`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then((response) => {
				message.success({
					content: `Blog Post Saved!`,
					className: "text-center",
					duration: 2,
				});
				setInstanceModification(false);
				history.push(`/knowledge-hub/infographic/edit/${response.data.id}`);
			})
			.catch((error) => {
				message.error({
					content: `${error.response.data.error}`,
					className: "text-center",
					duration: 2,
				});
			})
			.finally(() => {
				loadingMessage();
			});
	};

	return (
		<div className="relative">
			<Prompt
				when={instanceModification}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
			<div className=" sticky top-0 z-50 flex flex-row flex-wrap items-center justify-center gap-5 border-b-2 bg-grey-4 p-5 md:justify-between">
				<p className=" mb-0 text-center font-poppins text-lg font-bold text-grey-1 md:text-2xl lg:text-left">
					New Infographic
				</p>
				<div className="flex justify-center gap-4">
					<div>
						<div
							onClick={() => onSave()}
							className={`flex cursor-pointer items-center gap-2 rounded-2xl border-2 border-green-11 bg-green-11 px-5 py-2 shadow-lg transition-all duration-500`}
						>
							<svg
								viewBox="0 0 24 24"
								className=" h-4 w-4 fill-current text-white"
							>
								<path d="M12,10a4,4,0,1,0,4,4A4,4,0,0,0,12,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,16Z" />
								<path d="M22.536,4.122,19.878,1.464A4.966,4.966,0,0,0,16.343,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7.657A4.966,4.966,0,0,0,22.536,4.122ZM17,2.08V3a3,3,0,0,1-3,3H10A3,3,0,0,1,7,3V2h9.343A2.953,2.953,0,0,1,17,2.08ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2V3a5.006,5.006,0,0,0,5,5h4a4.991,4.991,0,0,0,4.962-4.624l2.16,2.16A3.02,3.02,0,0,1,22,7.657Z" />
							</svg>
							<p className=" font-montserrat font-bold uppercase text-white">
								Save
							</p>
						</div>
					</div>
					<div
						onClick={() => history.push("/knowledge-hub")}
						className="group flex cursor-pointer items-center gap-2 rounded-2xl border-2 border-red-600 px-5 py-2 shadow-lg transition-all duration-500 hover:bg-red-600"
					>
						<svg
							className=" h-4 w-4 fill-current text-red-600 transition-all duration-500 group-hover:text-white"
							viewBox="0 0 24 24"
						>
							<path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
							<path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
							<path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
						</svg>

						<p className=" font-montserrat font-bold uppercase text-red-600 transition-all duration-500 group-hover:text-white">
							Cancel
						</p>
					</div>
				</div>
			</div>
			<div className=" mx-auto hidden max-w-5xl md:block">
				<div className=" w-ful flex flex-row gap-5 p-5">
					<div
						className=" flex items-center"
						onClick={() => (progress > 0 ? setProgress(progress - 1) : null)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className={`transform fill-current text-green-11 transition-all duration-500 ${
								progress === 0 ? " opacity-50" : "cursor-pointer"
							}`}
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M17.921,1.505a1.5,1.5,0,0,1-.44,1.06L9.809,10.237a2.5,2.5,0,0,0,0,3.536l7.662,7.662a1.5,1.5,0,0,1-2.121,2.121L7.688,15.9a5.506,5.506,0,0,1,0-7.779L15.36.444a1.5,1.5,0,0,1,2.561,1.061Z" />
						</svg>
					</div>
					<Steps
						className=" select-none pt-4 font-poppins font-bold text-grey-1"
						current={progress}
						size="small"
						progressDot
						onChange={(value) => setProgress(value)}
						items={[
							{
								title: (
									<p
										className={`${
											progress === 0
												? "font-bold text-green-11"
												: "font-bold text-grey-11 hover:text-green-11"
										} font-montserrat transition-all duration-300`}
									>
										SEO Information
									</p>
								),
							},
							{
								title: (
									<p
										className={`${
											progress === 1
												? "font-bold text-green-11"
												: "font-bold text-grey-11 hover:text-green-11"
										} font-montserrat transition-all duration-300`}
									>
										Infographic Card
									</p>
								),
							},
						]}
					/>
					<div
						className=" flex items-center"
						onClick={() => (progress < 1 ? setProgress(progress + 1) : null)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className={`rotate-180 fill-current text-green-11 ${
								progress === 1 ? " opacity-50" : "cursor-pointer"
							}`}
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M17.921,1.505a1.5,1.5,0,0,1-.44,1.06L9.809,10.237a2.5,2.5,0,0,0,0,3.536l7.662,7.662a1.5,1.5,0,0,1-2.121,2.121L7.688,15.9a5.506,5.506,0,0,1,0-7.779L15.36.444a1.5,1.5,0,0,1,2.561,1.061Z" />
						</svg>
					</div>
				</div>
				<div
					id="section_1"
					className={`${progress === 0 ? "block" : "hidden"} p-5`}
				>
					<div className=" pb-10 text-center font-poppins text-4xl font-bold text-grey-1">
						<span className="text-green-11">SEO</span> Information
					</div>
					<div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
						<div className="relative w-full" id="topicSelect">
							<Select
								id="topic"
								showSearch
								className={`${
									blogTopic.error?.length !== 0
										? "border-orange-1 focus:ring-orange-1"
										: "hover:border-green-11"
								} peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11`}
								onChange={(value) => {
									setBlogTopic({ ...blogTopic, state: value });
									setInstanceModification(true);
								}}
								getPopupContainer={() => document.getElementById("topicSelect")}
							>
								<Option value="Privacy"> Eloqua Privacy </Option>
								<Option value="Implementation"> Eloqua Implementation </Option>
								<Option value="CampaignProduction">
									{" "}
									Eloqua Campaign Prod{" "}
								</Option>
								<Option value="PlatformAdmin"> Eloqua Platform Admin </Option>
								<Option value="Reporting"> Eloqua Reporting </Option>
							</Select>
							<label
								htmlFor="topic"
								className={`${
									blogTopic.state.length
										? "-top-2 text-sm"
										: "top-[0.65rem] text-lg"
								} absolute left-3 transform rounded-full bg-white px-1 font-poppins font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus-within:-top-2 peer-focus-within:text-sm`}
							>
								Topic
							</label>
							<div className="absolute">
								<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
									{" "}
									{blogTopic.error}{" "}
								</p>
							</div>
						</div>
						<div className="relative w-full">
							<input
								id="link"
								className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus:border-green-11 focus:outline-none ${
									link.error?.length !== 0
										? "border-orange-1 focus:ring-orange-1"
										: "hover:border-green-11"
								}`}
								value={link.state}
								onChange={(e) => {
									setLink({ ...link, state: e.target.value });
									setInstanceModification(true);
								}}
								placeholder="https://expertise.logarithmic.com/email-marketing-key-performance-indicators"
							/>
							<label
								htmlFor="link"
								className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
							>
								Link
							</label>
							<div className="absolute">
								<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
									{" "}
									{link.error}{" "}
								</p>
							</div>
						</div>
						<div className="relative z-10 w-full" id="datePicker">
							<DatePicker
								id="date"
								className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:outline-none ${
									blogPostedDate.error?.length !== 0
										? "border-orange-1 focus:ring-orange-1"
										: ""
								}`}
								placeholder=""
								format={["DD/MM/YYYY", "DD/MM/YY"]}
								getPopupContainer={(trigger) => trigger.parentElement}
								onChange={(value) => {
									setBlogPostedDate({
										...blogPostedDate,
										state: value?._d || "",
									});
									setInstanceModification(true);
								}}
							/>
							<label
								htmlFor="date"
								className={`${
									blogPostedDate.state
										? "-top-2 text-sm"
										: "top-[0.65rem] text-lg"
								} absolute left-3 transform rounded-full bg-white px-1 font-poppins font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus-within:-top-2 peer-focus-within:text-sm`}
							>
								Date
							</label>
							<div className="absolute">
								<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
									{" "}
									{blogPostedDate.error}{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					id="section_2"
					className={`${progress === 1 ? "block" : "hidden"} p-5`}
				>
					<div className=" pb-10 text-center font-poppins text-4xl font-bold text-grey-1">
						<span className="text-green-11">Infographic</span> Card
					</div>
					<div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
						<div className="flex w-full flex-col gap-5">
							<div className="relative w-full">
								<input
									id="title"
									className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus:border-green-11 focus:outline-none ${
										title.error?.length !== 0
											? "border-orange-1 focus:ring-orange-1"
											: "hover:border-green-11"
									}`}
									value={title.state}
									onChange={(e) => {
										setTitle({ ...title, state: e.target.value });
										setInstanceModification(true);
									}}
									placeholder="LinkedIn Campaign Manager Integration with Oracle Eloqua"
									//onBlur={e=>checkJobTitle(title,setJobTitle)}
								/>
								<label
									htmlFor="title"
									className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
								>
									Title
								</label>
								<div className="absolute">
									<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
										{" "}
										{title.error}{" "}
									</p>
								</div>
							</div>
							<div className="mb-3 flex flex-col">
								<div className="group relative w-full">
									<div
										className={`absolute flex h-12 w-full appearance-none items-center justify-between`}
									>
										<div
											className={`${
												img.error.length !== 0
													? "border-orange-1"
													: "group-hover:border-green-11"
											} w-full truncate rounded-l-xl border-y-2 border-l-2 py-2 px-3 text-lg transition-all duration-500`}
										>
											<p className="font-raleway text-grey-8">{img.ImgName}</p>
										</div>
										<div className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300">
											Featured image
										</div>
										<div
											className={`${
												img.error.length !== 0
													? "border-orange-1 group-hover:bg-orange-1"
													: "border-green-11 group-hover:bg-green-11"
											} flex h-full items-center justify-center rounded-r-xl border-2 bg-white px-10 transition-all duration-500`}
										>
											<svg
												viewBox="0 0 24 24"
												className={`${
													img.error.length !== 0
														? "text-orange-1"
														: "text-green-11"
												} h-6 w-6 fill-current transition-all duration-500 group-hover:text-white`}
											>
												<path d="M21,12h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Zm1,7c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-4c0-.552,.448-1,1-1l3-.002v.002c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4h3c.552,0,1,.448,1,1v4ZM7.293,5.293c-.391-.391-.391-1.023,0-1.414L10.586,.586C10.972,.2,11.479,.006,11.986,.003l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l3.293,3.293c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.293-2.293v7c0,.553-.447,1-1,1s-1-.447-1-1V3l-2.293,2.293c-.391,.391-1.023,.391-1.414,0Z" />
											</svg>
										</div>
									</div>
									<input
										onChange={handleChangeIMG}
										className="focus:shadow-outline h-12 w-full cursor-pointer appearance-none rounded border py-2 px-3 leading-tight text-gray-700 opacity-0 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
										id="resume"
										type="file"
										accept="image/png, image/jpg,image/jpeg"
									/>
									<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
										{" "}
										{img.error}{" "}
									</p>
								</div>
							</div>
						</div>
						<div className="relative mx-auto h-72 w-82 select-none overflow-hidden bg-gradient-to-b from-grey-4 via-grey-22 to-grey-22 shadow-lg">
							<div className="relative h-4/6 w-full">
								{img.ImgSrc.length ? (
									<img
										alt="FeaturedImage"
										src={img.ImgSrc}
										className="h-full w-full"
									/>
								) : (
									<div className="flex h-full w-full items-center justify-center">
										<p className=" font-poppins text-lg font-bold text-white">
											No Image
										</p>
									</div>
								)}
							</div>
							<div className="absolute top-1/2 flex h-36 w-full items-center justify-center px-8">
								<div className="absolute left-0 h-full w-full bg-white bg-opacity-20 bg-clip-content backdrop-blur-sm backdrop-filter"></div>
								<p className="text-md relative max-h-36 font-montserrat font-bold text-white">
									{title.state ? title.state : "No title"}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className=" block p-5 md:hidden">
				<p className="mb-0 text-center font-poppins text-lg font-bold text-grey-1 md:text-2xl lg:text-left">
					Editor is not available on mobile. Please login on a compatible
					device.
				</p>
			</div>
			<div className="h-10"></div>
		</div>
	);
}
