import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../context/context";
import { Modal, Button, DatePicker, Select, message, Steps } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import {DOMAIN, STORAGE_DOMAIN} from "../../env";

export default function GenerateQuizModal(props) {
	const user = useContext(UserContext);
	const { Option } = Select;
	const [currentStep, setCurrentStep] = useState(0);
	const history = useHistory();
	const { id } = useParams();

	const [quizData, setQuizData] = useState({
		expirationDate: { state: "", error: "" },
		expectedParcipant: { state: "", error: "" },
		duration: { state: "", error: "" },
		numberOfQuestions: { state: "", error: "" },
		hrContactPerson: { state: "", error: "" },
	});
	const [data, setData] = useState({
		questions: [],
		questionBankName: "",
	});
	const [questions, setQuestions] = useState([]);
	const [HRList, setHRList] = useState([]);

	const closeModal = () => {
		props.setIsModalActive(false);
		setQuizData({
			expirationDate: { state: "", error: "" },
			expectedParcipant: { state: "", error: "" },
			duration: { state: "", error: "" },
			numberOfQuestions: { state: "", error: "" },
			hrContactPerson: { state: "", error: "" },
		});
	};

	const handleValidation = () => {
		let error = false;
		const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!quizData.expirationDate.state) {
			error = true;
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.expirationDate.error = "Please select an expiration date!";
				return newData;
			});
		} else {
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.expirationDate.error = "";
				return newData;
			});
		}
		if (!quizData.expectedParcipant.state) {
			error = true;
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.expectedParcipant.error = "Please complete this field!";
				return newData;
			});
		} else if (!regEx.test(quizData.expectedParcipant.state)) {
			error = true;
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.expectedParcipant.error = "Please enter a valid email address!";
				return newData;
			});
		} else {
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.expectedParcipant.error = "";
				return newData;
			});
		}
		if (!quizData.duration.state) {
			error = true;
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.duration.error = "Please complete this field!";
				return newData;
			});
		} else {
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.duration.error = "";
				return newData;
			});
		}
		if (!quizData.numberOfQuestions.state) {
			error = true;
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.numberOfQuestions.error = "Please complete this field!";
				return newData;
			});
		} else if (quizData.numberOfQuestions.state > props.numberOfQuestions) {
			error = true;
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.numberOfQuestions.error =
					"Exceeding the maximum number of questions!";
				return newData;
			});
		} else {
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.numberOfQuestions.error = "";
				return newData;
			});
		}
		if (!quizData.hrContactPerson.state) {
			error = true;
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.hrContactPerson.error = "Please select a contact person!";
				return newData;
			});
		} else {
			setQuizData((oldData) => {
				let newData = { ...oldData };
				newData.hrContactPerson.error = "";
				return newData;
			});
		}
		return error;
	};

	const handleSubmit = () => {
		if (!handleValidation()) {
			const loadingMessage = message.loading("Loading...", 0);
			const data = {
				question_bank_id: props.questionBankId,
				expiration_date: quizData.expirationDate.state,
				expected_parcipant: quizData.expectedParcipant.state,
				duration: quizData.duration.state,
				questions: questions,
				hr_contact_person: quizData.hrContactPerson.state,
			};
			axios
				.post(
					`${DOMAIN}/api/careers_quiz/generate_quiz`,
					data,
					{
						headers: {
							"jwt-token": user.jwtToken,
						},
					}
				)
				.then((res) => {
					message.success({
						content: "Quiz created",
						className: "text-center",
						duration: 2,
					});
					props.setUpdateList(!props.updateList);
				})
				.catch((error) => {
					message.error({
						content: `Network Error!`,
						className: "text-center",
						duration: 2,
					});
				})
				.finally(() => {
					loadingMessage();
				});
			closeModal();
		}
	};

	function shuffle(array) {
		return array.sort(function () {
			return 0.5 - Math.random();
		});
	}

	useEffect(() => {
		const fetchApiData = async () => {
			if (
				!["Developer", "Marketing", "HumanResources", "Admin"].includes(
					user.user.user_role
				)
			) {
				message.error({
					content: `Unauthorized!`,
					className: "text-center",
					duration: 2,
				});
				history.push("/");
			} else {
				await axios
					.get(`${DOMAIN}/api/user/getHRUsers`, {
						headers: {
							"jwt-token": user.jwtToken,
						},
					})
					.then((res) => {
						setHRList(res.data.userList);
					});
			}
		};
		fetchApiData();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const fetchApiData = async () => {
			await axios
				.get(
					`${DOMAIN}/api/careers_quiz/get_questions/${id}`,
					{
						headers: {
							"jwt-token": user.jwtToken,
						},
					}
				)
				.then((res) => {
					setData({
						questions: res.data.questions,
						questionBankName: res.data.name,
					});
				});
		};
		fetchApiData();
	}, [id, user.jwtToken]);

	return (
		<Modal
			zIndex={1000}
			title={`Generate new quiz`}
			open={props.isModalActive}
			onCancel={() => closeModal()}
			footer={[
				<Button key="back" onClick={() => closeModal()}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={() => handleSubmit()}>
					Confirm
				</Button>,
			]}
			width={900}
		>
			<div className="pb-10 md:mx-40">
				<Steps
					current={currentStep}
					progressDot
					className="hover:text-green-11"
					onChange={(value) => setCurrentStep(value)}
					items={[
						{
							title: "Quiz Information",
							className: "font-bold hover:text-green-11",
						},
						{
							title: "Quiz Questions",
							className: "font-bold hover:text-green-11",
						},
					]}
				/>
			</div>
			<div
				className={`${
					currentStep === 0 ? "grid" : "hidden"
				} grid-cols-1 gap-x-4 gap-y-5 md:grid-cols-2`}
			>
				<div className=" relative col-span-1">
					<DatePicker
						id="expirationDate"
						allowClear={false}
						disabledDate={(current) =>
							current && current < moment().endOf("day")
						}
						className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg leading-tight placeholder-transparent focus:border-transparent focus:border-green-11 focus:outline-none ${
							quizData.expirationDate.error
								? "border-orange-1 focus:ring-orange-1"
								: ""
						}`}
						format={["DD/MM/YYYY", "DD/MM/YY"]}
						value={
							quizData.expirationDate.state
								? moment(new Date(quizData.expirationDate.state), "DD/MM/YYYY")
								: ""
						}
						getPopupContainer={(trigger) => trigger.parentElement}
						onChange={(value) => {
							setQuizData({
								...quizData,
								expirationDate: { ...quizData.expirationDate, state: value._d },
							});
						}}
					/>
					<label
						htmlFor="expirationDate"
						className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
					>
						Expiration Date
					</label>
					<div
						className={`absolute transform transition-all duration-500 ${
							quizData.expirationDate.error ? " translate-y-0" : "translate-y-2"
						}`}
					>
						<p className="font-raleway text-orange-1">
							{quizData.expirationDate.error}
						</p>
					</div>
				</div>
				<div className=" relative col-span-1">
					<input
						id="participant"
						placeholder="Please specify the email address of the participant"
						value={quizData.expectedParcipant.state}
						type="email"
						onChange={(e) =>
							setQuizData({
								...quizData,
								expectedParcipant: {
									...quizData.expectedParcipant,
									state: e.target.value,
								},
							})
						}
						className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 focus:outline-none ${
							quizData.expectedParcipant.error
								? "border-orange-1 focus:ring-orange-1"
								: "hover:border-green-11"
						}`}
					/>
					<label
						htmlFor="participant"
						className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
					>
						Participant email
					</label>
					<div
						className={`absolute transform transition-all duration-500 ${
							quizData.expectedParcipant.error
								? " translate-y-0"
								: "translate-y-2"
						}`}
					>
						<p className="font-raleway text-orange-1">
							{quizData.expectedParcipant.error}
						</p>
					</div>
				</div>
				<div className=" relative col-span-1">
					<input
						id="duration"
						placeholder="Please specify the time in minutes"
						value={quizData.duration.state}
						onChange={(e) =>
							setQuizData({
								...quizData,
								duration: { ...quizData.duration, state: e.target.value },
							})
						}
						className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 focus:outline-none ${
							quizData.duration.error
								? "border-orange-1 focus:ring-orange-1"
								: "hover:border-green-11"
						}`}
					/>
					<label
						htmlFor="duration"
						className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
					>
						Duration <span className=" text-grey-1 opacity-40">in minutes</span>
					</label>
					<div
						className={`absolute transform transition-all duration-500 ${
							quizData.duration.error ? " translate-y-0" : "translate-y-2"
						}`}
					>
						<p className="font-raleway text-orange-1">
							{quizData.duration.error}
						</p>
					</div>
				</div>
				<div className="relative col-span-1">
					<Select
						id="hrPerson"
						showSearch
						className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 focus:outline-none ${
							quizData.hrContactPerson.error
								? "border-orange-1 focus:ring-orange-1"
								: "hover:border-green-11"
						}`}
						value={quizData.hrContactPerson.state || undefined}
						onChange={(value) => {
							setQuizData({
								...quizData,
								hrContactPerson: {
									...quizData.hrContactPerson,
									state: value,
								},
							});
						}}
						getPopupContainer={(trigger) => trigger.parentElement}
					>
						{HRList.map((elem, index) => (
							<Option key={index} value={elem.user_email}>
								{elem.user_name}
							</Option>
						))}
					</Select>
					<label
						htmlFor="hrPerson"
						className={`${
							quizData.hrContactPerson.state
								? "-top-2 text-sm"
								: "top-[0.65rem] text-lg"
						} pointer-events-none absolute left-3 transform rounded-full bg-white px-1 font-poppins font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus-within:-top-2 peer-focus-within:text-sm`}
					>
						Contact Person
					</label>
					<div
						className={`absolute transform transition-all duration-500 ${
							quizData.hrContactPerson.error
								? " translate-y-0"
								: "translate-y-2"
						}`}
					>
						<p className="font-raleway text-orange-1">
							{quizData.hrContactPerson.error}
						</p>
					</div>
				</div>
			</div>
			<div
				className={`${
					currentStep === 1 ? "grid" : "hidden"
				} grid-cols-1 gap-x-4 gap-y-5 md:grid-cols-2`}
			>
				<div className=" relative col-span-1 md:col-span-2">
					<div className="flex items-center gap-2">
						<input
							id="numberOfQuestions"
							placeholder="Please specify the number of questions"
							value={quizData.numberOfQuestions.state}
							onChange={(e) => {
								setQuizData({
									...quizData,
									numberOfQuestions: {
										...quizData.numberOfQuestions,
										state: e.target.value,
									},
								});
								const timer = setTimeout(() => {
									const shuffleArray = shuffle(data.questions);
									const array = shuffleArray.slice(0, parseInt(e.target.value));
									setQuestions(array);
								}, 500);
								return () => clearTimeout(timer);
							}}
							className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 px-3 py-2 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11 focus:outline-none ${
								quizData.numberOfQuestions.error
									? "border-orange-1 focus:ring-orange-1"
									: "hover:border-green-11"
							}`}
						/>
						<label
							htmlFor="numberOfQuestions"
							className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
						>
							Number Of Questions
						</label>
						<p className="pointer-events-none absolute right-0 w-16 select-none font-montserrat text-gray-700">
							Max: {props.numberOfQuestions}
						</p>
					</div>
					<div
						className={`absolute transform transition-all duration-500 ${
							quizData.numberOfQuestions.error
								? " translate-y-0"
								: "translate-y-2"
						}`}
					>
						<p className="font-raleway text-orange-1">
							{quizData.numberOfQuestions.error}
						</p>
					</div>
				</div>
				{questions.length ? (
					<div className=" relative col-span-1 grid grid-cols-2 gap-2 md:col-span-2">
						{data.questions.map((element, index) => {
							return (
								<div
									key={`question-${index}`}
									onClick={() => {
										if (
											questions.find((question) => question.id === element.id)
										) {
											setQuestions(
												questions.filter((item) => item.id !== element.id)
											);
											setQuizData({
												...quizData,
												numberOfQuestions: {
													...quizData.numberOfQuestions,
													state: parseInt(quizData.numberOfQuestions.state) - 1,
												},
											});
										} else {
											setQuestions([...questions, element]);
											setQuizData({
												...quizData,
												numberOfQuestions: {
													...quizData.numberOfQuestions,
													state: parseInt(quizData.numberOfQuestions.state) + 1,
												},
											});
										}
									}}
									className=" col-span-2 md:col-span-1"
								>
									<div
										className={`${
											questions.find((question) => question.id === element.id)
												? "border-green-11"
												: ""
										} relative h-full max-h-[7rem] cursor-pointer overflow-hidden rounded-xl border-2 p-5 transition-all duration-300`}
									>
										{element.format === "Text" &&
										(element.type === "Single Choice" ||
											element.type === "Multiple Choice") ? (
											<div className="h-full overflow-hidden line-clamp-2">
												<p className="font-raleway text-grey-1">
													{element.name.split("<br/>").join("\n")}
												</p>
											</div>
										) : null}
										{element.format === "Text" &&
										element.type === "Paragraph" ? (
											<div className="h-full overflow-hidden line-clamp-2">
												<p className="font-raleway text-grey-1">
													{element.paragraph.split("<br/>").join("\n")}
												</p>
											</div>
										) : null}
										{element.format === "Text" &&
										element.type === "Free Text" ? (
											<div className="h-full overflow-hidden line-clamp-2">
												<p className="font-raleway text-grey-1">
													{element.name.split("<br/>").join("\n")}
												</p>
											</div>
										) : null}
										{element.format === "Image" ? (
											<div className="flex h-full justify-center overflow-hidden">
												<img
													id={element.image}
													alt="quiz_image"
													src={`${STORAGE_DOMAIN+ element.image}`}
													className="h-full w-full object-contain"
												/>
											</div>
										) : null}
									</div>
								</div>
							);
						})}
					</div>
				) : null}
			</div>
		</Modal>
	);
}
