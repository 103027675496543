import React, { useState, useEffect } from "react";

import { ReactComponent as LogoDark } from "./assets/images/log-dark-logo.svg";
import { ReactComponent as LogoWhite } from "./assets/images/log-white-logo.svg";

import BackgroudImage from "./images/bgV1.png";

import Login from "./components/MainComponents/Login&RegisterForms/Login";
import Main from "./pages/main";

function App() {
	const [user, setUser] = useState({
		loggedIn: false,
	});
	const [showLoginForm, setShowLoginForm] = useState(true);

	const logOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("JWT");
		setUser({
			loggedIn: false,
			user: [],
		});
	};

	const updateImage = (link) => {
		setUser((oldData) => {
			let newData = oldData;
			newData.user.user_image = link;
			return newData;
		});
	};

	const logIn = (val, jwt) => {
		setUser({
			loggedIn: true,
			logOut: logOut,
			user: val,
			jwtToken: jwt,
			updateImage: updateImage,
		});
	};

	useEffect(() => {
		const loggedInUser = localStorage.getItem("user");
		const token = JSON.parse(localStorage.getItem("JWT"));
		if (loggedInUser && token) {
			const foundUser = JSON.parse(loggedInUser);
			setUser({
				loggedIn: true,
				logOut: logOut,
				user: foundUser,
				jwtToken: token,
				updateImage: updateImage,
			});
		}
	}, []);

	if (user.loggedIn) {
		return <Main context={user} />;
	}

	return (
		<div className="flex flex-col lg:flex-row min-h-screen h-full items-center justify-center lg:justify-end overflow-hidden relative">
			<img
				src={BackgroudImage}
				alt="backgroud"
				className=" absolute top-0 w-full h-full min-h-screen min-w-max"
			/>
			<div className="w-full h-full lg:min-h-screen max-w-[450px] lg:max-w-[500px] relative transition-all duration-500 px-4 lg:px-0">
				<div className="flex lg:min-h-screen items-center justify-center flex-col py-10 bg-white dark:bg-grey-1 rounded-3xl shadow-xl lg:rounded-none">
					<div className="max-w-[350px] mx-auto w-full px-5">
						<div className="w-full block dark:hidden">
							<LogoDark />
						</div>
						<div className="w-full hidden dark:block">
							<LogoWhite />
						</div>
					</div>
					<div className="w-full max-w-[350px] mx-auto pt-10 px-4">
						<Login
							logIn={logIn}
							setShowLoginForm={setShowLoginForm}
							showLoginForm={showLoginForm}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
