import React, { useState, useRef } from "react";
import { Modal, message } from "antd";
import axios from "axios";
import {DOMAIN, STORAGE_DOMAIN} from "../../env";

export default function UploadUserImage({ user }) {
	const fileInput = useRef(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newImage, setNewImage] = useState(undefined);
	const [image, setImage] = useState(undefined);
	const [userImage, setUserImage] = useState(user.user.user_image);

	console.log(userImage)

	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			setImage(event.target.files[0]);
			let reader = new FileReader();
			reader.onload = (e) => {
				setNewImage(e.target.result);
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setNewImage(undefined);
		fileInput.current.value = "";
	};

	const uploadImage = async () => {
		const formData = new FormData();
		if (image !== undefined) {
			formData.append("image", image);
			const loadingMessage = message.loading("Loading...", 0);
			await axios
				.post(
					`${DOMAIN}/api/user/updateUserImage/${user.user.user_id}`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
							"jwt-token": user.jwtToken,
						},
					}
				)
				.then((response) => {
					closeModal();
					user.updateImage(response.data.path);
					setUserImage(response.data.user_image);

					if (localStorage.getItem("user"))
						localStorage.setItem("user", JSON.stringify(user.user));
					message.success({
						content: `Image Updated`,
						className: "text-center",
						duration: 2,
					});
				})
				.catch((error) => {
					message.error({
						content: `Network Error!`,
						className: "text-center",
						duration: 2,
					});
				})
				.finally(() => {
					loadingMessage();
				});
		}
	};

	return (
		<>
			<Modal
				zIndex={1000}
				title={
					<p className=" font-raleway text-lg text-grey-1">
						Update Profile Image
					</p>
				}
				open={isModalOpen}
				onCancel={() => closeModal()}
				onOk={() => uploadImage()}
				okText="Upload"
				cancelText="Discard"
			>
				<div className="flex flex-col items-center justify-center gap-5 md:flex-row">
					<div
						onClick={() => fileInput.current.click()}
						className="relative h-36 w-36 cursor-pointer overflow-hidden rounded-full bg-grey-4 shadow-xl"
					>
						{newImage && (
							<img
								id="target"
								alt="previewImage"
								src={newImage}
								className="relative z-10 h-full w-full object-cover"
							/>
						)}
						<div className="absolute top-0 flex h-full w-full items-center justify-center">
							<svg
								className=" h-10 w-10 fill-current text-grey-1"
								viewBox="0 0 24 24"
							>
								<path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0ZM5,2H19a3,3,0,0,1,3,3V19a2.951,2.951,0,0,1-.3,1.285l-9.163-9.163a5,5,0,0,0-7.072,0L2,14.586V5A3,3,0,0,1,5,2ZM5,22a3,3,0,0,1-3-3V17.414l4.878-4.878a3,3,0,0,1,4.244,0L20.285,21.7A2.951,2.951,0,0,1,19,22Z" />
								<path d="M16,10.5A3.5,3.5,0,1,0,12.5,7,3.5,3.5,0,0,0,16,10.5Zm0-5A1.5,1.5,0,1,1,14.5,7,1.5,1.5,0,0,1,16,5.5Z" />
							</svg>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						<input
							ref={fileInput}
							className="block cursor-pointer rounded-full bg-grey-4 text-sm text-grey-1 file:mr-4 file:rounded-full file:border-0 file:bg-green-11 file:py-2 file:px-4 file:text-sm file:font-semibold file:text-white file:transition-all file:duration-500 hover:file:bg-opacity-60"
							aria-describedby="update profile image"
							type="file"
							accept="image/png, image/jpeg, image/jpg"
							onChange={(e) => onImageChange(e)}
						/>
						<p className=" font-montserrat text-grey-1">
							Image type: PNG, JPEG, JPG
						</p>
					</div>
				</div>
			</Modal>
			<div
				onClick={() => setIsModalOpen(!isModalOpen)}
				className="mx-auto flex h-36 w-36 items-center justify-center rounded-full bg-white shadow-lg lg:mx-0"
			>
				<div className=" group relative h-32 w-32 cursor-pointer overflow-hidden rounded-full">
					<div className="absolute my-auto h-full w-full rounded-full bg-grey-1 bg-opacity-70 opacity-0 transition-all duration-500 group-hover:opacity-100">
						<svg
							className=" mx-auto mt-10 h-12 w-12 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
						>
							<path d="M17.812 23c-7.812 0-7.812-8-7.812-8h-3l4.979-4 5.021 4h-3s-.812 6.094 3.812 8zm1.667-14.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.302c-.275-.717-.46-1.404-.578-2h-2.724c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-2.518c.062.62.21 1.329.499 2h2.019c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z" />
						</svg>
					</div>
					{userImage ? (
						<img
							alt="UserProfile"
							className="h-full w-full object-cover"
							src={STORAGE_DOMAIN+userImage}
							key={userImage}
						/>
					) : (
						<div className="flex h-full w-full items-center justify-center bg-white">
							<p className=" font-poppins text-xl font-bold uppercase text-grey-1">
								{user.user.user_name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
