import React, { useEffect, useState, useContext } from "react";
import AddQuestionModal from "../../components/CareerQuizComponents/addQuestionModal";
import EditQuestionModal from "../../components/CareerQuizComponents/editQuestionModal";
import axios from "axios";
import UserContext from "../../context/context";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Question from "../../components/CareerQuizComponents/renderQuestion";
import {DOMAIN} from "../../env";

export default function QuestionBankQuestions() {
	const user = useContext(UserContext);
	const { id } = useParams();
	const [addQuestionModal, setAddQuestionModal] = useState(false);
	const [editQuestionModal, setEditQuestionModal] = useState(false);
	const [editQuestionData, setEditQuestionData] = useState({});

	const [data, setData] = useState({
		questions: [],
		questionBankName: "",
	});

	const [updateQuestionList, setUpdateQuestionList] = useState(false);

	useEffect(() => {
		const fetchApiData = async () => {
			await axios
				.get(
					`${DOMAIN}/api/careers_quiz/get_questions/${id}`,
					{
						headers: {
							"jwt-token": user.jwtToken,
						},
					}
				)
				.then((res) => {
					setData({
						questions: res.data.questions,
						questionBankName: res.data.name,
					});
				});
		};
		fetchApiData();
	}, [updateQuestionList, id, user.jwtToken]);

	const renderQuestions = () => {
		const questionsList = data.questions.map((elem, index) => (
			<Question key={index} edit={true} question={elem} index={index} setEditQuestionData={setEditQuestionData} setEditQuestionModal={setEditQuestionModal} setUpdateQuestionList={setUpdateQuestionList} updateQuestionList={updateQuestionList}/>
		));
		if (questionsList.length) return questionsList;
		else return [...new Array(12)].map((item, index) => (
			<div className="relative h-56 w-full rounded-xl bg-gray-200 border-2 border-zinc-300 animate-pulse" key={index}>
				<div className="absolute flex h-full w-full items-center justify-center">
					<div role="status">
						<svg aria-hidden="true" className="h-14 w-14 animate-spin fill-gray-300 text-gray-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			</div>
		));
	};

	return (
		<div>
			<div className="flex flex-col justify-between items-center gap-5">
				<div className="bg-grey-1 p-5 w-full shadow-lg">
					<p className=" font-bold font-poppins text-2xl text-white text-center lg:text-left mb-0">
						Question Bank : {data.questionBankName}
					</p>
				</div>
				<div className="flex flex-row gap-5 justify-center w-full px-5">
					<Link to={`/careerQuiz/questionBank/questions/${id}`} className={`flex flex-row gap-2 items-center cursor-pointer px-4 w-full justify-center py-3 rounded-2xl shadow-lg bg-green-11 select-none`} >
						<div>
							<svg className="fill-current text-white w-6 h-6" viewBox="0 0 24 24"><path d="M9,11c-.552,0-1-.447-1-1,0-1.308,1.038-1.879,1.481-2.123,.29-.159,.595-.535,.502-1.066-.069-.392-.402-.725-.793-.793-.306-.056-.602,.022-.832,.216-.228,.19-.358,.47-.358,.767,0,.553-.448,1-1,1s-1-.447-1-1c0-.889,.391-1.727,1.072-2.299,.681-.572,1.577-.814,2.463-.653,1.209,.211,2.204,1.205,2.417,2.417,.223,1.272-.382,2.543-1.506,3.164-.447,.246-.447,.318-.447,.371,0,.553-.448,1-1,1Zm0,1c-.69,0-1.25,.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Zm10.996-2.92c-.006,.769-.091,1.518-.248,2.242,1.371,1.101,2.252,2.787,2.252,4.678v5c0,.552-.448,1-1,1h-5c-1.891,0-3.577-.881-4.678-2.252-.724,.156-1.473,.242-2.242,.248,1.385,2.389,3.965,4.004,6.92,4.004h5c1.657,0,3-1.343,3-3v-5c0-2.955-1.615-5.535-4.004-6.92Zm-2.019,.571c.185-2.613-.768-5.17-2.613-7.016S10.964-.167,8.349,.023C3.823,.343,0,4.589,0,9.296v5.038c0,2.021,1.642,3.666,3.661,3.666h4.477c5.187,0,9.509-3.667,9.839-8.349Zm-4.027-5.601c1.436,1.435,2.176,3.425,2.033,5.46-.253,3.578-3.772,6.489-7.845,6.489H3.661c-.916,0-1.661-.747-1.661-1.666v-5.038c0-3.696,2.972-7.029,6.49-7.278,.167-.012,.333-.018,.499-.018,1.858,0,3.644,.732,4.961,2.051Z" /> </svg>
						</div>
						<p className=" font-raleway text-xl font-bold text-white text-center hidden md:block">
							Questions
						</p>
					</Link>
					<Link to={`/careerQuiz/questionBank/submissions/${id}`} className={`cursor-pointer px-4 w-full justify-center py-3 flex flex-row gap-2 items-center rounded-2xl shadow-lg bg-grey-3 hover:bg-green-11 transition-all duration-500 select-none`} >
						<div>
							<svg viewBox="0 0 24 24" className="fill-current text-white w-5 h-5"><path d="M18.5,24H13.034A13.5,13.5,0,0,1,3,19.789a11.467,11.467,0,0,1-2.947-8.9A12.024,12.024,0,0,1,9.908.188a11.641,11.641,0,0,1,9.654,2.569A12.448,12.448,0,0,1,24,12.324V18.5A5.507,5.507,0,0,1,18.5,24ZM12.03,3a9.021,9.021,0,0,0-8.988,8.164,8.509,8.509,0,0,0,2.18,6.605A10.5,10.5,0,0,0,13.034,21H18.5A2.5,2.5,0,0,0,21,18.5V12.324a9.466,9.466,0,0,0-3.366-7.27A8.637,8.637,0,0,0,12.03,3ZM10.5,17a2.5,2.5,0,0,1-1.771-.732L5.956,13.577a1.5,1.5,0,0,1,2.088-2.154L10.5,13.8l5.45-5.369a1.5,1.5,0,1,1,2.106,2.136L12.26,16.275A2.476,2.476,0,0,1,10.5,17Z" /> </svg>
						</div>
						<p className=" font-raleway text-xl font-bold text-white text-center hidden md:block">
							Submissions
						</p>
					</Link>
				</div>
			</div>
			<div className="p-5">
				<div className="flex flex-col lg:flex-row justify-between items-center gap-5 pb-8">
					<p className=" font-poppins text-grey-1 font-bold text-2xl">
						Question List
					</p>
					<div onClick={() => setAddQuestionModal(true)} className="bg-green-11 px-8 py-2 rounded-xl flex flex-row gap-2 justify-center items-center cursor-pointer shadow-lg" >
						<p className="font-poppins text-white font-semibold text-xl select-none">
							Add Question
						</p>
					</div>
				</div>
				<div className="grid grid-cols-2 gap-5">{renderQuestions()}</div>
			</div>
			<AddQuestionModal
				isModalActive={addQuestionModal}
				setIsModalActive={setAddQuestionModal}
				questionBankId={id}
				setUpdateQuestionList={setUpdateQuestionList}
				updateQuestionList={updateQuestionList}
			/>
			<EditQuestionModal
				isModalActive={editQuestionModal}
				setIsModalActive={setEditQuestionModal}
				questionBankId={id}
				questionData={editQuestionData}
				setUpdateQuestionList={setUpdateQuestionList}
				updateQuestionList={updateQuestionList}
			/>
		</div>
	);
}
