import { useState, useContext } from "react";
import axios from "axios";
import UserContext from "../../context/context";
import { Modal, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import {DOMAIN} from "../../env";

export default function AddQuestionBankModal() {
    const user = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const [questionBankName, setQuestionBankName] = useState({
		state: "",
		error: "",
	});

    const handleSubmit = async () => {
		if (questionBankName.state.length === 0) {
			setQuestionBankName({
				...questionBankName,
				error: "Question bank name can not be empty!",
			});
			return;
		} else {
			setQuestionBankName({
				...questionBankName,
				error: "",
			});
			const loadingMessage = message.loading("Loading...", 0);
			await axios
				.post(
					`${DOMAIN}/api/careers_quiz/create_question_bank`,
					{
						name: questionBankName.state,
					},
					{
						headers: {
							"jwt-token": user.jwtToken,
						},
					}
				)
				.then((response) => {
					if (response.data.success === false) {
						message.error({
							content: `${response.data.error}`,
							className: "text-center",
							duration: 2,
						});
					} else {
						message.success({
							content: `Question Bank Added!`,
							className: "text-center",
							duration: 2,
						});
					}
					history.push(`/careerQuiz/questionBank/${response.data.id}`);
				})
				.catch((error) => {
					message.error({
						content: `${error.response.data.error}`,
						className: "text-center",
						duration: 2,
					});
				})
				.finally(() => {
					loadingMessage();
				});
		}
	};

    return(
        <>
            <div className="">
                <button onClick={()=>setOpen(true)} className="flex h-full w-full cursor-pointer items-center justify-center gap-2 rounded-2xl bg-grey-1 px-6 py-3 shadow-lg transition-all duration-300 hover:bg-green-11">
                    <svg className=" h-5 w-5 fill-current text-white" viewBox="0 0 24 24" > <path d="m24 11.5a4.476 4.476 0 0 0 -1.706-3.5 4.481 4.481 0 0 0 -2.794-8h-15a4.481 4.481 0 0 0 -2.794 8 4.443 4.443 0 0 0 0 7 4.481 4.481 0 0 0 2.794 8h15a4.481 4.481 0 0 0 2.794-8 4.476 4.476 0 0 0 1.706-3.5zm-22-7a2.5 2.5 0 0 1 2.5-2.5h.5v1a1 1 0 0 0 2 0v-1h2v1a1 1 0 0 0 2 0v-1h8.5a2.5 2.5 0 0 1 0 5h-15a2.5 2.5 0 0 1 -2.5-2.5zm20 14a2.5 2.5 0 0 1 -2.5 2.5h-15a2.5 2.5 0 0 1 0-5h.5v1a1 1 0 0 0 2 0v-1h2v1a1 1 0 0 0 2 0v-1h8.5a2.5 2.5 0 0 1 2.5 2.5zm-17.5-4.5a2.5 2.5 0 0 1 0-5h.5v1a1 1 0 0 0 2 0v-1h2v1a1 1 0 0 0 2 0v-1h8.5a2.5 2.5 0 0 1 0 5z" /> </svg>
                    <p className=" font-poppins text-base font-bold uppercase text-white">
                        new question bank
                    </p>
                </button>
            </div>
            <Modal 
                zIndex={1000} 
                title={`Add new question bank`}
                open={open}
                onCancel={() => setOpen(false)}
                footer={[
                    <Button key="back" onClick={() => setOpen(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => handleSubmit()}>
                        Confirm
                    </Button>,
                ]}
            >
                <div className="relative">
                    <input
                        id="questionBankName"
                        placeholder="Please specify the text of the question"
                        value={questionBankName.state}
                        onChange={(e) => setQuestionBankName({ ...questionBankName, state: e.target.value }) }
                        className={` peer h-12 font-raleway appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 ${
                            questionBankName.error?.length !== 0
                                ? "border-orange-1 focus:ring-orange-1"
                                : "hover:border-green-11"
                        }`}
                    />
                    <label
                        htmlFor="questionBankName"
                        className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
                    >
                        Question Bank Name
                    </label>
                    <div
                        className={`absolute transform transition-all duration-500 ${
                            questionBankName.error ? " translate-y-0" : "translate-y-2"
                        }`}
                    >
                        <p className="text-orange-1 font-poppins font-semibold">
                            {questionBankName.error}
                        </p>
                    </div>
                </div>
            </Modal>
        </>
    )
}