/**
 * @typedef {object} ImageToolData
 * @description Image Tool's input and output data format
 * @property {string} caption — image caption
 * @property {boolean} shadow - should image be rendered with border
 * @property {boolean} borderRadius - should image be rendered with background
 * @property {boolean} shrink - should image be stretched to full width of container
 * @property {object} file — Image file data returned from backend
 * @property {string} file.url — image URL
 */

import Image from "@editorjs/image";

export default class ImageTest extends Image {
	static get tunes() {
		return [
			{
				name: "borderRadius",
				icon: `<svg viewBox="0 0 24 24" fill="currentColor" style="width:14px !important; height:14px !important" stroke-width="0px"><path d="M1.5,24c-.83,0-1.5-.67-1.5-1.5V5.5C0,2.47,2.47,0,5.5,0H22.5c.83,0,1.5,.67,1.5,1.5s-.67,1.5-1.5,1.5H5.5c-1.38,0-2.5,1.12-2.5,2.5V22.5c0,.83-.67,1.5-1.5,1.5Zm17.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm-11,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm16,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm0-5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm0-5.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm0-5.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5ZM13.5,22.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/></svg>
            `,
				title: "Border radius",
				toggle: true,
			},
			{
				name: "shadow",
				icon: `<svg viewBox="0 0 24 24" fill="currentColor" style="width:14px !important; height:14px !important" stroke-width="0px"><g><path d="m12 22c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm0-18c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"/></g><g><path d="m18 13h-5c-.6 0-1-.4-1-1s.4-1 1-1h5c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m17 16h-4c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m14 19h-1c-.6 0-1-.4-1-1s.4-1 1-1h1c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m17 10h-4c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m14 7h-1c-.6 0-1-.4-1-1s.4-1 1-1h1c.6 0 1 .4 1 1s-.4 1-1 1z"/></g></svg>
            `,
				title: "Shadow",
				toggle: true,
			},
			{
				name: "paddingY",
				icon: `<svg viewBox="0 0 24 24" fill="currentColor" style="width:14px !important; height:14px !important" stroke-width="0px"><path d="M23,4H1c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1Zm1,17c0-.55-.45-1-1-1H1c-.55,0-1,.45-1,1s.45,1,1,1H23c.55,0,1-.45,1-1Zm-4-8v-2c0-2.21-1.79-4-4-4H8c-2.21,0-4,1.79-4,4v2c0,2.21,1.79,4,4,4h8c2.21,0,4-1.79,4-4Zm-4-4c1.1,0,2,.9,2,2v2c0,1.1-.9,2-2,2H8c-1.1,0-2-.9-2-2v-2c0-1.1,.9-2,2-2h8Z"/></svg>
            `,
				title: "Padding Y",
				toggle: true,
			},
			{
				name: "shrink",
				icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M9,2.5A6.5,6.5,0,0,1,2.5,9h-1a1.5,1.5,0,0,1,0-3h1A3.5,3.5,0,0,0,6,2.5v-1a1.5,1.5,0,0,1,3,0Z"/><path d="M16.5,24A1.5,1.5,0,0,1,15,22.5v-1A6.5,6.5,0,0,1,21.5,15h1a1.5,1.5,0,0,1,0,3h-1A3.5,3.5,0,0,0,18,21.5v1A1.5,1.5,0,0,1,16.5,24Z"/><path d="M22.5,9h-1A6.5,6.5,0,0,1,15,2.5v-1a1.5,1.5,0,0,1,3,0v1A3.5,3.5,0,0,0,21.5,6h1a1.5,1.5,0,0,1,0,3Z"/><path d="M7.5,24A1.5,1.5,0,0,1,6,22.5v-1A3.5,3.5,0,0,0,2.5,18h-1a1.5,1.5,0,0,1,0-3h1A6.5,6.5,0,0,1,9,21.5v1A1.5,1.5,0,0,1,7.5,24Z"/></svg>
			`,
				title: "Shrink 1",
				toggle: true,
			},
			{
				name: "shrink2",
				icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M9,2.5A6.5,6.5,0,0,1,2.5,9h-1a1.5,1.5,0,0,1,0-3h1A3.5,3.5,0,0,0,6,2.5v-1a1.5,1.5,0,0,1,3,0Z"/><path d="M16.5,24A1.5,1.5,0,0,1,15,22.5v-1A6.5,6.5,0,0,1,21.5,15h1a1.5,1.5,0,0,1,0,3h-1A3.5,3.5,0,0,0,18,21.5v1A1.5,1.5,0,0,1,16.5,24Z"/><path d="M22.5,9h-1A6.5,6.5,0,0,1,15,2.5v-1a1.5,1.5,0,0,1,3,0v1A3.5,3.5,0,0,0,21.5,6h1a1.5,1.5,0,0,1,0,3Z"/><path d="M7.5,24A1.5,1.5,0,0,1,6,22.5v-1A3.5,3.5,0,0,0,2.5,18h-1a1.5,1.5,0,0,1,0-3h1A6.5,6.5,0,0,1,9,21.5v1A1.5,1.5,0,0,1,7.5,24Z"/></svg>
			`,
				title: "Shrink 2",
				toggle: true,
			},
			{
				name: "shrink3",
				icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M9,2.5A6.5,6.5,0,0,1,2.5,9h-1a1.5,1.5,0,0,1,0-3h1A3.5,3.5,0,0,0,6,2.5v-1a1.5,1.5,0,0,1,3,0Z"/><path d="M16.5,24A1.5,1.5,0,0,1,15,22.5v-1A6.5,6.5,0,0,1,21.5,15h1a1.5,1.5,0,0,1,0,3h-1A3.5,3.5,0,0,0,18,21.5v1A1.5,1.5,0,0,1,16.5,24Z"/><path d="M22.5,9h-1A6.5,6.5,0,0,1,15,2.5v-1a1.5,1.5,0,0,1,3,0v1A3.5,3.5,0,0,0,21.5,6h1a1.5,1.5,0,0,1,0,3Z"/><path d="M7.5,24A1.5,1.5,0,0,1,6,22.5v-1A3.5,3.5,0,0,0,2.5,18h-1a1.5,1.5,0,0,1,0-3h1A6.5,6.5,0,0,1,9,21.5v1A1.5,1.5,0,0,1,7.5,24Z"/></svg>
			`,
				title: "Shrink 3",
				toggle: true,
			},
		];
	}

	constructor({ data, config, api, readOnly }) {
		super({ data, config, api, readOnly }); // Call the parent constructor

		const tunesArr = {
			shrink: "shrink",
			shrink2: "shrink2",
			shrink3: "shrink3",
			borderRadius: "borderRadius",
			shadow: "shadow",
			center: "center",
			paddingY: "paddingY"
			// Add more tunes here as needed
		};

		for (const property in data) {
			if (tunesArr[property]) {
				this.setTune(tunesArr[property], data[property]);
			}
		}
	}

	/*
	renderSettings() {
		const tunes = ImageTest.tunes.concat(this.config.actions);

		return tunes.map((tune) => ({
			icon: tune.icon,
			label: this.api.i18n.t(tune.title),
			name: tune.name,
			toggle: tune.toggle,
			isActive: this.data[tune.name],
			onActivate: () => {
				if (typeof tune.action === "function") {
					tune.action(tune.name);
					return;
				}
				this.tuneToggled(tune.name);
			},
		}));
	}
	*/

	setTune(tuneName, value) {
		this.data[tuneName] = value;
		this.ui.applyTune(tuneName, value);

		if (tuneName === "borderRadius") {
			this.data.borderRadius = value;
			if (value) this.ui.nodes.imageEl.classList.add("rounded-3xl");
			else this.ui.nodes.imageEl.classList.remove("rounded-3xl");
		}

		if (tuneName === "shadow") {
			this.data.shadow = value;
			if (value) this.ui.nodes.imageEl.classList.add("shadow-lg");
			else this.ui.nodes.imageEl.classList.remove("shadow-lg");
		}

		if (tuneName === "paddingY"){
			this.data.paddingY = value;
			if (value) this.ui.nodes.imageEl.classList.add("py-4");
			else this.ui.nodes.imageEl.classList.remove("py-4");
		}

		if (tuneName.includes("shrink")){
			const shrink = ["w-full", "ml-auto", "mr-auto"];

			switch(tuneName){
				case "shrink":
					this.data.shrink = value;
					if (value) {
						this.data["shrink2"] = false;
						this.data["shrink3"] = false;
						// this.ui.api.toolbar.close()
						shrink.forEach((elem, index) => {
							this.ui.nodes.imageEl.classList.add(elem);
						});
						this.ui.nodes.imageEl.style.maxWidth = "42rem";
					} else if(!this.data["shrink2"] & !this.data["shrink3"]) {
						this.ui.nodes.imageEl.style.maxWidth = "100%";
					}
					break;
				case "shrink2":
					this.data.shrink2 = value;
					if (value) {
						this.data["shrink"] = false;
						this.data["shrink3"] = false;
						// this.ui.api.toolbar.close()
						shrink.forEach((elem, index) => {
							this.ui.nodes.imageEl.classList.add(elem);
						});
						this.ui.nodes.imageEl.style.maxWidth = "28rem";
					} else if(!this.data["shrink"] & !this.data["shrink3"]) {
						this.ui.nodes.imageEl.style.maxWidth = "100%";
					}
					break;
				case "shrink3":
					this.data.shrink3 = value;
					if (value) {
						this.data["shrink"] = false;
						this.data["shrink2"] = false;
						// this.ui.api.toolbar.close()
						shrink.forEach((elem, index) => {
							this.ui.nodes.imageEl.classList.add(elem);
						});
						this.ui.nodes.imageEl.style.maxWidth = "20rem";
					} else if(!this.data["shrink"] & !this.data["shrink2"]) {
						this.ui.nodes.imageEl.style.maxWidth = "100%";
					}
					break;
				default:
					break;
			}
		}

		if (tuneName === "center") {
			this.data.shadow = value;
			if (value) this.ui.nodes.imageEl.classList.add("mx-auto");
			else this.ui.nodes.imageEl.classList.remove("mx-auto");
		}

		if (tuneName === "stretched") {
			/**
			 * Wait until the API is ready
			 */
			Promise.resolve()
				.then(() => {
					const blockId = this.api.blocks.getCurrentBlockIndex();

					this.api.blocks.stretchBlock(blockId, value);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}
}
