import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Popconfirm, message } from "antd";
import axios from "axios";
import UserContext from "../../context/context";
import {DOMAIN} from "../../env";

function EloquaAuditAdd() {
	const history = useHistory();
	const user = useContext(UserContext);

	const [clientData, setClientData] = useState({
		name: { state: "", error: "" },
		link: { state: "", error: "" },
		image: {
			ImgName: "No file chosen",
			state: null,
			error: "",
			link: "",
			ImgSrc: [],
		},
	});

	function handleChangeIMG(event) {
		const fileType = ["image/png", "image/jpg", "image/jpeg"];
		if (event.target.value.length !== 0)
			if (event.target.files[0].size > 10485760) {
				event.target.value = null;
				setClientData({
					...clientData,
					image: {
						...clientData.image,
						error: "Only files under 10MB",
						ImgName: "No file chosen",
					},
				});
				return;
			}
		if (!fileType.includes(event.target.files[0].type)) {
			event.target.value = null;
			setClientData({
				...clientData,
				image: {
					...clientData.image,
					error: "Incorrect file type, only: .png, .jpg, .jpeg",
					ImgName: "No file chosen",
				},
			});
		} else {
			var reader = new FileReader();
			// eslint-disable-next-line
			var url = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function () {
				setClientData({
					...clientData,
					image: {
						...clientData.image,
						state: event.target.files[0],
						ImgName: event.target.files[0].name,
						error: "",
						ImgSrc: [reader.result],
					},
				});
			};
		}
	}

	const submit = async () => {
		const loadingMessage = message.loading("Loading...", 0);

		var formData = new FormData();
		formData.append("name", clientData.name.state);
		formData.append("link", clientData.link.state);
		formData.append("logo", clientData.image.state);

		await axios
			.post(`${DOMAIN}/api/audit/add`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					"jwt-token": user.jwtToken,
				},
			})
			.then((response) => {
				message.success({
					content: `Audit Created!`,
					className: "text-center",
					duration: 2,
				});
			})
			.catch((error) => {
				message.error({
					content: `${error.response.data.error}`,
					className: "text-center",
					duration: 2,
				});
			})
			.finally(() => {
				loadingMessage();
			});
	};

	return (
		<>
			<div className="hidden lg:block">
				<p className="font-poppins text-3xl text-center font-bold text-grey-1 pt-10 pb-5">
					Client information
				</p>
				<div className="p-5">
					<div className="flex flex-col gap-5 max-w-3xl mx-auto">
						<div className="col-span-1">
							<p className=" font-montserrat font-bold text-lg w-72">Name:</p>
							<div className="relative w-full">
								<input
									className={` h-12 appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 ${
										clientData.name.error?.length !== 0
											? "border-orange-1 focus:ring-orange-1"
											: "border-green-11 focus:ring-green-11"
									} text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent`}
									value={clientData.name.state}
									onChange={(e) => {
										setClientData({
											...clientData,
											name: { ...clientData.name, state: e.target.value },
										});
									}}
									placeholder="Client name"
									//onBlur={e=>checkJobTitle(title,setJobTitle)}
								/>
								<div className="absolute">
									<p className="font-raleway text-sm text-orange-1 pt-1 m-0">
										{" "}
										{clientData.name.error}{" "}
									</p>
								</div>
							</div>
						</div>
						<div className="col-span-1">
							<p className=" font-montserrat font-bold text-lg w-72">Link:</p>
							<div className="relative w-full">
								<input
									className={` h-12 appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 ${
										clientData.link.error?.length !== 0
											? "border-orange-1 focus:ring-orange-1"
											: "border-green-11 focus:ring-green-11"
									} text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent`}
									value={clientData.link.state}
									onChange={(e) => {
										setClientData({
											...clientData,
											link: { ...clientData.link, state: e.target.value },
										});
									}}
									placeholder="Client link"
									//onBlur={e=>checkJobTitle(title,setJobTitle)}
								/>
								<div className="absolute">
									<p className="font-raleway text-sm text-orange-1 pt-1 m-0">
										{" "}
										{clientData.link.error}{" "}
									</p>
								</div>
							</div>
						</div>
						<div className="">
							<div>
								<p className=" font-montserrat font-bold text-lg w-72">Logo:</p>
								<div className="relative w-full">
									<div
										className={`${
											clientData.image.error.length !== 0
												? "border-orange-1"
												: "border-green-11"
										} h-12 absolute flex items-center justify-between bg-grey-13 appearance-none border-b-4 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none`}
									>
										<p className="font-raleway text-grey-8">
											{clientData.image.ImgName}
										</p>
										<div className="bg-grey-14 py-2 px-8">
											<p className="text-white font-raleway">Browse</p>
										</div>
									</div>
									<input
										onChange={handleChangeIMG}
										className="h-12 cursor-pointer opacity-0 hover:border-blue-500 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-blue-500"
										id="resume"
										type="file"
										accept="image/png, image/jpg, image/jpeg"
									/>
									<p className="font-raleway text-sm text-orange-1 pt-1 m-0">
										{" "}
										{clientData.image.error}{" "}
									</p>
								</div>
							</div>
							<div
								style={{ maxHeight: "249px" }}
								className="flex justify-center rounded-xl mt-5"
							>
								{clientData.image.ImgSrc.length ? (
									<div className="rounded-xl shadow-xl px-24 py-5 border-2">
										<img
											src={clientData.image.ImgSrc}
											style={{ maxHeight: "200px" }}
											className="w-full h-full"
											alt="Featured_image"
										/>
									</div>
								) : (
									<div className=" bg-grey-17 w-full h-64 flex justify-center items-center shadow-xl rounded-xl">
										<p className=" font-poppins text-lg font-bold text-white">
											No image
										</p>
									</div>
								)}
							</div>
						</div>
						<div className="flex flex-row gap-5 justify-center mx-auto py-10">
							<div
								onClick={() => submit()}
								className="bg-green-11 hover:bg-white border-2 border-green-11 rounded-xl px-10 py-3 cursor-pointer text-center group transition-all duration-500"
							>
								<p className=" font-poppins text-white group-hover:text-green-11 font-bold uppercase transition-all duration-500">
									Start audit
								</p>
							</div>
							<Popconfirm
								title="Are you sure you want to leave? All the progress will be lost."
								onConfirm={() => history.push("/eloqua-audit")}
							>
								<div className="bg-red-500 hover:bg-white border-2 border-red-500 rounded-xl px-10 py-3 cursor-pointer text-center group transition-all duration-500">
									<p className=" font-poppins text-white group-hover:text-red-500 font-bold uppercase transition-all duration-500">
										Cancel
									</p>
								</div>
							</Popconfirm>
						</div>
					</div>
				</div>
			</div>
			<div className="block lg:hidden">
				<p className="font-poppins text-3xl text-center font-bold text-grey-1 pt-10">
					Please login on a compatible device.
				</p>
			</div>
		</>
	);
}

export default EloquaAuditAdd;
