import EditorJS from "@editorjs/editorjs";
import { v4 as uuidv4 } from "uuid";
export default class Columns {
	constructor({ data, config, api, readOnly }) {
		// start by setting up the required parts
		this.api = api;
		this.readOnly = readOnly;
		this.config = config || {};

		this._CSS = {
			block: this.api.styles.block,
			wrapper: "ce-EditorJsColumns",
		};

		if (!this.readOnly) {
			this.onKeyUp = this.onKeyUp.bind(this);
		}

		this._data = {};

		this.editors = {};

		this.colWrapper = undefined;

		this.editors.cols = [];

		this.data = data;

		if (!Array.isArray(this.data.cols)) {
			this.data.cols = [];
			this.editors.numberOfColumns = 2;
		} else {
			this.editors.numberOfColumns = this.data.cols.length;
		}
	}

	static get isReadOnlySupported() {
		return true;
	}

	onKeyUp(e) {
		console.log(e);
		console.log("heyup");
		if (e.code !== "Backspace" && e.code !== "Delete") {
			return;
		}
	}

	get CSS() {
		return {
			settingsButton: this.api.styles.settingsButton,
			settingsButtonActive: this.api.styles.settingsButtonActive,
		};
	}

	static get toolbox() {
		return {
			title: "Columns",
			icon: '<svg height="24" viewBox="0 0 24 24" width="24" outline="none" stroke-width="0px"><g><path d="m18 21h-12c-1.7 0-3-1.3-3-3v-12c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3zm-12-16c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h12c.6 0 1-.4 1-1v-12c0-.6-.4-1-1-1z"/></g><g><path d="m12 21c-.6 0-1-.4-1-1v-16c0-.6.4-1 1-1s1 .4 1 1v16c0 .6-.4 1-1 1z"/></g></svg>',
		};
	}

	renderSettings() {
		const buttonTwoCols = {
			name: "Two Cols",
			icon: `<div>2</div>`,
		};

		const buttonThreeCols = {
			name: "Three Cols",
			icon: `<div>3</div>`,
		};

		const buttonRollCols = {
			name: "Roll Cols",
			icon: `<div class="ce-popover__item-icon"><svg style="width:15px !important; height:15px !important" stroke-width="0px" viewBox="0 0 24 24"><g><path d="M23,16H2.681l.014-.015L4.939,13.7a1,1,0,1,0-1.426-1.4L1.274,14.577c-.163.163-.391.413-.624.676a2.588,2.588,0,0,0,0,3.429c.233.262.461.512.618.67l2.245,2.284a1,1,0,0,0,1.426-1.4L2.744,18H23a1,1,0,0,0,0-2Z"/><path d="M1,8H21.255l-2.194,2.233a1,1,0,1,0,1.426,1.4l2.239-2.279c.163-.163.391-.413.624-.675a2.588,2.588,0,0,0,0-3.429c-.233-.263-.461-.513-.618-.67L20.487,2.3a1,1,0,0,0-1.426,1.4l2.251,2.29L21.32,6H1A1,1,0,0,0,1,8Z"/></g></svg></div><div class="ce-popover__item-label">Reverse</div>`,
		};

		const wrapper = document.createElement("div");

		let buttonTwoCols_Button = document.createElement("div");
		buttonTwoCols_Button.classList.add("cdx-settings-button");
		buttonTwoCols_Button.innerHTML = buttonTwoCols.icon;

		let buttonThreeCols_Button = document.createElement("div");
		buttonThreeCols_Button.classList.add("cdx-settings-button");
		buttonThreeCols_Button.innerHTML = buttonThreeCols.icon;

		let buttonRollCols_Button = document.createElement("div");
		buttonRollCols_Button.classList.add("ce-popover__item");
		buttonRollCols_Button.innerHTML = buttonRollCols.icon;

		buttonTwoCols_Button.addEventListener("click", (event) => {
			this._updateCols(2);
		});

		buttonThreeCols_Button.addEventListener("click", (event) => {
			this._updateCols(3);
		});

		buttonRollCols_Button.addEventListener("click", (event) => {
			this._rollCols();
		});

		wrapper.appendChild(buttonTwoCols_Button);
		wrapper.appendChild(buttonThreeCols_Button);
		wrapper.appendChild(buttonRollCols_Button);

		return wrapper;
	}

	_rollCols() {
		// this shifts or "rolls" the columns
		this.data.cols.unshift(this.data.cols.pop());
		this.editors.cols.unshift(this.editors.cols.pop());
		this._rerender();
	}

	async _updateCols(num) {
		// Should probably update to make number dynamic... but this will do for now
		if (num === 2) {
			if (this.editors.numberOfColumns === 3) {
				let resp = true;

				if (resp) {
					this.editors.numberOfColumns = 2;
					this.data.cols.pop();
					this.editors.cols.pop();
					this._rerender();
				}
			}
		}
		if (num === 3) {
			this.editors.numberOfColumns = 3;
			this._rerender();
			// console.log(3);
		}
	}

	async _rerender() {
		await this.save();

		for (let index = 0; index < this.editors.cols.length; index++) {
			this.editors.cols[index].destroy();
		}
		this.editors.cols = [];

		this.colWrapper.innerHTML = "";

		// console.log("Building the columns");

		for (let index = 0; index < this.editors.numberOfColumns; index++) {
			// console.log("Start column, ", index);
			let col = document.createElement("div");

			const colClasses = [
				"border-2",
				"border-stone-200",
				"w-full",
				"flex",
				"border-dashed",
				"col-editor",
			];
			colClasses.forEach((elem, index) => {
				col.classList.add(elem);
			});
			col.classList.add("editorjs_col_" + index);

			let editor_col_id = uuidv4();
			// console.log("generating: ", editor_col_id);
			col.id = editor_col_id;

			this.colWrapper.appendChild(col);

			let editorjs_instance = new EditorJS({
				defaultBlock: "paragraph",
				placeholder: "Let`s write an awesome story!",
				holder: editor_col_id,
				tools: this.config.tools,
				data: this.data.cols[index],
				readOnly: this.readOnly,
				minHeight: 50,
				logLevel: "ERROR",
			});

			this.editors.cols.push(editorjs_instance);
		}
	}

	render() {
		// This is needed to prevent the enter / tab keys - it globally removes them!!!

		// it runs MULTIPLE times. - this is not good, but works for now
		window.helpme = document.addEventListener(
			"keydown",
			function (event) {
				// if (event.key === "Enter" && event.altKey) {
				// 	console.log("ENTER ALT Captured")
				// 	console.log(event.target)

				// 	// let b = event.target.dispatchEvent(new KeyboardEvent('keyup',{'key':'a'}));

				// 	event.target.innerText += "Aß"

				// 	// console.log(b)
				// }
				// else
				/*
				if (event.key === "Enter") {
					event.stopImmediatePropagation();
					event.preventDefault();
				}
				*/
				if (event.key === "Tab") {
					event.stopImmediatePropagation();
					event.preventDefault();
				}
			},
			true
		);

		// console.log("Generating Wrapper");

		// console.log(this.api.blocks.getCurrentBlockIndex());

		this.colWrapper = document.createElement("div");

		const colWrapperClasses = [
			"flex",
			"flex-col-reverse",
			"md:flex-row",
			"w-full",
			"gap-5",
			"my-4",
			"py-5"
		];

		colWrapperClasses.forEach((elem, index) => {
			this.colWrapper.classList.add(elem);
		});

		for (let index = 0; index < this.editors.cols.length; index++) {
			this.editors.cols[index].destroy();
		}

		// console.log(this.editors.cols);
		this.editors.cols = []; //empty the array of editors
		// console.log(this.editors.cols);

		// console.log("Building the columns");

		for (let index = 0; index < this.editors.numberOfColumns; index++) {
			// console.log("Start column, ", index);
			let col = document.createElement("div");
			const colClasses = [
				"border-2",
				"border-stone-200",
				"w-full",
				"flex",
				"border-dashed",
				"col-editor",
			];

			colClasses.forEach((elem, index) => {
				col.classList.add(elem);
			});
			col.classList.add("editorjs_col_" + index);

			let editor_col_id = uuidv4();
			// console.log("generating: ", editor_col_id);
			col.id = editor_col_id;

			this.colWrapper.appendChild(col);

			let editorjs_instance = new EditorJS({
				defaultBlock: "paragraph",
				placeholder: "Let`s write an awesome story!",
				holder: editor_col_id,
				tools: this.config.tools,
				data: this.data.cols[index],
				readOnly: this.readOnly,
				minHeight: 50,
				logLevel: "ERROR",
			});

			this.editors.cols.push(editorjs_instance);
			// console.log("End column, ", index);
		}
		return this.colWrapper;
	}

	async save() {
		if (!this.readOnly) {
			// console.log("Saving");
			for (let index = 0; index < this.editors.cols.length; index++) {
				await this.editors.cols[index].isReady;
				let colData = await this.editors.cols[index].save();
				this.data.cols[index] = colData;
			}
		}
		return this.data;
	}
}
