import React, { useState, useContext, useEffect, useRef } from "react";
import UserContext from "../../context/context";
import { useDetectOutsideClick } from "../../utils/useDetectOutsideClick";
import GenerateQuizModal from "../../components/CareerQuizComponents/generateQuizModal";
import { Link } from "react-router-dom";
import { message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import {DOMAIN} from "../../env";

export default function QuestionBankSubmissions() {
	const user = useContext(UserContext);
	const { id } = useParams();

	const [generateQuizModal, setGenerateQuizModal] = useState(false);
	const [updateQuizList, setUpdateQuizList] = useState(false);
	const [data, setData] = useState({
		numberOfQuestions: null,
		quizzes: [],
		questionBankName: "",
		dataFetched: false,
	});
	const history = useHistory();

	const categoryRef = useRef(null);
	const [isCategoryActive, setIsCategoryActive] = useDetectOutsideClick(
		categoryRef,
		false
	);
	const toggleCategory = () => {
		setIsCategoryActive((isCategoryActive) => !isCategoryActive);
	};

	const toggleCategorySelection = (val) => {
		if (!filter.status.includes(val)) {
			setFilter({ ...filter, status: [...filter.status, val] });
		} else {
			setFilter({ ...filter, status: filter.status.filter((e) => e !== val) });
		}
	};

	const [filter, setFilter] = useState({
		status: [],
		expected_parcipant: "",
	});

	const dataFilter = function (dataArr, filterArr) {
		let data = dataArr;
		if (filterArr.expected_parcipant)
			data =
				data &&
				data.filter((i) =>
					i.expected_parcipant
						.toLowerCase()
						.includes(filterArr.expected_parcipant.toLowerCase())
				);
		if (filterArr.status.length !== 0)
			data = data && data.filter((i) => filterArr.status.includes(i.status));
		return data;
	};

	const filteredData = dataFilter(data.quizzes, filter);

	const renderQuizzes = () => {
		const checkStatusColor = (status) => {
			if (status === "Completed") return "border-green-11 border-l-0";
			else if (status === "Started") return "border-yellow-400 border-l-0";
			else return "border-gray-300 border-l-0";
		};

		const BgStatusColor = (status) => {
			if (status === "Completed") return "bg-green-11 text-grey-1";
			else if (status === "Started") return "bg-yellow-400 text-grey-1";
			else return "bg-gray-300 text-grey-1";
		};

		const quizzes = filteredData.map((element, index) => {
			return (
				<Link
					to={`/careerQuiz/quiz/${element._id}`}
					key={index}
					className={`${BgStatusColor(
						element.status
					)}  w-full col-span-1 rounded-2xl shadow-lg cursor-pointer flex flex-row items-center relative p-0.5 overflow-hidden group`}
				>
					<div
						className={`${BgStatusColor(
							element.status
						)} w-8 md:w-36 flex items-center justify-center p-3 rounded-l-xl lg:absolute`}
					>
						<p className=" font-poppins text-white text-lg font-semibold transform -rotate-90 md:rotate-0">
							{element.status}
						</p>
					</div>
					<div className="m-[1px] overflow-hidden w-full rounded-xl">
						<div
							className={`${checkStatusColor(
								element.status
							)} bg-white flex flex-col md:flex-row p-3 gap-5 w-full rounded-xl relative lg:transform lg:group-hover:translate-x-36 transition-all duration-500`}
						>
							<div className="w-full max-w-[15rem] flex flex-row items-center justify-start gap-2">
								<svg
									className=" fill-current text-grey-1 w-5 h-5"
									viewBox="0 0 24 24"
								>
									<path d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z" />
									<path d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z" />
								</svg>
								<p className=" font-poppins text-lg font-semibold text-grey-1 truncate">
									{element.expected_parcipant.split("@")[0]}
								</p>
							</div>

							<div className="flex flex-row items-center justify-start w-full max-w-[14rem] gap-2">
								<svg
									className=" fill-current text-grey-1 w-5 h-5"
									viewBox="0 0 24 24"
								>
									<path d="M17,10.039c-3.859,0-7,3.14-7,7,0,3.838,3.141,6.961,7,6.961s7-3.14,7-7c0-3.838-3.141-6.961-7-6.961Zm0,11.961c-2.757,0-5-2.226-5-4.961,0-2.757,2.243-5,5-5s5,2.226,5,4.961c0,2.757-2.243,5-5,5Zm1.707-4.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1-1c-.188-.188-.293-.442-.293-.707v-2c0-.552,.447-1,1-1s1,.448,1,1v1.586l.707,.707Zm5.293-10.293v2c0,.552-.447,1-1,1s-1-.448-1-1v-2c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v1H11c.552,0,1,.448,1,1s-.448,1-1,1H2v9c0,1.654,1.346,3,3,3h4c.552,0,1,.448,1,1s-.448,1-1,1H5c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h1V1c0-.552,.448-1,1-1s1,.448,1,1v1h8V1c0-.552,.447-1,1-1s1,.448,1,1v1h1c2.757,0,5,2.243,5,5Z" />
								</svg>

								<p className=" font-poppins text-lg font-semibold text-grey-1">
									{new Date(element.exipiration_date).toLocaleString(
										"default",
										{
											month: "long",
										}
									)}
									{"/"}
									{new Date(element.exipiration_date).getDate()}
									{"/"}
									{new Date(element.exipiration_date).getFullYear()}
								</p>
							</div>

							{element.duration >= 60 ? (
								<div className="flex flex-row items-center justify-start gap-2">
									<svg
										className=" fill-current text-grey-1 w-5 h-5"
										viewBox="0 0 24 24"
									>
										<path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z" />
										<path d="M12,6a1,1,0,0,0-1,1v4.325L7.629,13.437a1,1,0,0,0,1.062,1.7l3.84-2.4A1,1,0,0,0,13,11.879V7A1,1,0,0,0,12,6Z" />
									</svg>

									<p className=" font-poppins text-lg font-semibold text-grey-1">
										{element.duration / 60}h
									</p>
								</div>
							) : (
								<div className="flex flex-row items-center justify-start gap-2">
									<svg
										className=" fill-current text-grey-1 w-5 h-5"
										viewBox="0 0 24 24"
									>
										<path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z" />
										<path d="M12,6a1,1,0,0,0-1,1v4.325L7.629,13.437a1,1,0,0,0,1.062,1.7l3.84-2.4A1,1,0,0,0,13,11.879V7A1,1,0,0,0,12,6Z" />
									</svg>

									<p className=" font-poppins text-lg font-semibold text-grey-1">
										{element.duration}m
									</p>
								</div>
							)}
						</div>
					</div>
				</Link>
			);
		});

		if (quizzes.length === 0 && data.dataFetched === false)
			return [...new Array(12)].map((item, index) => (
				<div
					key={`loading_${index}`}
					className="w-full h-14 col-span-1 rounded-2xl bg-gray-300 animate-pulse shadow-lg cursor-pointer flex flex-row items-center relative p-0.5 overflow-hidden group"
				>
					<div className="flex w-full">
						<div className=" w-1/2 bg-grey-1 h-5 mx-2 rounded-full"></div>
						<div className=" w-1/4 bg-grey-1 h-5 mx-2 rounded-full"></div>
						<div className=" w-1/4 bg-grey-1 h-5 mx-2 rounded-full"></div>
					</div>
				</div>
			));

		if (quizzes.length === 0 && data.dataFetched === true)
			return (
				<div className=" col-span-2">
					<p className=" font-poppins text-2xl text-grey-1 font-bold text-center py-20">
						No quizzes ...
					</p>
				</div>
			);

		return quizzes;
	};

	useEffect(() => {
		const fetchApiData = async () => {
			try {
				if (
					!["Developer", "Marketing", "HumanResources", "Admin"].includes(
						user.user.user_role
					)
				) {
					message.error({
						content: `Unauthorized!`,
						className: "text-center",
						duration: 2,
					});
					history.push("/");
				} else {
					await axios
						.get(
							`${DOMAIN}/api/careers_quiz/get_quizzes_by_question_bank_id/${id}`,
							{
								headers: {
									"jwt-token": user.jwtToken,
								},
							}
						)
						.then((res) => {
							setData({
								quizzes: res.data.quizzes,
								numberOfQuestions: res.data.number_of_questions,
								questionBankName: res.data.question_bank_name,
								dataFetched: true,
							});
						});
				}
			} catch (Error) {}
		};
		fetchApiData();
		// eslint-disable-next-line
	}, [updateQuizList]);

	return (
		<div>
			<div className="flex flex-col justify-between items-center gap-5">
				<div className="bg-grey-1 p-5 w-full shadow-lg">
					<p className=" font-bold font-poppins text-2xl text-white text-center lg:text-left mb-0">
						Question Bank : {data.questionBankName}
					</p>
				</div>
				<div className="flex flex-row gap-5 justify-center w-full px-5">
					<Link
						to={`/careerQuiz/questionBank/questions/${id}`}
						className={`cursor-pointer px-4 w-full justify-center py-3 flex flex-row gap-2 items-center rounded-2xl shadow-lg bg-grey-3 hover:bg-green-11 transition-all duration-500 select-none`}
					>
						<div>
							<svg
								className=" fill-current text-white w-6 h-6"
								viewBox="0 0 24 24"
							>
								<path d="M9,11c-.552,0-1-.447-1-1,0-1.308,1.038-1.879,1.481-2.123,.29-.159,.595-.535,.502-1.066-.069-.392-.402-.725-.793-.793-.306-.056-.602,.022-.832,.216-.228,.19-.358,.47-.358,.767,0,.553-.448,1-1,1s-1-.447-1-1c0-.889,.391-1.727,1.072-2.299,.681-.572,1.577-.814,2.463-.653,1.209,.211,2.204,1.205,2.417,2.417,.223,1.272-.382,2.543-1.506,3.164-.447,.246-.447,.318-.447,.371,0,.553-.448,1-1,1Zm0,1c-.69,0-1.25,.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Zm10.996-2.92c-.006,.769-.091,1.518-.248,2.242,1.371,1.101,2.252,2.787,2.252,4.678v5c0,.552-.448,1-1,1h-5c-1.891,0-3.577-.881-4.678-2.252-.724,.156-1.473,.242-2.242,.248,1.385,2.389,3.965,4.004,6.92,4.004h5c1.657,0,3-1.343,3-3v-5c0-2.955-1.615-5.535-4.004-6.92Zm-2.019,.571c.185-2.613-.768-5.17-2.613-7.016S10.964-.167,8.349,.023C3.823,.343,0,4.589,0,9.296v5.038c0,2.021,1.642,3.666,3.661,3.666h4.477c5.187,0,9.509-3.667,9.839-8.349Zm-4.027-5.601c1.436,1.435,2.176,3.425,2.033,5.46-.253,3.578-3.772,6.489-7.845,6.489H3.661c-.916,0-1.661-.747-1.661-1.666v-5.038c0-3.696,2.972-7.029,6.49-7.278,.167-.012,.333-.018,.499-.018,1.858,0,3.644,.732,4.961,2.051Z" />
							</svg>
						</div>
						<p className=" font-raleway text-xl font-bold text-white text-center hidden md:block">
							Questions
						</p>
					</Link>
					<Link
						to={`/careerQuiz/questionBank/submissions/${id}`}
						className={`flex flex-row gap-2 items-center cursor-pointer px-4 w-full justify-center py-3 rounded-2xl shadow-lg bg-green-11 select-none`}
					>
						<div>
							<svg
								viewBox="0 0 24 24"
								className=" fill-current text-white w-5 h-5"
							>
								<path d="M18.5,24H13.034A13.5,13.5,0,0,1,3,19.789a11.467,11.467,0,0,1-2.947-8.9A12.024,12.024,0,0,1,9.908.188a11.641,11.641,0,0,1,9.654,2.569A12.448,12.448,0,0,1,24,12.324V18.5A5.507,5.507,0,0,1,18.5,24ZM12.03,3a9.021,9.021,0,0,0-8.988,8.164,8.509,8.509,0,0,0,2.18,6.605A10.5,10.5,0,0,0,13.034,21H18.5A2.5,2.5,0,0,0,21,18.5V12.324a9.466,9.466,0,0,0-3.366-7.27A8.637,8.637,0,0,0,12.03,3ZM10.5,17a2.5,2.5,0,0,1-1.771-.732L5.956,13.577a1.5,1.5,0,0,1,2.088-2.154L10.5,13.8l5.45-5.369a1.5,1.5,0,1,1,2.106,2.136L12.26,16.275A2.476,2.476,0,0,1,10.5,17Z" />
							</svg>
						</div>
						<p className=" font-raleway text-xl font-bold text-white text-center hidden md:block">
							Submissions
						</p>
					</Link>
				</div>
			</div>
			<div className="p-5">
				<div className="flex flex-row gap-4 pb-5 pt-2 justify-center lg:justify-end">
					<div className="relative">
						<div className="flex h-12 w-full flex-row items-center justify-center gap-3 rounded-2xl bg-grey-1 py-2 pl-4 pr-2 md:w-48">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 fill-current text-white" viewBox="0 0 24 24"><path d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z"/></svg>
							<input
								type="text"
								placeholder="Search by name"
								onChange={(e) =>
									setFilter({ ...filter, expected_parcipant: e.target.value })
								}
								className={`h-8 w-full appearance-none rounded-lg bg-grey-13 py-2 px-3 leading-tight text-gray-700 focus:border-transparent focus:outline-none`}
							/>
						</div>
					</div>
					<div ref={categoryRef} className="relative group">
						<div onClick={toggleCategory} className="bg-grey-3 h-12 flex cursor-pointer items-center justify-center rounded-2xl shadow-lg transition-all duration-300 md:w-32 min-w-[8rem] z-50 relative">
							<p className="text-white font-montserrat font-bold text-xl mx-auto">
								Status
							</p>
						</div>
						<div className={`${isCategoryActive ? "translate-y-0 opacity-100" : " pointer-events-none -translate-y-10 opacity-0" } absolute z-40 mt-2 flex h-40 w-full flex-col justify-center gap-2 overflow-hidden rounded-2xl border-2 bg-grey-4 px-2 shadow-lg transition-all duration-500`}>
							{
								["Pending","Started","Completed"].map((elem, index)=>{
									return(
										<div onClick={() => toggleCategorySelection(elem)} className={`${filter.status.includes(elem)?"bg-orange-1":"bg-grey-3"} py-2 px-3 flex flex-row justify-center items-center cursor-pointer rounded-xl transition-all duration-500`} >
											<p className="text-white font-montserrat text-md mr-auto w-11/12 capitalize font-bold">
												{elem}
											</p>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
				<div className="flex flex-col md:flex-row justify-between items-center gap-5 pb-8">
					<p className=" font-poppins text-grey-1 font-bold text-2xl">
						Quizzes List
					</p>
					<div
						onClick={() => setGenerateQuizModal(true)}
						className="bg-green-11 px-8 py-2 rounded-2xl flex flex-row gap-2 justify-center items-center cursor-pointer shadow-lg"
					>
						<p className="font-poppins text-white font-semibold text-xl select-none">
							Generate Quiz
						</p>
					</div>
				</div>
				<div className=" grid grid-cols-1 xl:grid-cols-2 gap-5 w-full">
					{renderQuizzes()}
				</div>
			</div>
			<GenerateQuizModal
				isModalActive={generateQuizModal}
				setIsModalActive={setGenerateQuizModal}
				questionBankId={id}
				numberOfQuestions={data.numberOfQuestions}
				updateList={updateQuizList}
				setUpdateList={setUpdateQuizList}
			/>
		</div>
	);
}
