import { useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import UserContext from "../../context/context";
import { Popconfirm, message, Tooltip } from "antd";
import {DOMAIN, STORAGE_DOMAIN} from "../../env";

export default function Question({ question, index, userAnswer, edit, setEditQuestionData, setEditQuestionModal, setUpdateQuestionList, updateQuestionList }) {
    const user = useContext(UserContext);
    const { id } = useParams();

    const renderAnswersText = (answers, userAnswers) => {
        return answers.map((elem, index) => {
            const chekMarker = userAnswers === undefined ? "hidden" : userAnswers.answered !== false ? typeof userAnswers?.answer === "object" ? userAnswers.answer?.includes(elem.value) ? "block" : "hidden" : userAnswers?.answer === elem.value ? "block" : "hidden" : null;
            return (
                <div key={`answer_${index}`} className={`col-span-1 rounded-xl relative px-4 py-2 shadow-md border-2 border-solid flex flex-row gap-5 items-center justify-between w-full ${elem.correct === "true" ? " border-green-11" : " border-orange-1"}`}>
                    <p className={`font-raleway font-semibold text-lg truncate max-w-full`} > {elem.value} </p>
                    <div className={`rounded-full shadow-md ${chekMarker}`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="fill-current text-green-11" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" /></svg>
                    </div>
                </div>
            )
        });
    };

    const renderAnswersImages = (answers, userAnswers) => {
        return answers.map((elem, index) => {
            const checkMarker = userAnswers === undefined ? "hidden" : (userAnswers.answered !== false ? (typeof userAnswers.answer === "object" ? (userAnswers.answer.includes(elem.name) ? "block" : "hidden") : (userAnswers.answer === elem.name ? "block" : "hidden")) : "hidden")
            return (
                <div key={`answer_${index}`} className={`max-w-xs rounded-lg pb-2 relative ${elem.correct === "true" ? " bg-green-11" : " bg-orange-1"}`}>
                    <img className="max-w-[6rem] bg-white" key={elem.name} alt="answer_img" src={`${STORAGE_DOMAIN+elem.name}`} />
                    <div className={`${checkMarker} bg-white absolute -top-2 -right-2 rounded-full`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="fill-current text-green-11" viewBox="0 0 24 24" ><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" /></svg>
                    </div>
                </div>
            )
        });
    };

    const renderParagraphQuestions = (questions, userAnswers) => {

        const renderUserAnswer = (userAnswers, answer, questionIndex) => {
            let correct = false;
            if (userAnswers !== undefined)
                if (
                    userAnswers.answer.find(
                        (el, index) =>
                            el.question === questionIndex && answer.value === el.answer
                    )
                )
                    correct = true;
            return correct;
        };

        return questions.map((question, questionIndex) => (
            <div key={`paragraph_question_${questionIndex}`} className="border-2 rounded-lg shadow-md px-5 py-3 col-span-3 lg:col-span-1">
                <p className=" font-poppins font-bold text-grey-1 pb-2">
                    {questionIndex + 1} - {question.question}
                </p>
                <div className="grid grid-cols-2 gap-5">
                    {question.answers.map((answer, answerIndex) => (
                        <div className={`border-2 flex flex-row gap-5 items-center justify-between rounded-lg px-4 py-2 col-span-1 ${answer.correct === "true" ? "border-green-11" : "border-orange-1"}`} key={`paragraph_question_${questionIndex}_answer_${answerIndex}`} >
                            <p className="font-raleway font-semibold text-lg text-grey-1 line-clamp-1 whitespace-pre-line">
                                {answer.value}
                            </p>
                            <div className={`rounded-full shadow-md ${renderUserAnswer(userAnswers, answer, questionIndex) ? "block" : "hidden"}`} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className=" fill-current text-green-11" viewBox="0 0 24 24" > <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" /> </svg>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ));
    };

    const deleteFunction = (questionBankId, question) => {
        const loadingMessage = message.loading("Loading...", 0);
        let imageArray = [];
        if (question.format === "Image") {
            imageArray = question.answers.map((elem) => elem.name);
            imageArray.push(question.image);
        }
        axios
            .post(
                `${DOMAIN}/api/careers_quiz/delete_question`,
                {
                    questionBankId: questionBankId,
                    questionId: question.id,
                    imageArray: imageArray,
                },
                {
                    headers: {
                        "jwt-token": user.jwtToken,
                    },
                }
            )
            .then((res) => {
                setUpdateQuestionList(!updateQuestionList);
                message.success({
                    content: `Question deleted!`,
                    className: "text-center",
                    duration: 2,
                });
            })
            .catch((error) => {
                message.error({
                    content: `Something went wrong!`,
                    className: "text-center",
                    duration: 2,
                });
            })
            .finally(() => {
                loadingMessage();
            });
    };

    function renderQuestionType(data) {
        if (data.type === "Free Text") {
            return (
                <div className="p-4 mb-auto">
                    <p className=" font-poppins text-xl font-bold whitespace-pre-line line-clamp-5">
                        {data.name.split("<br/>").join("\n")}
                    </p>
                    <div className="pt-4">{userAnswer?.answer}</div>
                </div>
            )
        }
        if (data.type === "Single Choice" || data.type === "Multiple Choice") {
            if (data.format === "Text") {
                return (
                    <div className="p-4 h-full flex flex-col">
                        <p className="font-poppins text-xl font-bold whitespace-pre-line line-clamp-2 lg:h-14">
                            {data.name.split("<br/>").join("\n")}
                        </p>
                        <div className="grid grid-cols-2 justify-start gap-2 pt-4 mb-auto">
                            {renderAnswersText(data.answers, userAnswer)}
                        </div>
                    </div>
                )
            } else return (
                <div className="p-4 mb-auto">
                    <div className="max-w-[10rem] min-h-[10rem] mx-auto flex items-center justify-center">
                        <img alt="question_img" key={data.image} src={`${STORAGE_DOMAIN+ data.image}`} />
                    </div>
                    {data.imageText ? (
                        <p className=" font-raleway text-grey-1 text-center pt-4"> {data.imageText} </p>
                    ) : null}
                    <div className="flex justify-center items-center gap-2 pt-4">
                        {renderAnswersImages(data.answers, userAnswer)}
                    </div>
                </div>
            )
        }
        if (data.type === "Paragraph") {
            return (
                <div className="p-4 mb-auto">
                    <p className="font-poppins text-xl font-bold whitespace-pre-line line-clamp-5">
                        {data.paragraph.split("<br/>").join("\n")}
                    </p>
                    <div className="grid grid-cols-3 gap-4 pt-4">
                        {renderParagraphQuestions(data.questions, userAnswer)}
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={`w-full flex flex-col shadow-lg rounded-xl select-none ${question.type === "Paragraph" ? "col-span-2" : "col-span-2 md:col-span-1"}`}>
            <div className="flex flex-row justify-between items-center bg-zinc-200 py-2 px-4 rounded-t-xl">
                <p className="font-poppins text-lg font-bold text-grey-1">
                    Question {index + 1}
                </p>
                {
                    edit ? (
                        <div className="ml-auto">
                            <p className=" font-raleway text-lg font-semibold text-grey-1">
                                {question.type}
                            </p>
                        </div>
                    ) : userAnswer?.number_of_times_tab_leave > 0 ? (
                        <Tooltip placement="topRight" title="Number of tab switches">
                            <div className="flex gap-2 items-center">
                                <p className="font-montserrat text-xl font-bold text-grey-1">
                                    {userAnswer?.number_of_times_tab_leave}
                                </p>
                                <svg className="h-6 w-6 fill-current text-orange-1" viewBox="0 0 24 24"><path d="M23.08,15.33L15,2.57c-.68-.98-1.81-1.57-3-1.57s-2.32,.58-3.03,1.6L.93,15.31c-1.02,1.46-1.21,3.21-.5,4.56,.7,1.35,2.17,2.12,4.01,2.12h15.12c1.85,0,3.31-.77,4.01-2.12,.7-1.35,.51-3.09-.49-4.54ZM11,7c0-.55,.45-1,1-1s1,.45,1,1v6c0,.55-.45,1-1,1s-1-.45-1-1V7Zm1,12c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5,1.5,.67,1.5,1.5-.67,1.5-1.5,1.5Z" /></svg>
                            </div>
                        </Tooltip>) : null
                }
            </div>
            <div className="border-2 border-zinc-200 flex flex-col h-full rounded-b-xl">
                {renderQuestionType(question)}
                {edit ? (
                    <div className="px-4 pb-4">
                        <div className="flex flex-row justify-center gap-2 relative">
                            <div
                                onClick={() => {
                                    setEditQuestionData(question);
                                    setEditQuestionModal(true);
                                }}
                                className="group border-green-11 hover:bg-green-11 border-2 border-solid transition-all duration-500 w-full md:w-32 flex justify-center items-center py-2 rounded-xl shadow-lg cursor-pointer"
                            >
                                <svg className="fill-current text-green-11 group-hover:text-white h-5 w-5 transition-all duration-500" viewBox="0 0 24 24" > <path d="M22.853,1.148a3.626,3.626,0,0,0-5.124,0L1.465,17.412A4.968,4.968,0,0,0,0,20.947V23a1,1,0,0,0,1,1H3.053a4.966,4.966,0,0,0,3.535-1.464L22.853,6.271A3.626,3.626,0,0,0,22.853,1.148ZM5.174,21.122A3.022,3.022,0,0,1,3.053,22H2V20.947a2.98,2.98,0,0,1,.879-2.121L15.222,6.483l2.3,2.3ZM21.438,4.857,18.932,7.364l-2.3-2.295,2.507-2.507a1.623,1.623,0,1,1,2.295,2.3Z" /> </svg>
                            </div>
                            <Popconfirm
                                title="Are you sure to delete this question?"
                                onConfirm={() => deleteFunction(id, question)}
                                okText="Yes"
                                okType="danger"
                                cancelText="No"
                                getPopupContainer={(trigger) => trigger.parentElement}
                            >
                                <div className="group border-red-600 hover:bg-red-600 border-2 border-solid transition-all duration-500 w-full md:w-32 flex justify-center items-center py-2 rounded-xl shadow-lg cursor-pointer">
                                    <svg
                                        className="fill-current text-red-600 group-hover:text-white h-5 w-5 transition-all duration-500"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
                                        <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
                                        <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
                                    </svg>
                                </div>
                            </Popconfirm>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}