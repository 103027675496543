import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/images/log-white-logo.svg";
import { ReactComponent as LogoShort } from "../../../assets/images/logo-short.svg";
import { Tooltip } from "antd";
import { KnowledgeHubLink, CareerQuizLink, CareerPostsLink, UsersConfigLink, PingPongLink } from "./Links";
import { useClickOutside } from "@mantine/hooks";
import {DOMAIN, STORAGE_DOMAIN} from "../../../env";

function Sidebar({sidebarOpen,setSidebarOpen,context,sidebarCollapse,setSidebarCollapse}) {
	const trigger = useRef(null);
	const location = useLocation();

	const sidebar = useClickOutside(() => setSidebarOpen(false));

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
			if (!sidebarOpen || keyCode !== 27) return;
			setSidebarOpen(false);
		};
		document.addEventListener("keydown", keyHandler);
		return () => document.removeEventListener("keydown", keyHandler);
	});

	return (
		<div>
			{/* Sidebar backdrop (mobile only) */}
			<div
				className={`fixed inset-0 z-40 bg-grey-1 bg-opacity-30 transition-opacity duration-200 lg:z-auto lg:hidden ${
					sidebarOpen ? "opacity-100" : "pointer-events-none opacity-0"
				}`}
				aria-hidden="true"
			></div>

			{/* Sidebar */}
			<div
				id="sidebar"
				ref={sidebar}
				className={`no-scrollbar absolute left-0 top-0 z-40 flex h-screen transform flex-col overflow-y-scroll lg:static lg:left-auto lg:top-auto lg:translate-x-0 lg:overflow-y-auto ${
					sidebarCollapse ? "w-[5.5rem] lg:w-20" : "w-64 lg:w-56"
				} flex-shrink-0 bg-grey-1 p-4 transition-all duration-500 ${
					sidebarOpen ? "translate-x-0" : "-translate-x-64"
				}`}
			>
				{/* Sidebar header */}
				<div className="relative mb-5 flex justify-center gap-x-5 px-2">
					{/* Close button */}
					<button
						ref={trigger}
						className="flex w-full justify-center text-gray-500 hover:text-gray-400 lg:hidden"
						onClick={() => setSidebarOpen(!sidebarOpen)}
						aria-controls="sidebar"
						aria-expanded={sidebarOpen}
					>
						<span className="sr-only">Close sidebar</span>
						<svg
							viewBox="0 0 24 24"
							width="30"
							height="30"
							className={`fill-current text-green-11`}
						>
							<path d="M10.957,12.354a.5.5,0,0,1,0-.708l4.586-4.585a1.5,1.5,0,0,0-2.121-2.122L8.836,9.525a3.505,3.505,0,0,0,0,4.95l4.586,4.586a1.5,1.5,0,0,0,2.121-2.122Z" />
						</svg>
					</button>
					{/* Logo */}
					<div className=" hidden overflow-hidden lg:flex">
						{sidebarCollapse ? (
							<Link to="/" onClick={() => setSidebarOpen(!sidebarOpen)}>
								<LogoShort className="h-9 w-8 cursor-pointer" />
							</Link>
						) : (
							<Link to="/" onClick={() => setSidebarOpen(!sidebarOpen)}>
								<Logo className="h-9 w-40 cursor-pointer" />
							</Link>
						)}
					</div>
				</div>

				{/* Links */}
				<div className="flex flex-col">
					<div className="flex flex-col gap-2">
						<Link
							to="/"
							onClick={() => setSidebarOpen(!sidebarOpen)}
							className={`relative flex items-center overflow-x-hidden rounded-lg p-3 ${
								location.pathname.length === 1 ? "bg-green-11" : "bg-grey-7"
							} cursor-pointer transition-all duration-500 hover:bg-green-11 hover:text-gray-50`}
						>
							<div className="ml-0.5 w-full">
								<svg
									viewBox="0 0 24 24"
									className="h-7 w-7 fill-current text-white lg:h-5 lg:w-5"
								>
									<path d="M23.121,9.069,15.536,1.483a5.008,5.008,0,0,0-7.072,0L.879,9.069A2.978,2.978,0,0,0,0,11.19v9.817a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V11.19A2.978,2.978,0,0,0,23.121,9.069ZM15,22.007H9V18.073a3,3,0,0,1,6,0Zm7-1a1,1,0,0,1-1,1H17V18.073a5,5,0,0,0-10,0v3.934H3a1,1,0,0,1-1-1V11.19a1.008,1.008,0,0,1,.293-.707L9.878,2.9a3.008,3.008,0,0,1,4.244,0l7.585,7.586A1.008,1.008,0,0,1,22,11.19Z" />
								</svg>
							</div>
							<div className=" absolute left-16 lg:left-12">
								<p
									className={`w-full truncate font-raleway text-lg font-bold text-green-50 lg:text-base`}
								>
									Home
								</p>
							</div>
						</Link>
						<KnowledgeHubLink
							setSidebarOpen={setSidebarOpen}
							sidebarOpen={sidebarOpen}
							userRole={context.user.user_role}
							permitedUser={["Marketing", "Admin", "Developer"]}
						/>
						{/*
						<AuditAppLink
							setSidebarOpen={setSidebarOpen}
							sidebarOpen={sidebarOpen}
							userRole={context.user.user_role}
							permitedUser={["Developer"]}
						/>
						*/}
						<CareerQuizLink
							setSidebarOpen={setSidebarOpen}
							sidebarOpen={sidebarOpen}
							userRole={context.user.user_role}
							permitedUser={[
								"Marketing",
								"HumanResources",
								"Admin",
								"Developer",
							]}
						/>
						<CareerPostsLink
							setSidebarOpen={setSidebarOpen}
							sidebarOpen={sidebarOpen}
							userRole={context.user.user_role}
							permitedUser={[
								"Marketing",
								"HumanResources",
								"Admin",
								"Developer",
							]}
						/>
						<UsersConfigLink
							setSidebarOpen={setSidebarOpen}
							sidebarOpen={sidebarOpen}
							userRole={context.user.user_role}
							permitedUser={["Admin", "HumanResources", "Developer"]}
						/>
						<PingPongLink
							setSidebarOpen={setSidebarOpen}
							sidebarOpen={sidebarOpen}
							userRole={context.user.user_role}
							permitedUser={["Admin", "HumanResources", "Developer", "User"]}
						/>
					</div>
				</div>
				<div className=" mt-auto pb-6 lg:pb-0">
					<div
						onClick={() => setSidebarCollapse(!sidebarCollapse)}
						className="group relative mb-2 flex h-full max-h-[44px] cursor-pointer items-center justify-end overflow-hidden rounded-lg bg-grey-7 transition-all duration-500"
					>
						<Link
							to={"/userProfile"}
							className={`absolute left-1 top-0 mr-auto flex h-full w-full items-center gap-2 overflow-hidden py-1 transition-all duration-500`}
						>
							<div className="h-full w-full max-w-[36px] overflow-hidden rounded-lg bg-green-11">
								{context?.user.user_image !== undefined ? (
									<img
										className=" h-9 w-9 min-w-[2.25rem]"
										src={`${STORAGE_DOMAIN+context?.user.user_image}`}
										alt="player-1"
										key={context?.user.user_image}
									/>
								) : (
									<div className="flex h-full w-full items-center justify-center bg-green-11">
										<p className=" font-poppins font-bold text-white">
											{context?.user.user_name.match(/\b(\w)/g).join(".")}
										</p>
									</div>
								)}
							</div>
							<div className="w-full pr-1">
								<p className="truncate font-poppins font-bold leading-4 text-white">
									{context?.user.user_name}
								</p>
								<p className="truncate font-montserrat text-xs text-white">
									{context?.user.user_role}
								</p>
							</div>
						</Link>
						<Tooltip title="Log out" placement="right">
							<div
								onClick={context?.logOut}
								className=" relative flex h-full w-12 items-center justify-center rounded-lg bg-grey-7 p-2 transition-all duration-500 hover:bg-orange-1"
							>
								<svg
									className={`h-5 w-5 transform fill-current text-white transition-all duration-500`}
									viewBox="0 0 24 24"
								>
									<path d="M11.476,15a1,1,0,0,0-1,1v3a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H7.476a3,3,0,0,1,3,3V8a1,1,0,0,0,2,0V5a5.006,5.006,0,0,0-5-5H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H7.476a5.006,5.006,0,0,0,5-5V16A1,1,0,0,0,11.476,15Z" />
									<path d="M22.867,9.879,18.281,5.293a1,1,0,1,0-1.414,1.414l4.262,4.263L6,11a1,1,0,0,0,0,2H6l15.188-.031-4.323,4.324a1,1,0,1,0,1.414,1.414l4.586-4.586A3,3,0,0,0,22.867,9.879Z" />
								</svg>
							</div>
						</Tooltip>
					</div>
					<div
						onClick={() => setSidebarCollapse(!sidebarCollapse)}
						className="group flex cursor-pointer items-center justify-center gap-3 rounded-lg bg-grey-7 p-2 transition-all duration-500 hover:bg-green-11"
					>
						<svg
							viewBox="0 0 24 24"
							className={`h-7 w-7 transform fill-current text-white transition-all duration-500 ${
								sidebarCollapse ? "rotate-180" : ""
							}`}
						>
							<path d="M10.957,12.354a.5.5,0,0,1,0-.708l4.586-4.585a1.5,1.5,0,0,0-2.121-2.122L8.836,9.525a3.505,3.505,0,0,0,0,4.95l4.586,4.586a1.5,1.5,0,0,0,2.121-2.122Z" />
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Sidebar;
