import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "antd";
import axios from "axios";
import { message } from "antd";
import TextareaAutosize from "react-textarea-autosize";
import {DOMAIN} from "../../../env";

const checkAuthorName = (authorName, setAuthorName, setFormError) => {
	if (authorName.state.length === 0) {
		setAuthorName({ ...authorName, error: "Field can't be empty" });
		setFormError(true);
	} else {
		setAuthorName({ ...authorName, error: "" });
		setFormError(false);
	}
};

const checkAuthorDescripion = (
	authorDescription,
	setAuthorDescription,
	setFormError
) => {
	if (authorDescription.state.length === 0) {
		setAuthorDescription({
			...authorDescription,
			error: "Field can't be empty",
		});
		setFormError(true);
	} else {
		setAuthorDescription({ ...authorDescription, error: "" });
		setFormError(false);
	}
};

export default function EditModal(props) {
	const [data, setData] = useState();
	const [authorName, setAuthorName] = useState({ state: "", error: "" });
	const [authorPosition, setAuthorPosition] = useState({
		state: "",
		error: "",
	});
	const [authorDescription, setAuthorDescription] = useState({
		state: "",
		error: "",
	});
	const [authorImage, setAuthorImage] = useState({
		state: "No file chosen",
		error: "",
		uploading: false,
		file: [],
		ImgSrc: [],
	});
	const [formError, setFormError] = useState(false);
	const fileRef = useRef(null);

	const handleChangeIMG = (event) => {
		const fileType = ["image/png", "image/jpg", "image/jpeg"];
		if (event.target.value.length !== 0)
			if (event.target.files[0].size > 10485760) {
				event.target.value = null;
				setAuthorImage({
					...authorImage,
					error: "Only files under 10MB",
					state: "No file chosen",
				});
				return;
			}
		if (!fileType.includes(event.target.files[0].type)) {
			event.target.value = null;
			setAuthorImage({
				...authorImage,
				error: "Incorrect file type, only: .png, .jpg, .jpeg",
				state: "No file chosen",
			});
		} else {
			var reader = new FileReader();
			// eslint-disable-next-line
			var url = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function () {
				setAuthorImage({
					...authorImage,
					state: event.target.files[0].name,
					file: event.target.files,
					error: "",
					ImgSrc: [reader.result],
				});
			};
		}
	};

	useEffect(() => {
		const fetchApiData = async () => {
			try {
				if (props.authorId)
					await axios
						.get(
							`${DOMAIN}/api/knowledgeHub/author/getById/${props.authorId}`,
							{
								headers: {
									"jwt-token": props.jwtToken,
								},
							}
						)
						.then((response) => {
							setData(response.data.data);
							setAuthorName({
								...authorName,
								state: response.data.data.authorName,
							});
							setAuthorDescription({
								...authorDescription,
								state: response.data.data.authorDescription,
							});
							setAuthorPosition({
								...authorPosition,
								state: response.data.data.authorPosition,
							});
						})
						.catch((error) => {
							throw error;
						});
			} catch (error) {
				message.error({
					content: `Author not found!`,
					className: "text-center",
					duration: 2,
				});
			}
		};
		fetchApiData();
		// eslint-disable-next-line
	}, [props.isEditAuthorModalVisible]);

	const handleCancelModal = () => {
		props.setIsEditAuthorModalVisible(false);
		setAuthorImage({
			state: "No file chosen",
			error: "",
			uploading: false,
			file: [],
			ImgSrc: [],
		});
	};

	const handleSubmit = async () => {
		if (!formError) {
			const formData = new FormData();
			if (authorImage) formData.append("image", authorImage.file[0]);
			formData.append("authorName", authorName.state);
			formData.append("authorDescription", authorDescription.state);
			formData.append("authorPosition", authorPosition.state);
			const loadingMessage = message.loading("Loading...", 0);
			await axios
				.post(
					`${DOMAIN}/api/knowledgeHub/author/edit/${props.authorId}`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
							"jwt-token": props.jwtToken,
						},
					}
				)
				.then((response) => {
					setAuthorImage({
						state: "No file chosen",
						error: "",
						uploading: false,
						file: [],
						ImgSrc: [],
					});
					message.success({
						content: `Author modifications Saved!`,
						className: "text-center",
						duration: 2,
					});
					props.setModified(!props.modified);
					props.setIsEditAuthorModalVisible(false);
				})
				.catch((error) => {
					message.error({
						content: `Network Error!`,
						className: "text-center",
						duration: 2,
					});
				})
				.finally(() => {
					loadingMessage();
				});
		} else {
			message.error(`Please check all the fields`);
		}
	};
	return (
		<>
			<Modal
				zIndex={1000}
				title={`Edit author ${props.authorId}`}
				open={props.isEditAuthorModalVisible}
				onCancel={() => handleCancelModal()}
				footer={[
					<Button key="back" onClick={() => handleCancelModal()}>
						Cancel
					</Button>,
					<Button key="submit" type="primary" onClick={() => handleSubmit()}>
						Confirm
					</Button>,
				]}
				width={900}
			>
				<div className="w-full grid grid-cols-1 md:grid-cols-2 px-5 gap-5">
					<div className="relative w-full">
						<input
							id="authorName"
							className={` peer h-12 font-raleway appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 ${
								authorName.error?.length !== 0
									? "border-orange-1 focus:ring-orange-1"
									: "hover:border-green-11"
							}`}
							placeholder="author name"
							value={authorName.state}
							onChange={(e) =>
								setAuthorName({ ...authorName, state: e.target.value })
							}
							onBlur={(e) =>
								checkAuthorName(authorName, setAuthorName, setFormError)
							}
						/>
						<label
							htmlFor="authorName"
							className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
						>
							Author Name
						</label>
						<div className="absolute">
							<p className="font-raleway text-sm text-orange-1 pt-1 m-0">
								{" "}
								{authorName.error}{" "}
							</p>
						</div>
					</div>
					<div className="relative w-full">
						<input
							id="authorPosition"
							className={`peer h-12 font-raleway appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 ${
								authorPosition.error?.length !== 0
									? "border-orange-1 focus:ring-orange-1"
									: "hover:border-green-11"
							}`}
							placeholder="Author Position"
							value={authorPosition.state}
							onChange={(e) =>
								setAuthorPosition({
									...authorPosition,
									state: e.target.value,
								})
							}
						/>
						<label
							htmlFor="authorPosition"
							className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
						>
							Author Position
						</label>
						<div className="absolute">
							<p className="font-raleway text-sm text-orange-1 pt-1 m-0">
								{" "}
								{authorPosition.error}{" "}
							</p>
						</div>
					</div>
					<div className="col-span-2 relative w-full flex flex-row gap-7">
						<div className="w-full flex flex-col gap-7">
							<div className=" relative">
								<TextareaAutosize
									id="authorDecription"
									placeholder="Author Description"
									className={`peer h-12 min-h-[3.2rem] max-h-[15rem] font-raleway appearance-none w-full py-3 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none placeholder-transparent focus:border-green-11 transition-colors duration-500 ${
										authorDescription.error?.length !== 0
											? "border-orange-1 focus:ring-orange-1"
											: "hover:border-green-11"
									}`}
									value={authorDescription.state}
									onChange={(e) =>
										setAuthorDescription({
											...authorDescription,
											state: e.target.value,
										})
									}
									onBlur={(e) =>
										checkAuthorDescripion(
											authorDescription,
											setAuthorDescription,
											setFormError
										)
									}
								/>
								<label
									htmlFor="authorDecription"
									className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.75rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
								>
									Author Decription
								</label>
								<div className="absolute">
									<p className="font-raleway text-sm text-orange-1 pt-1 m-0">
										{" "}
										{authorDescription.error}{" "}
									</p>
								</div>
							</div>
							<div className="relative w-full">
								<input
									ref={fileRef}
									onChange={handleChangeIMG}
									className="h-12 absolute cursor-pointer opacity-0 pointer-events-none hover:border-blue-500 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-blue-500"
									id="resume"
									type="file"
									accept="image/png, image/jpg,image/jpeg"
								/>
								<div
									onClick={() => fileRef.current.click()}
									className={`h-12 flex w-full items-center justify-between appearance-none group opacity-100 cursor-pointer`}
								>
									<div
										className={`${
											authorImage.error.length !== 0 ? "border-orange-1" : ""
										} border-l-2 border-y-2 rounded-l-xl text-lg w-full py-2 px-3 transition-all duration-500 group-hover:border-green-11`}
									>
										<p className="font-raleway text-grey-8">
											{authorImage.state}
										</p>
									</div>
									<div className="transform transition-all duration-300 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold">
										Featured image
									</div>
									<div
										className={`${
											authorImage.error.length !== 0
												? "group-hover:bg-orange-1 border-orange-1"
												: "group-hover:bg-green-11 border-green-11"
										} bg-white transition-all duration-500 border-2 h-full flex items-center justify-center px-10 rounded-r-xl`}
									>
										<svg
											viewBox="0 0 24 24"
											className={`${
												authorImage.error.length !== 0
													? "text-orange-1"
													: "text-green-11"
											} h-6 w-6 fill-current group-hover:text-white transition-all duration-500`}
										>
											<path d="M21,12h-3c-1.103,0-2,.897-2,2s-.897,2-2,2h-4c-1.103,0-2-.897-2-2s-.897-2-2-2H3c-1.654,0-3,1.346-3,3v4c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-4c0-1.654-1.346-3-3-3Zm1,7c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-4c0-.552,.448-1,1-1l3-.002v.002c0,2.206,1.794,4,4,4h4c2.206,0,4-1.794,4-4h3c.552,0,1,.448,1,1v4ZM7.293,5.293c-.391-.391-.391-1.023,0-1.414L10.586,.586C10.972,.2,11.479,.006,11.986,.003l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l3.293,3.293c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-2.293-2.293v7c0,.553-.447,1-1,1s-1-.447-1-1V3l-2.293,2.293c-.391,.391-1.023,.391-1.414,0Z" />
										</svg>
									</div>
								</div>
							</div>
						</div>
						<div className="w-32">
							<div
								onClick={() => fileRef.current.click()}
								className="w-32 h-32 rounded-full bg-green-11 shadow-lg overflow-hidden relative cursor-pointer"
							>
								{authorImage.ImgSrc.length === 0 ? (
									<img
										id="original"
										alt="original"
										src={`${data?.authorImage}`}
										className="w-full h-full object-fill z-0 relative"
									/>
								) : (
									<img
										id="new"
										alt="new"
										src={authorImage.ImgSrc}
										className="w-full h-full object-cover"
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}
