import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function EloquaAudit() {
	return (
		<>
			<Helmet>
				<title>Eloqua Audit</title>
			</Helmet>
			<div className="flex flex-col lg:flex-row justify-between items-center bg-grey-1 p-5">
				<p className=" font-bold font-poppins text-xl md:text-2xl text-white text-center lg:text-left mb-0">
					Eloqua Audit
				</p>
			</div>
			<div className="p-3">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5 pb-5 pt-2">
					<div className="w-full mx-auto relative">
						<div className="bg-grey-3 rounded-3xl shadow-lg py-4 px-5 flex flex-row justify-center items-center">
							<div className="">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="#ffffff"
									width="34"
									height="34"
									viewBox="0 0 24 24"
								>
									<path d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z" />
								</svg>
							</div>
							<input
								type="text"
								placeholder="Search by name"
								className={`h-10 appearance-none font-raleway bg-grey-13 rounded-lg w-full py-2 px-3 ml-5 text-gray-700 leading-tight focus:outline-none focus:border-transparent`}
							/>
						</div>
					</div>
					<div className="w-full mx-auto relative group cursor-pointer">
						<Link to="/eloqua-audit/add">
							<div className="bg-grey-3 rounded-3xl shadow-lg group-hover:bg-green-11 transition-all duration-500 py-4 px-5 flex flex-row justify-center items-center">
								<div className="">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="#ffffff"
										width="34"
										height="34"
										viewBox="0 0 24 24"
									>
										<path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" />
									</svg>
								</div>
								<p className="h-10 flex items-center text-white font-montserrat font-bold text-xl mx-auto">
									Add audit
								</p>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}

export default EloquaAudit;
