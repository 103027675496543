import { useState, useEffect } from "react";
import { Popover } from "antd";

export default function CaseStudyTimeline({ data, setData }) {
	const [timelineArray, setTimelineArray] = useState(data.content);

	const updateFieldChanged = (index, e) => {
		const name = e.target.name;
		let newArr = [...timelineArray];
		newArr[index][name] = e.target.value;
		setTimelineArray(newArr);
	};

	const removeByIndex = (index) => {
		let newArr = [...timelineArray];
		newArr.splice(index, 1);
		setTimelineArray(newArr);
	};

	useEffect(() => {
		setData({
			timeline: {
				show: data.show,
				content: timelineArray,
			},
		});
	}, [timelineArray]);

	const changeDate = (index) => {
		return (
			<div>
				<input
					placeholder="1990"
					name="title"
					className="peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none"
					onChange={(e) => updateFieldChanged(index, e)}
					value={timelineArray[index].title}
				/>
			</div>
		);
	};

	const changeDescription = (index) => {
		return (
			<div>
				<textarea
					placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
					name="description"
					className="peer min-h-[10rem] w-full min-w-[30rem] appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none"
					onChange={(e) => updateFieldChanged(index, e)}
					value={timelineArray[index].description}
				/>
			</div>
		);
	};

	return (
		<div>
			{data.show ? (
				<div>
					<div className="flex w-full flex-col">
						<div className="flex w-full md:justify-center">
							<div
								onClick={() => {
									setData({
										timeline: {
											show: false,
											content: [],
										},
									});
								}}
								className=" group relative flex h-12 w-44 cursor-pointer items-center justify-center overflow-hidden rounded-xl bg-green-11 text-center text-white shadow-lg transition-all duration-500 hover:bg-red-600"
							>
								<p className="absolute m-0 -translate-y-9 font-poppins text-2xl font-bold uppercase transition-all duration-500 group-hover:translate-y-0">
									Remove
								</p>
								<p className="absolute m-0 font-poppins text-2xl font-bold uppercase transition-all duration-500 group-hover:translate-y-9">
									Timeline
								</p>
							</div>
						</div>
						<div className="group relative flex w-full justify-start md:justify-center">
							<div className="flex h-10 w-6 items-center justify-center">
								<div className="pointer-events-none h-full w-0.5 bg-stone-200"></div>
							</div>
						</div>
						{timelineArray.map((elem, index) => {
							if (index % 2 === 0) {
								return (
									<div
										key={index}
										className="group relative flex w-full md:justify-center"
									>
										<div
											onClick={() => removeByIndex(index)}
											className=" group/button pointer-events-none absolute top-[7px] right-0 z-10 mx-auto mr-0.5 h-8 w-8 -translate-y-5 cursor-pointer justify-self-center rounded-full border-2 border-red-600 bg-white p-1 opacity-0  shadow-lg transition-all duration-300 hover:bg-red-600 group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100 md:right-auto md:-top-3"
										>
											<svg
												viewBox="0 0 24 24"
												className="h-full w-full fill-current text-red-600 transition-all duration-300 group-hover/button:text-white"
											>
												<path d="M14.121,12,18,8.117A1.5,1.5,0,0,0,15.883,6L12,9.879,8.11,5.988A1.5,1.5,0,1,0,5.988,8.11L9.879,12,6,15.882A1.5,1.5,0,1,0,8.118,18L12,14.121,15.878,18A1.5,1.5,0,0,0,18,15.878Z" />
											</svg>
										</div>
										<div className="ml-auto hidden w-full pr-4 md:block">
											<Popover
												content={() => changeDescription(index)}
												title="Description"
												trigger="click"
												placement="topRight"
												className=" cursor-pointer"
											>
												<div className="my-4 ml-auto block w-fit max-w-lg transform overflow-hidden rounded-xl border-2 border-stone-200 bg-stone-100 p-4 shadow-lg">
													<div
														dangerouslySetInnerHTML={{
															__html:
																elem.description ||
																"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
														}}
														className="text-left font-raleway text-base leading-6 md:text-right"
													></div>
												</div>
											</Popover>
										</div>
										<div className="mr-2 flex justify-center md:mr-0 md:items-center">
											<div className="flex h-full w-6 items-center justify-center">
												<div className="pointer-events-none h-full w-0.5 bg-stone-200"></div>
											</div>
											<div className="absolute mt-[13px] h-5 w-5 transform justify-self-center rounded-full bg-green-11 shadow md:mt-0"></div>
										</div>
										<div className="hidden w-full items-center pl-4 md:flex">
											<Popover
												content={() => changeDate(index)}
												title="Date"
												trigger="click"
												placement="topLeft"
												className=" cursor-pointer"
											>
												<p className="transform font-poppins text-3xl font-bold tabular-nums text-grey-1">
													{elem.title || "1990"}
												</p>
											</Popover>
										</div>
										<div className="block md:hidden">
											<div className="flex flex-col">
												<div className="flex items-center">
													<p className="font-poppins text-4xl font-bold text-grey-1">
														{elem.title || "1990"}
													</p>
												</div>
												<div className="my-4 mr-auto rounded-lg border-2 border-stone-200 bg-stone-100 p-4 shadow-lg">
													<p className="text-left font-raleway text-base leading-6 md:text-right">
														{elem.description ||
															"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
													</p>
												</div>
											</div>
										</div>
									</div>
								);
							} else
								return (
									<div
										key={index}
										className="group relative flex w-full md:justify-center"
									>
										<div
											onClick={() => removeByIndex(index)}
											className=" group/button pointer-events-none absolute top-[7px] right-0 z-10 mx-auto mr-0.5 h-8 w-8 -translate-y-5 cursor-pointer justify-self-center rounded-full border-2 border-red-600 bg-white p-1 opacity-0 shadow-lg transition-all duration-300 hover:bg-red-600 group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100 md:right-auto md:-top-3"
										>
											<svg
												viewBox="0 0 24 24"
												className="h-full w-full fill-current text-red-600 transition-all duration-300 group-hover/button:text-white"
											>
												<path d="M14.121,12,18,8.117A1.5,1.5,0,0,0,15.883,6L12,9.879,8.11,5.988A1.5,1.5,0,1,0,5.988,8.11L9.879,12,6,15.882A1.5,1.5,0,1,0,8.118,18L12,14.121,15.878,18A1.5,1.5,0,0,0,18,15.878Z" />
											</svg>
										</div>
										<div className="my-auto ml-auto hidden w-full justify-end pr-4 md:flex">
											<Popover
												content={() => changeDate(index)}
												title="Date"
												trigger="click"
												placement="topRight"
												className=" cursor-pointer"
											>
												<p className="transform text-right font-poppins text-3xl font-bold text-grey-1">
													{elem.title || "1990"}
												</p>
											</Popover>
										</div>
										<div className="mr-2 flex justify-center md:mr-0 md:items-center">
											<div className="flex h-full w-6 items-center justify-center">
												<div className="pointer-events-none h-full w-0.5 bg-stone-200"></div>
											</div>
											<div className="absolute mt-[13px] h-5 w-5 transform justify-self-center rounded-full bg-green-11 shadow md:mt-0"></div>
										</div>
										<div className="hidden w-full items-center pl-4 md:block">
											<Popover
												content={() => changeDescription(index)}
												title="Description"
												trigger="click"
												placement="topLeft"
												className=" cursor-pointer"
											>
												<div className="my-4 mr-auto block w-fit max-w-lg transform rounded-xl border-2 border-stone-200 bg-stone-100 p-4 shadow-lg">
													<div 
														dangerouslySetInnerHTML={{ __html:
															elem.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
														}} 
														className="text-left font-raleway text-base leading-6"
													></div>
												</div>
											</Popover>
										</div>
										<div className="block md:hidden">
											<div className="flex w-full flex-col">
												<div className="flex items-center">
													<p className="font-poppins text-4xl font-bold text-grey-1">
														{elem.title || "1990"}
													</p>
												</div>
												<div className="my-4 mr-auto rounded-lg border-2 border-stone-200 bg-stone-100 p-4 shadow-lg">
													<p className="text-left font-raleway text-base leading-6  md:text-right">
														{elem.description ||
															"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
													</p>
												</div>
											</div>
										</div>
									</div>
								);
						})}
					</div>
					<div
						onClick={() =>
							setTimelineArray([
								...timelineArray,
								{
									title: "",
									description: "",
									icon: "",
								},
							])
						}
						className="flex md:justify-center"
					>
						<div className="group relative flex cursor-pointer flex-row gap-2 rounded-lg border-2 border-stone-300 bg-stone-100 px-4 py-2 shadow-md transition-all duration-300 hover:border-green-11 hover:bg-white md:hover:px-10">
							<p className="font-poppins font-bold uppercase text-stone-300 transition-all duration-300 group-hover:text-grey-1">
								New Element
							</p>
						</div>
					</div>
				</div>
			) : (
				<div className=" relative flex w-full items-center justify-center">
					<div
						onClick={() =>
							setData({
								timeline: {
									show: true,
									content: [],
								},
							})
						}
						className="group relative flex cursor-pointer flex-row gap-2 rounded-full border-2 border-stone-300 bg-stone-100 px-4 py-2 shadow-md transition-all duration-300 hover:border-green-11 hover:bg-white hover:px-10"
					>
						<p className="font-poppins font-bold uppercase text-stone-300 transition-all duration-300 group-hover:text-grey-1">
							Add timeline
						</p>
					</div>
				</div>
			)}
		</div>
	);
}
