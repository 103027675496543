import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserContext from "../context/context";
import { Helmet } from "react-helmet";
import { DOMAIN, STORAGE_DOMAIN } from "../env";
import axios from "axios";

function Home() {
  const user = useContext(UserContext);
  const [recentlyAccessed, setRecentlyAccessed] = useState([]);

  useEffect(() => {
    setRecentlyAccessed(
      JSON.parse(localStorage.getItem("RecentlyAccessed") || "[]")
    );
  }, []);

  const showRecentlyAccessed = () => {
    if (recentlyAccessed !== null) {
      const recentlyAccessedArray = recentlyAccessed;
      // eslint-disable-next-line
      return recentlyAccessedArray.map((element, index) => {
        if (
          element.type === "expertArticle" ||
          element.type === "caseStudy" ||
          element.type === "infographic"
        )
          return (
            <Link
              key={index}
              to={`/knowledge-hub/${element.data.category}/edit/${element.data._id}`}
            >
              <div
                className={`
                                        ${
                                          element.data.category === "caseStudy"
                                            ? "bg-gradient-to-b from-orange-2 to-orange-3"
                                            : null
                                        }
                                        ${
                                          element.data.category ===
                                          "expertArticle"
                                            ? "bg-green-11"
                                            : null
                                        }
                                        ${
                                          element.data.category ===
                                          "infographic"
                                            ? "bg-gradient-to-b from-grey-4 via-grey-22 to-grey-22"
                                            : null
                                        } 
                                        relative top-0 mx-auto h-36 w-full transform overflow-hidden rounded-2xl shadow-lg transition-all duration-500 hover:-top-3 md:w-64
                                    `}
              >
                <div className=" absolute h-full w-full">
                  <img
                    alt="Featured"
                    key={element.data.imageUrl}
                    src={STORAGE_DOMAIN + element.data.imageUrl}
                    className="h-full w-full object-contain md:object-cover"
                  />
                </div>
                <div className=" absolute z-10 h-full w-full bg-stone-800 opacity-50"></div>
                <div className="relative z-20 flex h-full w-full items-center justify-center px-4 py-3">
                  <p className="relative mt-auto line-clamp-2 h-14 font-montserrat text-lg font-bold text-white">
                    {element.data.title}
                  </p>
                </div>
              </div>
            </Link>
          );
        if (element.type === "trialService")
          return (
            <Link
              key={index}
              to={`/knowledge-hub/${element.data.category}/edit/${element.data._id}`}
            >
              <div
                style={{
                  backgroundImage: `url(${element.data.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className={`relative top-0 mx-auto h-36 w-64 transform overflow-hidden rounded-2xl shadow-lg transition-all duration-500 hover:-top-3`}
              >
                <div className=" absolute z-10 h-full w-full bg-stone-800 opacity-50"></div>
                <div className="relative z-20 flex h-full w-full items-center justify-center px-4 py-3">
                  <p className="relative mt-auto line-clamp-2 h-14 font-montserrat text-lg font-bold text-white">
                    {element.data.title}
                  </p>
                </div>
              </div>
            </Link>
          );
        if (element.type === "careerPost")
          return (
            <Link key={index} to={`/careerPosts/edit/${element.data._id}`}>
              <div
                className={`h-36 w-64 cursor-pointer rounded-2xl border-2 border-stone-200 bg-grey-4 px-4 py-3 shadow-lg ${
                  element.data.published ? "border-green-11" : "border-grey-4"
                } relative top-0 transform transition-all duration-500 hover:-top-3`}
              >
                <div className=" absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Outline"
                    viewBox="0 0 24 24"
                    className=" h-10 w-10 fill-current text-grey-1"
                  >
                    <path d="M19,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H5A5.006,5.006,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A5.006,5.006,0,0,0,19,4ZM11,2h2a3,3,0,0,1,2.816,2H8.184A3,3,0,0,1,11,2ZM5,6H19a3,3,0,0,1,3,3v3H2V9A3,3,0,0,1,5,6ZM19,22H5a3,3,0,0,1-3-3V14h9v1a1,1,0,0,0,2,0V14h9v5A3,3,0,0,1,19,22Z" />
                  </svg>
                </div>
                <div className="relative flex h-full flex-col justify-center gap-5">
                  <p className="mt-auto line-clamp-2 h-14 text-left font-poppins text-xl font-bold text-grey-1">
                    {element.data.jobTitle}
                  </p>
                </div>
              </div>
            </Link>
          );
        if (element.type === "jobPage")
          return (
            <Link key={index} to={`/jobPage/${element.data._id}`}>
              <div
                className={`h-60 w-60 cursor-pointer rounded-2xl border-4 bg-grey-4 p-9 shadow-lg ${
                  element.data.published ? "border-green-11" : "border-grey-4"
                } relative top-0 transform transition-all duration-500 hover:-top-3`}
              >
                <div className="flex h-full flex-col justify-center gap-8">
                  <p className="text-center font-poppins text-2xl font-bold leading-6 text-grey-1">
                    {element.data.jobTitle}
                  </p>
                  <p className="text-center font-poppins text-lg font-bold text-grey-1">
                    View Applications
                  </p>
                </div>
              </div>
            </Link>
          );
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Logarithmic Dashboard</title>
      </Helmet>
      <div className="flex flex-col items-center justify-between bg-grey-1 p-5 lg:flex-row">
        <p className=" mb-0 text-center font-poppins text-xl font-bold text-white md:text-2xl lg:text-left">
          Logarithmic Dashboard
        </p>
      </div>
      <div className=" flex flex-col">
        <div className="relative w-full">
          <p className=" mb-0 p-5 pb-0 text-center font-poppins text-2xl font-bold text-grey-1 lg:text-left">
            Quick Actions
          </p>
          <div className="scrollBarHome  flex w-full flex-col items-center gap-5 overflow-x-auto p-5 md:flex-row">
            {[
              "Developer",
              "HumanResources",
              "Admin",
              "Marketing",
            ].includes(user.user.user_role) && DOMAIN.includes(".stg.") && (
				<div className="h-full w-full">
              <div
                onClick={() => {
                  let data = JSON.stringify({
                    ref: "main",
                    inputs: {
                      source_environment: "stg",
                      source_environment_aws_account_id: "471112502674",
		      source_region: "eu-central-1",
                      target_environment: "prod",
                      target_environment_aws_account_id: "339712754542",
		      target_region: "us-east-1"
                    },
                  });

                  let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: "https://api.github.com/repos/logdms/logdms-backend/actions/workflows/environments-sync.yaml/dispatches",
                    headers: {
                      Authorization:
                        "Bearer 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",
                      "Content-Type": "application/json",
                    },
                    data: data,
                  };

                  axios
                    .request(config)
                    .then((response) => {
                      alert("Sync started.")
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
                className="mb-auto flex h-36 w-full transform cursor-pointer flex-col items-center justify-center gap-4 rounded-2xl bg-green-11 p-6 shadow-lg transition duration-500 hover:scale-105 md:w-64"
              >
                <div>
                  <svg
                    className="fill-current text-white"
                    width="40"
                    height="40"
                    viewBox="0 0 20 20"
                  >
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#ffffff"
                      d="M19.854 8.646c-0.195-0.195-0.512-0.195-0.707 0l-1.149 1.149c-0.051-2.060-0.878-3.99-2.341-5.452-1.511-1.511-3.52-2.343-5.657-2.343-2.974 0-5.686 1.635-7.077 4.266-0.129 0.244-0.036 0.547 0.208 0.676s0.547 0.036 0.676-0.208c1.217-2.303 3.59-3.734 6.193-3.734 3.789 0 6.885 3.027 6.997 6.789l-1.143-1.143c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l2 2c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l2-2c0.195-0.195 0.195-0.512 0-0.707z"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#ffffff"
                      d="M16.869 13.058c-0.244-0.129-0.547-0.036-0.676 0.208-1.217 2.303-3.59 3.734-6.193 3.734-3.789 0-6.885-3.027-6.997-6.789l1.143 1.143c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707l-2-2c-0.195-0.195-0.512-0.195-0.707 0l-2 2c-0.195 0.195-0.195 0.512 0 0.707s0.512 0.195 0.707 0l1.149-1.149c0.051 2.060 0.878 3.99 2.341 5.452 1.511 1.511 3.52 2.343 5.657 2.343 2.974 0 5.686-1.635 7.077-4.266 0.129-0.244 0.036-0.547-0.208-0.676z"
                    />
                  </svg>
                </div>
                <p className=" font-poppins text-2xl font-bold text-white">
                  Sync Staging to Production
                </p>
              </div>
			  </div>
            )}
            {[
              "User",
              "Developer",
              "HumanResources",
              "Admin",
              "Marketing",
            ].includes(user.user.user_role) && (
              <Link to={`/ping-pong`} className="h-full w-full">
                <div className="mb-auto flex h-36 w-full transform cursor-pointer flex-col items-center justify-center gap-4 rounded-2xl bg-green-11 p-6 shadow-lg transition duration-500 hover:scale-105 md:w-64">
                  <div>
                    <svg
                      className="fill-current text-white"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21.261,2.739A9.836,9.836,0,0,0,8.3,2.114,4.489,4.489,0,1,0,4.134,8.963a9.415,9.415,0,0,0,.842,5.668.5.5,0,0,1-.07.564L2,16.945A3.743,3.743,0,0,0,3.735,24a3.891,3.891,0,0,0,.457-.027,3.705,3.705,0,0,0,2.725-1.735l2.068-3.127a.5.5,0,0,1,.575-.089,9.663,9.663,0,0,0,11.315-2.147A10.5,10.5,0,0,0,24,9.758,9.409,9.409,0,0,0,21.261,2.739ZM2,4.5A2.5,2.5,0,1,1,4.5,7,2.5,2.5,0,0,1,2,4.5Zm8.44,12.726a2.494,2.494,0,0,0-3.017.632c-.024.029-.046.059-.067.09L5.229,21.166A1.742,1.742,0,0,1,2.02,20a1.76,1.76,0,0,1,.961-1.312l3.041-1.831a.956.956,0,0,0,.126-.09,2.49,2.49,0,0,0,.623-3.016,7.331,7.331,0,0,1-.693-4.259l8.433,8.433A7.31,7.31,0,0,1,10.44,17.226Zm9.021-1.765a8.871,8.871,0,0,1-2.732,1.865c-.009-.01-.012-.023-.022-.033L7.36,7.945A4.473,4.473,0,0,0,9,4.5c0-.119-.026-.231-.035-.347a8.01,8.01,0,0,1,10.882,0A7.423,7.423,0,0,1,22,9.7,8.506,8.506,0,0,1,19.461,15.461Z" />
                    </svg>
                  </div>
                  <p className=" font-poppins text-2xl font-bold text-white">
                    Ping Pong
                  </p>
                </div>
              </Link>
            )}
            {["Developer", "HumanResources", "Admin"].includes(
              user.user.user_role
            ) && (
              <Link to={`/users`} className="h-full w-full">
                <div className="mb-auto flex h-36 w-full transform cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl bg-green-11 p-6 shadow-lg transition duration-500 hover:scale-105 md:w-64">
                  <div>
                    <svg
                      className="fill-current text-white"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22.853,1.148a3.626,3.626,0,0,0-5.124,0L1.465,17.412A4.968,4.968,0,0,0,0,20.947V23a1,1,0,0,0,1,1H3.053a4.966,4.966,0,0,0,3.535-1.464L22.853,6.271A3.626,3.626,0,0,0,22.853,1.148ZM5.174,21.122A3.022,3.022,0,0,1,3.053,22H2V20.947a2.98,2.98,0,0,1,.879-2.121L15.222,6.483l2.3,2.3ZM21.438,4.857,18.932,7.364l-2.3-2.295,2.507-2.507a1.623,1.623,0,1,1,2.295,2.3Z" />
                    </svg>
                  </div>
                  <p className=" font-poppins text-2xl font-bold text-white">
                    Edit User
                  </p>
                </div>
              </Link>
            )}
            {["Developer", "HumanResources", "Admin"].includes(
              user.user.user_role
            ) && (
              <Link to={`/users?modal=true`} className="h-full w-full">
                <div className="mb-auto flex h-36 w-full transform cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl bg-green-11 p-6 shadow-lg transition duration-500 hover:scale-105 md:w-64">
                  <div>
                    <svg
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      xmlSpace="preserve"
                      className="fill-current text-white"
                      width="40"
                      height="40"
                    >
                      <g>
                        <path d="M480,224H288V32c0-17.673-14.327-32-32-32s-32,14.327-32,32v192H32c-17.673,0-32,14.327-32,32s14.327,32,32,32h192v192   c0,17.673,14.327,32,32,32s32-14.327,32-32V288h192c17.673,0,32-14.327,32-32S497.673,224,480,224z" />
                      </g>
                    </svg>
                  </div>
                  <p className=" font-poppins text-2xl font-bold text-white">
                    Add User
                  </p>
                </div>
              </Link>
            )}
            {["Developer", "HumanResources", "Admin"].includes(
              user.user.user_role
            ) && (
              <Link to={`/careers`} className="h-full w-full">
                <div className="mb-auto flex h-36 w-full transform cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl bg-green-11 p-6 shadow-lg transition duration-500 hover:scale-105 md:w-64">
                  <div>
                    <svg
                      className="fill-current text-white"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H5A5.006,5.006,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A5.006,5.006,0,0,0,19,4ZM11,2h2a3,3,0,0,1,2.816,2H8.184A3,3,0,0,1,11,2ZM5,6H19a3,3,0,0,1,3,3v3H2V9A3,3,0,0,1,5,6ZM19,22H5a3,3,0,0,1-3-3V14h9v1a1,1,0,0,0,2,0V14h9v5A3,3,0,0,1,19,22Z" />
                    </svg>
                  </div>
                  <p className=" font-poppins text-2xl font-bold text-white">
                    Career Posts
                  </p>
                </div>
              </Link>
            )}
            {["Developer", "Marketing", "Admin"].includes(
              user.user.user_role
            ) && (
              <Link to={`/knowledge-hub`} className="h-full w-full">
                <div className="mb-auto flex h-36 w-full transform cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl bg-green-11 p-6 shadow-lg transition duration-500 hover:scale-105 md:w-64">
                  <div>
                    <svg
                      className="fill-current text-white"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                    >
                      <path d="m17 14a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-4 3h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-6.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z" />
                    </svg>
                  </div>
                  <p className=" font-poppins text-2xl font-bold text-white">
                    Knowledge Hub
                  </p>
                </div>
              </Link>
            )}
            {["Developer", "Marketing", "Admin"].includes(
              user.user.user_role
            ) && (
              <Link
                to={`/knowledge-hub/expertArticle/add`}
                className="h-full w-full"
              >
                <div className="mb-auto flex h-36 w-full transform cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl bg-green-11 p-6 shadow-lg transition duration-500 hover:scale-105 md:w-64">
                  <div>
                    <svg
                      viewBox="0 0 24 24"
                      className="fill-current text-white"
                      width="40"
                      height="40"
                    >
                      <path d="m16 16a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1zm6-5.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z" />
                    </svg>
                  </div>
                  <p className=" font-poppins text-2xl font-bold text-white">
                    Expert Article
                  </p>
                </div>
              </Link>
            )}
            {["Developer", "Marketing", "Admin"].includes(
              user.user.user_role
            ) && (
              <Link
                to={`/knowledge-hub/caseStudy/add`}
                className="h-full w-full"
              >
                <div className="mb-auto flex h-36 w-full transform cursor-pointer flex-col items-center justify-center gap-4 rounded-3xl bg-gradient-to-b from-orange-2 to-orange-3 p-6 shadow-lg transition duration-500 hover:scale-105 md:w-64">
                  <div>
                    <svg
                      viewBox="0 0 24 24"
                      className="fill-current text-white"
                      width="40"
                      height="40"
                    >
                      <path d="m16 16a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1zm6-5.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z" />
                    </svg>
                  </div>
                  <p className=" font-poppins text-2xl font-bold text-white">
                    Case Study
                  </p>
                </div>
              </Link>
            )}
          </div>
        </div>
        <div className=" w-full">
          <div className="flex flex-col items-center justify-between gap-5 px-5 pt-5 md:flex-row">
            <p className=" mb-0 pb-0 text-center font-poppins text-2xl font-bold text-grey-1 lg:text-left">
              Recently Accessed
            </p>
            <div
              className={`${
                recentlyAccessed.length === 0 ? "hidden" : "block"
              } cursor-pointer rounded-xl border-2 border-red-600 bg-white px-3 py-1 text-grey-1 shadow-md transition-all duration-500 hover:bg-red-600 hover:text-white`}
              onClick={() => {
                localStorage.setItem("RecentlyAccessed", JSON.stringify([]));
                setRecentlyAccessed([]);
              }}
            >
              <p className="font-poppins font-bold">Clear List</p>
            </div>
          </div>
          <div
            id="scrollBarHome"
            className="scrollBarHome flex w-full flex-col items-center gap-10 overflow-x-auto p-5 md:flex-row"
          >
            <div className="relative flex flex-col items-center gap-5 md:flex-row">
              {showRecentlyAccessed()}
            </div>
          </div>
        </div>
        <div className=" h-10"></div>
      </div>
    </>
  );
}

export default Home;
