import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//*Components
import Sidebar from "../components/MainComponents/SideBar/Sider";
import Header from "../components/MainComponents/Header";
import UserContext from "../context/context";

import Home from "../pages/Home";

//*KnowledgeHub : Start
import PostsList from "../pages/KnowledgeHub/PostsList";
//*Infographics: Start
import AddInfographic from "../pages/KnowledgeHub/Infographics/add";
import EditInfographic from "../pages/KnowledgeHub/Infographics/edit";
//*Infographics: End
//*ExpertArticle: Start
import AddExpertArticle from "../pages/KnowledgeHub/ExpertArticle/add";
import EditExpertArticle from "../pages/KnowledgeHub/ExpertArticle/edit";
//*ExpertArticle: End
//*CaseStudy: Start
import AddCaseStudy from "../pages/KnowledgeHub/CaseStudy/add";
import EditCaseStudy from "../pages/KnowledgeHub/CaseStudy/edit";
import NewAddCaseStudy from "./KnowledgeHub/NewCaseStudy/add";
import NewEditCaseStudy from "./KnowledgeHub/NewCaseStudy/edit";
//*CaseStudy: End
//*TrialService: Start
import AddTrialService from "../pages/KnowledgeHub/TrialServices/add";
import EditTrialService from "../pages/KnowledgeHub/TrialServices/edit";
//*TrialService: End
//*KnowledgeHub : End

//* Eloqua Audit Pages:
import EloquaAudit from "../pages/EloquaAudit/EloquaAudit";
import EloquaAuditAdd from "../pages/EloquaAudit/EloquaAuditAdd";
import EloquaAuditAddSection from "../pages/EloquaAudit/EloquaAuditAddSection";

//* Career Post Pages :
import CareerPosts from "../pages/CareerPages/Posts";
import EditCareerPosts from "../pages/CareerPages/Edit";
import EditCareerPostsOrder from "../pages/CareerPages/Order";

//* Career Quiz Pages :
import CareerQuiz from "../pages/CareerQuiz/CareerQuiz";
import QuesitonBank from "../pages/CareerQuiz/QuesitonBank";
import QuestionBankSubmissions from "../pages/CareerQuiz/QuestionBankSubmissions";
import QuestionBankQuestions from "../pages/CareerQuiz/QuestionBankQuestions";
import Quiz from "../pages/CareerQuiz/Quiz";

//* User Pages :
import AddUser from "./Users/Add";
import EditUsers from "./Users/Edit";
import Users from "./Users/List";
import UserProfile from "../pages/UserProfile/UserProfile";

//* PingPong Pages:
import PingPong from "./PingPong/pingpong";
import PingPongNewGame from "./PingPong/pingpong_newgame";
import PingPongGame from "./PingPong/pingpong_game";

export default function Main({ context }) {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [sidebarCollapse, setSidebarCollapse] = useState(true);

	return (
		<>
			<Router>
				<div className="flex h-screen w-full overflow-hidden">
					{/* Sidebar */}
					<Sidebar
						context={context}
						sidebarOpen={sidebarOpen}
						setSidebarOpen={setSidebarOpen}
						sidebarCollapse={sidebarCollapse}
						setSidebarCollapse={setSidebarCollapse}
					/>

					{/* Content area */}
					<div className="relative flex w-full flex-1 flex-col overflow-x-hidden overflow-y-scroll">
						{/*  Site header */}
						<UserContext.Provider value={context}>
							<Header
								context={context}
								sidebarOpen={sidebarOpen}
								setSidebarOpen={setSidebarOpen}
								sidebarCollapse={sidebarCollapse}
								setSidebarCollapse={setSidebarCollapse}
							/>
							<div className="h-full w-full">
								<Switch>
									<Route path="/careers/order" children={<EditCareerPostsOrder />}/>
									<Route path="/careers/:id" children={<EditCareerPosts />}/>
									<Route path="/careers" children={<CareerPosts />} />

									<Route path="/careerQuiz/questionBank/submissions/:id" children={<QuestionBankSubmissions />}/>
									<Route path="/careerQuiz/questionBank/questions/:id" children={<QuestionBankQuestions />}/>
									<Route path="/careerQuiz/quiz/:id" children={<Quiz />} />
									<Route path="/careerQuiz/questionBank/:id" children={<QuesitonBank />}/>
									<Route path="/careerQuiz" children={<CareerQuiz />} />

									<Route path="/users/add" children={<AddUser />} />
									<Route path="/users/edit/:id" children={<EditUsers />} />
									<Route path="/users" children={<Users />} />
									<Route path="/userProfile" children={<UserProfile />} />

									<Route path="/knowledge-hub/infographic/add" children={<AddInfographic />} />
									<Route path="/knowledge-hub/infographic/edit/:id" children={<EditInfographic />} />
									<Route path="/knowledge-hub/expertArticle/add" children={<AddExpertArticle />} />
									<Route path="/knowledge-hub/expertArticle/edit/:id" children={<EditExpertArticle />} />
									<Route path="/knowledge-hub/caseStudyNew/add" children={<NewAddCaseStudy />} />
									<Route path="/knowledge-hub/caseStudyNew/edit/:id" children={<NewEditCaseStudy />} />
									<Route path="/knowledge-hub/caseStudy/add" children={<AddCaseStudy />} />
									<Route path="/knowledge-hub/caseStudy/edit/:id" children={<EditCaseStudy />} />
									<Route path="/knowledge-hub/trialService/add" children={<AddTrialService />} />
									<Route path="/knowledge-hub/trialService/edit/:id" children={<EditTrialService />} />
									<Route path="/knowledge-hub" children={<PostsList />} />

									<Route path="/eloqua-audit/addSection/:id" children={<EloquaAuditAddSection />} />
									<Route path="/eloqua-audit/add" children={<EloquaAuditAdd />} />
									<Route path="/eloqua-audit" children={<EloquaAudit />} />

									<Route path="/ping-pong/new-match" children={<PingPongNewGame />} />
									<Route path="/ping-pong/:id" children={<PingPongGame />} />
									<Route path="/ping-pong" children={<PingPong />} />

									<Route path="/" children={<Home />} />
								</Switch>
							</div>
						</UserContext.Provider>
					</div>
				</div>
			</Router>
		</>
	);
}
