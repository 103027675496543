import React, { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {DOMAIN} from "../../env";

const CareerLayout = ({ children }) => {
	const { id } = useParams();
	const [data, setData] = useState([]);

	useEffect(() => {
		const fetchApiData = async () => {
			try {
				const response = await axios.get(
					`${DOMAIN}/api/careers/getAllJobPosts`
				);
				setData(response.data.jobPosts);
			} catch (error) {
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			}
		};
		fetchApiData();
	}, []);

	const renderCards = () => {
		if (data.length === 0)
			return new Array(10).fill("").map((placeholder, index) => {
				return (
					<div key={index} className="flex h-20 w-full items-center justify-center rounded-2xl border-2 p-4 shadow-lg">
						<div className="h-4 w-full animate-pulse rounded-full bg-grey-6"></div>
					</div>
				);
			});
		else
			return data
				.sort((a, b) => a.order - b.order)
				.map((post, index) => {
					return (
						<Link
							key={index}
							to={`/careers/${post._id}`}
							className={`${
								id === post._id
									? "border-green-11 bg-green-11"
									: "bg-grey-13 hover:border-green-11"
							} ${
								post.published ? "border-green-11" : ""
							} flex min-w-[15.5rem] items-center justify-center rounded-2xl border-2 p-4 shadow-lg transition-all duration-500 lg:justify-start`}
						>
							<p
								className={`${
									id === post._id ? "text-white" : "text-grey-1"
								} text-center font-poppins text-base font-bold line-clamp-3 lg:text-left lg:line-clamp-1`}
							>
								{post.jobTitle}
							</p>
						</Link>
					);
				});
	};

	return (
		<div className="flex w-full flex-col lg:flex-row">
			<div className="relative w-full lg:w-100">
				<div className="pointer-events-none absolute top-0 -left-4 z-10 hidden h-8 w-full bg-gradient-to-b from-white to-transparent lg:block"></div>
				<div className="pointer-events-none absolute -top-4 z-10 block h-full w-8 bg-gradient-to-r from-white to-transparent lg:hidden"></div>
				<div className="scrollBarHome relative flex max-h-screen w-full flex-row gap-4 overflow-x-auto py-5 px-4 lg:flex-col lg:overflow-y-scroll">
					{renderCards()}
				</div>
				<div className="pointer-events-none absolute bottom-0 -left-4 z-10 hidden h-8 w-full bg-gradient-to-t from-white to-transparent lg:block"></div>
				<div className="pointer-events-none absolute -top-4 right-0 z-10 block h-full w-8 bg-gradient-to-l from-white to-transparent lg:hidden"></div>
			</div>
			<div className="w-full">
				{children}
			</div>
		</div>
	);
};

export default CareerLayout;
