import { Popover } from "antd";
import LogLogo from "../../../images/Logos/log-white-logo.svg";
import Hero from "../../../images/Case-Study-Hero-Image.jpg";

export default function CaseStudyHero({ data, setData }) {
	const changeTitle = (
		<div>
			<input
				placeholder="Eloqua Privacy WorkflowRedesign in Under 2 Months"
				className="peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none"
				onChange={(e) =>
					setData((current) => ({
						hero: {
							title: e.target.value,
							description: current.hero.description,
						},
					}))
				}
				value={data.title}
			/>
		</div>
	);

	const changeDescription = (
		<div>
			<textarea
				placeholder="Improving our client's approach to <strong> E2E Eloqua digital campaign management </strong> through the introduction of systems aimed at <strong> reducing the cost and duration </strong> of campaign intake and delivery"
				className="peer h-32 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none md:min-w-[30rem]"
				onChange={(e) =>
					setData((current) => ({
						hero: { description: e.target.value, title: current.hero.title },
					}))
				}
				value={data.description}
			/>
		</div>
	);

	return (
		<div className="relative h-full">
			<img
				className="absolute h-full w-full rounded-3xl object-cover shadow-lg"
				alt="Hero"
				src={Hero}
			/>
			<div className=" absolute h-full w-full bg-stone-800 opacity-0"></div>
			<div className=" relative mx-auto max-w-[1300px] px-4">
				<div className="h-32"></div>
				<div className="max-w-[500px] lg:max-w-[700px]">
					<p className="font-poppins text-4xl font-extrabold text-white lg:text-5xl">
						Case Study
					</p>
					<Popover
						content={changeTitle}
						title="Title"
						trigger="click"
						placement="topLeft"
						className=" cursor-pointer"
					>
						<p className=" font-raleway text-3xl text-white lg:text-4xl">
							{data.title || (
								<span className="text-stone-400">
									"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do."
								</span>
							)}
						</p>
					</Popover>
				</div>
				<div className="h-52"></div>
				<div className="flex h-full flex-col gap-5 md:flex-row">
					<div className="ml-auto w-full max-w-[550px] border-green-11">
						<Popover
							content={changeDescription}
							title="Description"
							trigger="click"
							placement="topRight"
							className=" cursor-pointer"
						>
							{data.description ? (
								<div
									dangerouslySetInnerHTML={{ __html: data.description }}
									className="w-full bg-transparent text-left font-montserrat text-base leading-5 text-white md:text-right"
								></div>
							) : (
								<p className="text-left font-montserrat text-base leading-5 text-stone-400 md:text-right">
									Improving our client's approach to{" "}
									<strong> E2E Eloqua digital campaign management </strong>{" "}
									through the introduction of systems aimed at{" "}
									<strong> reducing the cost and duration </strong> of campaign
									intake and delivery
								</p>
							)}
						</Popover>
					</div>
					<div className="h-0.5 bg-green-11 md:h-auto md:w-0.5">&nbsp;</div>
					<img
						className="h-full w-full max-w-[250px] cursor-pointer md:self-center"
						src={LogLogo}
						alt="Logarithmic Solutions Logo"
					/>
				</div>
				<div className="h-14"></div>
			</div>
		</div>
	);
}
