import { useEffect, useContext } from "react";
import UserContext from "../../context/context";
import axios from "axios";
import { useState } from "react";
import {DOMAIN, STORAGE_DOMAIN} from "../../env";

function RenderOnePlayer({ player }) {
	return (
		<div className="flex flex-col items-center justify-center h-full">
			<div className="pb-2">
				<div
					className={`h-12 w-12 rounded-full shadow-lg overflow-hidden relative`}
				>
					{player.user_image !== undefined ? (
						<img
							className="h-12 w-12"
							src={`${STORAGE_DOMAIN+player.user_image}`}
							alt="player-1"
						/>
					) : (
						<div className="w-full h-full flex justify-center items-center bg-white">
							<p className=" text-grey-1 font-bold font-poppins text-lg">
								{player.user_name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
			</div>
			<p className="text-grey-1 text-2xl font-poppins font-bold max-w-[100px] whitespace-pre-wrap truncate my-auto">
				{player.user_name.split(" ")[0]}
			</p>
		</div>
	);
}

function RenderTwoPlayers({ player1, player2 }) {
	return (
		<div className="flex flex-col items-center justify-center">
			<div className="flex transform gap-2 justify-center pb-2">
				<div
					className={`h-12 w-12 rounded-full shadow-lg overflow-hidden transition-all duration-500`}
				>
					{player1.user_image !== undefined ? (
						<img
							className="h-12 w-12"
							src={`${STORAGE_DOMAIN+player1.user_image}`}
							alt="player-1"
						/>
					) : (
						<div className="w-full h-full flex justify-center items-center bg-white">
							<p className=" text-grey-1 font-bold font-poppins text-lg">
								{player1.user_name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
				<div
					className={`h-12 w-12 rounded-full shadow-lg overflow-hidden transform transition-all duration-500`}
				>
					{player2.user_image !== undefined ? (
						<img
							className="h-12 w-12"
							src={`${STORAGE_DOMAIN+player2.user_image}`}
							alt="player-2"
						/>
					) : (
						<div className="w-full h-full flex justify-center items-center bg-white">
							<p className=" text-grey-1 font-bold font-poppins text-lg">
								{player2.user_name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
			</div>
			<p className="text-grey-1 text-lg font-poppins font-bold max-w-[100px] whitespace-pre-wrap truncate text-center leading-5">
				{player1.user_name.split(" ")[0]} & {player2.user_name.split(" ")[0]}
			</p>
		</div>
	);
}

export default function IndividualGame({ data }) {
	const user = useContext(UserContext);

	const [team1, setTeam1] = useState(undefined);
	const [team2, setTeam2] = useState(undefined);

	useEffect(() => {
		axios
			.get(
				`${DOMAIN}/api/pingpong/getUsersNameAndImage/${data._id}`,
				{
					headers: {
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then((results) => {
				setTeam1(results.data.team1);
				setTeam2(results.data.team2);
			});
		// eslint-disable-next-line
	}, []);

	if (team1 === undefined || team2 === undefined)
		return (
			<div className="bg-grey-4 py-4 px-4 rounded-2xl shadow-lg border-2 border-grey-9 w-full md:max-w-[280px] h-40 flex justify-center">
				<div className="flex flex-row items-center w-full justify-between relative">
					<div className="flex flex-col gap-1 justify-center items-center h-full w-full relative z-10">
						<div className="flex flex-col items-center justify-center h-full">
							<div className="pb-2">
								<div
									className={`h-12 w-12 rounded-full shadow-lg overflow-hidden relative bg-grey-10 animate-pulse`}
								></div>
							</div>
							<div className="bg-grey-10 rounded-full w-full h-5 my-auto animate-pulse"></div>
						</div>
					</div>
					<div className=" absolute w-full flex justify-center items-center h-full top-0 left-0 z-0">
						<div className=" transform -rotate-12 opacity-30">
							<p className="text-orange-1 text-6xl font-poppins font-black">
								vs
							</p>
						</div>
					</div>
					<div className="flex flex-col gap-1 justify-center items-center h-full w-full relative z-10">
						<div className="flex flex-col items-center justify-center h-full">
							<div className="pb-2">
								<div
									className={`h-12 w-12 rounded-full shadow-lg overflow-hidden relative bg-grey-10 animate-pulse`}
								></div>
							</div>
							<div className="bg-grey-10 rounded-full w-full h-5 my-auto animate-pulse"></div>
						</div>
					</div>
				</div>
			</div>
		);

	return (
		<div className="bg-grey-4 py-4 px-4 rounded-2xl shadow-lg border-2 border-grey-9 w-full md:max-w-[300px] flex justify-center">
			<div className="flex flex-row items-center w-full justify-between relative">
				<div className="flex flex-col gap-1 justify-center items-center h-full w-full relative z-10">
					{parseInt(data.type) === 1 ? (
						<RenderOnePlayer player={team1.player1} />
					) : (
						<RenderTwoPlayers player1={team1.player1} player2={team1.player2} />
					)}
					<p
						className={`${
							data.team1.score > data.team2.score
								? "text-green-11"
								: "text-orange-1"
						} text-2xl font-poppins font-bold`}
					>
						{data.team1.score}
					</p>
				</div>
				<div className=" absolute w-full flex justify-center items-center h-full top-0 left-0 z-0">
					<div className=" transform -rotate-12 opacity-30">
						<p className="text-orange-1 text-6xl font-poppins font-black">vs</p>
					</div>
				</div>
				<div className="flex flex-col gap-1 justify-center items-center h-full w-full relative z-10">
					{parseInt(data.type) === 1 ? (
						<RenderOnePlayer player={team2.player1} />
					) : (
						<RenderTwoPlayers player1={team2.player1} player2={team2.player2} />
					)}
					<p
						className={`${
							data.team2.score > data.team1.score
								? "text-green-11"
								: "text-orange-1"
						} text-2xl font-poppins font-bold`}
					>
						{data.team2.score}
					</p>
				</div>
			</div>
		</div>
	);
}
