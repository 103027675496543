import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/log-dark-logo.svg";

function Header({ sidebarOpen, setSidebarOpen, context }) {
	return (
		<header className="sticky lg:hidden top-0 bg-white border-b border-gray-200 z-30">
			<div className="px-4 lg:px-4">
				<div className="flex items-center justify-between h-16 -mb-px relative">
					{/* Header: Left side */}
					<div className="flex absolute left-0">
						{/* Hamburger button */}
						<button
							className="text-gray-500 hover:text-gray-600 lg:hidden"
							aria-controls="sidebar"
							aria-expanded={sidebarOpen}
							onClick={() => {
								setSidebarOpen(!sidebarOpen);
								//setSidebarCollapse(true);
							}}
						>
							<span className="sr-only">Open sidebar</span>
							<svg
								className="w-6 h-6 fill-current text-green-11"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect x="4" y="5" width="16" height="2" />
								<rect x="4" y="11" width="16" height="2" />
								<rect x="4" y="17" width="16" height="2" />
							</svg>
						</button>
					</div>
					{/* Header: Right side */}
					<div className="flex w-full justify-center cursor-pointer">
						<Link to="/">
							<Logo className="cursor-pointer w-48 h-14" />
						</Link>
					</div>
				</div>
			</div>
		</header>
	);
}

export default Header;
