import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import UserContext from "../../context/context";
import { message, Tooltip, Drawer, Popconfirm } from "antd";
import { useHistory, useParams } from "react-router-dom";
import Question from "../../components/CareerQuizComponents/renderQuestion";
import {DOMAIN} from "../../env";

function Quiz() {
	const user = useContext(UserContext);
	const history = useHistory();
	const { id } = useParams();
	const [data, setData] = useState();
	const [score, setScore] = useState(0);
	const [numberOfScorableQuestions, setNumberOfScorableQuestions] = useState(0);
	const [visible, setVisible] = useState(false);

	function mapOrder(array, order, key) {
		array.sort(function (a, b) {
			var A = a[key],
				B = b[key];
			if (order.indexOf(A) > order.indexOf(B)) {
				return 1;
			} else {
				return -1;
			}
		});
		return array;
	}

	function calculateScore(questions, userAnswers) {
		var score = 0;
		var numberOfScorable = 0;
		questions.forEach((elem, index) => {
			if (elem.format === "Text") {
				if (elem.type === "Single Choice") {
					numberOfScorable++;
					if (
						elem.answers.find(
							(ans) =>
								ans.value === userAnswers[index].answer &&
								ans.correct === "true"
						)
					)
						score = score + 1;
				}
				if (elem.type === "Multiple Choice") {
					numberOfScorable++;
					let numberOfCorrectAnswers = 0;
					elem.answers.forEach((elem1) => {
						if (elem1.correct === "true")
							numberOfCorrectAnswers = numberOfCorrectAnswers + 1;
					});
					elem.answers.forEach((elem1) => {
						if (
							userAnswers[index].answer.includes(elem1.value) &&
							elem1.correct === "true"
						) {
							score = score + 1 / numberOfCorrectAnswers;
						}
					});
				}
				if (elem.type === "Paragraph") {
					elem.questions.forEach(
						(paragraphQuestion, paragraphQuestionIndex) => {
							numberOfScorable++;
							userAnswers[index].answer.forEach((answer) => {
								if (answer.question === paragraphQuestionIndex) {
									if (
										elem.questions[paragraphQuestionIndex].answers.find(
											(correctAnswer) =>
												correctAnswer.value === answer.answer &&
												correctAnswer.correct === "true"
										)
									)
										score = score + 1;
								}
							});
						}
					);
				}
			}
			if (elem.format === "Image") {
				if (elem.type === "Single Choice") {
					numberOfScorable++;
					if (
						elem.answers.find(
							(ans) =>
								ans.name === userAnswers[index].answer && ans.correct === "true"
						)
					)
						score = score + 1;
				}
				if (elem.type === "Multiple Choice") {
					numberOfScorable++;
					let numberOfCorrectAnswers = 0;
					elem.answers.forEach((elem1) => {
						if (elem1.correct === "true")
							numberOfCorrectAnswers = numberOfCorrectAnswers + 1;
					});
					elem.answers.forEach((elem1) => {
						if (
							userAnswers[index].answer.includes(elem1.name) &&
							elem1.correct === "true"
						) {
							score = score + 1 / numberOfCorrectAnswers;
						}
					});
				}
			}
		});
		setNumberOfScorableQuestions(numberOfScorable);
		setScore(score);
	}

	useEffect(() => {
		axios
			.get(
				`${DOMAIN}/api/careers_quiz/get_quiz_data/${id}`,
				{
					headers: {
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then((res) => {
				let answersArray = res.data.userAnswers;
				let questionsArray = res.data.questions.map((p) => p.id);
				answersArray = mapOrder(answersArray, questionsArray, "id");
				setData({ ...res.data, userAnswers: answersArray });
				if (res.data.status === "Completed")
					calculateScore(res.data.questions, answersArray);
			});
	}, [id, user.jwtToken]);

	const deleteQuiz = () => {
		const loadingMessage = message.loading("Loading...", 0);
		axios
			.delete(
				`${DOMAIN}/api/careers_quiz/delete_quiz/${id}`,
				{
					headers: {
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then((res) => {
				message.success({
					content: `Quiz deleted!`,
					className: "text-center",
					duration: 2,
				});
				history.push(
					`/careerQuiz/questionBank/submissions/${data.question_bank_id}`
				);
			})
			.catch((error) => {
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			})
			.finally(() => {
				loadingMessage();
			});
	};

	const renderQuestions = () => {
		const questionsList = data.questions.map((elem, index) => (
			<Question key={index} edit={false} question={elem} index={index} userAnswer={data.userAnswers[index]}/>
		));

		if (questionsList.length) return questionsList;
		else return <p className=" font-poppins text-lg">No questions</p>;
	};

	if (data === undefined)
		return (
			<div className="h-screen flex justify-center items-center bg-white">
				<div className="flex flex-col items-center justify-center gap-5">
					<div className="w-40 h-40 border-t-4 border-b-4 border-green-11 rounded-full animate-spin"></div>
				</div>
			</div>
		);

	return (
		<div className="relative">
			<Helmet>
				<title>Career Quizzes</title>
			</Helmet>
			<div className="flex flex-col lg:flex-row justify-between items-center gap-5 bg-grey-1 px-5 py-3 shadow-lg sticky w-full top-0 z-50">
				<p className=" max-w-full font-bold font-poppins text-2xl text-white text-center lg:text-left mb-0 truncate">
					{data.expected_parcipant}
				</p>
				{
					data.status === "Completed" ? (<div className="flex flex-row gap-2">
						<div
							className={`bg-white px-4 py-1 rounded-xl border-2 border-green-11`}
						>
							<p className="font-montserrat text-center lg:text-left text-lg text-grey-1 font-bold">
								{Math.ceil(score)} / {numberOfScorableQuestions}
							</p>
						</div>
					</div>) : null
				}
			</div>
			<div className="px-5 pt-4 w-full flex justify-center">
				<div className="max-w-xs truncate select-all">
					<p className="px-4 py-2 overflow-clip font-semibold text-ellipsis text-grey-1 font-montserrat border-2 border-green-11 rounded-xl">
						{`https://logarithmic.com/8275A356E2E6C9C58E93EC1AB17B6?key=${id}`}
					</p>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-5 px-5 py-4">
				{renderQuestions()}
			</div>
			<Tooltip placement="left" title="Actions">
				<div
					onClick={() => setVisible(true)}
					className={`fixed right-8 bottom-10 bg-green-11 w-14 h-14 transition-all duration-500 flex justify-center items-center rounded-xl shadow-lg cursor-pointer group`}
				>
					<svg
						x="0px"
						y="0px"
						viewBox="0 0 512 512"
						className=" w-7 h-7 fill-current text-white transform group-hover:rotate-90 transition-all duration-500"
					>
						<g>
							<path d="M34.283,384c17.646,30.626,56.779,41.148,87.405,23.502c0.021-0.012,0.041-0.024,0.062-0.036l9.493-5.483   c17.92,15.332,38.518,27.222,60.757,35.072V448c0,35.346,28.654,64,64,64s64-28.654,64-64v-10.944   c22.242-7.863,42.841-19.767,60.757-35.115l9.536,5.504c30.633,17.673,69.794,7.167,87.467-23.467   c17.673-30.633,7.167-69.794-23.467-87.467l0,0l-9.472-5.461c4.264-23.201,4.264-46.985,0-70.187l9.472-5.461   c30.633-17.673,41.14-56.833,23.467-87.467c-17.673-30.633-56.833-41.14-87.467-23.467l-9.493,5.483   C362.862,94.638,342.25,82.77,320,74.944V64c0-35.346-28.654-64-64-64s-64,28.654-64,64v10.944   c-22.242,7.863-42.841,19.767-60.757,35.115l-9.536-5.525C91.073,86.86,51.913,97.367,34.24,128s-7.167,69.794,23.467,87.467l0,0   l9.472,5.461c-4.264,23.201-4.264,46.985,0,70.187l-9.472,5.461C27.158,314.296,16.686,353.38,34.283,384z M256,170.667   c47.128,0,85.333,38.205,85.333,85.333S303.128,341.333,256,341.333S170.667,303.128,170.667,256S208.872,170.667,256,170.667z" />
						</g>
					</svg>
				</div>
			</Tooltip>
			<Drawer
				title={<p className="font-bold font-poppins text-xl text-grey-1">Actions:</p>}
				placement="right"
				onClose={() => setVisible(false)}
				open={visible}
			>
				<div className="flex flex-col gap-5 h-full">
					<div className=" mt-auto">
						<Popconfirm
							title={<p>Are you sure you want to delete this quiz?</p>}
							onConfirm={() => deleteQuiz()}
							okText="Yes"
							cancelText="No"
							placement="top"
							className="bg-red-600 border-2 border-red-600 transition-all duration-500 hover:bg-white rounded-lg cursor-pointer text-center shadow-lg group"
						>
							<p className=" text-white text-lg transition-all duration-500 hover:text-red-600 font-raleway font-bold py-3">
								Delete
							</p>
						</Popconfirm>
					</div>
				</div>
			</Drawer>
		</div>
	);
}

export default Quiz;
