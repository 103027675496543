import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Popconfirm, message } from "antd";
import { Select } from "antd";
import UserContext from "../../context/context";
import axios from "axios";
import UsersLayout from "../../components/UserPagesComponents/userLayout";
import {DOMAIN, STORAGE_DOMAIN} from "../../env";

export default function EditUsers() {
	const [data, setData] = useState();
	const [updateEffect, setUpdateEffect] = useState(false);
	const user = useContext(UserContext);
	const { id } = useParams();
	const { Option } = Select;
	const history = useHistory();

	const [userPassword, setUserPassword] = useState({ state: "", error: "", });
	const [showPassword, setShowPassword] = useState(false);
	const handleGeneratePassword = () => {
		const characterList =
			"0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!'^+%&/()=?_#$[]|;:>÷`<.*-@";

		let password = "";
		const characterListLength = characterList.length;

		for (let i = 0; i < 25; i++) {
			const characterIndex = Math.round(Math.random() * characterListLength);
			password = password + characterList.charAt(characterIndex);
		}
		setUserPassword({ ...userPassword, state: password });
		setShowPassword(true);
	};

	const [userRole, setUserRole] = useState({ state: "", error: "" });
	const [userName, setUserName] = useState({ state: "", error: "" });

	useEffect(() => {
		axios
			.get(`${DOMAIN}/api/user/getUserInfo/${id}`, {
				headers: {
					"jwt-token": user.jwtToken,
				},
			})
			.then((response) => {
				setData(response.data.user[0]);
				setUserName({ ...userName, state: response.data.user[0].user_name });
				setUserRole({ ...userRole, state: response.data.user[0].user_role });
			})
			.catch((error) => {
				message.error({
					content: `${error.response.statusText}!`,
					className: "text-center",
					duration: 2,
				});
				history.push("/users");
			});
		// eslint-disable-next-line
	}, [id, updateEffect]);

	const inputChecker = () => {
		let formIsValid = true;
		if (!userName.state) {
			formIsValid = false;
			setUserName({ ...userName, error: "This field can not be empty!" });
		} else {
			setUserName({ ...userName, error: "" });
		}
		if (!userRole.state) {
			formIsValid = false;
			setUserRole({ ...userRole, error: "Please select a value!" });
		} else {
			setUserRole({ ...userRole, error: "" });
		}
		return formIsValid;
	};

	const submitFunction = async () => {
		if (inputChecker()) {
			const loadingMessage = message.loading("Loading...", 0);
			await axios
				.post(
					`${DOMAIN}/api/user/updateUserInfo/${id}`,
					{
						user_name: userName.state,
						user_role: userRole.state,
						user_password: userPassword.state,
					},
					{
						headers: {
							"jwt-token": user.jwtToken,
						},
					}
				)
				.then((response) => {
					if (response.data.success === false) {
						message.error({
							content: response.data.error,
							className: "text-center",
							duration: 2,
						});
					} else {
						message.success({
							content: `User Updated!`,
							className: "text-center",
							duration: 2,
						});
						setUpdateEffect(!updateEffect);
					}
				})
				.catch((error) => {
					message.error({
						content: `Network Error!`,
						className: "text-center",
						duration: 2,
					});
				})
				.finally(() => {
					loadingMessage();
				});
		} else {
			message.error({
				content: `Please check all fields!`,
				className: "text-center",
				duration: 2,
			});
		}
	};

	if (data === undefined)
		return (
			<div className="h-screen flex justify-center items-center bg-white">
				<div className="flex flex-col items-center justify-center gap-5">
					<div className="w-40 h-40 border-t-4 border-b-4 border-green-11 rounded-full animate-spin"></div>
				</div>
			</div>
		);

	async function confirm(e) {
		const loadingMessage = message.loading("Loading...", 0);
		await axios
			.delete(
				`${DOMAIN}/api/user/deleteUser/${id}`,
				{
					headers: {
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then((response) => {
				if (response.data.success === false) {
					message.error({
						content: response.data.error,
						className: "text-center",
						duration: 2,
					});
				} else {
					message.success({
						content: `User Deleted!`,
						className: "text-center",
						duration: 2,
					});
					history.push("/users");
				}
			})
			.catch((error) => {
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			})
			.finally(() => {
				loadingMessage();
			});
	}
	return (
		<UsersLayout>
			<div className="flex flex-col lg:flex-row flex-wrap justify-between items-center bg-grey-4 p-4 gap-4 border-b-2">
				<div className="flex flex-row gap-4 justify-center items-center">
					<div className="w-20 h-20 min-w-[5rem] bg-green-11 rounded-full shadow-lg overflow-hidden">
						{data?.user_image !== undefined ? (
							<img
								className="h-full w-full"
								alt="user_avatar"
								src={STORAGE_DOMAIN+data?.user_image}
								key={data?.user_image}
							/>
						) : (
							<div className=" h-full w-full flex items-center justify-center">
								<p className=" text-white font-bold font-poppins text-2xl">
									{data?.user_name.match(/\b(\w)/g).join(".")}
								</p>
							</div>
						)}
					</div>
					<div>
						<p className=" font-bold font-poppins text-2xl text-grey-1 text-left mb-0 leading-5">
							{data?.user_name}
						</p>
						<p className=" font-montserrat text-lg text-grey-1">
							{data?.user_role}
						</p>
					</div>
				</div>
				<div className="flex flex-row items-center gap-5">
					<div
						onClick={() => submitFunction()}
						className={`bg-green-11 border-green-11 border-2 flex items-center gap-2 px-5 py-2 cursor-pointer shadow-lg rounded-2xl transition-all duration-500`}
					>
						<svg
							viewBox="0 0 24 24"
							className=" fill-current text-white w-4 h-4"
						>
							<path d="M12,10a4,4,0,1,0,4,4A4,4,0,0,0,12,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,16Z" />
							<path d="M22.536,4.122,19.878,1.464A4.966,4.966,0,0,0,16.343,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7.657A4.966,4.966,0,0,0,22.536,4.122ZM17,2.08V3a3,3,0,0,1-3,3H10A3,3,0,0,1,7,3V2h9.343A2.953,2.953,0,0,1,17,2.08ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2V3a5.006,5.006,0,0,0,5,5h4a4.991,4.991,0,0,0,4.962-4.624l2.16,2.16A3.02,3.02,0,0,1,22,7.657Z" />
						</svg>
						<p className=" text-white font-montserrat font-bold uppercase">
							Save
						</p>
					</div>
					<Popconfirm
						title="Are you sure to delete this user?"
						onConfirm={confirm}
						placement="bottomLeft"
						okText="Yes"
						cancelText="No"
						okButtonProps={{ danger: true }}
					>
						<div className="hover:bg-red-600 border-2 border-red-600 flex items-center gap-2 px-5 py-2 cursor-pointer shadow-lg rounded-2xl transition-all duration-500 group">
							<svg
								className=" fill-current text-red-600 w-4 h-4 group-hover:text-white transition-all duration-500"
								viewBox="0 0 24 24"
							>
								<path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
								<path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
								<path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
							</svg>

							<p className=" text-red-600 group-hover:text-white transition-all duration-500 font-montserrat font-bold uppercase">
								Delete
							</p>
						</div>
					</Popconfirm>
				</div>
			</div>
			<div className="grid grid-cols-2 col-span-4 lg:col-span-3 gap-5 px-4 pt-4 pb-10 lg:pb-4">
				<div className=" col-span-2 md:col-span-1">
					<div className="relative w-full group">
						<input
							id="username"
							name="username"
							placeholder="username"
							value={userName.state}
							onChange={(e) =>
								setUserName({ ...userName, state: e.target.value })
							}
							className={`${
								userName.error?.length !== 0
									? "border-orange-1 focus:ring-orange-1"
									: ""
							} peer h-12 font-raleway appearance-none bg-white w-full py-2 px-3 border-2 rounded-xl text-grey-1 text-lg leading-tight focus:outline-none focus:ring-0 focus:border-green-11 placeholder-transparent`}
						/>
						<label
							htmlFor="username"
							className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
						>
							Name
						</label>
						<div className="absolute">
							<p className="font-raleway text-sm text-orange-1 pt-1 m-0">
								{" "}
								{userName.error}{" "}
							</p>
						</div>
					</div>
				</div>
				<div className=" col-span-2 md:col-span-1">
					<div className="relative w-full">
						<input
							id="email"
							name="email"
							className={`peer h-12 font-raleway appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none focus:ring-0 focus:border-transparent placeholder-transparent cursor-default`}
							value={data?.user_email}
							placeholder="email"
							disabled
						/>
						<label
							htmlFor="email"
							className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
						>
							Email
						</label>
					</div>
				</div>
				<div className=" col-span-2 md:col-span-1">
					<div className="relative w-full">
						<Select
							id="role"
							showSearch
							placement="bottomLeft"
							className={`peer h-12 font-raleway appearance-none w-full py-2 border-2 rounded-xl text-lg leading-tight focus:outline-none focus:border-transparent placeholder-transparent cursor-default focus:border-green-11 ${
								userRole.error?.length !== 0
									? "border-orange-1 focus:ring-orange-1"
									: ""
							}`}
							placeholder="Select a role"
							defaultValue={data.user_role}
							getPopupContainer={(trigger) => trigger.parentElement}
							onChange={(value) => setUserRole({ ...userRole, state: value })}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							<Option value="HumanResources"> Human Resources </Option>
							<Option value="Marketing"> Marketing </Option>
							<Option value="Developer"> Developer </Option>
							<Option value="User"> User </Option>
						</Select>
						<label
							htmlFor="role"
							className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
						>
							Role
						</label>
						<div className="absolute">
							<p className="font-raleway text-sm text-orange-1 pt-1 m-0">
								{" "}
								{userRole.error}{" "}
							</p>
						</div>
					</div>
				</div>
				<div className=" col-span-2 md:col-span-1">
					<div className="w-full flex flex-row gap-2">
						<div className="relative w-full h-full">
							<div className="flex flex-row gap-2">
								<div className="relative w-full">
									<input
										name="password"
										id="password"
										className={`peer h-12 font-raleway appearance-none w-full py-2 px-3 border-2 rounded-xl text-lg leading-tight focus:outline-none focus:ring-0 placeholder-transparent focus:border-green-11 ${
											userPassword.error?.length !== 0
												? "border-orange-1 focus:ring-orange-1"
												: ""
										}`}
										placeholder="User Password"
										value={userPassword.state}
										type={showPassword ? "text" : "password"}
										onChange={(e) =>
											setUserPassword({
												...userPassword,
												state: e.target.value,
											})
										}
									/>
									<label
										htmlFor="password"
										className="transform transition-all duration-500 absolute -top-2 left-3 px-1 text-sm font-poppins text-grey-1 rounded-full bg-white font-bold peer-placeholder-shown:top-[0.65rem] peer-focus:-top-2 peer-focus:text-sm peer-placeholder-shown:text-lg"
									>
										Password
									</label>
									<div
										className="absolute top-1/4 right-5 cursor-pointer group"
										onClick={() =>
											setShowPassword((showPassword) => !showPassword)
										}
									>
										{showPassword ? (
											<svg
												className=" fill-current text-grey-1 w-6 h-6 group-hover:text-green-11 transition-all duration-500"
												viewBox="0 0 24 24"
											>
												<path d="M17,8H7V7a5,5,0,0,1,9.375-2.422,1,1,0,0,0,1.749-.971A7,7,0,0,0,5,7V8.424A5,5,0,0,0,2,13v6a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V13A5.006,5.006,0,0,0,17,8Zm3,11a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V13a3,3,0,0,1,3-3H17a3,3,0,0,1,3,3Z" />
												<path d="M12,14a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V15A1,1,0,0,0,12,14Z" />
											</svg>
										) : (
											<svg
												className=" fill-current text-grey-1 w-6 h-6 group-hover:text-green-11 transition-all duration-500"
												viewBox="0 0 24 24"
											>
												<path d="M19,8.424V7A7,7,0,0,0,5,7V8.424A5,5,0,0,0,2,13v6a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V13A5,5,0,0,0,19,8.424ZM7,7A5,5,0,0,1,17,7V8H7ZM20,19a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V13a3,3,0,0,1,3-3H17a3,3,0,0,1,3,3Z" />
												<path d="M12,14a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V15A1,1,0,0,0,12,14Z" />
											</svg>
										)}
									</div>
								</div>
								<div className="flex justify-center items-center">
									<div
										className="cursor-pointer transition-all hover:rotate-180 duration-500 group"
										onClick={() => handleGeneratePassword()}
									>
										<svg
											x="0px"
											y="0px"
											viewBox="0 0 512.016 512.016"
											className=" fill-current text-grey-1 w-6 h-6 group-hover:text-green-11 transition-all duration-500"
										>
											<g>
												<path d="M256.008,42.675c57.16,0.188,111.87,23.233,151.936,64h-66.603c-11.782,0-21.333,9.551-21.333,21.333   s9.551,21.333,21.333,21.333h88.384c21.874-0.012,39.604-17.742,39.616-39.616V21.341c0-11.782-9.551-21.333-21.333-21.333   s-21.333,9.551-21.333,21.333v44.331C321.782-28.795,160.169-20.343,65.702,84.55C28.733,125.6,6.058,177.523,1.075,232.541   c-1.096,11.814,7.593,22.279,19.407,23.375c0.64,0.059,1.283,0.09,1.927,0.092c10.813,0.138,19.954-7.977,21.099-18.731   C53.35,127.213,145.505,42.82,256.008,42.675z" />
												<path d="M489.629,256.008c-10.813-0.138-19.954,7.977-21.099,18.731C458.35,391.953,355.076,478.721,237.861,468.54   c-50.715-4.405-98.176-26.825-133.789-63.199h66.603c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333   H82.291c-21.868-0.012-39.604,17.706-39.616,39.573c0,0.014,0,0.028,0,0.043v88.384c0,11.782,9.551,21.333,21.333,21.333   s21.333-9.551,21.333-21.333v-44.331c104.893,94.467,266.505,86.015,360.972-18.878c36.97-41.05,59.645-92.973,64.628-147.992   c1.096-11.814-7.593-22.279-19.407-23.375C490.901,256.041,490.265,256.01,489.629,256.008z" />
											</g>
										</svg>
									</div>
								</div>
							</div>
							<div className="absolute">
								<p className="font-raleway text-sm text-orange-1 pt-1 m-0">
									{" "}
									{userPassword.error}{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</UsersLayout>
	);
}
