import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { message } from "antd";
import JobCard from "../../components/CareerPagesComponents/careerPageCard";
import axios from "axios";
import { Helmet } from "react-helmet";
import NewPostModal from "../../components/CareerPagesComponents/newPostModal";
import {DOMAIN} from "../../env";
function CareerPosts() {
	const [data, setData] = useState();
	const [filter, setFilter] = useState({
		name: "",
		published: true,
	});

	const dataFilter = function (dataArr, filterArr) {
		let data = dataArr;
		if (filterArr.name)
			data =
				data &&
				data.filter((i) =>
					i.jobTitle.toLowerCase().includes(filterArr.name.toLowerCase())
				);
		if (filterArr.published)
			data = data && data.filter((i) => i.published === true);
		if (!filterArr.published)
			data = data && data.filter((i) => i.published === false);
		return data;
	};

	const filteredData = dataFilter(
		data?.jobPosts.sort((a, b) => a.order - b.order),
		filter
	);

	useEffect(() => {
		const fetchApiData = async () => {
			try {
				const response = await axios.get(
					`${DOMAIN}/api/careers/getAllJobPosts`
				);
				setData(response.data);
			} catch (error) {
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			}
		};
		fetchApiData();
	}, []);

	const renderCard = () => {
		if (data === undefined)
			return (
				<>
					{[...new Array(6)].map((item, index) => (
						<div
							key={index}
							className="relative mx-auto flex h-52 w-full flex-col rounded-3xl bg-gray-600 p-6 shadow"
						>
							<div className="relative flex h-full animate-pulse flex-col gap-y-4">
								<div className="h-4 w-full animate-pulse rounded-lg bg-gray-500"></div>
								<div className="h-4 w-3/4 animate-pulse rounded-lg bg-gray-500"></div>
							</div>
							<div className="flex h-full w-full flex-col items-center justify-center gap-y-4">
								<div className="h-4 w-full animate-pulse rounded-lg bg-gray-500"></div>
								<div className="h-4 w-full animate-pulse rounded-lg bg-gray-500"></div>
							</div>
						</div>
					))}
				</>
			);
		else {
			return filteredData.map((element, index) => {
				return (
					<Link
						key={index}
						onClick={() => addToRecentlyAccessed(element)}
						to={`/careers/${element._id}`}
					>
						<JobCard
							jobTitle={element.jobTitle}
							jobLocation={element.jobLocation}
							jobDescription={element.jobDescription1}
							jobLink={element._id}
							published={element.published}
						/>
					</Link>
				);
			});
		}
	};

	const addToRecentlyAccessed = (element) => {
		if (localStorage.getItem("RecentlyAccessed") === null) {
			const object = {
				data: element,
				date: new Date(),
				type: "careerPost",
				_id: element._id + "CP",
			};
			localStorage.setItem("RecentlyAccessed", JSON.stringify([object]));
		} else {
			var array = JSON.parse(localStorage.getItem("RecentlyAccessed") || "[]");
			const duplicate = (id) => {
				return array.some((el) => {
					return el._id === id + "CP";
				});
			};
			if (!duplicate(element._id)) {
				const object = {
					data: element,
					date: new Date(),
					type: "careerPost",
					_id: element._id + "CP",
				};
				array.unshift(object);
				if (array.length > 10) array.pop();
				localStorage.setItem("RecentlyAccessed", JSON.stringify(array));
			} else {
				array = array.sort((x, y) => { return x._id === element._id + "CP" ? -1 : y._id === element._id + "CP" ? 1 : 0; });
				localStorage.setItem("RecentlyAccessed", JSON.stringify(array));
			}
		}
	};

	return (
		<>
			<Helmet>
				<title>Career Posts</title>
			</Helmet>
			<div className="flex flex-col items-center justify-between bg-grey-1 p-5 lg:flex-row">
				<p className=" mb-0 text-center font-poppins text-xl font-bold text-white md:text-2xl lg:text-left">
					Career Posts
				</p>
			</div>
			<div className="p-3">
				<div className="flex w-full flex-col gap-5 pb-5 pt-2 md:flex-row">
					<div className="flex flex-col gap-5 md:flex-row">
						<div
							onClick={() =>
								setFilter({ ...filter, published: !filter.published })
							}
							className={`${
								filter.published ? "bg-green-11" : "bg-orange-1"
							} flex w-full cursor-pointer items-center justify-center rounded-2xl py-3 shadow-lg transition-all duration-300 md:w-32`}
						>
							<p className=" font-poppins text-base font-bold uppercase text-white">
								{filter.published ? "Active" : "Draft"}
							</p>
						</div>
						<NewPostModal />
						<div className="group relative cursor-pointer">
							<Link to="/careers/order">
								<div className="flex w-full min-w-[10rem] cursor-pointer items-center justify-center gap-2 rounded-2xl bg-grey-1 px-6 py-3 shadow-lg transition-all duration-300 hover:bg-green-11">
									<div className="">
										<svg
											className=" h-5 w-5 fill-current text-white"
											viewBox="0 0 24 24"
										>
											<path d="M12 0l8 10h-16l8-10zm8 14h-16l8 10 8-10z" />
										</svg>
									</div>
									<p className=" font-poppins text-base font-bold uppercase text-white">
										Edit Order
									</p>
								</div>
							</Link>
						</div>
					</div>
					<div className="relative md:ml-auto">
						<div className="flex max-h-[3rem] w-full flex-row items-center justify-center gap-2 rounded-2xl bg-grey-1 py-2 pl-4 pr-2 md:w-48">
							<div className="">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className=" h-5 w-5 fill-current text-white"
									viewBox="0 0 24 24"
								>
									<path d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z" />
								</svg>
							</div>
							<input
								type="text"
								placeholder="Search by name"
								onChange={(e) => setFilter({ ...filter, name: e.target.value })}
								className={`h-8 w-full appearance-none rounded-lg bg-grey-13 py-2 px-3 leading-tight text-gray-700 focus:border-transparent focus:outline-none`}
							/>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-5 md:grid-cols-2">
					{renderCard()}
				</div>
				<div className="h-10"></div>
			</div>
		</>
	);
}

export default CareerPosts;
