import React, { useEffect, useState, useContext, useCallback } from "react";
import update from "immutability-helper";
import { message } from "antd";
import axios from "axios";
import { Helmet } from "react-helmet";
import UserContext from "../../context/context";
import { Card } from "../../components/CareerPagesComponents/Card";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {DOMAIN} from "../../env";

function EditCareerPostsOrder() {
	const user = useContext(UserContext);
	const [data, setData] = useState();

	useEffect(() => {
		const fetchApiData = async () => {
			try {
				const response = await axios.get(
					`${DOMAIN}/api/careers/getAllJobPosts`
				);
				setData(response.data.jobPosts.sort((a, b) => a.order - b.order));
			} catch (error) {
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			}
		};
		fetchApiData();
	}, []);

	const onSave = () => {
		const order = data.map((element, index) => {
			return {
				id: element._id,
				order: index,
			};
		});
		const loadingMessage = message.loading("Loading...", 0);
		axios
			.post(
				`${DOMAIN}/api/careers/editCareersPostsOrder`,
				order,
				{
					headers: {
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then(function (response) {
				message.success({
					content: "Order saved!",
					className: "text-center",
					duration: 2,
				});
			})
			.catch(function (error) {
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			})
			.finally(() => {
				loadingMessage();
			});
	};

	const moveCard = useCallback((dragIndex, hoverIndex) => {
		setData((prevData) =>
			update(prevData, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevData[dragIndex]],
				],
			})
		);
	}, []);

	const renderCard = useCallback((card, index) => {
		return (
			<Card
				key={card._id}
				index={index}
				id={card.order}
				text={card.jobTitle}
				published={card.published}
				moveCard={moveCard}
			/>
		);
	}, [moveCard]);

	if (data === undefined)
		return (
			<div className="flex h-screen items-center justify-center bg-white">
				<div className="flex flex-col items-center justify-center gap-5">
					<div className="h-40 w-40 animate-spin rounded-full border-t-4 border-b-4 border-green-11"></div>
				</div>
			</div>
		);

	return (
		<>
			<Helmet>
				<title>Career Posts</title>
			</Helmet>
			<div className="flex flex-col items-center justify-between bg-grey-1 p-5 lg:flex-row">
				<p className=" mb-0 text-center font-poppins text-xl font-bold text-white md:text-2xl lg:text-left">
					Edit Order
				</p>
			</div>
			<div className="p-3">
				<DndProvider backend={HTML5Backend}>
					<div style={{ maxWidth: "700px" }} className="mx-auto w-full">
						{data.map((card, i) => renderCard(card, i))}
						<div className="h-5"></div>
						<div className="flex w-full justify-center">
							<div
								onClick={() => onSave()}
								className="mx-12 cursor-pointer rounded-lg bg-green-11 text-center shadow-lg lg:mx-0"
							>
								<p className=" px-9 py-3 font-raleway font-bold uppercase text-white">
									Save
								</p>
							</div>
						</div>
						<div className="h-10"></div>
					</div>
				</DndProvider>
			</div>
		</>
	);
}

export default EditCareerPostsOrder;
