import axios from "axios";
import { useEffect, useState, useContext } from "react";
//import { useReward } from "react-rewards";
import UserContext from "../../context/context";
import {DOMAIN, STORAGE_DOMAIN} from "../../env";

const LeaderboardRow = ({ player, index }) => {
	if (player === undefined)
		return (
			<div className="flex flex-row w-full even:bg-grey-15 px-2 py-2 first:pt-4 gap-5">
				<div className="h-5 w-20 md:w-40 rounded-full bg-grey-10 animate-pulse"></div>
				<div className="h-5 w-full rounded-full bg-grey-10 animate-pulse"></div>
				<div className="h-5 w-20 md:w-40 rounded-full bg-grey-10 animate-pulse"></div>
			</div>
		);

	return (
		<div className="flex flex-row w-full even:bg-grey-15 px-2 py-2 first:pt-4">
			<div className=" w-20 md:w-20 transition-all duration-500 flex items-center justify-center">
				<p className="text-grey-1 font-bold font-poppins text-xl text-center uppercase">
					{index + 1}
				</p>
			</div>
			<div className="w-full flex flex-row items-center gap-2">
				<div className="h-12 w-12 min-w-[3rem] rounded-full bg-green-11 overflow-hidden">
					{player.image !== undefined ? (
						<img
							src={`${ STORAGE_DOMAIN+player.image}`}
							alt="playerImage"
							className="h-12 w-12"
						/>
					) : (
						<div className="w-full h-full flex justify-center items-center bg-white">
							<p className=" text-grey-1 font-bold font-poppins text-lg">
								{player.name.match(/\b(\w)/g).join(".")}
							</p>
						</div>
					)}
				</div>
				<p className="text-grey-1 font-bold font-poppins text-lg text-left uppercase truncate whitespace-nowrap max-w-[8rem] min-[425px]:max-w-[12rem]">
					{player.name}
				</p>
			</div>
			<div className="w-20 md:w-20 transition-all duration-500 flex items-center justify-center">
				<p className="text-grey-1 font-bold font-poppins text-xl text-center uppercase">
					{player.score}
				</p>
			</div>
		</div>
	);
};
/*
const FirstThreePlaces = ({ place }) => {
	return (
		<div className="grid grid-cols-6 justify-center items-end gap-5 w-full max-w-[600px] mx-auto">
			<div className="w-full col-span-3 row-start-2 md:col-span-2 md:row-start-1 flex flex-col justify-center items-center">
				<p className=" font-poppins text-grey-1 text-3xl font-bold">2</p>
				<div className="bg-grey-1 h-24 w-24 flex justify-center items-center rounded-full overflow-hidden shadow-xl">
					<div className=" h-[5.5rem] w-[5.5rem] rounded-full overflow-hidden">
						{place[1] && place[1].image !== undefined ? (
							<img
								className="h-[5.5rem] w-[5.5rem]"
								src={`${DOMAIN}/api/user/image/${place[1].id}/${place[1].image}`}
								alt="playerImage"
							/>
						) : (
							<div className="w-full h-full flex justify-center items-center bg-grey-1">
								<p className=" text-white font-bold font-poppins text-2xl">
									{place[1] && place[1].name.match(/\b(\w)/g).join(".")}
								</p>
							</div>
						)}
					</div>
				</div>
				<div className="flex flex-col justify-center items-center pt-4">
					<p className=" font-poppins text-grey-1 text-2xl font-bold text-center whitespace-pre-line">
						{place[1] && place[1].name.split(" ")[0]}
					</p>
					<p className=" font-poppins text-grey-1 text-3xl font-bold text-center whitespace-pre-line tabular-nums">
						{place[1] && place[1].score}
					</p>
				</div>
			</div>
			<div className="w-full col-span-6 row-start-1 md:col-span-2 md:row-start-1 flex flex-col justify-center items-center">
				<span id="confettiReward1" />
				<span id="confettiReward2" />
				<p className="font-poppins text-green-11 text-4xl font-bold">1</p>
				<div className="bg-green-11 h-32 w-32 flex justify-center items-center rounded-full overflow-hidden shadow-xl">
					<div className="h-[7.5rem] w-[7.5rem] rounded-full overflow-hidden">
						{place[0] && place[0].image !== undefined ? (
							<img
								className="h-[7.5rem] w-[7.5rem]"
								src={`${DOMAIN}/api/user/image/${place[0].id}/${place[0].image}`}
								alt="playerImage"
							/>
						) : (
							<div className="w-full h-full flex justify-center items-center bg-green-11">
								<p className=" text-white font-bold font-poppins text-3xl">
									{place[0] && place[0].name.match(/\b(\w)/g).join(".")}
								</p>
							</div>
						)}
					</div>
				</div>
				<div className="flex flex-col justify-center items-center pt-4">
					<p className=" font-poppins text-grey-1 text-3xl font-bold text-center whitespace-pre-line">
						{place[0] && place[0].name.split(" ")[0]}
					</p>
					<p className=" font-poppins text-grey-1 text-4xl font-bold text-center whitespace-pre-line tabular-nums">
						{place[0] && place[0].score}
					</p>
				</div>
			</div>
			<div className="w-full col-span-3 row-start-2 md:col-span-2 md:row-start-1 flex flex-col justify-center items-center">
				<p className=" font-poppins text-grey-1 text-3xl font-bold">3</p>
				<div className="bg-grey-1 h-24 w-24 flex justify-center items-center rounded-full overflow-hidden shadow-xl">
					<div className=" h-[5.5rem] w-[5.5rem] rounded-full overflow-hidden">
						{place[2] && place[2].image !== undefined ? (
							<img
								className="h-[5.5rem] w-[5.5rem]"
								src={`${DOMAIN}/api/user/image/${place[2].id}/${place[2].image}`}
								alt="playerImage"
							/>
						) : (
							<div className="w-full h-full flex justify-center items-center bg-grey-1">
								<p className=" text-white font-bold font-poppins text-2xl">
									{place[2] && place[2].name.match(/\b(\w)/g).join(".")}
								</p>
							</div>
						)}
					</div>
				</div>
				<div className="flex flex-col justify-center items-center pt-4">
					<p className="font-poppins text-grey-1 text-2xl font-bold text-center whitespace-pre-line">
						{place[2] && place[2].name.split(" ")[0]}
					</p>
					<p className=" font-poppins text-grey-1 text-3xl font-bold text-center whitespace-pre-line tabular-nums">
						{place[2] && place[2].score}
					</p>
				</div>
			</div>
		</div>
	);
};
*/

export default function Leaderboard() {
	const user = useContext(UserContext);
	const [leaderboard, setLeaderboard] = useState([]);

	/*
	const { reward: confettiReward1 } = useReward("confettiReward1", "confetti", {
		spread: 100,
		startVelocity: 15,
		elementCount: 150,
		zIndex: 31,
		angle: 60,
	});
	const { reward: confettiReward2 } = useReward("confettiReward2", "confetti", {
		spread: 100,
		startVelocity: 15,
		elementCount: 150,
		zIndex: 31,
		angle: 120,
	});
	*/

	useEffect(() => {
		axios
			.get(`${DOMAIN}/api/pingpong/getLeaderboard`, {
				headers: {
					"jwt-token": user.jwtToken,
				},
			})
			.then((results) => {
				setLeaderboard(results.data.leaderboard);
			})
			.catch((error) => {
				console.log(error);
			});
		// eslint-disable-next-line
	}, []);

	/*
	useEffect(() => {
		if (leaderboard.length >= 3)
			setTimeout(() => {
				confettiReward1();
				confettiReward2();
			}, 1000);
		// eslint-disable-next-line
	}, [leaderboard]);
	*/

	return (
		<div className="w-full flex flex-col items-center lg:max-w-[700px]">
			<div className="flex justify-center md:justify-start pb-4">
				<p className="text-grey-1 font-poppins font-bold text-4xl">
					Leaderboard
				</p>
			</div>

			<div className="w-full flex flex-col gap-5">
				<div className="w-full max-w-[600px] mx-auto">
					<div className="bg-green-11 rounded-t-3xl w-full shadow-lg z-10 relative">
						<div className="flex flex-row w-full px-2 py-4 gap-2">
							<div className=" w-20 md:w-40 px-2 transition-all duration-500">
								<p className="text-white font-bold font-poppins text-lg md:text-xl text-center uppercase">
									Place
								</p>
							</div>
							<div className="w-full px-2">
								<p className="text-white font-bold font-poppins text-lg md:text-xl text-left uppercase">
									Name
								</p>
							</div>
							<div className="w-20 md:w-40 px-2 transition-all duration-500">
								<p className="text-white font-bold font-poppins text-lg md:text-xl text-center uppercase">
									Score
								</p>
							</div>
						</div>
					</div>
					<div className="bg-grey-4 rounded-b-3xl w-full shadow-lg">
						<div className="flex flex-col w-full pb-6">
							{leaderboard.map((elem, index) => {
								return <LeaderboardRow player={elem} index={index} key={index} />;
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
