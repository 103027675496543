import React from 'react';
import { Select, DatePicker } from "antd";

export default function SeoInformation({data , setData}) {
    const { Option } = Select;

    return(
        <div className="grid w-full grid-cols-2 gap-5">
            <div className="relative w-full col-span-2 lg:col-span-1" id="topicSelect">
                <Select
                    id="topic"
                    showSearch
                    className={`${ data.topic.error?.length !== 0
                        ? "border-orange-1 focus:ring-orange-1"
                        : "hover:border-green-11"
                    } peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus-within:border-green-11 focus:border-green-11`}
                    placeholder=""
                    onChange={(value) => { setData({ topic: { state: value, error: ""}}) }}
                    getPopupContainer={() => document.getElementById("topicSelect") as HTMLElement}
                >
                    <Option value="Privacy"> Eloqua Privacy </Option>
                    <Option value="Implementation"> Eloqua Implementation </Option>
                    <Option value="CampaignProduction"> Eloqua Campaign Prod </Option>
                    <Option value="PlatformAdmin"> Eloqua Platform Admin </Option>
                    <Option value="Reporting"> Eloqua Reporting </Option>
                </Select>
                <label
                    htmlFor="topic"
                    className={`${ data.topic.state.length
                        ? "-top-2 text-sm"
                        : "top-[0.65rem] text-lg"
                    } absolute left-3 transform rounded-full bg-white px-1 font-poppins font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus-within:-top-2 peer-focus-within:text-sm`}
                >
                    Topic
                </label>
                <div className="absolute">
                    <p className="m-0 pt-1 font-raleway text-sm text-orange-1"> {data.topic.error} </p>
                </div>
            </div>
            <div className="relative z-10 w-full col-span-2 lg:col-span-1" id="datePicker">
                <DatePicker
                    id="date"
                    className={`peer h-12 w-full cursor-default appearance-none rounded-xl border-2 py-2 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:outline-none ${
                        data.date.error?.length !== 0
                            ? "border-orange-1 focus:ring-orange-1"
                            : ""
                    }`}
                    placeholder=""
                    format={["DD/MM/YYYY", "DD/MM/YY"]}
                    getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                    onChange={(value) => {setData({ date:{state:value?.toDate(), error:""} })}}
                />
                <label
                    htmlFor="date"
                    className={`${
                        data.date.state
                            ? "-top-2 text-sm"
                            : "top-[0.65rem] text-lg"
                    } absolute left-3 transform rounded-full bg-white px-1 font-poppins font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus-within:-top-2 peer-focus-within:text-sm`}
                >
                    Date
                </label>
                <div className="absolute">
                    <p className="m-0 pt-1 font-raleway text-sm text-orange-1">{data.date.error}</p>
                </div>
            </div>
            <div className="relative w-full col-span-2">
                <input
                    id="link"
                    className={`peer h-12 w-full appearance-none truncate rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent transition-colors duration-500 focus:border-green-11 focus:outline-none ${
                        data.link.error?.length !== 0
                            ? "border-orange-1 focus:ring-orange-1"
                            : "hover:border-green-11"
                    }`}
                    value={data.link.state}
                    onChange={(e) => { setData({ link:{state:e.target.value, error:""}}) }}
                    placeholder="https://expertise.logarithmic.com/email-marketing-key-performance-indicators"
                />
                <label htmlFor="link" className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-500 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm" >
                    Link
                </label>
                <div className="absolute">
                    <p className="m-0 pt-1 font-raleway text-sm text-orange-1">{data.link.error}</p>
                </div>
            </div>
        </div>
    )
}