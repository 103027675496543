import React, { useContext } from "react";
import axios from "axios";
import EditorJs from "react-editor-js";
import {
  Embed,
  Table,
  Paragraph,
  List,
  ImageTest,
  Header,
  Quote,
  Marker,
  Delimiter,
  Alignment,
  Columns,
  Undo,
} from "./ToolImports";
import { handleChange } from "./HandleChange";
import UserContext from "../../context/context";
import { DOMAIN, STORAGE_DOMAIN } from "../../env";

export default function Editor(props) {
  const user = useContext(UserContext);

  return (
    <EditorJs
      instanceRef={(instance) => props.setEditorInstance(instance)}
      data={props.bodyData}
      onChange={() => {
        handleChange(
          props.imagesUploaded,
          props.setImagesUploaded,
          user.jwtToken,
          props.setEditorModification
        );
        props.setEditorModification(true);
      }}
      onReady={(editor) => {
        const config = {
          shortcuts: {
            undo: "CMD+Z",
            redo: "CMD+SHIFT+Z",
          },
        };
        new Undo({ editor, config });
        //*Get a list with all images in the post
        document.querySelectorAll(".image-tool__image-picture").forEach((x) => {
          const string = x.src?.substring(x.src.lastIndexOf("/") + 1);
          props.setImagesUploaded((x) => [...x, string]);
        });
      }}
      logLevel="ERROR"
      tools={{
        embed: Embed,
        table: Table,
        paragraph: {
          class: Paragraph,
          config: {
            placeholder: "Enter text",
          },
          inlineToolbar: ["bold", "italic", "link"],
          tunes: ["alignment"],
        },
        list: {
          class: List,
          inlineToolbar: ["bold", "italic", "link"],
        },
        image: {
          class: ImageTest,
          config: {
            uploader: {
              /**
               * Upload file to the server and return an uploaded image data
               * @param {File} file - file selected from the device or pasted by drag-n-drop
               * @return {Promise.<{success, file: {url}}>}
               */
              async uploadByFile(file) {
                var formData = new FormData();
                formData.append("image", file);
                return await axios
                  .post(`${DOMAIN}/api/knowledgeHub/uploadImage`, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      "jwt-token": user.jwtToken,
                    },
                  })
                  .then((results) => {
                    // keep track of images, add the url of each new image to our array
                    props.setImagesUploaded((x) => [
                      ...x,
                      STORAGE_DOMAIN + results.data.file.url,
                    ]);
                    props.setEditorModification(true);
                    return {
                      ...results.data,
                      file: { url: STORAGE_DOMAIN + results.data.file.url },
                    };
                  });
              },
              /**
               * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
               * @param {string} url - pasted image URL
               * @return {Promise.<{success, file: {url}}>}
               */
              async uploadByUrl(url) {
                //TODO: Upload image by link;
              },
            },
            actions: [
              {
                name: "borderRadius",
                icon: `<svg viewBox="0 0 24 24" fill="currentColor" style="width:14px !important; height:14px !important" stroke-width="0px"><path d="M1.5,24c-.83,0-1.5-.67-1.5-1.5V5.5C0,2.47,2.47,0,5.5,0H22.5c.83,0,1.5,.67,1.5,1.5s-.67,1.5-1.5,1.5H5.5c-1.38,0-2.5,1.12-2.5,2.5V22.5c0,.83-.67,1.5-1.5,1.5Zm17.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm-11,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm16,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm0-5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm0-5.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm0-5.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5ZM13.5,22.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/></svg>
							`,
                title: "Border radius",
                toggle: true,
              },
              {
                name: "paddingY",
                icon: `<svg viewBox="0 0 24 24" fill="currentColor" style="width:14px !important; height:14px !important" stroke-width="0px"><path d="M23,4H1c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1Zm1,17c0-.55-.45-1-1-1H1c-.55,0-1,.45-1,1s.45,1,1,1H23c.55,0,1-.45,1-1Zm-4-8v-2c0-2.21-1.79-4-4-4H8c-2.21,0-4,1.79-4,4v2c0,2.21,1.79,4,4,4h8c2.21,0,4-1.79,4-4Zm-4-4c1.1,0,2,.9,2,2v2c0,1.1-.9,2-2,2H8c-1.1,0-2-.9-2-2v-2c0-1.1,.9-2,2-2h8Z"/></svg>
							`,
                title: "Padding Y",
                toggle: true,
              },
              {
                name: "shadow",
                icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px"><g><path d="m12 22c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm0-18c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"/></g><g><path d="m18 13h-5c-.6 0-1-.4-1-1s.4-1 1-1h5c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m17 16h-4c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m14 19h-1c-.6 0-1-.4-1-1s.4-1 1-1h1c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m17 10h-4c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m14 7h-1c-.6 0-1-.4-1-1s.4-1 1-1h1c.6 0 1 .4 1 1s-.4 1-1 1z"/></g></svg>
							`,
                title: "Shadow",
                toggle: true,
              },
              {
                name: "shrink",
                icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M9,2.5A6.5,6.5,0,0,1,2.5,9h-1a1.5,1.5,0,0,1,0-3h1A3.5,3.5,0,0,0,6,2.5v-1a1.5,1.5,0,0,1,3,0Z"/><path d="M16.5,24A1.5,1.5,0,0,1,15,22.5v-1A6.5,6.5,0,0,1,21.5,15h1a1.5,1.5,0,0,1,0,3h-1A3.5,3.5,0,0,0,18,21.5v1A1.5,1.5,0,0,1,16.5,24Z"/><path d="M22.5,9h-1A6.5,6.5,0,0,1,15,2.5v-1a1.5,1.5,0,0,1,3,0v1A3.5,3.5,0,0,0,21.5,6h1a1.5,1.5,0,0,1,0,3Z"/><path d="M7.5,24A1.5,1.5,0,0,1,6,22.5v-1A3.5,3.5,0,0,0,2.5,18h-1a1.5,1.5,0,0,1,0-3h1A6.5,6.5,0,0,1,9,21.5v1A1.5,1.5,0,0,1,7.5,24Z"/></svg>
							`,
                title: "Shrink 1",
                toggle: true,
              },
              {
                name: "shrink2",
                icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M9,2.5A6.5,6.5,0,0,1,2.5,9h-1a1.5,1.5,0,0,1,0-3h1A3.5,3.5,0,0,0,6,2.5v-1a1.5,1.5,0,0,1,3,0Z"/><path d="M16.5,24A1.5,1.5,0,0,1,15,22.5v-1A6.5,6.5,0,0,1,21.5,15h1a1.5,1.5,0,0,1,0,3h-1A3.5,3.5,0,0,0,18,21.5v1A1.5,1.5,0,0,1,16.5,24Z"/><path d="M22.5,9h-1A6.5,6.5,0,0,1,15,2.5v-1a1.5,1.5,0,0,1,3,0v1A3.5,3.5,0,0,0,21.5,6h1a1.5,1.5,0,0,1,0,3Z"/><path d="M7.5,24A1.5,1.5,0,0,1,6,22.5v-1A3.5,3.5,0,0,0,2.5,18h-1a1.5,1.5,0,0,1,0-3h1A6.5,6.5,0,0,1,9,21.5v1A1.5,1.5,0,0,1,7.5,24Z"/></svg>
							`,
                title: "Shrink 2",
                toggle: true,
              },
              {
                name: "shrink3",
                icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M9,2.5A6.5,6.5,0,0,1,2.5,9h-1a1.5,1.5,0,0,1,0-3h1A3.5,3.5,0,0,0,6,2.5v-1a1.5,1.5,0,0,1,3,0Z"/><path d="M16.5,24A1.5,1.5,0,0,1,15,22.5v-1A6.5,6.5,0,0,1,21.5,15h1a1.5,1.5,0,0,1,0,3h-1A3.5,3.5,0,0,0,18,21.5v1A1.5,1.5,0,0,1,16.5,24Z"/><path d="M22.5,9h-1A6.5,6.5,0,0,1,15,2.5v-1a1.5,1.5,0,0,1,3,0v1A3.5,3.5,0,0,0,21.5,6h1a1.5,1.5,0,0,1,0,3Z"/><path d="M7.5,24A1.5,1.5,0,0,1,6,22.5v-1A3.5,3.5,0,0,0,2.5,18h-1a1.5,1.5,0,0,1,0-3h1A6.5,6.5,0,0,1,9,21.5v1A1.5,1.5,0,0,1,7.5,24Z"/></svg>
							`,
                title: "Shrink 3",
                toggle: true,
              },
              {
                name: "center",
                icon: `<svg viewBox="0 0 24 24" class="rotate-90" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M7,12c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm6,0c0,.552,.448,1,1,1s1-.448,1-1-.448-1-1-1-1,.448-1,1Zm-4,0c0,.552,.448,1,1,1s1-.448,1-1-.448-1-1-1-1,.448-1,1Zm-7-1c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm16,0c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm4,0c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm-11.414-2.586c.39,.39,.902,.585,1.414,.585s1.024-.195,1.414-.585l3.293-3.293c.391-.391,.391-1.023,0-1.414s-1.023-.391-1.414,0l-2.293,2.293V1c0-.553-.448-1-1-1s-1,.447-1,1V6l-2.293-2.293c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l3.293,3.293Zm2.828,7.172c-.779-.779-2.049-.779-2.828,0l-3.293,3.293c-.391,.391-.391,1.023,0,1.414s1.023,.391,1.414,0l2.293-2.293v5c0,.553,.448,1,1,1s1-.447,1-1v-5l2.293,2.293c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414l-3.293-3.293Z"/></svg>
							`,
                title: "Center",
                toggle: true,
              },
            ],
          },
        },
        header: {
          class: Header,
          config: {
            placeholder: "Enter a header",
            levels: [1, 2, 3, 4],
            defaultLevel: 3,
          },
          inlineToolbar: ["bold", "italic", "link"],
          tunes: ["alignment"],
        },
        quote: Quote,
        marker: Marker,
        delimiter: Delimiter,
        alignment: {
          class: Alignment,
        },
        columns: {
          class: Columns,
          config: {
            tools: {
              header: {
                class: Header,
                config: {
                  placeholder: "Enter a header",
                  levels: [1, 2, 3, 4],
                  defaultLevel: 3,
                },
                inlineToolbar: ["bold", "italic", "link"],
                tunes: ["alignment"],
              },
              paragraph: {
                class: Paragraph,
                config: {
                  placeholder: "Enter text",
                },
                inlineToolbar: ["bold", "italic", "link"],
                tunes: ["alignment"],
              },
              image: {
                class: ImageTest,
                config: {
                  uploader: {
                    /**
                     * Upload file to the server and return an uploaded image data
                     * @param {File} file - file selected from the device or pasted by drag-n-drop
                     * @return {Promise.<{success, file: {url}}>}
                     */
                    async uploadByFile(file) {
                      var formData = new FormData();
                      formData.append("image", file);
                      return await axios
                        .post(
                          `${DOMAIN}/api/knowledgeHub/uploadImage`,
                          formData,
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                              "jwt-token": user.jwtToken,
                            },
                          }
                        )
                        .then((results) => {
                          // keep track of images, add the url of each new image to our array
                          props.setImagesUploaded((x) => [
                            ...x,
                            STORAGE_DOMAIN + results.data.file.url,
                          ]);
                          props.setEditorModification(true);
                          return {
                            ...results.data,
                            file: {
                              url: STORAGE_DOMAIN + results.data.file.url,
                            },
                          };
                        });
                    },
                    /**
                     * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
                     * @param {string} url - pasted image URL
                     * @return {Promise.<{success, file: {url}}>}
                     */
                    async uploadByUrl(url) {
                      //TODO: Upload image by link;
                    },
                  },
                  actions: [
                    {
                      name: "borderRadius",
                      icon: `<svg viewBox="0 0 24 24" fill="currentColor" style="width:14px !important; height:14px !important" stroke-width="0px"><path d="M1.5,24c-.83,0-1.5-.67-1.5-1.5V5.5C0,2.47,2.47,0,5.5,0H22.5c.83,0,1.5,.67,1.5,1.5s-.67,1.5-1.5,1.5H5.5c-1.38,0-2.5,1.12-2.5,2.5V22.5c0,.83-.67,1.5-1.5,1.5Zm17.5-1.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm-11,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm16,0c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm0-5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm0-5.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Zm0-5.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5ZM13.5,22.5c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5,1.5-.67,1.5-1.5Z"/></svg>
                                        `,
                      title: "Border radius",
                      toggle: true,
                    },
                    {
                      name: "paddingY",
                      icon: `<svg viewBox="0 0 24 24" fill="currentColor" style="width:14px !important; height:14px !important" stroke-width="0px"><path d="M23,4H1c-.55,0-1-.45-1-1s.45-1,1-1H23c.55,0,1,.45,1,1s-.45,1-1,1Zm1,17c0-.55-.45-1-1-1H1c-.55,0-1,.45-1,1s.45,1,1,1H23c.55,0,1-.45,1-1Zm-4-8v-2c0-2.21-1.79-4-4-4H8c-2.21,0-4,1.79-4,4v2c0,2.21,1.79,4,4,4h8c2.21,0,4-1.79,4-4Zm-4-4c1.1,0,2,.9,2,2v2c0,1.1-.9,2-2,2H8c-1.1,0-2-.9-2-2v-2c0-1.1,.9-2,2-2h8Z"/></svg>
										`,
                      title: "Padding Y",
                      toggle: true,
                    },
                    {
                      name: "shadow",
                      icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px"><g><path d="m12 22c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm0-18c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"/></g><g><path d="m18 13h-5c-.6 0-1-.4-1-1s.4-1 1-1h5c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m17 16h-4c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m14 19h-1c-.6 0-1-.4-1-1s.4-1 1-1h1c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m17 10h-4c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1z"/></g><g><path d="m14 7h-1c-.6 0-1-.4-1-1s.4-1 1-1h1c.6 0 1 .4 1 1s-.4 1-1 1z"/></g></svg>
                                        `,
                      title: "Shadow",
                      toggle: true,
                    },
                    {
                      name: "shrink",
                      icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M9,2.5A6.5,6.5,0,0,1,2.5,9h-1a1.5,1.5,0,0,1,0-3h1A3.5,3.5,0,0,0,6,2.5v-1a1.5,1.5,0,0,1,3,0Z"/><path d="M16.5,24A1.5,1.5,0,0,1,15,22.5v-1A6.5,6.5,0,0,1,21.5,15h1a1.5,1.5,0,0,1,0,3h-1A3.5,3.5,0,0,0,18,21.5v1A1.5,1.5,0,0,1,16.5,24Z"/><path d="M22.5,9h-1A6.5,6.5,0,0,1,15,2.5v-1a1.5,1.5,0,0,1,3,0v1A3.5,3.5,0,0,0,21.5,6h1a1.5,1.5,0,0,1,0,3Z"/><path d="M7.5,24A1.5,1.5,0,0,1,6,22.5v-1A3.5,3.5,0,0,0,2.5,18h-1a1.5,1.5,0,0,1,0-3h1A6.5,6.5,0,0,1,9,21.5v1A1.5,1.5,0,0,1,7.5,24Z"/></svg>
                                        `,
                      title: "Shrink 1",
                      toggle: true,
                    },
                    {
                      name: "shrink2",
                      icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M9,2.5A6.5,6.5,0,0,1,2.5,9h-1a1.5,1.5,0,0,1,0-3h1A3.5,3.5,0,0,0,6,2.5v-1a1.5,1.5,0,0,1,3,0Z"/><path d="M16.5,24A1.5,1.5,0,0,1,15,22.5v-1A6.5,6.5,0,0,1,21.5,15h1a1.5,1.5,0,0,1,0,3h-1A3.5,3.5,0,0,0,18,21.5v1A1.5,1.5,0,0,1,16.5,24Z"/><path d="M22.5,9h-1A6.5,6.5,0,0,1,15,2.5v-1a1.5,1.5,0,0,1,3,0v1A3.5,3.5,0,0,0,21.5,6h1a1.5,1.5,0,0,1,0,3Z"/><path d="M7.5,24A1.5,1.5,0,0,1,6,22.5v-1A3.5,3.5,0,0,0,2.5,18h-1a1.5,1.5,0,0,1,0-3h1A6.5,6.5,0,0,1,9,21.5v1A1.5,1.5,0,0,1,7.5,24Z"/></svg>
                                        `,
                      title: "Shrink 2",
                      toggle: true,
                    },
                    {
                      name: "shrink3",
                      icon: `<svg viewBox="0 0 24 24" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M9,2.5A6.5,6.5,0,0,1,2.5,9h-1a1.5,1.5,0,0,1,0-3h1A3.5,3.5,0,0,0,6,2.5v-1a1.5,1.5,0,0,1,3,0Z"/><path d="M16.5,24A1.5,1.5,0,0,1,15,22.5v-1A6.5,6.5,0,0,1,21.5,15h1a1.5,1.5,0,0,1,0,3h-1A3.5,3.5,0,0,0,18,21.5v1A1.5,1.5,0,0,1,16.5,24Z"/><path d="M22.5,9h-1A6.5,6.5,0,0,1,15,2.5v-1a1.5,1.5,0,0,1,3,0v1A3.5,3.5,0,0,0,21.5,6h1a1.5,1.5,0,0,1,0,3Z"/><path d="M7.5,24A1.5,1.5,0,0,1,6,22.5v-1A3.5,3.5,0,0,0,2.5,18h-1a1.5,1.5,0,0,1,0-3h1A6.5,6.5,0,0,1,9,21.5v1A1.5,1.5,0,0,1,7.5,24Z"/></svg>
                                        `,
                      title: "Shrink 3",
                      toggle: true,
                    },
                    {
                      name: "center",
                      icon: `<svg viewBox="0 0 24 24" class="rotate-90" fill="currentColor" stroke-width="0px" style="width:14px !important; height:14px !important"><path d="M7,12c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm6,0c0,.552,.448,1,1,1s1-.448,1-1-.448-1-1-1-1,.448-1,1Zm-4,0c0,.552,.448,1,1,1s1-.448,1-1-.448-1-1-1-1,.448-1,1Zm-7-1c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm16,0c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm4,0c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm-11.414-2.586c.39,.39,.902,.585,1.414,.585s1.024-.195,1.414-.585l3.293-3.293c.391-.391,.391-1.023,0-1.414s-1.023-.391-1.414,0l-2.293,2.293V1c0-.553-.448-1-1-1s-1,.447-1,1V6l-2.293-2.293c-.391-.391-1.023-.391-1.414,0s-.391,1.023,0,1.414l3.293,3.293Zm2.828,7.172c-.779-.779-2.049-.779-2.828,0l-3.293,3.293c-.391,.391-.391,1.023,0,1.414s1.023,.391,1.414,0l2.293-2.293v5c0,.553,.448,1,1,1s1-.447,1-1v-5l2.293,2.293c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414l-3.293-3.293Z"/></svg>
                                        `,
                      title: "Center",
                      toggle: true,
                    },
                  ],
                },
              },
              delimiter: Delimiter,
              alignment: {
                class: Alignment,
              },
              list: {
                class: List,
                inlineToolbar: ["bold", "italic", "link"],
              },
            },
          },
        },
      }}
    />
  );
}
