import axios from "axios";
import { message } from "antd";
import {DOMAIN} from "../../env";

const modifyLinks = () => {
	const links = document.getElementsByTagName("a");
	for (const link of links) {
		link.setAttribute("target", "_blank");
		link.setAttribute("rel", "noopener");
	}
};

const getCurrentImages = () => {
	const currentImages = [];
	document
		.querySelectorAll(".image-tool__image-picture")
		.forEach((imageElement) => {
			const imageSrc = imageElement.src;
			currentImages.push(imageSrc.substring(imageSrc.lastIndexOf("/") + 1));
		});
	return currentImages;
};

const deleteImage = async (imageId, jwtToken) => {
	try {
		const response = await axios.get(
			`${DOMAIN}/api/knowledgeHub/deleteImage/${imageId}`,
			{
				headers: {
					"jwt-token": jwtToken,
				},
			}
		);
		return response.status === 200;
	} catch (err) {
		console.log(err.message);
		return false;
	}
};

const handleChange = async (
	imagesUploaded,
	setImagesUploaded,
	jwtToken,
	setEditorModification
) => {
	modifyLinks();

	const currentImages = getCurrentImages();
	setImagesUploaded(currentImages);

	for (const imageSrc of imagesUploaded) {
		const imageId = imageSrc
		if (!currentImages.includes(imageId)) {
			const imageDeleted = await deleteImage(imageId, jwtToken);
			if (imageDeleted) {
				setEditorModification(true);
				setImagesUploaded((images) => images.filter((x) => x !== imageSrc));
				message.success({
					content: `Image deleted!`,
					className: "text-center",
					duration: 2,
				});
			}
		}
	}
};

export { handleChange };
