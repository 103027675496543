import { useState, useContext } from "react";
import { Modal, message } from "antd";
import UserContext from "../../context/context";
import axios from "axios";
import TextareaAutosize from "react-textarea-autosize";
import { useHistory } from "react-router-dom";
import {DOMAIN} from "../../env";

export default function NewPostModal() {
	const history = useHistory();
	const [isVisible, setIsVisible] = useState(false);

	const user = useContext(UserContext);
	const [description, setDescription] = useState({
		state: "",
		error: "",
	});
	const [title, setTitle] = useState({ state: "", error: "" });
	const [location, setLocation] = useState({ state: "", error: "" });
	const [link, setLink] = useState({
		state: "",
		error: "",
	});

	const close = () => {
		setLocation({ state: "", error: "" });
		setTitle({ state: "", error: "" });
		setDescription({ state: "", error: "" });
		setLink({ state: "", error: "" });
		setIsVisible(false);
	};

	const addNewPost = () => {
		const loadingMessage = message.loading("Loading...", 0);
		axios
			.post(
				`${DOMAIN}/api/careers/editCareerPost/add`,
				{
					jobDescription1: description.state,
					jobTitle: title.state,
					jobLocation: location.state,
					jobLinkBambooHR: link.state,
					published: false,
				},
				{
					headers: {
						"jwt-token": user.jwtToken,
					},
				}
			)
			.then(function (response) {
				if (response.status === 200) {
					close();
					history.push(`/careers/${response.data.id}`);
					message.success({
						content: "Post Saved!",
						className: "text-center",
						duration: 2,
					});
				}
			})
			.catch(function (error) {
				console.log(error);
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			})
			.finally(() => {
				loadingMessage();
			});
	};

	return (
		<div>
			<Modal
				title="Add new post"
				open={isVisible}
				onOk={() => addNewPost()}
				onCancel={() => close()}
				width={800}
			>
				<div className="grid grid-cols-2 gap-5">
					<div className="relative col-span-2 flex w-full flex-col gap-x-5 gap-y-3 md:col-span-1">
						<input
							className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:outline-none focus:ring-0 ${
								title.error?.length !== 0
									? "border-orange-1 focus:ring-orange-1"
									: ""
							}`}
							value={title.state}
							onChange={(e) => setTitle({ ...title, state: e.target.value })}
							placeholder="Ex: Marketing Automation Data Scientist"
						/>
						<label
							htmlFor="title"
							className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
						>
							Title
						</label>
						<div className="absolute">
							<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
								{title.error}
							</p>
						</div>
					</div>
					<div className="relative col-span-2 flex w-full flex-col gap-x-5 gap-y-3 md:col-span-1">
						<input
							id="location"
							name="location"
							className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:outline-none focus:ring-0 ${
								location.error?.length !== 0 ? "border-orange-1" : ""
							}`}
							onChange={(e) =>
								setLocation({ ...location, state: e.target.value })
							}
							value={location.state}
							placeholder="Ex: Bucharest, Iasi"
						/>
						<label
							htmlFor="location"
							className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
						>
							Location
						</label>
						<div className="absolute">
							<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
								{location.error}
							</p>
						</div>
					</div>
					<div className="relative col-span-2 flex w-full flex-col gap-x-5 gap-y-3">
						<input
							id="link"
							name="link"
							className={` peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:outline-none focus:ring-0 ${
								link.error?.length !== 0 ? "border-orange-1" : ""
							}`}
							placeholder="link"
							onChange={(e) =>
								setLink({
									...link,
									state: e.target.value,
								})
							}
							value={link.state}
						/>
						<label
							htmlFor="link"
							className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
						>
							Link
						</label>
						<div className="absolute">
							<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
								{link.error}
							</p>
						</div>
					</div>
					<div className="relative col-span-2 flex w-full flex-col gap-x-5 gap-y-3">
						<TextareaAutosize
							id="description"
							name="description"
							placeholder="description"
							className={`peer h-full w-full appearance-none rounded-xl border-2 py-3 px-3 font-raleway text-lg leading-tight placeholder-transparent focus:border-green-11 focus:outline-none focus:ring-0 ${
								description.error?.length !== 0
									? "border-orange-1 focus:ring-orange-1"
									: ""
							}`}
							value={description.state}
							onChange={(e) =>
								setDescription({
									...description,
									state: e.target.value,
								})
							}
						/>
						<label
							htmlFor="jobDescription"
							className="absolute -top-2 left-3 transform rounded-full bg-white px-1 font-poppins text-sm font-bold text-grey-1 transition-all duration-300 peer-placeholder-shown:top-[0.65rem] peer-placeholder-shown:text-lg peer-focus:-top-2 peer-focus:text-sm"
						>
							Description
						</label>
						<div className="absolute">
							<p className="m-0 pt-1 font-raleway text-sm text-orange-1">
								{description.error}
							</p>
						</div>
					</div>
				</div>
			</Modal>
			<button
				onClick={() => setIsVisible(true)}
				className="flex h-full w-full min-w-[10rem] cursor-pointer items-center justify-center gap-2 rounded-2xl bg-grey-1 px-6 py-3 shadow-lg transition-all duration-300 hover:bg-green-11"
			>
				<svg
					className=" h-5 w-5 fill-current text-white"
					viewBox="0 0 24 24"
					data-name="Layer 1"
				>
					<path d="m16 16a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1zm6-5.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z" />
				</svg>
				<p className=" font-poppins text-base font-bold uppercase text-white">
					new post
				</p>
			</button>
		</div>
	);
}
