import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import UserContext from "../../context/context";
import { message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import {DOMAIN} from "../../env";

function QuesitonBank() {
	const user = useContext(UserContext);
	const history = useHistory();
	const { id } = useParams();

	const [data, setData] = useState();

	const [questionBankName, setQuestionBankName] = useState({
		state: "",
		error: "",
	});

	useEffect(() => {
		const fetchApiData = async () => {
			try {
				if (
					!["Developer", "Marketing", "HumanResources", "Admin"].includes(
						user.user.user_role
					)
				) {
					message.error({
						content: `Unauthorized!`,
						className: "text-center",
						duration: 2,
					});
					history.push("/");
				} else {
					await axios
						.get(
							`${DOMAIN}/api/careers_quiz/get_question_bank/${id}`,
							{
								headers: {
									"jwt-token": user.jwtToken,
								},
							}
						)
						.then((res) => {
							setData(res.data.data);
						});
				}
			} catch (Error) {}
		};
		fetchApiData();
	}, [history, user.jwtToken, user.user.user_role, id]);

	if (data === undefined)
		return (
			<div className="h-screen flex justify-center items-center bg-white">
				<div className="flex flex-col items-center justify-center gap-5">
					<div className="w-40 h-40 border-t-4 border-b-4 border-green-11 rounded-full animate-spin"></div>
				</div>
			</div>
		);

	return (
		<>
			<div className="flex flex-col justify-between items-center gap-5">
				<div className="bg-grey-1 p-5 w-full shadow-lg">
					<p className=" font-bold font-poppins text-2xl text-white text-center lg:text-left mb-0">
						Question Bank : {data.name}
					</p>
				</div>
				<div className="flex flex-row gap-5 justify-center w-full px-5">
					<Link
						to={`/careerQuiz/questionBank/${id}`}
						className={`flex flex-row gap-2 items-center cursor-pointer px-4 w-full justify-center py-3 rounded-2xl shadow-lg bg-green-11 select-none`}
					>
						<div>
							<svg
								viewBox="0 0 24 24"
								className=" fill-current text-white w-5 h-5"
							>
								<path d="M18.5,24H13.034A13.5,13.5,0,0,1,3,19.789a11.467,11.467,0,0,1-2.947-8.9A12.024,12.024,0,0,1,9.908.188a11.641,11.641,0,0,1,9.654,2.569A12.448,12.448,0,0,1,24,12.324V18.5A5.507,5.507,0,0,1,18.5,24ZM12.03,3a9.021,9.021,0,0,0-8.988,8.164,8.509,8.509,0,0,0,2.18,6.605A10.5,10.5,0,0,0,13.034,21H18.5A2.5,2.5,0,0,0,21,18.5V12.324a9.466,9.466,0,0,0-3.366-7.27A8.637,8.637,0,0,0,12.03,3ZM12,6a1.5,1.5,0,0,0,0,3A1.5,1.5,0,0,0,12,6Zm.5,13A1.5,1.5,0,0,1,11,17.5V13h-.5a1.5,1.5,0,0,1,0-3h1A2.5,2.5,0,0,1,14,12.5v5A1.5,1.5,0,0,1,12.5,19Z" />
							</svg>
						</div>
						<p className=" font-raleway text-xl font-bold text-white text-center hidden md:block">
							Info
						</p>
					</Link>
					<Link
						to={`/careerQuiz/questionBank/questions/${id}`}
						className={`cursor-pointer px-4 w-full justify-center py-3 flex flex-row gap-2 items-center rounded-2xl shadow-lg bg-grey-3 hover:bg-green-11 transition-all duration-500 select-none`}
					>
						<div>
							<svg
								className=" fill-current text-white w-6 h-6"
								viewBox="0 0 24 24"
							>
								<path d="M9,11c-.552,0-1-.447-1-1,0-1.308,1.038-1.879,1.481-2.123,.29-.159,.595-.535,.502-1.066-.069-.392-.402-.725-.793-.793-.306-.056-.602,.022-.832,.216-.228,.19-.358,.47-.358,.767,0,.553-.448,1-1,1s-1-.447-1-1c0-.889,.391-1.727,1.072-2.299,.681-.572,1.577-.814,2.463-.653,1.209,.211,2.204,1.205,2.417,2.417,.223,1.272-.382,2.543-1.506,3.164-.447,.246-.447,.318-.447,.371,0,.553-.448,1-1,1Zm0,1c-.69,0-1.25,.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Zm10.996-2.92c-.006,.769-.091,1.518-.248,2.242,1.371,1.101,2.252,2.787,2.252,4.678v5c0,.552-.448,1-1,1h-5c-1.891,0-3.577-.881-4.678-2.252-.724,.156-1.473,.242-2.242,.248,1.385,2.389,3.965,4.004,6.92,4.004h5c1.657,0,3-1.343,3-3v-5c0-2.955-1.615-5.535-4.004-6.92Zm-2.019,.571c.185-2.613-.768-5.17-2.613-7.016S10.964-.167,8.349,.023C3.823,.343,0,4.589,0,9.296v5.038c0,2.021,1.642,3.666,3.661,3.666h4.477c5.187,0,9.509-3.667,9.839-8.349Zm-4.027-5.601c1.436,1.435,2.176,3.425,2.033,5.46-.253,3.578-3.772,6.489-7.845,6.489H3.661c-.916,0-1.661-.747-1.661-1.666v-5.038c0-3.696,2.972-7.029,6.49-7.278,.167-.012,.333-.018,.499-.018,1.858,0,3.644,.732,4.961,2.051Z" />
							</svg>
						</div>
						<p className=" font-raleway text-xl font-bold text-white text-center hidden md:block">
							Questions
						</p>
					</Link>
					<Link
						to={`/careerQuiz/questionBank/submissions/${id}`}
						className={`flex flex-row gap-2 items-center cursor-pointer px-4 w-full justify-center py-3 rounded-2xl shadow-lg bg-grey-3 hover:bg-green-11 transition-all duration-500 select-none`}
					>
						<div>
							<svg
								viewBox="0 0 24 24"
								className=" fill-current text-white w-5 h-5"
							>
								<path d="M18.5,24H13.034A13.5,13.5,0,0,1,3,19.789a11.467,11.467,0,0,1-2.947-8.9A12.024,12.024,0,0,1,9.908.188a11.641,11.641,0,0,1,9.654,2.569A12.448,12.448,0,0,1,24,12.324V18.5A5.507,5.507,0,0,1,18.5,24ZM12.03,3a9.021,9.021,0,0,0-8.988,8.164,8.509,8.509,0,0,0,2.18,6.605A10.5,10.5,0,0,0,13.034,21H18.5A2.5,2.5,0,0,0,21,18.5V12.324a9.466,9.466,0,0,0-3.366-7.27A8.637,8.637,0,0,0,12.03,3ZM10.5,17a2.5,2.5,0,0,1-1.771-.732L5.956,13.577a1.5,1.5,0,0,1,2.088-2.154L10.5,13.8l5.45-5.369a1.5,1.5,0,1,1,2.106,2.136L12.26,16.275A2.476,2.476,0,0,1,10.5,17Z" />
							</svg>
						</div>
						<p className=" font-raleway text-xl font-bold text-white text-center hidden md:block">
							Submissions
						</p>
					</Link>
				</div>
			</div>
			<div className="p-5">
				<div className="relative pb-5">
					<p className="font-montserrat font-bold text-xl w-72 py-2">
						Question Bank Name
					</p>
					<input
						value={data.name}
						placeholder="Please complete the question bank name"
						onChange={(e) =>
							setQuestionBankName({
								...questionBankName,
								state: e.target.value,
							})
						}
						className={` h-12 appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 ${
							questionBankName.error?.length !== 0
								? "border-orange-1 focus:ring-orange-1"
								: "border-green-11 focus:ring-green-11"
						} text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent`}
					/>
					<div
						className={`absolute transform transition-all duration-500 ${
							questionBankName.error?.length !== 0
								? "translate-y-0"
								: "translate-y-2"
						}`}
					>
						<p className="font-raleway text-sm text-orange-1 pt-1 m-0">
							{questionBankName.error}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default QuesitonBank;
