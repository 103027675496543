import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const KnowledgeHubLink = (props) => {
	const location = useLocation();
	if (props.permitedUser.includes(props.userRole))
		return (
			<Link
				to="/knowledge-hub"
				onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
				className={`p-3 flex relative overflow-x-hidden items-center rounded-lg group ${
					location.pathname.includes("knowledge-hub")
						? "bg-green-11"
						: "bg-grey-7"
				} hover:bg-green-11 hover:text-gray-50 cursor-pointer transition-all duration-500`}
			>
				<div className="w-full ml-0.5">
					<svg
						className="fill-current text-white h-7 w-7 lg:h-5 lg:w-5"
						viewBox="0 0 24 24"
					>
						<path d="m17 14a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-4 3h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-6.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z" />
					</svg>
				</div>
				<div className=" absolute left-16 lg:left-12">
					<p
						className={`font-bold text-green-50 font-raleway text-lg lg:text-base w-full truncate`}
					>
						Knowledge Hub
					</p>
				</div>
			</Link>
		);
};

const AuditAppLink = (props) => {
	const location = useLocation();
	if (props.permitedUser.includes(props.userRole))
		return (
			<Link
				to="/eloqua-audit"
				onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
				className={`p-3 flex relative overflow-x-hidden items-center rounded-lg ${
					location.pathname.includes("eloqua-audit")
						? "bg-green-11"
						: "bg-grey-7"
				} hover:bg-green-11 hover:text-gray-50 cursor-pointer transition-all duration-500`}
			>
				<div className="w-full ml-0.5">
					<svg
						className="fill-current text-white h-7 w-7 lg:h-5 lg:w-5"
						viewBox="0 0 24 24"
					>
						<path d="M23,22H5a3,3,0,0,1-3-3V1A1,1,0,0,0,0,1V19a5.006,5.006,0,0,0,5,5H23a1,1,0,0,0,0-2Z" />
						<path d="M6,20a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v7A1,1,0,0,0,6,20Z" />
						<path d="M10,10v9a1,1,0,0,0,2,0V10a1,1,0,0,0-2,0Z" />
						<path d="M15,13v6a1,1,0,0,0,2,0V13a1,1,0,0,0-2,0Z" />
						<path d="M20,9V19a1,1,0,0,0,2,0V9a1,1,0,0,0-2,0Z" />
						<path d="M6,9a1,1,0,0,0,.707-.293l3.586-3.586a1.025,1.025,0,0,1,1.414,0l2.172,2.172a3,3,0,0,0,4.242,0l5.586-5.586A1,1,0,0,0,22.293.293L16.707,5.878a1,1,0,0,1-1.414,0L13.121,3.707a3,3,0,0,0-4.242,0L5.293,7.293A1,1,0,0,0,6,9Z" />
					</svg>
				</div>
				<div className=" absolute left-16 lg:left-12">
					<p
						className={`font-bold text-green-50 font-raleway text-lg lg:text-base w-full truncate`}
					>
						Audit App
					</p>
				</div>
			</Link>
		);
};

const CareerQuizLink = (props) => {
	const location = useLocation();
	if (props.permitedUser.includes(props.userRole))
		return (
			<Link
				to="/careerQuiz"
				onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
				className={`p-3 flex relative overflow-x-hidden items-center rounded-lg ${
					location.pathname.includes("careerQuiz") ? "bg-green-11" : "bg-grey-7"
				} hover:bg-green-11 hover:text-gray-50 cursor-pointer transition-all duration-500`}
			>
				<div className="w-full ml-0.5">
					<svg className="fill-current text-white h-7 w-7 lg:h-5 lg:w-5" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve"><path d="M470.549,111.573L313.237,36.629c-34.628-20.684-77.728-21.051-112.704-0.96L41.451,111.573  c-0.597,0.299-1.216,0.619-1.792,0.96c-37.752,21.586-50.858,69.689-29.272,107.441c7.317,12.798,18.08,23.284,31.064,30.266  l43.883,20.907V375.68c0.026,46.743,30.441,88.039,75.072,101.931c31.059,8.985,63.264,13.384,95.595,13.056  c32.326,0.362,64.531-4,95.595-12.949c44.631-13.891,75.046-55.188,75.072-101.931V271.104l42.667-20.395v175.957  c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333v-256C512.143,145.615,492.363,122.473,470.549,111.573z   M384,375.787c0.011,27.959-18.129,52.69-44.8,61.077c-27.046,7.728-55.073,11.479-83.2,11.136  c-28.127,0.343-56.154-3.408-83.2-11.136c-26.671-8.388-44.811-33.118-44.8-61.077v-84.309l70.763,33.707  c17.46,10.368,37.401,15.816,57.707,15.765c19.328,0.137,38.331-4.98,54.976-14.805L384,291.477V375.787z M452.267,211.733  l-160.896,76.8c-22.434,13.063-50.241,12.693-72.32-0.96l-157.419-74.88c-17.547-9.462-24.101-31.357-14.639-48.903  c3.2-5.934,7.998-10.853,13.85-14.201l159.893-76.373c22.441-13.034,50.233-12.665,72.32,0.96l157.312,74.944  c11.569,6.424,18.807,18.555,18.965,31.787C469.354,193.441,462.9,205.097,452.267,211.733L452.267,211.733z"/></svg>
				</div>
				<div className=" absolute left-16 lg:left-12">
					<p
						className={`font-bold text-green-50 font-raleway text-lg lg:text-base w-full truncate`}
					>
						Career Quiz
					</p>
				</div>
			</Link>
		);
};

const CareerPostsLink = (props) => {
	const location = useLocation();
	if (props.permitedUser.includes(props.userRole))
		return (
			<Link
				to="/careers"
				onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
				className={`p-3 flex relative overflow-x-hidden items-center rounded-lg ${
					location.pathname.includes("careers")
						? "bg-green-11"
						: "bg-grey-7"
				} hover:bg-green-11 hover:text-gray-50 cursor-pointer transition-all duration-500`}
			>
				<div className="w-full ml-0.5">
					<svg className="fill-current text-white h-7 w-7 lg:h-5 lg:w-5" viewBox="0 0 24 24"><path d="M19,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H5A5.006,5.006,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A5.006,5.006,0,0,0,19,4ZM11,2h2a3,3,0,0,1,2.816,2H8.184A3,3,0,0,1,11,2ZM5,6H19a3,3,0,0,1,3,3v3H2V9A3,3,0,0,1,5,6ZM19,22H5a3,3,0,0,1-3-3V14h9v1a1,1,0,0,0,2,0V14h9v5A3,3,0,0,1,19,22Z"/></svg>
				</div>
				<div className=" absolute left-16 lg:left-12">
					<p className={`font-bold text-green-50 font-raleway text-lg lg:text-base w-full truncate`} >
						Career Posts
					</p>
				</div>
			</Link>
		);
};

const UsersConfigLink = (props) => {
	const location = useLocation();
	if (props.permitedUser.includes(props.userRole))
		return (
			<Link
				to="/users"
				onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
				className={`p-3 flex relative overflow-x-hidden items-center rounded-lg ${
					location.pathname.includes("users") ? "bg-green-11" : "bg-grey-7"
				} hover:bg-green-11 hover:text-gray-50 cursor-pointer transition-all duration-500`}
			>
				<div className="w-full ml-0.5">
					<svg
						className="fill-current text-white h-7 w-7 lg:h-5 lg:w-5"
						viewBox="0 0 24 24"
					>
						<path d="m7.5 13a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5zm7.5 17v-.5a7.5 7.5 0 0 0 -15 0v.5a1 1 0 0 0 2 0v-.5a5.5 5.5 0 0 1 11 0v.5a1 1 0 0 0 2 0zm9-5a7 7 0 0 0 -11.667-5.217 1 1 0 1 0 1.334 1.49 5 5 0 0 1 8.333 3.727 1 1 0 0 0 2 0zm-6.5-9a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0 -2.5-2.5z" />
					</svg>
				</div>
				<div className=" absolute left-16 lg:left-12">
					<p
						className={`font-bold text-green-50 font-raleway text-lg lg:text-base w-full truncate`}
					>
						Users Config
					</p>
				</div>
			</Link>
		);
};

const PingPongLink = (props) => {
	const location = useLocation();
	if (props.permitedUser.includes(props.userRole))
		return (
			<Link
				to="/ping-pong"
				onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
				className={`p-3 flex relative overflow-x-hidden items-center rounded-lg ${
					location.pathname.includes("ping-pong") ? "bg-green-11" : "bg-grey-7"
				} hover:bg-green-11 hover:text-gray-50 cursor-pointer transition-all duration-500`}
			>
				<div className="w-full ml-0.5">
					<svg
						className="fill-current text-white h-7 w-7 lg:h-5 lg:w-5"
						viewBox="0 0 24 24"
					>
						<path d="M21.261,2.739A9.836,9.836,0,0,0,8.3,2.114,4.489,4.489,0,1,0,4.134,8.963a9.415,9.415,0,0,0,.842,5.668.5.5,0,0,1-.07.564L2,16.945A3.743,3.743,0,0,0,3.735,24a3.891,3.891,0,0,0,.457-.027,3.705,3.705,0,0,0,2.725-1.735l2.068-3.127a.5.5,0,0,1,.575-.089,9.663,9.663,0,0,0,11.315-2.147A10.5,10.5,0,0,0,24,9.758,9.409,9.409,0,0,0,21.261,2.739ZM2,4.5A2.5,2.5,0,1,1,4.5,7,2.5,2.5,0,0,1,2,4.5Zm8.44,12.726a2.494,2.494,0,0,0-3.017.632c-.024.029-.046.059-.067.09L5.229,21.166A1.742,1.742,0,0,1,2.02,20a1.76,1.76,0,0,1,.961-1.312l3.041-1.831a.956.956,0,0,0,.126-.09,2.49,2.49,0,0,0,.623-3.016,7.331,7.331,0,0,1-.693-4.259l8.433,8.433A7.31,7.31,0,0,1,10.44,17.226Zm9.021-1.765a8.871,8.871,0,0,1-2.732,1.865c-.009-.01-.012-.023-.022-.033L7.36,7.945A4.473,4.473,0,0,0,9,4.5c0-.119-.026-.231-.035-.347a8.01,8.01,0,0,1,10.882,0A7.423,7.423,0,0,1,22,9.7,8.506,8.506,0,0,1,19.461,15.461Z" />
					</svg>
				</div>
				<div className=" absolute left-16 lg:left-12">
					<p
						className={`font-bold text-green-50 font-raleway text-lg lg:text-base w-full truncate`}
					>
						Ping-pong
					</p>
				</div>
			</Link>
		);
};

export {
	KnowledgeHubLink,
	AuditAppLink,
	CareerQuizLink,
	CareerPostsLink,
	UsersConfigLink,
	PingPongLink,
};
