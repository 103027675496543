import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { message, Modal } from "antd";
import { useDetectOutsideClick } from "../../utils/useDetectOutsideClick";
import { Link } from "react-router-dom";
import UserContext from "../../context/context";
import { Helmet } from "react-helmet";
import {DOMAIN, STORAGE_DOMAIN} from "../../env";

export default function PostsList() {
	const user = useContext(UserContext);
	const history = useHistory();
	const [data, setData] = useState();
	const [filteredData, setFilteredData] = useState([]);
	const [filter, setFilter] = useState({
		category: [],
		name: "",
		published: true,
	});
	const [newPostModal, setNewPostModal] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [pagination, setPagination] = useState({
		firstItem: 0,
		lastItem: 12,
		numberOfPages: 0,
		currentPage: 1,
		itemsPerPage: 12,
	});
	const [showSelect, setShowSelect] = useState(false);

	const categoryRef = useRef(null);
	const [isCategoryActive, setIsCategoryActive] = useDetectOutsideClick(
		categoryRef,
		false
	);

	const toggleCategory = () => {
		setIsCategoryActive((isCategoryActive) => !isCategoryActive);
	};

	const toggleCategorySelection = (val) => {
		if (!filter.category.includes(val)) {
			setFilter({ ...filter, category: [...filter.category, val] });
		} else {
			setFilter({
				...filter,
				category: filter.category.filter((e) => e !== val),
			});
		}
	};

	const checkColorCategory = (arr) => {
		if (arr.length === 0) return "bg-grey-1";
		else if (arr.length === data.categories?.length) return "bg-green-11";
		else return "bg-orange-1";
	};

	const dataFilter = function (dataArr, filterArr) {
		let data = dataArr;
		if (filterArr.category.length !== 0)
			data =
				data && data.filter((i) => filterArr.category.includes(i.category));
		if (filterArr.name)
			data =
				data &&
				data.filter((i) =>
					i.title.toLowerCase().includes(filterArr.name.toLowerCase())
				);
		if (filterArr.published)
			data = data && data.filter((i) => i.published === true);
		if (!filterArr.published)
			data = data && data.filter((i) => i.published === false);
		return data;
	};

	useEffect(() => {
		const filterFunction = async () => {
			const newFilter = await dataFilter(data?.blogPosts, filter);
			if(newFilter){
				setFilteredData(newFilter);
				setPagination({
					...pagination,
					numberOfPages: Math.ceil(newFilter.length / pagination.itemsPerPage),
					currentPage: 1,
					firstItem: 0,
					lastItem: pagination.itemsPerPage,
				});
			}
		};
		filterFunction();
		// eslint-disable-next-line
	}, [filter]);

	useEffect(() => {
		if (!["Developer", "Marketing", "Admin"].includes(user.user.user_role)) {
			message.error({
				content: `Unauthorized!`,
				className: "text-center",
				duration: 2,
			});
			history.push("/");
		}
		const fetchApiData = async () => {
			try {
				axios
					.get(
						`${DOMAIN}/api/knowledgeHub/expertArticle/getAllPostsOneArr`,
						{
							headers: {
								"jwt-token": user.jwtToken,
							},
						}
					)
					.then((results) => {
						setData(results.data);
						setFilteredData(dataFilter(results.data.blogPosts, filter));
						setPagination({
							...pagination,
							numberOfPages: Math.ceil(
								results.data.blogPosts.length / pagination.itemsPerPage
							),
						});
					});
			} catch (error) {
				message.error({
					content: `Network Error!`,
					className: "text-center",
					duration: 2,
				});
			}
		};
		fetchApiData();
		// eslint-disable-next-line
	}, [history, user]);

	const addToRecentlyAccessed = (element) => {
		if (localStorage.getItem("RecentlyAccessed") === null) {
			const object = {
				data: element,
				date: new Date(),
				type: element.category,
				_id: element._id,
			};
			localStorage.setItem("RecentlyAccessed", JSON.stringify([object]));
		} else {
			var array = JSON.parse(localStorage.getItem("RecentlyAccessed") || "[]");
			const duplicate = (id) => {
				return array.some((el) => {
					return el.data._id === id;
				});
			};
			if (!duplicate(element._id)) {
				const object = {
					data: element,
					date: new Date(),
					type: element.category,
					_id: element._id,
				};
				array.unshift(object);
				if (array.length > 10) array.pop();
				localStorage.setItem("RecentlyAccessed", JSON.stringify(array));
			} else {
				array = array.sort(function (x, y) {
					return x._id === element._id ? -1 : y._id === element._id ? 1 : 0;
				});
				localStorage.setItem("RecentlyAccessed", JSON.stringify(array));
			}
		}
	};

	const cardBackground = (category) => {
		if (category === "caseStudy" || category === "caseStudyNew")
			return "bg-gradient-to-b from-orange-2 to-orange-3";
		if (category === "expertArticle") return "bg-green-11";
		if (category === "infographic")
			return "bg-gradient-to-b from-white via-grey-25 to-grey-25";
		if (category === "trialService") return "bg-grey-1";
	};

	const renderCards = () => {
		const cards = filteredData?.slice(pagination.firstItem, pagination.lastItem).map((element, index) => {
				if (element.category === "trialService")
					return (
						<Link
							onClick={() => addToRecentlyAccessed(element)}
							key={index}
							to={`/knowledge-hub/${element.category}/edit/${element._id}`}
							className="group relative h-48 z-0 col-span-1 mx-auto lg:h-56 w-full overflow-hidden rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl"
						>
							<div className="absolute z-10 h-full w-full bg-stone-800 opacity-60 transition-all duration-300 group-hover:opacity-10"></div>
							<div
								style={{
									backgroundImage: `url(${element.imageUrl})`,
									backgroundSize: "cover",
									backgroundPosition: "center",
								}}
								className={`${cardBackground(
									element.category
								)} absolute z-0 h-full w-full`}
							>
								{element.imageUrl ? null : (
									<div className="flex h-full items-center justify-center">
										<p className="text-center font-montserrat text-lg font-bold text-white">
											No image
										</p>
									</div>
								)}
							</div>
							<div className="relative z-20 flex h-full w-full items-start justify-start p-5">
								<p className="relative mt-auto h-14 font-montserrat text-lg font-bold text-white line-clamp-2">
									{element.title}
								</p>
							</div>
						</Link>
					);
				if (element.category === "caseStudyNew")
					return (
						<Link
							onClick={() => addToRecentlyAccessed(element)}
							key={index}
							to={`/knowledge-hub/${element.category}/edit/${element._id}`}
						>
							<div
								className={`group relative z-0 mx-auto h-48 overflow-hidden rounded-xl bg-gradient-to-b from-orange-2 to-orange-3 shadow-lg transition-all duration-300 hover:shadow-xl xl:h-56`}
							>
								<div className="absolute z-10 h-full w-full bg-stone-800 opacity-60 transition-all duration-300 group-hover:opacity-10"></div>
								<div className="absolute h-full w-full pb-14">
									{element.cardImage.length ? (
										<img
											alt="Featured"
											className="mx-auto h-full object-cover"
											key={element.cardImage}
											loading="lazy"
											src={STORAGE_DOMAIN+ element.cardImage}
										/>
									) : (
										<div className="flex h-full items-center justify-center">
											<p className="text-center font-montserrat text-lg font-bold text-white">
												No image
											</p>
										</div>
									)}
								</div>
								<div className="relative z-20 flex h-full w-full items-center justify-center p-5">
									<p className="line relative mt-auto h-14 font-montserrat text-lg font-bold text-white line-clamp-2">
										{element.title}
									</p>
								</div>
							</div>
						</Link>
					);
				return (
					<Link
						onClick={() => addToRecentlyAccessed(element)}
						key={index}
						to={`/knowledge-hub/${element.category}/edit/${element._id}`}
					>
						<div
							className={`${cardBackground(
								element.category
							)} group relative z-0 mx-auto h-48 overflow-hidden rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl xl:h-56`}
						>
							<div className="absolute z-10 h-full w-full bg-stone-800 opacity-60 transition-all duration-300 group-hover:opacity-10"></div>
							<div className="absolute h-full w-full pb-14">
								{element.imageUrl ? (
									<img
										alt="Featured"
										className="mx-auto h-full object-cover"
										key={element.imageUrl}
										loading="lazy"
										src={STORAGE_DOMAIN+element.imageUrl}
									/>
								) : (
									<div className="flex h-full items-center justify-center">
										<p className="text-center font-montserrat text-lg font-bold text-white">
											No image
										</p>
									</div>
								)}
							</div>
							<div className="relative z-20 flex h-full w-full items-center justify-center p-5">
								<p className="line relative mt-auto h-14 font-montserrat text-lg font-bold text-white line-clamp-2">
									{element.title}
								</p>
							</div>
						</div>
					</Link>
				);
		});

		if (cards.length) return cards;
		else {
			return [...new Array(12)].map((item, index) => (
				<div className="relative h-56 w-full rounded-xl bg-gray-600 " key={index} >
					<div className="absolute flex h-full w-full items-center justify-center pb-14">
						<div role="status">
							<svg aria-hidden="true" className="h-14 w-14 animate-spin fill-gray-500 text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /> <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /> </svg>
							<span className="sr-only">Loading...</span>
						</div>
					</div>
					<div className="relative z-20 flex h-full w-full items-center justify-center p-5">
						<div className="relative mt-auto flex h-14 w-full flex-col gap-2">
							<div className="h-full w-full animate-pulse rounded-xl bg-gray-500"></div>
							<div className="h-full w-4/5 animate-pulse rounded-xl bg-gray-500"></div>
						</div>
					</div>
				</div>
			));
		}
	};

	return (
		<div className="relative h-full">
			<Helmet>
				<title>Knowledge Hub</title>
			</Helmet>
			<div className="flex justify-center bg-grey-1 p-3  md:justify-start">
				<p className=" mb-0 font-poppins text-xl font-bold text-white md:text-2xl">
					Knowledge Hub
				</p>
			</div>
			<div className="p-3">
				<div className="flex flex-col justify-center gap-4 md:flex-row md:justify-between">
					<div className="flex justify-center gap-4 md:justify-start">
						<div
							onClick={() =>
								setFilter({ ...filter, published: !filter.published })
							}
							className={`${filter.published ? "bg-green-11" : "bg-orange-1" } flex w-full cursor-pointer items-center justify-center rounded-2xl py-3 shadow-lg transition-all duration-300 md:w-32`}
						>
							<p className=" font-poppins text-base font-bold uppercase text-white">
								{filter.published ? "Active" : "Draft"}
							</p>
						</div>
						<div>
							<button
								type="button"
								onClick={() => setNewPostModal(true)}
								className="flex w-full min-w-[10rem] cursor-pointer items-center justify-center gap-2 rounded-2xl bg-grey-1 px-6 py-3 shadow-lg transition-all duration-300 hover:bg-green-11"
							>
								<svg
									className=" h-5 w-5 fill-current text-white"
									viewBox="0 0 24 24"
									data-name="Layer 1"
								>
									<path d="m16 16a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1zm6-5.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z" />
								</svg>
								<p className=" font-poppins text-base font-bold uppercase text-white">
									new post
								</p>
							</button>
							<Modal
								title={
									<p className="font-poppins text-lg font-bold uppercase text-grey-1">
										new post
									</p>
								}
								open={newPostModal}
								onOk={() => setNewPostModal(false)}
								onCancel={() => setNewPostModal(false)}
								footer={null}
								closeIcon={
									<svg
										viewBox="0 0 24 24"
										className="h-6 w-6 fill-current text-grey-1 transition-all duration-300 hover:rotate-180"
									>
										<path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" />
									</svg>
								}
							>
								<div className="flex flex-col gap-3">
									<Link
										to={`/knowledge-hub/expertArticle/add`}
										className="w-full rounded-xl bg-green-11 py-4 px-6 shadow-lg"
									>
										<p className="text-center font-poppins text-lg font-bold uppercase text-white">
											Expert Article
										</p>
									</Link>
									<div className=" flex flex-row gap-5">
										<Link
											to={`/knowledge-hub/caseStudy/add`}
											className="w-full rounded-xl bg-gradient-to-b from-orange-2 to-orange-3 py-4 px-6 shadow-lg"
										>
											<p className="text-center font-poppins text-lg font-bold uppercase text-white">
												Case Study
											</p>
										</Link>
										<Link
											to={`/knowledge-hub/caseStudyNew/add`}
											className="w-full rounded-xl bg-gradient-to-b from-orange-2 to-orange-3 py-4 px-6 shadow-lg"
										>
											<p className="text-center font-poppins text-lg font-bold uppercase text-white">
												New Case Study
											</p>
										</Link>
									</div>
									<Link
										to={`/knowledge-hub/infographic/add`}
										className="w-full rounded-xl bg-gradient-to-b from-grey-4 via-grey-22 to-grey-22 py-4 px-6 shadow-lg"
									>
										<p className="text-center font-poppins text-lg font-bold uppercase text-white">
											Infographic
										</p>
									</Link>
									<Link
										to={`/knowledge-hub/trialService/add`}
										className="w-full rounded-xl bg-grey-1 py-4 px-6 shadow-lg"
									>
										<p className="text-center font-poppins text-lg font-bold uppercase text-white">
											Trial Service
										</p>
									</Link>
								</div>
							</Modal>
						</div>
					</div>
					<div>
						<div className="flex lg:hidden">
							<div
								onClick={() => setFilterModal(true)}
								className=" flex w-full items-center justify-center gap-3 rounded-2xl bg-grey-1 py-2 px-4 md:w-40"
							>
								<svg
									className=" h-5 w-5 fill-current text-white"
									viewBox="0 0 24 24"
								>
									<path d="m14 24a1 1 0 0 1 -.6-.2l-4-3a1 1 0 0 1 -.4-.8v-5.62l-7.016-7.893a3.9 3.9 0 0 1 2.916-6.487h14.2a3.9 3.9 0 0 1 2.913 6.488l-7.013 7.892v8.62a1 1 0 0 1 -1 1zm-3-4.5 2 1.5v-7a1 1 0 0 1 .253-.664l7.268-8.177a1.9 1.9 0 0 0 -1.421-3.159h-14.2a1.9 1.9 0 0 0 -1.421 3.158l7.269 8.178a1 1 0 0 1 .252.664z" />
								</svg>
								<p className="font-poppins text-lg font-bold uppercase text-white">
									filter
								</p>
							</div>
							<Modal
								title={
									<p className="font-poppins text-lg font-bold uppercase text-grey-1">
										Filter
									</p>
								}
								open={filterModal}
								onOk={() => setFilterModal(false)}
								onCancel={() => setFilterModal(false)}
								footer={null}
								closeIcon={
									<svg
										viewBox="0 0 24 24"
										className="h-6 w-6 fill-current text-grey-1 transition-all duration-300 hover:rotate-180"
									>
										<path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" />
									</svg>
								}
							>
								<div className=" space-y-5">
									<div className="relative">
										<label
											htmlFor="title"
											className="font-poppins text-lg font-bold uppercase text-grey-1"
										>
											Title
										</label>
										<input
											id="title"
											type="text"
											placeholder="Search by name"
											onChange={(e) =>
												setFilter({ ...filter, name: e.target.value })
											}
											className={`peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none`}
										/>
									</div>
									<div>
										<p className="font-poppins text-lg font-bold uppercase text-grey-1">
											Category
										</p>
										<div className=" flex flex-col space-y-2 rounded-xl border-2 px-2 py-3">
											{data &&
												data?.categories?.map((element, index) => {
													return (
														<div
															key={index}
															onClick={() => toggleCategorySelection(element)}
															className={`${filter.category.includes(element)
																? "bg-orange-1"
																: "bg-grey-1"
																} flex cursor-pointer flex-row items-center justify-center gap-3 rounded-xl py-2 px-3 transition-all duration-300`}
														>
															<p className="mr-auto w-11/12 font-montserrat font-bold capitalize text-white">
																{element.replace(/([A-Z])/g, " $1").trim()}
															</p>
														</div>
													);
												})}
										</div>
									</div>
									<div>
										<p className="font-poppins text-lg font-bold uppercase text-grey-1">
											Cards per page
										</p>
										<div className="flex flex-row gap-2 rounded-xl border-2 px-2 py-3">
											<div
												className={`${pagination.itemsPerPage === 12
													? "bg-green-11"
													: "bg-grey-1"
													} flex w-full justify-center rounded-xl py-2`}
												onClick={() =>
													setPagination({
														...pagination,
														itemsPerPage: 12,
														firstItem: 0,
														lastItem: 12,
														numberOfPages: Math.ceil(filteredData?.length / 12),
														currentPage: 1,
													})
												}
											>
												<p className="font-poppins text-base font-bold text-white">
													12
												</p>
											</div>
											<div
												className={`${pagination.itemsPerPage === 24
													? "bg-green-11"
													: "bg-grey-1"
													} flex w-full justify-center rounded-xl py-2`}
												onClick={() =>
													setPagination({
														...pagination,
														itemsPerPage: 24,
														firstItem: 0,
														lastItem: 24,
														numberOfPages: Math.ceil(filteredData?.length / 24),
														currentPage: 1,
													})
												}
											>
												<p className="font-poppins text-base font-bold text-white">
													24
												</p>
											</div>
											<div
												className={`${pagination.itemsPerPage === 48
													? "bg-green-11"
													: "bg-grey-1"
													} flex w-full justify-center rounded-xl py-2`}
												onClick={() =>
													setPagination({
														...pagination,
														itemsPerPage: 48,
														firstItem: 0,
														lastItem: 48,
														numberOfPages: Math.ceil(filteredData?.length / 48),
														currentPage: 1,
													})
												}
											>
												<p className="font-poppins text-base font-bold text-white">
													48
												</p>
											</div>
										</div>
									</div>
								</div>
							</Modal>
						</div>
						<div className="ml-auto hidden flex-row gap-5 lg:flex">
							<div ref={categoryRef} className="group relative">
								<div
									onClick={() => toggleCategory()}
									className={`${checkColorCategory(
										filter.category
									)} relative z-50 flex w-40 cursor-pointer flex-row items-center justify-center gap-4 rounded-2xl px-6 py-3 shadow-lg`}
								>
									<p className="font-montserrat text-base font-bold uppercase text-white">
										Category
									</p>
								</div>
								<div
									className={`${isCategoryActive
										? "translate-y-0 opacity-100"
										: " pointer-events-none -translate-y-10 opacity-0"
										} absolute z-40 mt-2 flex h-56 w-full flex-col justify-center gap-2 overflow-hidden rounded-2xl border-2 bg-grey-4 px-2 shadow-lg transition-all duration-500`}
								>
									{data &&
										data?.categories?.map((element, index) => {
											return (
												<div
													key={index}
													onClick={() => toggleCategorySelection(element)}
													className={`${filter.category.includes(element)
														? "bg-orange-1"
														: "bg-grey-1"
														} flex cursor-pointer flex-row items-center justify-center gap-3 rounded-xl py-2 px-3 transition-all duration-300`}
												>
													<p className="mr-auto w-11/12 font-montserrat font-bold capitalize text-white line-clamp-1">
														{element.replace(/([A-Z])/g, " $1").trim()}
													</p>
												</div>
											);
										})}
								</div>
							</div>
							<div className="flex max-h-[3rem] w-48 flex-row items-center justify-center gap-2 rounded-2xl bg-grey-1 py-2 pl-4 pr-2">
								<div className="">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className=" h-5 w-5 fill-current text-white"
										viewBox="0 0 24 24"
									>
										<path d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z" />
									</svg>
								</div>
								<input
									type="text"
									placeholder="Search by name"
									onChange={(e) =>
										setFilter({ ...filter, name: e.target.value })
									}
									className={`h-8 w-full appearance-none rounded-lg bg-grey-13 py-2 px-3 leading-tight text-gray-700 focus:border-transparent focus:outline-none`}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-x-6 gap-y-4 py-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{renderCards()}
				</div>
				<div className={`${filteredData?.length === 0 ? "hidden" : "flex"} sticky z-10 flex-wrap justify-center gap-5 pb-6 bottom-0 inset-x-0`}>
					<div className="pointer-events-auto flex flex-row rounded-xl bg-white shadow-lg">
						<div
							className={`${pagination.currentPage === 1 ? " opacity-70" : ""
								} group cursor-pointer select-none rounded-l-xl border-2 px-4 py-2 transition-colors duration-500 hover:bg-green-11`}
							onClick={() => {
								if (pagination.currentPage > 1) {
									setPagination({
										...pagination,
										firstItem: pagination.firstItem - pagination.itemsPerPage,
										lastItem: pagination.lastItem - pagination.itemsPerPage,
										currentPage: pagination.currentPage - 1,
									});
								}
							}}
						>
							<div className=" flex h-full items-center justify-center">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className=" h-4 w-4 fill-current text-grey-1 transition-colors duration-500 group-hover:text-white"
									viewBox="0 0 24 24"
								>
									<path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
								</svg>
							</div>
						</div>
						{[...Array(pagination.numberOfPages)].map((elem, index) => {
							return (
								<div
									onClick={() => {
										setPagination({
											...pagination,
											firstItem: index * pagination.itemsPerPage,
											lastItem: (index + 1) * pagination.itemsPerPage,
											currentPage: index + 1,
										});
									}}
									className={`${pagination.currentPage === index + 1
										? "bg-green-11 text-white"
										: "text-grey-1"
										} flex h-full cursor-pointer select-none items-center justify-center border-y-2 border-r-2 px-4 py-2 transition-all duration-500 hover:bg-green-11 hover:text-white`}
									key={`page-btn-${index}`}
								>
									<p className="font-poppins font-bold">{index + 1}</p>
								</div>
							);
						})}
						<div
							className={`${pagination.currentPage === pagination.numberOfPages
								? " opacity-70"
								: ""
								} group cursor-pointer select-none rounded-r-xl border-r-2 border-b-2 border-t-2 px-4 py-2 transition-colors duration-500 hover:bg-green-11`}
							onClick={() => {
								if (pagination.currentPage < pagination.numberOfPages) {
									setPagination({
										...pagination,
										firstItem: pagination.firstItem + pagination.itemsPerPage,
										lastItem: pagination.lastItem + pagination.itemsPerPage,
										currentPage: pagination.currentPage + 1,
									});
								}
							}}
						>
							<div className=" flex h-full rotate-180 transform items-center justify-center">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className=" h-4 w-4 fill-current text-grey-1 transition-colors duration-500 group-hover:text-white"
									viewBox="0 0 24 24"
								>
									<path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
								</svg>
							</div>
						</div>
					</div>
					<div
						className="relative hidden cursor-pointer rounded-xl border-2 bg-white px-3 py-2 lg:block"
						onClick={() => setShowSelect(!showSelect)}
					>
						<div className="flex flex-row items-center justify-center gap-2">
							<p className=" font-poppins font-bold text-grey-1">
								{pagination.itemsPerPage}
							</p>
							<div
								className={`transform transition-transform duration-500 ${showSelect ? "" : "rotate-180"
									}`}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="12"
									height="12"
									viewBox="0 0 24 24"
								>
									<path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
								</svg>
							</div>
						</div>
						<div
							className={`${showSelect
								? "translate-y-0 opacity-100"
								: "pointer-events-none -translate-y-10 opacity-0"
								} absolute -top-32 left-0 flex w-full transform flex-col transition-all duration-500`}
						>
							<div
								className="flex justify-center rounded-t-xl border-2 bg-white px-3 py-2 text-grey-1 transition-colors duration-500 hover:bg-green-11 hover:text-white"
								onClick={() =>
									setPagination({
										...pagination,
										itemsPerPage: 48,
										firstItem: 0,
										lastItem: 48,
										numberOfPages: Math.ceil(filteredData?.length / 48),
										currentPage: 1,
									})
								}
							>
								<p className="font-poppins font-bold">48</p>
							</div>
							<div
								className="flex justify-center border-r-2 border-l-2 bg-white px-3 py-2 text-grey-1 transition-colors duration-500 hover:bg-green-11 hover:text-white"
								onClick={() =>
									setPagination({
										...pagination,
										itemsPerPage: 24,
										firstItem: 0,
										lastItem: 24,
										numberOfPages: Math.ceil(filteredData?.length / 24),
										currentPage: 1,
									})
								}
							>
								<p className="font-poppins font-bold">24</p>
							</div>
							<div
								className="flex justify-center rounded-b-xl border-2 bg-white px-3 py-2 text-grey-1 transition-colors duration-500 hover:bg-green-11 hover:text-white"
								onClick={() =>
									setPagination({
										...pagination,
										itemsPerPage: 12,
										firstItem: 0,
										lastItem: 12,
										numberOfPages: Math.ceil(filteredData?.length / 12),
										currentPage: 1,
									})
								}
							>
								<p className="font-poppins font-bold">12</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
