import { Popover } from "antd";

export default function CaseStudyTestimonial({ data, setData }) {
	const changeTitle = (
		<div>
			<input
				placeholder="LogDMS in a nutshell"
				className="peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none"
				onChange={(e) =>
					setData({
						testimonial: {
							title: e.target.value,
							description: data.description,
							headling: data.headling,
							person: data.person,
						},
					})
				}
				value={data.title}
			/>
		</div>
	);

	const changeFirstLine = (
		<div>
			<input
				placeholder={`"Our business was born as a response to this on-going knowledge sharing.`}
				className="peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none"
				onChange={(e) =>
					setData({
						testimonial: {
							headling: e.target.value,
							title: data.title,
							description: data.description,
							person: data.person,
						},
					})
				}
				value={data.headling}
			/>
		</div>
	);

	const changeDescription = (
		<div>
			<textarea
				placeholder={`It all comes down to how we manage to articulate very <strong> technical aspects </strong> of what we do in a way that clients easily understand. Solutions and best-practice processes often come in handy right off the shelf, but when we <strong> customize </strong> or build from scratch, we feel it really makes a difference. <br /> It's <strong> rewarding </strong> not just for our clients, but for us too.”`}
				className="peer h-32 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none md:min-w-[30rem]"
				onChange={(e) =>
					setData({
						testimonial: {
							description: e.target.value,
							headling: data.headling,
							title: data.title,
							person: data.person,
						},
					})
				}
				value={data.description}
			/>
		</div>
	);

	const changePerson = (
		<div>
			<input
				placeholder="- Catalin, <strong> Co Founder & MarTech Consultant </strong>"
				className="peer h-12 w-full appearance-none rounded-xl border-2 py-2 px-3 font-raleway text-lg leading-tight transition-colors duration-500 focus:border-green-11 focus:outline-none"
				onChange={(e) =>
					setData({
						testimonial: {
							person: e.target.value,
							headling: data.headling,
							title: data.title,
							description: data.description,
						},
					})
				}
				value={data.person}
			/>
		</div>
	);

	return (
		<div className="flex flex-col gap-10">
			<div className="flex flex-row items-center gap-4">
				<svg
					className=" h-16 min-h-[3rem] w-16 min-w-[3rem] fill-current text-green-11"
					viewBox="0 0 24 24"
				>
					<path d="M18.757,16a5.214,5.214,0,0,0-3.707,1.535A15.14,15.14,0,0,1,4.272,22H3.122a1.121,1.121,0,0,1-.794-1.914l.693-.693a7.768,7.768,0,0,0,5.1-2.272L12.447,12.8a6.01,6.01,0,0,0,1.292.2Q13.868,13,14,13a6.018,6.018,0,0,0,4.246-1.758l1-1a6.01,6.01,0,0,0,1.751-4.5A5.946,5.946,0,0,0,18.848,1.4a6.179,6.179,0,0,0-8.274.544l-.633.634A6.33,6.33,0,0,0,8.247,8.511L3.879,12.879a7.759,7.759,0,0,0-2.272,5.1l-.693.693A3.121,3.121,0,0,0,3.122,24h1.15a17.13,17.13,0,0,0,12.192-5.05A3.243,3.243,0,0,1,22,21.242V23a1,1,0,0,0,2,0V21.242A5.249,5.249,0,0,0,18.757,16Zm-7.4-12.012.633-.634a4.413,4.413,0,0,1,3.1-1.3,3.815,3.815,0,0,1,2.471.877A3.971,3.971,0,0,1,19,5.826a4.013,4.013,0,0,1-1.167,3l-1,1a4,4,0,0,1-5.9-.266A4.169,4.169,0,0,1,11.355,3.988ZM5.293,14.293l3.836-3.837c.091.13.164.27.267.393a6.119,6.119,0,0,0,1.131,1.038l-3.82,3.82a5.789,5.789,0,0,1-3.018,1.6A5.789,5.789,0,0,1,5.293,14.293Z" />
				</svg>
				<Popover
					content={changeTitle}
					title="Title"
					trigger="click"
					placement="topLeft"
					className=" cursor-pointer"
				>
					<h1 className=" font-poppins text-3xl font-extrabold md:text-5xl">
						{data.title || (
							<span className="text-stone-400">"Duis aute irure dolor"</span>
						)}
					</h1>
				</Popover>
			</div>
			<div className="flex flex-col gap-5 md:flex-row md:gap-10">
				<div className="my-4 h-1 w-full bg-green-11 md:h-auto md:w-2">
					&nbsp;
				</div>
				<div>
					<div className=" max-w-[600px]">
						<Popover
							content={changeFirstLine}
							title="First Line"
							trigger="click"
							placement="topLeft"
							className=" cursor-pointer"
						>
							<h1 className=" font-poppins text-2xl font-extrabold text-grey-1 md:text-3xl">
								{data.headling || (
									<span className="text-stone-400">
										"Lorem ipsum dolor sit, consectetur adipiscing elit, sed do
										eiusmod."
									</span>
								)}
							</h1>
						</Popover>
					</div>
					<div className=" max-w-[800px]">
						<Popover
							content={changeDescription}
							title="Description"
							trigger="click"
							placement="topLeft"
							className=" cursor-pointer"
						>
							{data.description ? (
								<div
									className=" font-montserrat text-lg text-grey-1 md:text-xl"
									dangerouslySetInnerHTML={{ __html: data.description }}
								></div>
							) : (
								<p className=" font-montserrat text-lg text-stone-400 md:text-xl">
									It all comes down to how we manage to articulate very
									<strong> technical aspects </strong> of what we do in a way
									that clients easily understand. Solutions and best-practice
									processes often come in handy right off the shelf, but when we
									<strong> customize </strong> or build from scratch, we feel it
									really makes a difference. <br /> It's
									<strong> rewarding </strong> not just for our clients, but for
									us too.”
								</p>
							)}
						</Popover>
					</div>
					<Popover
						content={changePerson}
						title="Person"
						trigger="click"
						placement="topLeft"
						className=" cursor-pointer"
					>
						{data.person ? (
							<div
								className=" pt-5 font-montserrat text-lg text-grey-1"
								dangerouslySetInnerHTML={{ __html: data.person }}
							></div>
						) : (
							<p className=" pt-5 font-montserrat text-lg text-stone-400">
								- Catalin, <strong>Co Founder & MarTech Consultant</strong>
							</p>
						)}
					</Popover>
				</div>
			</div>
		</div>
	);
}
