import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Select, message } from "antd";
import UserContext from "../../context/context";
import axios from "axios";
import {DOMAIN} from "../../env";

export default function PingPongNewGame() {
	const history = useHistory();
	const { Option } = Select;
	const user = useContext(UserContext);
	const [usersList, setUsersList] = useState([]);
	const [players, setPlayers] = useState([null, null, null, null]);

	const [gameType, setGameType] = useState({
		value: null,
		error: false,
	});
	const [team1, setTeam1] = useState({
		player1: null,
		player2: null,
		error: false,
	});
	const [team2, setTeam2] = useState({
		player1: null,
		player2: null,
		error: false,
	});

	useEffect(() => {
		axios
			.get(`${DOMAIN}/api/user/getAllUsers`, {
				headers: {
					"jwt-token": user.jwtToken,
				},
			})
			.then((response) => {
				setUsersList(response.data.userList);
			})
			.catch((error) => {
				console.log(error.response.statusText);
			});
	}, [user]);

	useEffect(() => {
		setPlayers([team1.player1, team1.player2, team2.player1, team2.player2]);
	}, [team1, team2]);

	const handleTypeChange = (type) => {
		setGameType({ ...gameType, value: type });
	};

	const handleValidation = () => {
		let valid = true;
		if (gameType.value === 1) {
			if (team1.player1 === null) {
				valid = false;
				setTeam1({ ...team1, error: true });
			} else setTeam1({ ...team1, error: false });

			if (team2.player1 === null) {
				valid = false;
				setTeam2({ ...team2, error: true });
			} else setTeam2({ ...team2, error: false });
		}
		if (gameType.value === 2) {
			if (team1.player1 === null || team1.player2 === null) {
				valid = false;
				setTeam1({ ...team1, error: true });
			} else setTeam1({ ...team1, error: false });

			if (team2.player1 === null || team2.player2 === null) {
				valid = false;
				setTeam2({ ...team2, error: true });
			} else setTeam2({ ...team2, error: false });
		}
		return valid;
	};

	const handleSubmit = () => {
		const isValid = handleValidation();
		if (isValid) {
			let data = {};
			if (gameType.value === 1)
				data = {
					type: gameType.value,
					createdBy: user.user.user_id,
					team1: {
						player1: team1.player1,
					},
					team2: {
						player1: team2.player1,
					},
				};
			if (gameType.value === 2)
				data = {
					type: gameType.value,
					createdBy: user.user.user_id,
					team1: {
						player1: team1.player1,
						player2: team1.player2,
					},
					team2: {
						player1: team2.player1,
						player2: team2.player2,
					},
				};
			axios
				.post(`${DOMAIN}/api/pingpong/newGame`, data, {
					headers: {
						"jwt-token": user.jwtToken,
					},
				})
				.then((response) => {
					history.push(response.data.id);
				})
				.catch((error) => {
					message.error({
						content: `Network Error!`,
						className: "text-center",
						duration: 2,
					});
				});
		}
	};

	return (
		<div>
			<Helmet>
				<title>Logarithmic PingPong</title>
			</Helmet>
			<div className="flex flex-col lg:flex-row justify-between items-center bg-grey-1 px-4 py-5">
				<p className=" font-bold font-poppins text-xl md:text-2xl text-white text-center lg:text-left mb-0">
					New Game
				</p>
			</div>
			<div className="px-4 py-5">
				<div>
					<p className=" font-poppins text-grey-1 text-3xl font-bold text-center">
						Game Type
					</p>
					<div className=" flex flex-col lg:flex-row gap-5 justify-center pt-4 max-w-[400px] mx-auto">
						<div className="flex justify-center">
							<div
								onClick={() => handleTypeChange(1)}
								className={`${
									gameType.value === 1 ? "bg-green-11" : "bg-grey-4"
								} py-4 w-full min-w-[8rem] px-10 rounded-full shadow-lg transition-all duration-500 hover:bg-green-11 group cursor-pointer`}
							>
								<p
									className={`${
										gameType.value === 1
											? "text-white"
											: "text-grey-1 group-hover:text-white"
									} font-poppins font-bold text-4xl text-center tabular-nums transition-all duration-500`}
								>
									1 v 1
								</p>
							</div>
						</div>
						<div className="flex justify-center">
							<div
								onClick={() => handleTypeChange(2)}
								className={`${
									gameType.value === 2 ? "bg-green-11" : "bg-grey-4"
								} py-4 w-full min-w-[8rem] px-10 rounded-full shadow-lg transition-all duration-500 hover:bg-green-11 group cursor-pointer`}
							>
								<p
									className={`${
										gameType.value === 2
											? "text-white"
											: "text-grey-1 group-hover:text-white"
									} font-poppins font-bold text-4xl text-center tabular-nums transition-all duration-500`}
								>
									2 v 2
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`${
						gameType.value === null ? "hidden" : "block"
					} pt-10 max-w-[600px] mx-auto`}
				>
					<p className=" font-poppins text-grey-1 text-3xl font-bold text-center">
						Teams
					</p>
					<div className="flex flex-col md:flex-row justify-center gap-5 pt-4">
						<div className="w-full">
							<div className="bg-green-11 py-2 justify-center items-center flex rounded-t-2xl">
								<p className=" font-poppins text-white text-xl font-bold uppercase">
									Team 1
								</p>
							</div>
							<div className="bg-grey-4 py-4 px-4 rounded-b-2xl flex flex-col gap-4 shadow-lg">
								<Select
									showSearch
									className={`h-12 appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 border-green-11 focus:ring-green-11 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent cursor-pointer rounded-lg`}
									placeholder="Select player 1"
									getPopupContainer={(trigger) => trigger.parentElement}
									onChange={(value) => {
										setTeam1({ ...team1, player1: value });
									}}
									filterOption={(input, option) =>
										option?.children[1]
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
								>
									{usersList &&
										usersList.map((element, index) => {
											return (
												<Option
													key={`team1player1option${index}`}
													value={element._id}
													disabled={players.includes(element._id)}
												>
													{" "}
													{element.user_name}{" "}
												</Option>
											);
										})}
								</Select>
								<div className={`${gameType.value === 2 ? "block" : "hidden"}`}>
									<Select
										showSearch
										className={`h-12 appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 border-green-11 focus:ring-green-11 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent cursor-pointer rounded-lg`}
										placeholder="Select player 2"
										getPopupContainer={(trigger) => trigger.parentElement}
										onChange={(value) => {
											setTeam1({ ...team1, player2: value });
										}}
										filterOption={(input, option) =>
											option?.children[1]
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
									>
										{usersList &&
											usersList.map((element, index) => {
												return (
													<Option
														key={`team1player2option${index}`}
														value={element._id}
														disabled={players.includes(element._id)}
													>
														{" "}
														{element.user_name}{" "}
													</Option>
												);
											})}
									</Select>
								</div>
								<div
									className={`${
										team1.error === true ? "block" : "hidden"
									} transition-all duration-500`}
								>
									<p className=" font-raleway text-orange-1 font-bold text-center">
										Please complete team
									</p>
								</div>
							</div>
						</div>
						<div className="w-full">
							<div className="bg-green-11 py-2 justify-center items-center flex rounded-t-2xl">
								<p className=" font-poppins text-white text-xl font-bold uppercase">
									Team 2
								</p>
							</div>
							<div className="bg-grey-4 py-4 px-4 rounded-b-2xl flex flex-col gap-4 shadow-lg">
								<Select
									showSearch
									className={`h-12 appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 border-green-11 focus:ring-green-11 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent cursor-pointer rounded-lg`}
									placeholder="Select player 1"
									getPopupContainer={(trigger) => trigger.parentElement}
									onChange={(value) => {
										setTeam2({ ...team2, player1: value });
									}}
									filterOption={(input, option) =>
										option?.children[1]
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
								>
									{usersList &&
										usersList.map((element, index) => {
											return (
												<Option
													key={`team2player1option${index}`}
													value={element._id}
													disabled={players.includes(element._id)}
												>
													{" "}
													{element.user_name}{" "}
												</Option>
											);
										})}
								</Select>
								<div className={`${gameType.value === 2 ? "block" : "hidden"}`}>
									<Select
										showSearch
										className={`h-12 appearance-none bg-grey-13 w-full py-2 px-3 border-b-4 border-green-11 focus:ring-green-11 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:border-transparent cursor-pointer rounded-lg`}
										placeholder="Select player 2"
										getPopupContainer={(trigger) => trigger.parentElement}
										onChange={(value) => {
											setTeam2({ ...team2, player2: value });
										}}
										filterOption={(input, option) =>
											option?.children[1]
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
									>
										{usersList &&
											usersList.map((element, index) => {
												return (
													<Option
														key={`team2player2option${index}`}
														value={element._id}
														disabled={players.includes(element._id)}
													>
														{" "}
														{element.user_name}{" "}
													</Option>
												);
											})}
									</Select>
								</div>
								<div
									className={`${
										team2.error === true ? "block" : "hidden"
									} transition-all duration-500`}
								>
									<p className=" font-raleway text-orange-1 font-bold text-center">
										Please complete team
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`${
						gameType.value === null ? "hidden" : "block"
					} py-10 max-w-[600px] mx-auto`}
				>
					<div className="flex justify-center">
						<div
							onClick={() => handleSubmit()}
							className="bg-green-11 py-2 px-10 rounded-2xl shadow-xl cursor-pointer"
						>
							<p className=" font-poppins text-xl font-bold uppercase text-white">
								Start
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
